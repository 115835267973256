import React, { Component, ReactNode } from 'react';
import { IUser, IVehicle, IVehicleSetting } from '../../models';
import ImpersonationWarning from '../shared/ImpersonationWarning';
import PaneHeadline from '../shared/paneHeadline';
import './VehicleSettings.scss';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { locale } from '../../common/localization';
import VehicleSettingService from '../../services/vehicleSettingService';
import { Toastr } from '../../utils/Toastr';
import VehicleSettingsGrid from './VehicleSettingsGrid';
import VehicleSettingEditDialog from './VehicleSettingEditDialog';
import { Breadcrumbs } from '../shared/Breadcrumbs/Breadcrumbs';
import VehicleSettingCopyFromDialog from './VehicleSettingCopyFromDialog';
import { getVehicleById } from '../vehicles/vehicleUtils';

export interface IVehicleSettingsProps {
  match: any;
  impersonatedUser: any;
  authentication: IUser;
  vehiclesList: Array<IVehicle>;
  fetchVehicles: (ignoreCache?: boolean) => Promise<void>;
  stopImpersonation: () => void;
}

export interface IVehicleSettingsState {
  vehicleId: string;
  vehicle: IVehicle;
  vehicleSettings: Array<IVehicleSetting>;
  editDialog: {
    show: boolean;
    vehicleSetting: IVehicleSetting;
  };
  copyFromDialog: {
    show: boolean;
    isEnabled: boolean;
  };
}
export class VehicleSettings extends Component<
  IVehicleSettingsProps,
  IVehicleSettingsState
> {
  public async componentDidMount(): Promise<void> {
    if (
      !this.props.impersonatedUser &&
      (this.props.authentication.isAdmin ||
        this.props.authentication.isLocalAdmin)
    ) {
      this.loadVehicleSettings(this.state.vehicleId, true);
      await this.props.fetchVehicles(false);
      this.setState({
        ...this.state,
        vehicle: getVehicleById(this.props.vehiclesList, this.state.vehicleId),
      });
    }
  }

  private stopImpersonation = () => {
    this.props.stopImpersonation();
    this.loadVehicleSettings(this.state.vehicleId);
  };

  private loadVehicleSettings = async (
    vehicleId: string,
    ignoreCache = false
  ): Promise<void> => {
    const vehicleSettings = await this.getVehicleSettings(
      vehicleId,
      ignoreCache
    );
    this.setState({
      ...this.state,
      vehicleSettings,
      copyFromDialog: { ...this.state.copyFromDialog, isEnabled: true },
    });
  };

  private getVehicleSettings = async (
    vehicleId: string,
    ignoreCache = false
  ) => {
    try {
      const response = await VehicleSettingService.getVehicleSettings(
        vehicleId,
        ignoreCache
      );
      return response;
    } catch (error: any) {
      if (error?.message) {
        console.error(error?.message);
        Toastr.error(error?.message);
      }
    }
  };

  private hideVehicleSettingEditDialog(rerender = false): void {
    this.setState({
      ...this.state,
      editDialog: { show: false, vehicleSetting: null },
    });
    if (rerender) {
      this.loadVehicleSettings(this.state.vehicleId, true);
    }
  }

  private hideVehicleSettingCopyFromDialog(rerender = false): void {
    this.setState({
      ...this.state,
      copyFromDialog: { ...this.state.copyFromDialog, show: false },
    });
    if (rerender) {
      this.loadVehicleSettings(this.state.vehicleId, true);
    }
  }

  private editVehicleSetting(vehicleSetting: IVehicleSetting) {
    this.openVehicleSettingEditDialog(vehicleSetting);
  }

  private async deleteVehicleSetting(vehicleSetting: IVehicleSetting) {
    if (
      confirm(`${locale.vehicleSettings._deleteConfirm}: ${vehicleSetting.key}`)
    ) {
      try {
        await VehicleSettingService.deleteVehicleSettings(
          this.state.vehicleId,
          vehicleSetting.key
        );
        this.loadVehicleSettings(this.state.vehicleId, true); // refetch after successful deletion
        Toastr.success(locale.vehicleSettings._deleteMessageSuccessful);
      } catch (error) {
        console.log(error);
        Toastr.error(locale.vehicleSettings._deleteMessageFailed, true);
      }
    }
  }

  private openVehicleSettingEditDialog(vehicleSetting: IVehicleSetting) {
    this.setState({
      ...this.state,
      editDialog: { show: true, vehicleSetting },
    });
  }

  private openVehicleSettingCopyFromDialog() {
    this.setState({
      ...this.state,
      copyFromDialog: {
        ...this.state.copyFromDialog,
        show: true,
      },
    });
  }

  public readonly state: IVehicleSettingsState = {
    vehicleId: this.props.match.params.vehicleId,
    vehicle: null,
    vehicleSettings: [],
    editDialog: {
      show: false,
      vehicleSetting: null,
    },
    copyFromDialog: {
      show: false,
      isEnabled: false,
    },
  } as IVehicleSettingsState;

  public render(): ReactNode {
    const { impersonatedUser } = this.props;
    if (impersonatedUser) {
      return (
        <>
          <PaneHeadline
            titleText={locale.general._vehicleSettings}
            titleIcon={faCog}
          />
          <ImpersonationWarning
            impersonatedUserName={impersonatedUser}
            stopImpersonation={this.stopImpersonation}
          />
        </>
      );
    }
    return (
      <div className="vehicle-settings">
        <PaneHeadline
          titleText={locale.general._vehicleSettings}
          titleIcon={faCog}
        />
        <Breadcrumbs className="mb-3" />
        {!impersonatedUser && (
          <>
            <div className="row">
              <div className="col-sm-4">
                <h2>
                  {locale.vehicleSettings._vehicle}: {this.state.vehicleId}
                </h2>
              </div>
              <div className="col-sm-8 text-right">
                {this.state.vehicleSettings.length === 0 &&
                  this.state.copyFromDialog.isEnabled && (
                    <button
                      className="btn btn-outline-dark mb-3 mr-1"
                      onClick={() => this.openVehicleSettingCopyFromDialog()}
                    >
                      {locale.vehicleSettings._copyFromVehicleButton}
                    </button>
                  )}
                <button
                  className="btn btn-outline-dark mb-3"
                  onClick={() => this.openVehicleSettingEditDialog(null)}
                >
                  {locale.vehicleSettings._addNewSetting}
                </button>
              </div>
            </div>
            <div className="d-flex">
              <VehicleSettingsGrid
                data={this.state.vehicleSettings}
                editVehicleSetting={this.editVehicleSetting.bind(this)}
                deleteVehicleSetting={this.deleteVehicleSetting.bind(this)}
                className="mb-3"
              />
            </div>
          </>
        )}
        {this.state.editDialog.show && (
          <VehicleSettingEditDialog
            show={this.state.editDialog.show}
            vehicleSetting={this.state.editDialog.vehicleSetting}
            vehicleSettingIdsExisting={this.state.vehicleSettings.map(
              (vs: IVehicleSetting) => vs.key.toLocaleLowerCase()
            )}
            targetVehicle={this.state.vehicle}
            onHide={this.hideVehicleSettingEditDialog.bind(this)}
          />
        )}
        {this.state.copyFromDialog.show && (
          <VehicleSettingCopyFromDialog
            show={this.state.copyFromDialog.show}
            onHide={this.hideVehicleSettingCopyFromDialog.bind(this)}
            targetVehicle={this.state.vehicle}
          />
        )}
      </div>
    );
  }
}

export default VehicleSettings;
