import { isNaN, isNumber } from 'lodash';
import React, { Component, FormEvent, ReactNode, RefObject } from 'react';
import { Button, FormControl, Modal, Table } from 'react-bootstrap';
import { InstanceProps } from 'react-modal-promise';
import { locale } from '../../common/localization';
import { Spinner2 } from '../shared/spinner2';
import {
  IRoutePoi,
  IChangeSequenceList,
  IChangeSequenceBody,
} from '../../models';
import { AsyncAction, SyncAction } from '../../utils';

import './SequenceModal.scss';
import { Toastr } from '../../utils/Toastr';

const { Header, Title, Body, Footer } = Modal;

export interface ISequenceModalProps extends InstanceProps<Array<IRoutePoi>> {
  poi: IRoutePoi;
  isLoading: boolean;
  isAsyncValidated: boolean;
  validationsResults: Array<IChangeSequenceList>;
  reset: () => SyncAction;
  onValidate: (result: IChangeSequenceBody) => AsyncAction;
  onSubmit: (results: IChangeSequenceBody) => AsyncAction;
}
export interface ISequenceModalState {
  isValid: boolean;
  isDirty: boolean;
  poi: IRoutePoi;
  fieldErrors: Set<string>;
  isAsyncValidated: boolean;
}

export class SequenceModal extends Component<
  ISequenceModalProps,
  ISequenceModalState
> {
  public readonly state: ISequenceModalState = {
    isValid: false,
    isDirty: false,
    poi: {} as IRoutePoi,
    fieldErrors: new Set<string>(),
    isAsyncValidated: this.props.isAsyncValidated,
  };

  private inputRef: RefObject<any> = React.createRef();

  public componentDidMount(): void {
    this.componentDidUpdate();
  }

  public componentDidUpdate(prevProps?: ISequenceModalProps): void {
    if (prevProps?.isOpen === this.props.isOpen) return;
    if (!this.props.isOpen) return;
    this.setState({
      ...this.state,
      poi: { ...this.props.poi },
      fieldErrors: this.state.fieldErrors.add('sequence'),
    });
    setTimeout(() => {
      this.inputRef.current.focus();
    });
  }

  public onCancel(): void {
    if (this.props.isLoading) return;
    this.props.reset();
    this.props.onReject();
  }

  public async onSubmit(event: FormEvent<HTMLFormElement>): Promise<void> {
    event.preventDefault();
    if (!this.state.isAsyncValidated) return this.onValidate();
    else {
      await this.onUpload();
      this.props.reset();
      this.props.onResolve();
      Toastr.success(locale.routeAdminPage._sequenceSuccessfullyChanged);
    }
  }

  public async onValidate(): Promise<void> {
    try {
      await this.validate();
      if (!this.state.isValid) throw new Error('Invalid form');
      await this.props.onValidate(this.getResult());
      this.setState({ ...this.state, isAsyncValidated: true });
    } catch (error) {
      console.error(error);
    }
  }

  public async onUpload(): Promise<void> {
    const poi = this.state.poi;
    const result: IChangeSequenceBody = {
      routeId: poi.route,
      poId: poi.poiId,
      routeLineId: poi.routeLineId,
      sequenceNumber: poi.sequence,
    };
    try {
      await this.props.onSubmit(result);
    } catch (error) {
      console.error(error);
    }
  }

  public validate(): void {
    const fieldErrors = new Set<string>();

    const poi = this.state.poi;

    const sameAsOrigin = poi.sequence === this.props.poi.sequence;

    if (
      !isNumber(poi.sequence) ||
      isNaN(poi.sequence) ||
      poi.sequence < -1 ||
      poi.sequence === 0 ||
      sameAsOrigin
    )
      fieldErrors.add('sequence');

    this.setState({
      ...this.state,
      fieldErrors,
      isValid: fieldErrors.size === 0,
      isAsyncValidated: false,
    });
  }

  public getResult(): IChangeSequenceBody {
    const poi = this.state.poi;
    const result: IChangeSequenceBody = {
      routeId: poi.route,
      poId: poi.poiId,
      routeLineId: poi.routeLineId,
      sequenceNumber: poi.sequence,
    };
    return result;
  }

  public onChange(value: string): void {
    const poi = { ...this.state.poi, sequence: parseFloat(value) || 0 };

    this.setState(
      {
        ...this.state,
        poi,
      },
      () => this.validate()
    );
  }

  public render(): ReactNode {
    const { poi } = this.state;
    const { isOpen, isLoading } = this.props;
    const validations = this.props.validationsResults || [];
    return (
      <Modal
        autoFocus={false}
        className="sequence-modal"
        show={isOpen}
        onHide={() => this.onCancel()}
      >
        <Spinner2 show={isLoading} />
        <form onSubmit={(e) => this.onSubmit(e)}>
          <Header>
            <Title>{locale.routeAdminPage._changeSequence}</Title>
          </Header>

          <Body>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>{locale.routeAdminPage._estate}</th>
                  <th>{locale.routeAdminPage._routeLineId}</th>
                  <th>{locale.routeAdminPage._sequence}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{poi.estate}</td>
                  <td>{poi.routeLineId}</td>
                  <td>
                    <FormControl
                      ref={this.inputRef}
                      aria-label="Default"
                      aria-describedby="inputGroup-sizing-default"
                      type="number"
                      min="-1"
                      className="form-control"
                      defaultValue={poi.sequence}
                      onChange={(e: any) => this.onChange(e.target.value)}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>

            <div
              hidden={!this.state.fieldErrors.has('sequence')}
              className="alert alert-danger mt-3 mb-0"
              role="alert"
            >
              {locale.routeAdminPage._invalidSequence}
            </div>

            <div
              hidden={!this.props.isAsyncValidated}
              className="alert alert-info mt-3 mb-0"
              role="alert"
            >
              <strong>{locale.routeAdminPage._changesHeader}</strong>
              <ul>
                {validations.map((result, index) => (
                  <li key={index}>
                    <strong>{result.poi.estate}</strong>
                    <span>{` ${locale.routeAdminPage._changedFrom} ${result.oldSequenceValue}`}</span>
                    <span>{` ${locale.routeAdminPage._changedTo} ${result.newSequenceValue}`}</span>
                  </li>
                ))}
              </ul>
            </div>
          </Body>

          <Footer>
            <Button
              variant="secondary"
              onClick={() => this.onCancel()}
              disabled={isLoading}
            >
              {locale.routeAdminPage._cancelButton}
            </Button>
            <Button
              disabled={!this.state.isValid || isLoading}
              variant="primary"
              type="submit"
            >
              {this.state.isAsyncValidated
                ? locale.routeAdminPage._submitButton
                : locale.routeAdminPage._validateButton}
            </Button>
          </Footer>
        </form>
      </Modal>
    );
  }
}
