import React from 'react';
import PropTypes from 'prop-types';
import { locale } from '../../common/localization';

export default function OrderComment({ orderDetails }) {
  if (!orderDetails || !orderDetails.comment) {
    return null;
  }

  return (
    <div className="card mb-3">
      <div className="card-header">
        <h3>{locale.routeStopDetails._comment}</h3>
      </div>
      <div className="card-body">
        <p className="card-text">{orderDetails.comment}</p>
      </div>
    </div>
  );
}

OrderComment.propTypes = {
  orderDetails: PropTypes.object,
};
