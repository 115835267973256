import depotConstants from './depotConstants';

const initialState = {
  depots: [],
}

export default function depotsReducer(state = initialState, action) {
  switch (action.type) {
    case depotConstants.GET_DEPOTS_SUCCESS:
      return {
        depots: action.depots,
      };
    case depotConstants.GET_DEPOTS_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}
