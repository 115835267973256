import { uniq as _uniq } from 'lodash';
import { RouteEditMode, IRowData, IRowMap } from '../../../models';
import { All as Actions } from './routeActions';
import { RouteConstants } from './routeConstants';
import { IRouteState } from './routeState';

const initialState: IRouteState = {
  routeList: [],
  poiList: [],
  selectedRouteIds: [],
  markedRouteSequences: [],
  errorMessage: null,
  isRouteListLoading: false,
  isPoiListLoading: false,
  isValidateSequenceLoading: false,
  isChangeSequenceLoading: false,
  validationResult: [],
  isChangePositionLoading: false,
  editMode: RouteEditMode.Default,
  rowData: IRowMap.Empty(),
  gridState: {
    group: [{ field: 'routeName' }, { field: 'description' }],
    skip: 0,
    take: 500,
    sort: [{ field: 'name', dir: 'asc' }],
  },
};

export function route(state = initialState, action: Actions): IRouteState {
  switch (action.type) {
    case RouteConstants.ROUTE_LIST_REQUEST: {
      return {
        ...state,
        isRouteListLoading: true,
      };
    }
    case RouteConstants.ROUTE_LIST_SUCCESS: {
      return {
        ...state,
        isRouteListLoading: false,
        routeList: action.payload,
      };
    }
    case RouteConstants.ROUTE_LIST_FAILURE: {
      return {
        ...state,
        isRouteListLoading: false,
        errorMessage: action.error.message,
      };
    }
    case RouteConstants.ROUTE_AGREEMENT_LINE_REQUEST: {
      return {
        ...state,
        selectedRouteIds: action.payload,
        isPoiListLoading: true,
      };
    }
    case RouteConstants.ROUTE_AGREEMENT_LINE_SUCCESS: {
      const rowData = IRowMap.persistCollapseState(
        state.rowData,
        state.gridState,
        action.payload
      );

      return {
        ...state,
        isPoiListLoading: false,
        poiList: action.payload,
        rowData,
        gridState: { ...state.gridState, skip: 0 },
        markedRouteSequences: _uniq(
          action.payload
            .filter((poi) => state.markedRouteSequences.includes(poi.id))
            .map((poi) => poi.id)
        ),
      };
    }
    case RouteConstants.ROUTE_AGREEMENT_LINE_FAILURE: {
      return {
        ...state,
        isPoiListLoading: false,
        errorMessage: action.error.message,
      };
    }
    case RouteConstants.CHANGE_POSITION_REQUEST: {
      return {
        ...state,
        isChangePositionLoading: true,
      };
    }
    case RouteConstants.CHANGE_POSITION_SUCCESS: {
      return {
        ...state,
        isChangePositionLoading: false,
      };
    }
    case RouteConstants.CHANGE_POSITION_FAILURE: {
      return {
        ...state,
        isChangePositionLoading: false,
        errorMessage: action.error.message,
      };
    }
    case RouteConstants.VALIDATE_SEQUENCE_REQUEST: {
      return {
        ...state,
        isValidateSequenceLoading: true,
      };
    }
    case RouteConstants.VALIDATE_SEQUENCE_SUCCESS: {
      return {
        ...state,
        validationResult: action.payload,
        isValidateSequenceLoading: false,
      };
    }
    case RouteConstants.VALIDATE_SEQUENCE_FAILURE: {
      return {
        ...state,
        isValidateSequenceLoading: false,
        errorMessage: action.error.message,
      };
    }
    case RouteConstants.VALIDATE_SEQUENCE_CLEAR: {
      return {
        ...state,
        validationResult: [],
      };
    }
    case RouteConstants.CHANGE_SEQUENCE_REQUEST: {
      return {
        ...state,
        isChangeSequenceLoading: true,
      };
    }
    case RouteConstants.CHANGE_SEQUENCE_SUCCESS: {
      return {
        ...state,
        isChangeSequenceLoading: false,
      };
    }
    case RouteConstants.CHANGE_SEQUENCE_FAILURE: {
      return {
        ...state,
        isChangeSequenceLoading: false,
        errorMessage: action.error.message,
      };
    }
    case RouteConstants.ROUTE_SEQUENCE_MOVE: {
      const poiList = [...state.poiList];
      for (const { id, coords } of action.payload) {
        for (let index = 0; index < state.poiList.length; ++index) {
          const poi = state.poiList[index];
          if (poi.id !== id) continue;
          poiList[index].degLat = coords[0];
          poiList[index].degLong = coords[1];
        }
      }

      return { ...state, poiList };
    }
    case RouteConstants.ROUTE_SEQUENCE_MARK: {
      const markedRouteSequences = [...state.markedRouteSequences];
      for (const id of action.payload) {
        const index = markedRouteSequences.indexOf(id);
        if (index > -1) markedRouteSequences.splice(index, 1);
        else markedRouteSequences.push(id);
      }

      return {
        ...state,
        markedRouteSequences,
      };
    }
    case RouteConstants.ROUTE_SET_EDIT_MODE: {
      return {
        ...state,
        editMode: action.payload,
      };
    }
    case RouteConstants.SET_GRID_STATE: {
      const gridState = action.payload;
      const rowData = IRowMap.persistCollapseState(
        state.rowData,
        gridState,
        state.poiList
      );

      return {
        ...state,
        gridState,
        rowData,
      };
    }
    case RouteConstants.TOGGLE_ROUTE_COLLAPSE: {
      const rowData = action.payload.reduce(
        (memo, id) => {
          memo[IRowData.hash(id)] = id;
          return memo;
        },
        { ...state.rowData }
      );

      return {
        ...state,
        rowData,
      };
    }
    case RouteConstants.RESET_STATE: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
}
