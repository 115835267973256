import { All as Actions } from './trackInfoActions';
import {
  TrackInfoConstants,
  TrackInfoLineConstants,
} from './trackInfoConstants';
import { ITrackInfoState } from './trackInfoState';

const initialState: ITrackInfoState = {
  trackInfoList: [],
  trackInfoLines: {},
  errorMessage: null,
  isTrackInfoListLoading: false,
};

export function trackInfo(
  state = initialState,
  action: Actions
): ITrackInfoState {
  switch (action.type) {
    case TrackInfoConstants.GET_TRACK_INFO_REQUEST: {
      return {
        ...state,
        isTrackInfoListLoading: true,
      };
    }
    case TrackInfoConstants.GET_TRACK_INFO_SUCCESS: {
      return {
        ...state,
        isTrackInfoListLoading: false,
        trackInfoList: action.payload,
      };
    }
    case TrackInfoConstants.GET_TRACK_INFO_FAILURE: {
      return {
        ...state,
        isTrackInfoListLoading: false,
        errorMessage: action.error.message,
      };
    }
    case TrackInfoLineConstants.GET_TRACK_INFO_LINES_REQUEST: {
      return {
        ...state,
        isTrackInfoListLoading: true,
      };
    }
    case TrackInfoLineConstants.GET_TRACK_INFO_LINES_SUCCESS: {
      return {
        ...state,
        isTrackInfoListLoading: false,
        trackInfoLines: action.payload,
      };
    }
    case TrackInfoLineConstants.GET_TRACK_INFO_LINES_FAILURE: {
      return {
        ...state,
        isTrackInfoListLoading: false,
        errorMessage: action.error.message,
      };
    }
    case TrackInfoConstants.RESET_STATE: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
}
