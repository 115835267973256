import wasteManagementClient from './wasteManagementClient';
import { wasteManagementUrls } from '../appConfig';

export default class UsersService {
  static getUsers(customerId, includeModules = false) {
    return wasteManagementClient.get(wasteManagementUrls.users, {
      params: {
        customerId,
        includeModules,
      },
      cache: { ignoreCache: true },
    }).then(response => {
      if (response.data && response.status === 200) {
        return response.data;
      } else {
        return [];
      }
    }).catch(error => {
      console.error(error);
      throw error;
    });
  }

  static createUser(user) {
    return wasteManagementClient.post(wasteManagementUrls.users, user)
      .then(response => {
        if (response.data && response.status === 200) {
          return response.data;
        } else {
          return null;
        }
      }).catch(error => {
        console.error(error);
        throw error;
      });
  }

  static updateUser(user) {
    return wasteManagementClient.put(wasteManagementUrls.users, user)
      .then(response => {
        if (response.data && response.status === 200) {
          return response.data;
        } else {
          return null;
        }
      }).catch(error => {
        console.error(error);
        throw error;
      });
  }
}
