import axios from 'axios';
import { serviceUrls } from './../appConfig';

const containerHttpClient = axios.create({
  baseURL: serviceUrls.baseUrl,
  headers: { 'Content-Type': 'application/json' },
});

export function setAuthToken(token) {
  containerHttpClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

export function resetAuthToken() {
  delete containerHttpClient.defaults.headers.common['Authorization'];
}

export default containerHttpClient;
