import React from 'react';
import { locale } from '../../common/localization';
import PropTypes from 'prop-types';

function ShowReportButton({ onClick }) {
  return (
    <button
      onClick={onClick}
      className="btn btn-outline-dark"
    >
      {locale.reports._showReport}
    </button>
  );
}

ShowReportButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default ShowReportButton;
