import * as L from 'leaflet';
import { mapSettings } from './appConfig';
import { MapType } from './models';

const streetLayerUrls = {
  WGS84:
    'https://services.arcgisonline.com/arcgis/rest/services/World_Topo_Map/MapServer',
  UTM33N:
    'https://services.geodataonline.no/arcgis/rest/services/Geocache_UTM33_WGS84/GeocacheBasis/MapServer',
};

export const LeafletMapSettings = {
  center: {
    lat: 63.44,
    lng: 10.42,
    zoom: 4,
  },
  projection: {
    code: 'EPSG:32633',
    proj4def: '+proj=utm +zone=33 +ellps=WGS84 +datum=WGS84 +units=m +no_defs',
    options: {
      origin: [-2500000.0, 9045984.0],
      resolutions: [
        21674.7100160867, 10837.35500804335, 5418.677504021675,
        2709.3387520108377, 1354.6693760054188, 677.3346880027094,
        338.6673440013547, 169.33367200067735, 84.66683600033868,
        42.33341800016934, 21.16670900008467, 10.583354500042335,
        5.291677250021167, 2.6458386250105836, 1.3229193125052918,
        0.6614596562526459, 0.33072982812632296, 0.16536491406316148,
      ],
      tranformation: new L.Transformation(1, 2500000, -1, 9045984),
    },
  },
  streetLayerSetting: {
    url:
      mapSettings.mapType === MapType.WGS84
        ? streetLayerUrls.WGS84
        : streetLayerUrls.UTM33N,
    detectRetina: false,
    minZoom: 4,
    maxZoom: 17,
    opacity: 1,
  },
  satelliteLayerSetting: {
    url: 'https://services.geodataonline.no/arcgis/rest/services/Geocache_UTM33_WGS84/GeocacheBilder/MapServer',
    detectRetina: false,
    minZoom: 4,
    maxZoom: 17,
    opacity: 0,
  },
  propertyLayerSetting: {
    url: 'https://services.geodataonline.no/arcgis/rest/services/Geomap_UTM33_EUREF89/GeomapEiendom2/MapServer',
    detectRetina: false,
    minZoom: 4,
    maxZoom: 17,
    opacity: 0,
    token: '',
  },
  clustering: {
    maxZoom: 13,
  },
  routeStopSelectionZoomLevel: 17,
};
