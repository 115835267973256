import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import UserAdminGrid from './UserAdminGrid';
import CustomerSelector from './CustomerSelector';
import { locale } from '../../common/localization';
import './user-admin.scss';
import UserEditDialog from './UserEditDialog';
import Spinner from '../shared/spinner/Spinner';
import { isEqual } from 'lodash';
import ImpersonationWarning from '../shared/ImpersonationWarning';

class UserAdmin extends PureComponent {
  constructor(props) {
    super(props);

    this.handleCustomerChanged = this.handleCustomerChanged.bind(this);
    this.openUserEditDialog = this.openUserEditDialog.bind(this);
    this.createNewUser = this.createNewUser.bind(this);
    this.hideUserEditDialog = this.hideUserEditDialog.bind(this);
    this.saveUser = this.saveUser.bind(this);
    this.stopImpersonation = this.stopImpersonation.bind(this);

    this.state = {
      selectedCustomerId: props.loggedInCustomerId,
      userEditDialogOptions: { show: false },
    };
  }

  static propTypes = {
    userActions: PropTypes.object.isRequired,
    users: PropTypes.object.isRequired,
    customerActions: PropTypes.object.isRequired,
    customers: PropTypes.array.isRequired,
    moduleActions: PropTypes.object.isRequired,
    modules: PropTypes.object.isRequired,
    contractorActions: PropTypes.object.isRequired,
    contractors: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    loggedInCustomerId: PropTypes.number.isRequired,
    impersonatedUser: PropTypes.string,
    vehicleTrackingActions: PropTypes.object.isRequired,
    isVehiclesLoading: PropTypes.bool.isRequired,
    settingsActions: PropTypes.object.isRequired,
    authActions: PropTypes.object.isRequired,
  };

  componentDidMount() {
    if (!this.props.impersonatedUser) {
      this.props.customerActions.getCustomers();
      this.props.userActions.getUsers(this.state.selectedCustomerId, true);
      this.props.contractorActions.getContractors(
        this.state.selectedCustomerId
      );
      this.props.moduleActions.getModules(this.state.selectedCustomerId);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      !this.props.impersonatedUser &&
      !isEqual(prevProps.impersonatedUser, this.props.impersonatedUser)
    ) {
      this.props.vehicleTrackingActions.getVehicles();
      this.props.customerActions.getCustomers();
      this.props.userActions.getUsers(this.state.selectedCustomerId, true);
      this.props.contractorActions.getContractors(
        this.state.selectedCustomerId
      );
      this.props.moduleActions.getModules(this.state.selectedCustomerId);
    }
  }

  handleCustomerChanged(option, event) {
    if (event.action === 'select-option' && option.value) {
      this.setSelectedCustomer(option.value);
    }
  }

  setSelectedCustomer(customerId) {
    this.setState({ selectedCustomerId: customerId });
    this.props.userActions.getUsers(customerId, true);
    this.props.moduleActions.getModules(customerId);
    this.props.contractorActions.getContractors(customerId);
  }

  openUserEditDialog(user) {
    this.setState({
      userEditDialogOptions: {
        show: true,
        isNew: !user,
        user: {
          ...user,
          customerId: this.state.selectedCustomerId,
        },
      },
    });
  }

  createNewUser() {
    this.openUserEditDialog();
  }

  saveUser(user, isNew) {
    const { userActions } = this.props;
    this.setState({ userEditDialogOptions: { show: false } });

    if (isNew) {
      userActions.createUser(user);
    } else {
      userActions.updateUser(user);
    }
  }

  stopImpersonation() {
    const { authActions, settingsActions } = this.props;

    authActions.resetImpersonatedUser();
    settingsActions.getDataButtons();
    settingsActions.getVisibleFields();
    settingsActions.getCompanySettings();
  }

  hideUserEditDialog() {
    this.setState({ userEditDialogOptions: { show: false } });
  }

  render() {
    const {
      users,
      customers,
      modules,
      contractors,
      isLoading,
      isVehiclesLoading,
      impersonatedUser,
    } = this.props;
    const { selectedCustomerId } = this.state;

    const customerContractors = contractors[selectedCustomerId] || [];
    const customerModules = modules[selectedCustomerId] || [];
    const customerUsers = users[selectedCustomerId] || [];

    if (!impersonatedUser) {
      return (
        <div className="user-admin">
          {isVehiclesLoading && <Spinner />}
          <div className="row mb-3">
            <div className="col-8">
              <CustomerSelector
                customers={customers}
                handleChange={this.handleCustomerChanged}
                selectedCustomerId={selectedCustomerId}
              />
            </div>
            <div className="col-4 new-user-button-col">
              <button
                className="btn btn-outline-dark"
                onClick={this.createNewUser}
              >
                {locale.userAdmin._newUser}
              </button>
            </div>
          </div>
          <div className="user-admin-grid-wrapper">
            {isLoading && <Spinner />}
            <UserAdminGrid
              users={customerUsers}
              startEditingUser={this.openUserEditDialog}
              contractors={customerContractors}
            />
          </div>

          <UserEditDialog
            {...this.state.userEditDialogOptions}
            save={this.saveUser}
            onHide={this.hideUserEditDialog}
            modules={customerModules}
            contractors={customerContractors}
          />
        </div>
      );
    } else {
      return (
        <ImpersonationWarning
          impersonatedUserName={impersonatedUser}
          stopImpersonation={this.stopImpersonation}
        />
      );
    }
  }
}

export default UserAdmin;
