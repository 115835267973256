import { parseIntMultipleLocale } from "./mathUtility";

const defaultDataButtons = [
  {
    parameter: 'Databutton1',
    tag: 'Weight',
    text: 'Vekt',
  },
  {
    parameter: 'Databutton2',
    tag: 'Extrabag',
    text: 'Ekstra sekk',
  },
  {
    parameter: 'Databutton3',
    tag: 'Fill',
    text: 'Fyll (%)',
  },
  {
    parameter: 'Databutton4',
    tag: 'Sort',
    text: 'Sort',
  },
  {
    parameter: 'Databutton5',
    tag: 'Extrawaste',
    text: 'Ekstra avfall',
  }
];

function getTagsAndTexts(userDataButtons) {
  if (!userDataButtons) {
    return defaultDataButtons;
  }

  return defaultDataButtons.map((dataButton) => {
    const userDataButton = userDataButtons
      .find((btn) => btn.parameter === dataButton.parameter);

    if (userDataButton) {
      return {
        tag: dataButton.tag,
        text: userDataButton.value_NO,
      };
    }

    return dataButton;
  });
}

export function getDataButtonValues(logData, userDataButtons) {
  const tagsAndTexts = getTagsAndTexts(userDataButtons);
  const result = [];

  for (const { tag, text } of tagsAndTexts) {
    let value = logData[tag];
    if (value) {
      if (typeof value !== 'number') {
        value = parseIntMultipleLocale(value);
      }
      result.push({ tag, text, value });
    }
  }

  return result;
}
