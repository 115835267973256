import depotsConstants from './depotConstants';
import loadingActions from '../loading/loadingActions';
import DepotsService from '../../../services/DepotsService';

function getDepots(ignoreCache = false, silent = false) {
  return dispatch => {
    if (!silent) {
      dispatch(loadingActions.addLoadingInProgress(depotsConstants.GET_DEPOTS));
    }

    return DepotsService.getDepots(ignoreCache)
      .then(
        (depots) => dispatch(success(depots)),
        (error) => dispatch(failure(JSON.stringify(error))),
      ).finally(() => {
        dispatch(loadingActions.removeLoadingInProgress(depotsConstants.GET_DEPOTS));
      });
  }

  function success(depots) {
    return { type: depotsConstants.GET_DEPOTS_SUCCESS, depots };
  }

  function failure(error) {
    return { type: depotsConstants.GET_DEPOTS_FAILURE, error }
  }
}

export default {
  getDepots,
}
