import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Popup } from 'react-leaflet';
import './popup-tooltip.scss';

class PopupTooltip extends Component {
  static propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.popupOpen = this.popupOpen.bind(this);
    this.popupClosed = this.popupClosed.bind(this);
  }

  popupOpen() {
    const tooltipNode = this.tooltip.leafletElement.getElement();
    if (!tooltipNode) return;
    tooltipNode.classList.add('tooltip-in-background');
  }

  popupClosed() {
    const tooltipNode = this.tooltip.leafletElement.getElement();
    if (!tooltipNode) return;
    tooltipNode.classList.remove('tooltip-in-background');
  }

  render() {
    const { children, className } = this.props;

    return (
      <>
        <Tooltip
          className={`popup-tooltip leaflet-popup ${className}`}
          direction="top"
          opacity=""
          ref={(ref) => (this.tooltip = ref)}
          maxWidth={400}
        >
          <div className="leaflet-popup-content-wrapper">
            <div className="leaflet-popup-content">{children}</div>
            <div className="leaflet-popup-tip-container">
              <div className="leaflet-popup-tip"></div>
            </div>
          </div>
        </Tooltip>
        <Popup
          className={`popup-tooltip ${className}`}
          onOpen={this.popupOpen}
          onClose={this.popupClosed}
        >
          {children}
        </Popup>
      </>
    );
  }
}

export default PopupTooltip;
