import React, { Component, ReactNode } from 'react';
import { Dispatch } from 'redux';
import {
  Route,
  Switch,
  Redirect,
  Router,
  RouteComponentProps,
} from 'react-router-dom';
import {
  IntlProvider,
  LocalizationProvider,
  load,
  loadMessages,
} from '@progress/kendo-react-intl';
import ModalContainer from 'react-modal-promise';
import Login from './login/index';
import Main from './main';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import nbCaGregorian from 'cldr-dates-full/main/nb/ca-gregorian.json';
import nbDateFields from 'cldr-dates-full/main/nb/dateFields.json';
import nbLocalCurrency from 'cldr-numbers-full/main/nb/currencies.json';
import nbNumbers from 'cldr-numbers-full/main/nb/numbers.json';
import nbTimeZoneNames from 'cldr-dates-full/main/nb/timeZoneNames.json';
import weekData from 'cldr-core/supplemental/weekData.json';
import nbMessages from './no.json';
import { History } from 'history';
import { persistor } from '../redux/store';
import { WithAuth0Props, withAuth0 } from '@auth0/auth0-react';

load(
  currencyData,
  likelySubtags,
  nbCaGregorian,
  nbDateFields,
  nbLocalCurrency,
  nbNumbers,
  nbTimeZoneNames,
  weekData
);

loadMessages(nbMessages, 'nb');

export interface IAppProps extends RouteComponentProps, WithAuth0Props {
  token?: string;
  history: History;
  getDataButtons: () => (dispatch: Dispatch) => Promise<void>;
  getCompanySettings: () => (dispatch: Dispatch) => void;
  getVisibleFields: () => (dispatch: Dispatch) => Promise<void>;
  logout: () => (dispatch: Dispatch) => Promise<void>;
}

export interface IAppState {
  locale: string;
}

class App extends Component<IAppProps, IAppState> {
  
  public static removeLegacyToken(): void {
    const legacyToken = localStorage.getItem('token');
    if (legacyToken) {
      localStorage.removeItem('token');
    }
  }

  public readonly state = {
    locale: 'nb',
    hasError: false,
  };

  public componentDidMount(): void {
    this.configureAuthToken();
  }

  public componentDidUpdate(prevProps: IAppProps): void {
    const { token } = this.props;

    if (prevProps.token !== token) {
      this.configureAuthToken();
    }
  }

  public componentDidCatch(): void {
    this.clearState();
  }

  public async clearState(): Promise<void> {
    await this.props.logout();
    await persistor.flush();
  }

  private configureAuthToken(): void {    
    const { user, isAuthenticated } = this.props.auth0;
    App.removeLegacyToken();

    

    if (isAuthenticated ) {
      this.props.getCompanySettings();
      this.props.getDataButtons();
      this.props.getVisibleFields();
    }
  }

  public render(): ReactNode {
    const { token, history } = this.props;
    const { user, isAuthenticated } = this.props.auth0;
    return (
      <>
        <ModalContainer />
        <LocalizationProvider language={this.state.locale}>
          <IntlProvider locale={this.state.locale}>
            <Router history={history}>
              <Switch>
                <Route exact path="/login" component={Login} />
                <Route
                  render={(props) =>
                    isAuthenticated ? <Main {...props} /> : <Redirect to="/login" />
                  }
                />
              </Switch>
            </Router>
          </IntlProvider>
        </LocalizationProvider>
      </>
    );
  }
}

export default withAuth0(App);