import React from 'react';
import PropTypes from 'prop-types';
import OrderItemTypeIcon from './OrderItemTypeIcon';

function OrderItemTypeCell(props) {
  const { dataItem, field, onClick } = props;
  return (
    <td className="icon-cell" onClick={() => onClick && onClick(props)}>
      <OrderItemTypeIcon orderItemType={dataItem[field]} />
    </td>
  );
}

OrderItemTypeCell.propTypes = {
  dataItem: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}

export default OrderItemTypeCell;
