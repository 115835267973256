import routeOptimalizationConstants from './routeOptimalizationConstants';

const initialState = {
  importedRoutes: [],
  worklists: [],
  selectedWorklist: 0,
  activeTab: 0,
  unassignedStops: [],
}

export default function routeOptimalizationReducer(state = initialState, action) {
  switch (action.type) {
    case routeOptimalizationConstants.SET_IMPORTED_ROUTES:
      return {
        ...state,
        importedRoutes: action.importedRoutes,
      };
    case routeOptimalizationConstants.ADD_IMPORTED_ROUTE:
      return {
        ...state,
        importedRoutes: [
          action.route,
          ...state.importedRoutes,
        ],
      };
    case routeOptimalizationConstants.REMOVE_IMPORTED_ROUTE:
      return {
        ...state,
        importedRoutes: state.importedRoutes
          .filter((route) => route.gln !== action.routeGln),
      };
    case routeOptimalizationConstants.SET_UNASSIGNED_STOPS:
      return {
        ...state,
        unassignedStops: action.unassignedStops,
      };
    case routeOptimalizationConstants.SET_WORKLISTS:
      return {
        ...state,
        worklists: action.worklists,
      };
    case routeOptimalizationConstants.SET_DEPOT_STOP_LISTS:
      return {
        ...state,
        depotStopLists: action.depotStopLists,
      };
    case routeOptimalizationConstants.SET_SELECTED_WORKLIST:
      return {
        ...state,
        selectedWorklist: action.selectedWorklist,
      };
    case routeOptimalizationConstants.SET_ACTIVE_ROUTE_OPTIMALIZATION_TAB:
      return {
        ...state,
        activeTab: action.activeTab,
      };
    case routeOptimalizationConstants.CLEAR_IMPORTED_ROUTE_STORE:
      return initialState;
    default:
      return state;
  }
}
