/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { wasteManagementUrls } from '../appConfig';
import { IFraction } from '../models/Fraction.model';
import wasteManagementClient from './wasteManagementClient';

export default class FractionService {
  static getAllFractions(ignoreCache = false): Promise<Array<IFraction>> {
    return wasteManagementClient
      .get(wasteManagementUrls.fractions, { cache: { ignoreCache } })
      .then((resp) => {
        if (resp?.data && resp.status === 200) {
          return resp.data;
        } else {
          return [];
        }
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }
}
