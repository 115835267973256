import React, { Component, ReactNode } from 'react';
import { IAddContrtactor, IContractor } from '../../models';
import { Formik } from 'formik';
import { Button, Form, Modal } from 'react-bootstrap';
import { locale } from '../../common/localization';
import TextField from '../shared/formikFields/TextField';
import { debounceEventHandler } from '../shared/submitFormUtility';
import yup from '../../common/validation';
import ContractorsService from '../../services/ContractorsService';
import { Toastr } from '../../utils/Toastr';

export interface IContractorDialogProps {
  customerId: number;
  contractor: IContractor;
  contractors: IContractor[];
  show: boolean;
  onHide: (renderer: boolean) => void;
}

export default class ContractorDialog extends Component<IContractorDialogProps> {
  private async onSubmit(
    contractor: IContractor | IAddContrtactor,
    isNew: boolean
  ): Promise<void> {
    if (isNew) {
      try {
        await ContractorsService.addContractor(contractor);
        Toastr.success(locale.contractors._addSuccess);
      } catch (error) {
        Toastr.error(error);
      } finally {
        this.props.onHide(true);
      }
    } else {
      try {
        await ContractorsService.updateContractor(contractor);
        Toastr.success(locale.contractors._editSuccess);
      } catch (error) {
        Toastr.error(error);
      } finally {
        this.props.onHide(true);
      }
    }
  }

  private newContractor(): IAddContrtactor {
    const newContractor: IAddContrtactor = {
      contractorName: '',
      customerId: this.props.customerId,
      extContractorId: null,
    };
    return newContractor;
  }

  private getSchema() {
    const contractorNames = this.props.contractors.map((cont) => {
      return cont.contractorName;
    });
    return yup.object({
      contractorName: yup
        .string()
        .required()
        .notOneOf(contractorNames)
        .label(locale.contractors._name),
      extContractorId: yup
        .number()
        .label(locale.contractors._externalId),
    });
  }

  public render(): ReactNode {
    const isNew = !this.props.contractor;
    const contractor = this.props.contractor || this.newContractor();
    const show = this.props.show;
    const onHide = this.props.onHide;

    return (
      <Formik
        onSubmit={(contractor: IContractor | IAddContrtactor) =>
          this.onSubmit(contractor, isNew)
        }
        initialValues={{ ...contractor }}
        validationSchema={this.getSchema()}
      >
        {({ handleSubmit }) => (
          <Modal
            show={show}
            onHide={() => onHide(false)}
            className="user-edit-modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {contractor.contractorName
                  ? locale.vehicles._edit + ' ' + contractor.contractorName
                  : locale.contractors._addContractor}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form noValidate onSubmit={handleSubmit}>
                <TextField
                  name="contractorName"
                  label={locale.contractors._name}
                  placeholder={locale.contractors._name}
                />
                <TextField
                  name="extContractorId"
                  label={locale.contractors._externalId}
                  placeholder={locale.contractors._externalId}
                />
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => onHide(false)}>
                {locale.vehicles._cancel}
              </Button>
              <Button
                type="submit"
                variant="primary"
                onClick={debounceEventHandler(handleSubmit, 250)}
              >
                {isNew ? locale.vehicles._submit : locale.vehicles._update}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    );
  }
}
