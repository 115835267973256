import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { locale } from '../../../common/localization';

export default function DuplicateRoutesWarning({ duplicateGlns }) {
  if (!duplicateGlns || duplicateGlns.length === 0) {
    return null;
  }

  return (
    <div className="duplicate-routes-warning">
      <FontAwesomeIcon icon={faExclamationTriangle} className="text-danger" />
      <span>{`${locale.routeOptimalizationWizard._duplicateRoutesWarning}: ${duplicateGlns.join(', ')}`}</span>
    </div>
  );
}

DuplicateRoutesWarning.propTypes = {
  duplicateGlns: PropTypes.array,
};

DuplicateRoutesWarning.defaultProps = {
  duplicateGlns: [],
};
