import React from 'react';
import { useElapsedTime } from 'use-elapsed-time';
import PropTypes from 'prop-types';
import { formatTime } from '../dateUtility';
import classNames from 'classnames';
import './statusBarWithTime.scss';

function StatusBarWithTime({ active }) {
  const { elapsedTime, reset } = useElapsedTime(active);
  if (!active && elapsedTime > 0) {
    reset();
  }

  const className = classNames(
    'progress',
    'status-bar-with-time',
    { 'active': active },
  );

  return (
    <div className={className}>
      <div className="progress-bar progress-bar-striped progress-bar-animated">
        {formatTime(elapsedTime)}
      </div>
    </div>
  );
}

StatusBarWithTime.propTypes = {
  active: PropTypes.bool,
};

export default StatusBarWithTime;
