import React, { Component, ReactNode } from 'react';
import PopupTooltip from '../popupTooltip/PopupTooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './customTooltip.scss';

export interface IDetailsTooltipProps {
  details: Array<any>;
}

export class DetailsTooltip extends Component<IDetailsTooltipProps> {
  public render(): ReactNode {
    const details = this.props.details;
    const nonEmptyDetails = details.filter((detail) => !!detail.value);
    if (nonEmptyDetails.length === 0) {
      return null;
    }

    return (
      <PopupTooltip className="custom-tooltip">
        <table>
          <tbody>
            {nonEmptyDetails.map((detail) => (
              <tr key={detail.icon.iconName}>
                <td>
                  <FontAwesomeIcon icon={detail.icon} fixedWidth />
                </td>
                <td>{detail.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </PopupTooltip>
    );
  }
}
