import React, { Component, ReactNode } from 'react';
import { Popup } from 'react-leaflet';
import { ITrackInfoDisplay } from '../../models';

export interface ITrackInfoLinePopupProps {
  trackInfo: ITrackInfoDisplay;
}

export class TrackInfoLinePopup extends Component<ITrackInfoLinePopupProps> {
  public render(): ReactNode {
    const { trackInfo } = this.props;
    return (
      <Popup>
        <table>
          <tbody>
            <tr>
              <td>
                <strong>Id: </strong>
              </td>
              <td>{trackInfo.id}</td>
            </tr>
            <tr>
              <td>
                <strong>Date: </strong>
              </td>
              <td>
                {trackInfo.displayDate}
              </td>
            </tr>
            <tr>
              <td>
                <strong>Latitude: </strong>
              </td>
              <td>{trackInfo.latitude}</td>
            </tr>
            <tr>
              <td>
                <strong>Longitude: </strong>
              </td>
              <td>{trackInfo.longitude}</td>
            </tr>
            <tr>
              <td>
                <strong>Speed: </strong>
              </td>
              <td>{trackInfo.speed} km/h</td>
            </tr>
            <tr>
              <td>
                <strong>User: </strong>
              </td>
              <td>{trackInfo.username}</td>
            </tr>
            <tr>
              <td>
                <strong>Order id: </strong>
              </td>
              <td>{trackInfo.orderId}</td>
            </tr>
            <tr>
              <td>
                <strong>Accuracy: </strong>
              </td>
              <td>{trackInfo.accuracy}</td>
            </tr>
            <tr>
              <td>
                <strong>Client software version: </strong>
              </td>
              <td>{trackInfo.clientSoftwareVersion}</td>
            </tr>
          </tbody>
        </table>
      </Popup>
    );
  }
}

export default TrackInfoLinePopup;
