import React from 'react';
import AnimatedNumber from '../shared/AnimatedNumber';
import PropTypes from 'prop-types';

const maxNumberOfDataButtons = 4;

function getTitle(dataButton) {
  if (dataButton.unit) {
    return `${dataButton.text} (${dataButton.unit})`;
  }

  return dataButton.text;
}

function renderMultiple(dataButtons) {
  return (
    <div className="card diagram-card" >
      <ul className="list-group list-group-flush">
        {dataButtons.slice(0, maxNumberOfDataButtons).map((dataButton) => (
          <li key={dataButton.text} className="list-group-item databuttons-statistic">
            <span className="databutton-title">
              {getTitle(dataButton)}:&nbsp;
            </span>
            <AnimatedNumber
              number={dataButton.value}
            />
          </li>
        ))}
      </ul>
    </div>
  );
}

function renderSingle(dataButton) {
  return (
    <div className="card diagram-card" >
      <div className="card-header">
        <h3>{getTitle(dataButton)}</h3>
      </div>
      <div className="card-body databuttons-statistic-single">
        <AnimatedNumber
          number={dataButton.value}
        />
      </div>
    </div>
  );
}

function DataButtonsStatistics({ dataButtons }) {
  if (dataButtons.length === 1) {
    return renderSingle(dataButtons[0]);
  }

  return renderMultiple(dataButtons);
}

DataButtonsStatistics.propTypes = {
  dataButtons: PropTypes.array,
}

export default DataButtonsStatistics;
