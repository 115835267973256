import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CountUp from 'react-countup';
import { defaultAnimatedNumberAnimationDuration as defaultDuration } from '../../appConfig';

class AnimatedNumber extends Component {
  static propTypes = {
    number: PropTypes.number.isRequired,
    duration: PropTypes.number,
  }

  state = { previous: 0 }

  componentDidUpdate(prevProps) {
    if (prevProps.number !== this.props.number) {
      this.setState({ previous: prevProps.number });
    }
  }

  render() {
    const { number, duration, ...restProps } = this.props;
    const { previous } = this.state;
    const hasFraction = Math.abs(Math.floor(number) - number) > 0;
    
    return (
      <CountUp
        start={previous}
        end={number}
        duration={duration || defaultDuration}
        decimal=","
        decimals={hasFraction ? 2 : 0}
        {...restProps}
      />
    )
  }
}

export default AnimatedNumber;
