import { wasteManagementUrls } from '../appConfig';
import { correctTypeTypo } from '../components/vehicleSettings/vehicleSettingsUtil';
import {
  IAddVehicleSetting,
  IAddVehicleSettingBulk,
  IVehicleSetting,
  IVehicleSettingTemplate,
  VehicleSettingType,
} from '../models';
import wasteManagementClient from './wasteManagementClient';

export default abstract class VehicleSettingService {
  public static getInitVehicleSetting(
    type: VehicleSettingType,
    key: string = ''
  ): IAddVehicleSetting {
    const initValueByType =
      VehicleSettingService.getInitialValueByTemplateType(type);
    const initVehicleSetting: IAddVehicleSetting = {
      key: key,
      value: initValueByType,
    };
    return { ...initVehicleSetting };
  }

  private static getInitialValueByTemplateType = (
    type: VehicleSettingType
  ): boolean | number | string => {
    switch (type) {
      case VehicleSettingType.Boolean:
        return false;
      case VehicleSettingType.String:
        return '';
      case VehicleSettingType.Int:
      case VehicleSettingType.Double:
        return 0;
      default:
        return '';
    }
  };

  public static async getVehicleSettingTemplates(
    ignoreCache = false
  ): Promise<Array<IVehicleSettingTemplate>> {
    try {
      const url = wasteManagementUrls.vehicles.settingTemplates;
      const response = await wasteManagementClient.get(url, {
        cache: { ignoreCache },
      });

      if (response.status === 200 && response.data?.length) {
        const result: Array<IVehicleSettingTemplate> = response.data;

        const formattedResult = result.map((item) => {
          return {
            ...item,
            parameterType: correctTypeTypo(item.parameterType),
          };
        });
        return formattedResult;
      } else {
        return [];
      }
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  public static async getVehicleSettings(
    vehicleId: string,
    ignoreCache = false
  ): Promise<Array<IVehicleSetting>> {
    try {
      const url = `${wasteManagementUrls.vehicles.base}/${vehicleId}${wasteManagementUrls.vehicles.settings}`;
      const response = await wasteManagementClient.get(url, {
        cache: { ignoreCache },
      });

      if (response.status === 200 && response.data?.length) {
        const result: Array<IVehicleSetting> = response.data;
        return result;
      } else {
        return [];
      }
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  public static async deleteVehicleSettings(
    vehicleId: string,
    settingKey: string
  ): Promise<any> {
    try {
      const url = `${wasteManagementUrls.vehicles.base}/${vehicleId}${wasteManagementUrls.vehicles.settings}/${settingKey}`;
      const response = await wasteManagementClient.delete(url, {});

      if (response.data && response.status === 200) {
        return response.data;
      } else {
        return [];
      }
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  public static async updateVehicleSetting(
    vehicleSetting: IAddVehicleSetting,
    vehicleId: string,
    ignoreCache = false
  ): Promise<IAddVehicleSetting> {
    try {
      const url = `${wasteManagementUrls.vehicles.base}/${vehicleId}${wasteManagementUrls.vehicles.settings}`;
      const response = await wasteManagementClient.put(url, vehicleSetting, {
        cache: { ignoreCache },
      });

      if (response.status === 200 && response.data?.length) {
        const result: IAddVehicleSetting = response.data;
        return result;
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  public static async addVehicleSetting(
    vehicleSetting: IAddVehicleSetting,
    vehicleId: string,
    ignoreCache = false
  ): Promise<IAddVehicleSetting> {
    try {
      const url = `${wasteManagementUrls.vehicles.base}/${vehicleId}${wasteManagementUrls.vehicles.settings}`;
      const response = await wasteManagementClient.post(url, vehicleSetting, {
        cache: { ignoreCache },
      });

      if (response.status === 200 && response.data?.length) {
        const result: IAddVehicleSetting = response.data;
        return result;
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  public static async addBulkVehicleSetting(
    vehicleSettingsList: Array<IAddVehicleSettingBulk>
  ): Promise<void> {
    try {
      const url =
        wasteManagementUrls.vehicles.base +
        wasteManagementUrls.vehicles.bulkSettings;
      const response = await wasteManagementClient.post(
        url,
        vehicleSettingsList
      );

      if (response.status >= 200 && response.status < 300) {
        return;
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }
}
