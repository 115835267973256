import { createSelector } from 'reselect';
import { ITrackInfo, ITrackInfoLines } from '../../../models';
import { IRootState } from '../../state';

export const getTrackInfos = createSelector(
  [(state: IRootState) => state],
  (state: IRootState): Array<ITrackInfo> => {
    return state.trackInfo.trackInfoList || [];
  }
);

export const getTrackInfoLines = createSelector(
  [(state: IRootState) => state],
  (state: IRootState): ITrackInfoLines => {
    return state.trackInfo.trackInfoLines || {};
  }
);
