export enum TrackInfoConstants {
  GET_TRACK_INFO_REQUEST = 'GET_TRACK_INFO_REQUEST',
  GET_TRACK_INFO_SUCCESS = 'GET_TRACK_INFO_SUCCESS',
  GET_TRACK_INFO_FAILURE = 'GET_TRACK_INFO_FAILURE',
  RESET_STATE = 'RESET_STATE',
}
export enum TrackInfoLineConstants {
  GET_TRACK_INFO_LINES_REQUEST = 'GET_TRACK_INFO_LINES_REQUEST',
  GET_TRACK_INFO_LINES_SUCCESS = 'GET_TRACK_INFO_LINES_SUCCESS',
  GET_TRACK_INFO_LINES_FAILURE = 'GET_TRACK_INFO_LINES_FAILURE',
  RESET_STATE = 'RESET_STATE',
}
