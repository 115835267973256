import React, { Component, ReactNode } from 'react';
import { Marker } from 'react-leaflet';
import { clamp } from '../mathUtility';
import { renderToStaticMarkup } from 'react-dom/server';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { DivIcon, divIcon } from 'leaflet';
import classNames from 'classnames';
import './circleMarkerIcon.scss';

export interface ICircleMarkerProps {
  zoomLevel: number;
  position: [number, number];
  icon: IconDefinition;
  color: string;
  isHighlighted: boolean;
}

export class CircleMarker extends Component<ICircleMarkerProps> {
  public static readonly BORDER_WIDTH: number = 4;

  public getSize(): number {
    const { zoomLevel, isHighlighted } = this.props;
    const borderWidth = CircleMarker.BORDER_WIDTH;
    const diameter = 20 - (17 - zoomLevel);
    const clampedDiameter = clamp(diameter, 6, 20);

    return clampedDiameter + (isHighlighted ? borderWidth : 0);
  }

  public getIcon(): DivIcon {
    const { icon, isHighlighted, color } = this.props;
    const size = this.getSize();
    const iconHtml = renderToStaticMarkup(
      <FontAwesomeIcon style={{ color }} icon={icon} />
    );
    const className = classNames('circle-marker-icon', {
      highlighted: isHighlighted,
    });

    return divIcon({
      html: iconHtml,
      className,
      iconSize: [size, size],
    });
  }

  public render(): ReactNode {
    const { children, position } = this.props;
    const icon = this.getIcon();
    return (
      <Marker className="link" position={position} icon={icon}>
        {children}
      </Marker>
    );
  }
}
