import React from 'react';
import PropTypes from 'prop-types';

export default function ModulesCell({ dataItem, field }) {
  const selectedModules = dataItem[field];
  const modulesText = selectedModules
    ? selectedModules.map((module) => module.moduleName).join(', ')
    : '';

  return (
    <td>{modulesText}</td>
  );
}

ModulesCell.propTypes = {
  dataItem: PropTypes.any.isRequired,
  field: PropTypes.string.isRequired,
}
