import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormikContext } from 'formik';

export default function Field(props) {
  const {
    getFieldProps,
    touched,
    errors,
  } = useFormikContext();
  const { name } = props;

  return (
    <Form.Control
      {...props}
      {...getFieldProps(name)}
      isInvalid={!!touched[name] && !!errors[name]}
    />
  )
}
