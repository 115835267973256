import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import routes from '../../../common/routes';
import ZoomManager from '../../zoomManager';

function mapRouteToCoordinates(route) {
  return route
    ? route.map((item) => [item.latitude, item.longitude])
    : [];
}

function routeOptimalizationViewActive(location) {
  return location.pathname.startsWith(routes.routeOptimalizationWizardPath);
}

function ImportedRouteZoomManager({
  importedRoutes,
  location,
  activeTab,
  unassignedStops,
}) {
  const isActive = routeOptimalizationViewActive(location)
    && (
      activeTab === 0
      || activeTab === 2
    );

  let activeRoutes = null;
  if (activeTab === 0) {
    activeRoutes = importedRoutes;
  } else if (activeTab === 2) {
    activeRoutes = unassignedStops;
  }

  return (
    <ZoomManager
      name="IMPORTED_ROUTES"
      isActive={isActive}
      objectId={activeRoutes}
      priority={10}
      positions={mapRouteToCoordinates(activeRoutes)}
    />
  );
}

ImportedRouteZoomManager.propTypes = {
  importedRoutes: PropTypes.array,
  activeTab: PropTypes.number,
  location: PropTypes.object.isRequired,
  unassignedStops: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {
    importedRoutes: state.routeOptimalization.importedRoutes,
    location: state.router.location,
    activeTab: state.routeOptimalization.activeTab,
    unassignedStops: state.routeOptimalization.unassignedStops,
  }
}

export default connect(mapStateToProps)(ImportedRouteZoomManager);
