import {
  mapServiceSettings,
  mapServiceUrls,
  selectedRegion,
} from '../appConfig';
import api from '../services/mapServiceClient';
import { ITrackingLine, ITrackingLineSegment } from '../models';
import { MapUtility } from '../components/map/MapUtility';
import { IAxiosCacheAdapterOptions } from 'axios-cache-adapter';

export default abstract class TrackingLineService {
  public static async getAllTrackingLines(
    ignoreCache = false
  ): Promise<Array<ITrackingLine>> {
    try {
      const url = mapServiceUrls.baseUrl + '/lines/visited';
      const axiosCacheConfig: IAxiosCacheAdapterOptions = {
        maxAge: mapServiceSettings.trackingLinesRequestCacheInMilliseconds,
        ignoreCache,
      };
      const response = await api.get(url, { cache: axiosCacheConfig });

      if (response.status === 200 && response.data?.length) {
        const decreasedLines = response.data;
        const lines = decreasedLines
          .filter((line: ITrackingLine) => line.lineSegments.length)
          .map((line: ITrackingLine) => {
            const filteredSegments = line.lineSegments.filter(
              (segment: ITrackingLineSegment) => {
                if (
                  MapUtility.isInRegion(
                    segment.startY,
                    segment.startX,
                    selectedRegion
                  ) &&
                  MapUtility.isInRegion(
                    segment.endY,
                    segment.endX,
                    selectedRegion
                  ) &&
                  MapUtility.getDistanceInMeters(
                    segment.endY,
                    segment.endX,
                    segment.startY,
                    segment.startX
                  ) <= mapServiceSettings.maxSegmentLengthMeters
                ) {
                  return true;
                } else {
                  return false;
                }
              }
            );
            return {
              ...line,
              lineSegments: filteredSegments,
            };
          });
        return lines;
      } else {
        return [];
      }
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }
}
