import cacheRefresherConstants from './cacheRefresherConstants';

const initialState = {
  countdownEnd: 0,
  requests: [],
}

export default function cacheRefresher(state = initialState, action) {
  switch (action.type) {
    case cacheRefresherConstants.START_CACHE_REFRESH_TIMER:
      return {
        ...state,
        countdownEnd: action.countdownEnd,
      };
    case cacheRefresherConstants.STOP_CACHE_REFRESH_TIMER:
      return {
        ...state,
        countdownEnd: 0,
      };
    case cacheRefresherConstants.SET_CACHE_REFRESH_FILTER:
      return {
        ...state,
        filter: action.filter,
      };
    case cacheRefresherConstants.ADD_REQUEST_IN_PROGRESS_CACHE_REFRESH:
      return {
        ...state,
        requests: [...state.requests, action.request],
      };
    case cacheRefresherConstants.REMOVE_REQUEST_IN_PROGRESS_CACHE_REFRESH:
      return {
        ...state,
        requests: state.requests.filter((request) => request !== action.request),
      };
    default:
      return state;
  }
}
