export function isSameOrder(order, otherOrder) {
  if (!(order && otherOrder)) {
    return false;
  }

  return order === otherOrder
    || order.orderId === otherOrder.orderId
    && order.customerId === otherOrder.customerId;
}

export function isSameRouteStop(routeStop, otherRouteStop) {
  if (!(routeStop && otherRouteStop)) {
    return false;
  }

  return routeStop === otherRouteStop
    || routeStop.orderId === otherRouteStop.orderId
    && routeStop.customerId === otherRouteStop.customerId
    && routeStop.routeLineId === otherRouteStop.routeLineId
    && routeStop.paSystem === otherRouteStop.paSystem
    && routeStop.agreementLineId === otherRouteStop.agreementLineId;
}

