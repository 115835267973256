import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Grid } from '@progress/kendo-react-grid';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { locale } from '../../common/localization';
import ReportColumns from './ReportColumns';
import { reportsGridPageSize } from "../../appConfig";
import { process } from "@progress/kendo-data-query";

const defaultDataState = {
  skip: 0,
  take: reportsGridPageSize,
}

class ReportsGrid extends Component {
  static propTypes = {
    report: PropTypes.array,
    reportName: PropTypes.string,
  }

  constructor(props) {
    super(props);

    this.exportReport = this.exportReport.bind(this);
    this.dataStateChange = this.dataStateChange.bind(this);
  }

  state = {
    dataState: defaultDataState,
  }

  componentDidUpdate(prevProps) {
    if (prevProps.report !== this.props.report) {
      this.setState({
        dataState: defaultDataState,
        shouldGridReload: true,
      });
    }
  }

  exportReport() {
    if (this.exporter) {
      this.exporter.save();
    }
  }

  dataStateChange(event) {
    this.setState({ dataState: event.data });
  }

  renderReport(report, reportName) {
    if (this.state.shouldGridReload) {
      setTimeout(() => {
        this.setState({ shouldGridReload: false });
      }, 0);
      return null;
    }

    const processedReport = process(report, this.state.dataState);
    return (
      <div className="row">
        <div className="col">
          <ExcelExport
            data={report}
            fileName={reportName}
            ref={(ref) => this.exporter = ref}
          >
            <Grid
              {...this.state.dataState}
              data={processedReport}
              className="mt-2"
              pageable
              pageSize={reportsGridPageSize}
              onDataStateChange={this.dataStateChange}
            >
              {ReportColumns({ report: processedReport.data })}
            </Grid>
          </ExcelExport>
        </div>
      </div>
    );
  }

  renderEmptyReport() {
    return (
      <div className="alert report-empty-alert mt-2">
        {locale.reports._reportEmpty}
      </div>
    );
  }

  render() {
    const { report, reportName } = this.props;
    if (report && report.length > 0 && reportName) {
      return this.renderReport(report, reportName);
    }

    return this.renderEmptyReport();
  }
}

export default ReportsGrid;
