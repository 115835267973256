import React, { Component } from 'react';
import ReportsService from './ReportsService';
import moment from 'moment';
import ReportSelector from './ReportSelector';
import DateSelector from './DateSelector';
import ReportsGrid from './ReportsGrid';
import { isDate } from '../shared/dateUtility';
import Spinner from '../shared/spinner/Spinner';
import AboutAndContact from './AboutAndContact';
import ShowReportButton from './ShowReportButton';
import ExportReportButton from './ExportReportButton';
import { locale } from '../../common/localization';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import './reports.scss';

class Reports extends Component {
  constructor(props) {
    super(props);

    this.handleReportChange = this.handleReportChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.executeReport = this.executeReport.bind(this);
    this.exportReport = this.exportReport.bind(this);
    this.onReportsLoaded = this.onReportsLoaded.bind(this);
  }

  state = {
    reports: [],
    selectedReport: '',
    report: [],
    isLoading: false,
    from: moment().subtract(1, 'week').toDate(),
    to: new Date(),
  }

  componentDidMount() {
    ReportsService.getReports()
      .then(this.onReportsLoaded);
  }

  static getReportName(reports, selectedReport) {
    const reportId = Number(selectedReport);
    const report = reports.find(r => r.reportId === reportId);
    return report && report.reportName;
  }

  onReportsLoaded(reports) {
    const selectedReport = reports
      && reports[0]
      && String(reports[0].reportId);

    this.setState({
      reports,
      selectedReport,
    });
  }

  handleReportChange(event) {
    this.setState({ selectedReport: event.target.value });
  }

  processReport(report) {
    return report.map((row) => {
      const processedRow = { ...row };
      for (const key of Object.keys(processedRow)) {
        if (isDate(processedRow[key])) {
          processedRow[key] = new Date(processedRow[key]);
        }
      }
      return processedRow;
    });
  }

  handleDateChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  exportReport() {
    if (this.reportsGrid) {
      this.reportsGrid.exportReport();
    }
  }

  executeReport() {
    if (!this.state.selectedReport) {
      return;
    }

    this.setState({ isLoading: true });

    ReportsService.executeReport(
      this.state.selectedReport,
      moment(this.state.from).format('YYYY-MM-DD'),
      moment(this.state.to).format('YYYY-MM-DD'),
    ).then((report) => this.setState({
      report: this.processReport(report),
      isLoading: false,
    })).catch((error) => {
      console.error(error);
      this.setState({
        report: [],
        isLoading: false,
      });
    });
  }

  render() {
    const {
      isLoading,
      report,
      reports,
      selectedReport,
    } = this.state;
    const selectedReportName = Reports.getReportName(reports, selectedReport);
    const exportButtonEnabled = !report || report.length === 0 || !selectedReportName;

    return (
      <div className="reports">
        <div className="row">
          <div className="col-4">
            <h2>
              <FontAwesomeIcon icon={faFile} />
              {locale.general._reports}
            </h2>
          </div>
        </div>
        {isLoading && <Spinner />}
        <div className="row">
          <div className="col">
            <div className="row">
              <div className="col">
                <ReportSelector
                  selectedReport={this.state.selectedReport}
                  handleChange={this.handleReportChange}
                  reports={this.state.reports}
                />
              </div>
            </div>
            <div className="row">
              <div className="col date-selector-col">
                <DateSelector
                  from={this.state.from}
                  to={this.state.to}
                  handleChange={this.handleDateChange}
                />
              </div>
              <div className="col show-report-button-col">
                <ShowReportButton onClick={this.executeReport} />
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="row">
              <div className="col">
                <AboutAndContact />
              </div>
            </div>
            <div className="row">
              <div className="col export-report-button-col">
                <ExportReportButton
                  onClick={this.exportReport}
                  disabled={exportButtonEnabled}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <ReportsGrid
              report={report}
              reportName={selectedReportName}
              ref={(ref) => this.reportsGrid = ref}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Reports;
