import React from 'react';
import PropTypes from 'prop-types';
import {
  faCircle
} from '@fortawesome/free-solid-svg-icons';
import ServiceOrderTooltip from './ServiceOrderTooltip';
import { CircleMarker } from '../shared/customMapLayer';
import { getAddress } from '../shared/serviceOrderUtility';

function getColor(serviceOrder) {
  return serviceOrder.executed
    ? 'green'
    : 'red';
}

function getServiceOrderPosition(serviceOrder) {
  return [serviceOrder.agreementGpsX, serviceOrder.agreementGpsY];
}

function ServiceOrderMarker({ serviceOrder, zoomLevel }) {
  const position = getServiceOrderPosition(serviceOrder);
  const color = getColor(serviceOrder);
  const address = getAddress(serviceOrder);

  return (
    <CircleMarker
      position={position}
      icon={faCircle}
      color={color}
      zoomLevel={zoomLevel}
    >
      <ServiceOrderTooltip
        address={address}
        time={serviceOrder.updated}
      />
    </CircleMarker>
  );
}

ServiceOrderMarker.propTypes = {
  serviceOrder: PropTypes.object.isRequired,
  zoomLevel: PropTypes.number.isRequired,
};

export default React.memo(ServiceOrderMarker);
