import wasteManagementClient from '../../services/wasteManagementClient';
import { wasteManagementUrls } from '../../appConfig';
import { OrderFilterService } from '../../services/filterService';

class OrdersService {
  static getOrderHeadsByAndDate(
    date,
    ignoreCache = false,
    contractorIds = [],
    fractionIds = []
  ) {
    return wasteManagementClient
      .get(wasteManagementUrls.orders, {
        params: { date },
        cache: { ignoreCache },
      })
      .then((response) => {
        if (response.data && response.status === 200 && response.data) {
          let orders = [];
          if (fractionIds.length > 0) {
            const filteredOrdes = [];
            fractionIds.forEach((fractionId) => {
              response.data.forEach((order) => {
                if (
                  order.fractions.some(
                    (fraction) => fraction.fractionId === fractionId
                  ) &&
                  !filteredOrdes.some(
                    (filteredOrder) => filteredOrder.orderId === order.orderId
                  )
                ) {
                  filteredOrdes.push(order);
                }
              });
              orders = filteredOrdes;
            });
          } else {
            orders = response.data;
          }
          if (contractorIds.length > 0) {
            orders = orders.filter((order) =>
              contractorIds.includes(order.contractorId)
            );
          }
          return OrderFilterService.filter(orders);
        } else {
          return [];
        }
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  static getOrderDetails(orderId, ignoreCache = false) {
    const url = `${wasteManagementUrls.orders}/${orderId}`;
    return wasteManagementClient
      .get(url, {
        cache: { ignoreCache, maxAge: 1000 * 60 * 60 * 12 },
      })
      .then((response) => {
        if (response.data && response.status === 200) {
          return response.data;
        } else {
          return null;
        }
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  static getOrderDetailsBulk(orderIds, ignoreCache = false) {
    const url = `${wasteManagementUrls.orders}/details/bulk`;
    return wasteManagementClient
      .get(url, {
        params: {
          orderIds,
        },
        cache: { ignoreCache },
      })
      .then((response) => {
        if (response.data && response.status === 200) {
          return response.data;
        } else {
          return null;
        }
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  static getOrderDetailsByDate(
    date,
    ignoreCache = false,
    contractorIds = [],
    fractionIds = []
  ) {
    const url = `${wasteManagementUrls.orders}/detailsByDate`;
    return wasteManagementClient
      .get(url, {
        params: {
          date,
        },
        cache: { ignoreCache },
      })
      .then((response) => {
        if (response.data && response.status === 200) {
          let orders = [];
          if (fractionIds.length > 0) {
            const filteredOrdes = [];
            fractionIds.forEach((fractionId) => {
              response.data.forEach((order) => {
                if (
                  order.fractions.some(
                    (fraction) => fraction.fractionId === fractionId
                  ) &&
                  !filteredOrdes.some(
                    (filteredOrder) => filteredOrder.orderId === order.orderId
                  )
                ) {
                  filteredOrdes.push(order);
                }
              });
              orders = filteredOrdes;
            });
          } else {
            orders = response.data;
          }
          if (contractorIds.length > 0) {
            orders = orders.filter((order) =>
              contractorIds.includes(order.contractorId)
            );
          }
          return orders;
        } else {
          return null;
        }
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  static getRouteLinesByOrderId(orderId) {
    return wasteManagementClient
      .get(wasteManagementUrls.route, {
        params: {
          orderId,
        },
      })
      .then((response) => {
        if (response.data && response.status === 200 && response.data) {
          return response.data;
        } else {
          return [];
        }
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  static updateOrder(updatedOrder, ignoreCache = false) {
    return wasteManagementClient
      .put(wasteManagementUrls.orders, updatedOrder, { cache: { ignoreCache } })
      .then((resp) => {
        if (resp.data && resp.status === 200 && resp.data) {
          return resp.data;
        } else {
          return [];
        }
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }
}

export default OrdersService;
