export enum RouteConstants {
  ROUTE_LIST_REQUEST = 'ROUTE_LIST_REQUEST',
  ROUTE_LIST_SUCCESS = 'ROUTE_LIST_SUCCESS',
  ROUTE_LIST_FAILURE = 'ROUTE_LIST_FAILURE',
  ROUTE_AGREEMENT_LINE_REQUEST = 'ROUTE_AGREEMENT_LINE_REQUEST',
  ROUTE_AGREEMENT_LINE_SUCCESS = 'ROUTE_AGREEMENT_LINE_SUCCESS',
  ROUTE_AGREEMENT_LINE_FAILURE = 'ROUTE_AGREEMENT_LINE_FAILURE',
  CHANGE_POSITION_REQUEST = 'CHANGE_POSITION_REQUEST',
  CHANGE_POSITION_SUCCESS = 'CHANGE_POSITION_SUCCESS',
  CHANGE_POSITION_FAILURE = 'CHANGE_POSITION_FAILURE',
  CHANGE_SEQUENCE_REQUEST = 'CHANGE_SEQUENCE_REQUEST',
  CHANGE_SEQUENCE_SUCCESS = 'CHANGE_SEQUENCE_SUCCESS',
  CHANGE_SEQUENCE_FAILURE = 'CHANGE_SEQUENCE_FAILURE',
  VALIDATE_SEQUENCE_REQUEST = 'VALIDATE_SEQUENCE_REQUEST',
  VALIDATE_SEQUENCE_SUCCESS = 'VALIDATE_SEQUENCE_SUCCESS',
  VALIDATE_SEQUENCE_FAILURE = 'VALIDATE_SEQUENCE_FAILURE',
  VALIDATE_SEQUENCE_CLEAR = 'VALIDATE_SEQUENCE_CLEAR',
  ROUTE_SEQUENCE_MOVE = 'ROUTE_SEQUENCE_MOVE',
  ROUTE_SEQUENCE_MARK = 'ROUTE_SEQUENCE_MARK',
  ROUTE_SET_EDIT_MODE = 'ROUTE_SET_EDIT_MODE',
  TOGGLE_ROUTE_COLLAPSE = 'TOGGLE_ROUTE_COLLAPSE',
  SET_GRID_STATE = 'SET_GRID_STATE',
  RESET_STATE = 'RESET_STATE',
  MERGE_REQUEST = 'MERGE_REQUEST',
  MERGE_SUCCESS = 'MERGE_SUCCESS',
  MERGE_FAILURE = 'MERGE_FAILURE',
  SPLIT_REQUEST = 'SPLIT_REQUEST',
  SPLIT_SUCCESS = 'SPLIT_SUCCESS',
  SPLIT_FAILURE = 'SPLIT_FAILURE',
}
