import { VehicleSettingType } from '../../models';

export const correctTypeTypo = (type: string): VehicleSettingType => {
  const formatted = type.toLowerCase().trim();
  switch (formatted) {
    case 'int':
      return VehicleSettingType.Int;
    case 'boolean':
      return VehicleSettingType.Boolean;
    case 'string':
      return VehicleSettingType.String;
    case 'double':
      return VehicleSettingType.Double;
    case 'boolen':
      return VehicleSettingType.Boolean;
    case 'boolan':
      return VehicleSettingType.Boolean;
    default:
      return VehicleSettingType.String;
  }
};
