import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import routes from '../../../common/routes';
import {
  getSelectedDepotStopList,
  getSelectedWorklist,
} from '../../../redux/modules/routeOptimalization/routeOptimalizationSelectors';
import ZoomManager from '../../zoomManager';

function mapWorklistToCoordinates(worklist, selectedDepotStopList) {
  if (!worklist || !selectedDepotStopList) {
    return [];
  }

  return worklist
    .concat(selectedDepotStopList)
    .map((item) => [item.latitude, item.longitude]);
}

function routeOptimalizationViewActive(location) {
  return location.pathname.startsWith(routes.routeOptimalizationWizardPath);
}

function WorklistZoomManager({
  selectedWorklist,
  selectedDepotStopList,
  location,
  activeTab,
}) {
  const isActive = routeOptimalizationViewActive(location) && activeTab === 1;
  return (
    <ZoomManager
      name="SELECTED_WORKLIST"
      isActive={isActive}
      objectId={selectedWorklist}
      priority={10}
      positions={mapWorklistToCoordinates(selectedWorklist, selectedDepotStopList)}
    />
  );
}

WorklistZoomManager.propTypes = {
  selectedWorklist: PropTypes.array,
  selectedDepotStopList: PropTypes.bool,
  activeTab: PropTypes.number,
  location: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    selectedWorklist: getSelectedWorklist(state),
    selectedDepotStopList: getSelectedDepotStopList(state),
    location: state.router.location,
    activeTab: state.routeOptimalization.activeTab,
  }
}

export default connect(mapStateToProps)(WorklistZoomManager);
