import React, { Component, ReactNode } from 'react';
import { Pane, WMSTileLayer } from 'react-leaflet';
import { mapServiceUrls } from '../../appConfig';
import { ClusteredMapLayer } from '../shared/customMapLayer';

export interface IWMSLayerProps {
  clusteringActive: boolean;
  zoomLevel: number;
  layers: string;
}
export class WMSLayer extends Component<IWMSLayerProps> {
  public render(): ReactNode {
    const { clusteringActive } = this.props;

    return (
      <ClusteredMapLayer isClusteringActive={clusteringActive}>
        <Pane name="wms-layer" style={{ zIndex: 300 }}>
          <WMSTileLayer
            layers={this.props.layers}
            url={mapServiceUrls.wms}
            transparent={true}
            format={'image/png'}
          />
        </Pane>
      </ClusteredMapLayer>
    );
  }
}

export default WMSLayer;
