import React, { Component, ReactElement } from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import classNames from 'classnames';
import { IRoutePoi } from '../../models';

export interface IRouteAdminCellProps extends GridCellProps {
  onClick: (pois: Array<IRoutePoi>) => void;
  pipe?: (prop: any) => any;
  isSelected: (poi: IRoutePoi) => boolean;
  groups: Array<{ field: string }>;
}
export class RouteAdminCell extends Component<IRouteAdminCellProps> {
  public render(): ReactElement {
    if (this.props.rowType === 'groupHeader') return null;
    const groups = this.props.groups.map((group) => group.field);
    const { dataItem, field, onClick, isSelected } = this.props;
    const { pipe = (prop: any) => prop } = this.props;
    const className = classNames('cell-wrapper', {
      active: isSelected(dataItem),
    });

    return (
      <td>
        <div
          onClick={() => (groups.includes(field) ? null : onClick([dataItem]))}
          className={className}
          title={dataItem[field]}
        >
          {pipe(dataItem[field])}
        </div>
      </td>
    );
  }
}
