import React from 'react';
import PropTypes from 'prop-types';
import { locale } from '../../../common/localization';
import HeaderStep from './HeaderStep';
import {
  faFile,
  faFlask,
  faSave,
} from '@fortawesome/free-solid-svg-icons';
import './stepsHeader.scss';


function StepsHeader({
  onSelectFile,
  analyseEnabled,
  onAnalyse,
  saveEnabled,
  onSave,
}) {
  return (
    <div className="steps-header">
      <HeaderStep
        title={locale.routeOptimalizationWizard._uploadFileStep}
        buttonText={locale.routeOptimalizationWizard._uploadFileButton}
        buttonClick={onSelectFile}
        icon={faFile}
      />
      <HeaderStep
        title={locale.routeOptimalizationWizard._analyseStep}
        buttonText={locale.routeOptimalizationWizard._analyseButton}
        buttonClick={onAnalyse}
        disabled={!analyseEnabled}
        icon={faFlask}
      />
      <HeaderStep
        title={locale.routeOptimalizationWizard._saveStep}
        buttonText={locale.routeOptimalizationWizard._saveButton}
        buttonClick={onSave}
        disabled={!saveEnabled}
        icon={faSave}
      />
    </div>
  );
}

StepsHeader.propTypes = {
  onSelectFile: PropTypes.func.isRequired,
  onAnalyse: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  analyseEnabled: PropTypes.bool,
  saveEnabled: PropTypes.bool,
}

StepsHeader.defaultProps = {
  onSelectFile: () => { },
  onAnalyse: () => { },
  onSave: () => { },
}

export default StepsHeader;
