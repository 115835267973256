import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faTachometerAlt } from '@fortawesome/free-solid-svg-icons';
import OrdersGrid from './OrdersGrid';
import { locale } from '../../common/localization';
import RefreshButton from '../shared/refreshButton';
import { isSameOrder } from '../shared/orderUtility';
import moment from 'moment';
import OrderDetailsDiagram from '../orderDetailsDiagram/OrderDetailsDiagram';
import './orders.scss';
import ContractorsService from '../../services/ContractorsService';
import UpdateOrderDialog from './UpdateOrderDialog';
import { order } from '../../redux/modules/order/orderReducer';

class Orders extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contractors: [],
      isContractorsLoading: true,
      selectedContractorIds: [],
      selectedFractionIds: [],
      showFilterRow: false,
      updateDialog: {
        order: {},
        show: false,
        fractions: [],
      },
    };

    this.multiSelectRef = React.createRef();
    this.fractionsMultiSelectRef = React.createRef();
    this.refreshItems = this.refreshItems.bind(this);
    this.handleNextDayButton = this.handleNextDayButton.bind(this);
    this.handlePreviousDayButton = this.handlePreviousDayButton.bind(this);
    this.handleNowButton = this.handleNowButton.bind(this);
    this.handleFilterDateChange = this.handleFilterDateChange.bind(this);
  }

  static filterOrders(orders, orderTypes) {
    if (!orderTypes) {
      return orders;
    }
    return orders.filter((order) => orderTypes.includes(order.orderType));
  }

  static filterByContractorId(orders, contractorIds) {
    if (!contractorIds || contractorIds.length < 1) {
      return orders;
    }
    return orders.filter((order) => contractorIds.includes(order.contractorId));
  }

  contractors = [];
  contractorIds = [];

  loadContractors() {
    ContractorsService.getSelfContractors().then((resp) => {
      this.setState({ contractors: resp, isContractorsLoading: false });

      // initialize by number of contractors
      if (this.state.contractors && this.state.contractors.length == 1) {
        this.filterByContractor(
          {
            target: {
              value: [this.state.contractors[0]],
            },
          },
          this.props.match
        );
      }

      if (this.state.contractors && this.state.contractors.length > 1) {
        this.showAllContractor(this.props.match);
      }
    });
  }

  componentDidMount() {
    this.loadContractors();
    this.props.orderActions.setSelectedOrder(null);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.date !== this.props.match.params.date) {
      this.getOrdersDebounced();
    }
  }

  checkOrderChange(prevProps) {
    const prevPropsOrdersLength = prevProps.orders.length;
    const propsOrdersLength = this.props.orders.length;
    if (prevPropsOrdersLength !== propsOrdersLength) {
      return true;
    }
    if (
      prevPropsOrdersLength > 0 &&
      prevPropsOrdersLength === propsOrdersLength
    ) {
      for (let i = 0; i < prevPropsOrdersLength; i++) {
        if (prevProps.orders[i].orderId !== this.props.orders[i].orderId) {
          return true;
        }
      }
      return false;
    }
    return false;
  }

  getFilterDate() {
    const { match } = this.props;
    const parsedDate = moment(match.params.date, 'YYYY-MM-DD', true);

    return parsedDate.isValid() ? parsedDate : moment();
  }

  getOrdersDayFilter() {
    return this.getFilterDate().format('YYYYMMDD');
  }

  setFilterDate(filterDate) {
    const { match, history } = this.props;
    const url = match.params.date
      ? match.url.substring(0, match.url.lastIndexOf('/'))
      : match.url;

    history.push(`${url}/${filterDate.format('YYYY-MM-DD')}`);
  }

  handleNextDayButton() {
    this.setFilterDate(this.getFilterDate().add(1, 'days'));
  }

  handlePreviousDayButton() {
    this.setFilterDate(this.getFilterDate().subtract(1, 'days'));
  }

  handleNowButton() {
    this.setFilterDate(moment());
  }

  handleFilterDateChange(event) {
    this.setFilterDate(moment(event.target.value));
  }

  setSelectedOrder = (row) => {
    if (isSameOrder(row.dataItem, this.props.selected)) {
      this.props.orderActions.setSelectedOrder(null);
    } else {
      this.props.orderActions.setSelectedOrder(row.dataItem);
    }
  };

  /**
   * Calls the getOrders() method in a way that only the last call
   * results in an actual request if the user clicks the date selector
   * buttons multiple times quickly.
   */
  getOrdersDebounced() {
    if (this.getOrdersDebouncedTimer) {
      clearTimeout(this.getOrdersDebouncedTimer);
    }

    this.getOrdersDebouncedTimer = setTimeout(() => {
      this.getOrdersDebouncedTimer = null;
      this.loadContractors();
      this.getOrders();
    }, 200);
  }

  getOrders(ignoreCache = false) {
    const dayFilter = this.getOrdersDayFilter();
    const filterIds = this.state.selectedContractorIds;
    this.props.orderActions.getOrders(dayFilter, ignoreCache, false, filterIds);
  }

  refreshItems() {
    if (this.props.countdownEnd) {
      this.props.cacheRefresherActions.startCacheRefreshTimer();
    }

    this.getOrders(true);
    this.props.vehicleTrackingActions.getVehicles(true);
  }

  filterByContractor(e, match) {
    if (e && e.target.value) {
      const selectedIds = e.target.value.map((cont) => cont.contractorId);
      this.setState({ selectedContractorIds: selectedIds }, () => {
        this.filterCallback(match);
      });
    }
  }

  filterByFraction(e, match) {
    if (e && e.target.value) {
      const selectedIds = e.target.value.map((cont) => cont.fractionId);
      this.setState({ selectedFractionIds: selectedIds }, () => {
        this.filterCallback(match);
      });
    }
  }

  filterCallback(match) {
    const parsedDate = moment(match.params.date, 'YYYY-MM-DD', true);
    const dayFilter = parsedDate.isValid() ? parsedDate : moment();
    const filterIds = this.state.selectedContractorIds;
    const fractionIds = this.state.selectedFractionIds;
    this.props.orderActions.getOrders(
      dayFilter.format('YYYYMMDD'),
      false,
      false,
      filterIds,
      fractionIds
    );
  }

  showAllContractor(match) {
    this.setState({ selectedContractorIds: [] }, () => {
      // clear multiselect
      if (this.multiSelectRef.current) {
        var multiselect = this.multiSelectRef.current;
        multiselect.clearButtonClick({ stopPropagation: () => {} });
      }

      // get all orders
      const parsedDate = moment(match.params.date, 'YYYY-MM-DD', true);
      const dayFilter = parsedDate.isValid() ? parsedDate : moment();
      const filterIds = this.state.selectedContractorIds;
      this.props.orderActions.getOrders(
        dayFilter.format('YYYYMMDD'),
        false,
        false,
        filterIds
      );
    });
  }

  showFilters() {
    this.setState({ showFilterRow: !this.state.showFilterRow });
  }

  updateOrder(order) {
    this.props.orderActions.getAllFractions();
    this.setState({
      ...this.state,
      updateDialog: { order: order, show: true },
    });
  }

  hideUpdateOrderDialog(rerender = false) {
    this.setState({
      ...this.state,
      updateDialog: { order: null, fractions: [], show: false },
    });
    if (rerender) {
      this.getOrders(true);
    }
  }

  render() {
    const {
      orders,
      selected,
      match,
      isLoading,
      orderTypes,
      fractions,
      allFractions,
    } = this.props;
    const {
      contractors,
      isContractorsLoading,
      showFilterRow,
      selectedFractionIds,
      selectedContractorIds,
    } = this.state;

    const isFiltersActive =
      selectedContractorIds.length > 0 || selectedFractionIds.length > 0
        ? true
        : false;

    const defaultContractors = contractors.filter((contractor) =>
      selectedContractorIds.includes(contractor.contractorId)
    );
    const defaultFractions = fractions.filter((fraction) =>
      selectedFractionIds.includes(fraction.fractionId)
    );

    let filteredOrders = Orders.filterOrders(orders, orderTypes);

    if (isContractorsLoading) {
      return <div></div>;
    }
    return (
      <div className="orders-view">
        <div className="row orders-action-menu">
          <div>
            <h2 className="orders-tachometer">
              <FontAwesomeIcon icon={faTachometerAlt} />
              {locale.general._orderOverview}
            </h2>
          </div>
          <div className="d-flex pb-2">
            <div>
              <button
                className={`show-filters-button ${
                  isFiltersActive ? 'filter-active' : ''
                }`}
                onClick={() => this.showFilters()}
              >
                <FontAwesomeIcon icon={faFilter} />
              </button>
            </div>
            <div className="date-controls">
              <button
                className="btn btn-outline-dark mr-1"
                onClick={this.handlePreviousDayButton}
              >
                <FontAwesomeIcon icon="angle-left" />
              </button>
              <DatePicker
                value={this.getFilterDate().toDate()}
                onChange={this.handleFilterDateChange}
                format={'dd.MM.yyyy'}
                className="date-picker"
                popupSettings={{ popupClass: 'date-picker-popup' }}
              />
              <button
                className="btn btn-outline-dark ml-1 mr-1"
                onClick={this.handleNowButton}
              >
                {locale.orders._now}
              </button>
              <button
                className="btn btn-outline-dark mr-1"
                onClick={this.handleNextDayButton}
              >
                <FontAwesomeIcon icon="angle-right" />
              </button>
              <RefreshButton onClick={this.refreshItems} />
            </div>
          </div>
        </div>
        {showFilterRow ? ( //Velg transportør
          <div className="row order-filter-row">
            <div className="orders-multiselect">
              {fractions && fractions.length > 1 && (
                <MultiSelect
                  style={{ width: '100%' }}
                  data={fractions}
                  value={defaultFractions}
                  autoClose={false}
                  textField="name"
                  onChange={(e) => this.filterByFraction(e, match)}
                  disabled={fractions && fractions.length < 2}
                  placeholder={locale.orders._chooseFraction}
                  ref={this.fractionsMultiSelectRef}
                />
              )}
            </div>
            <div className="orders-multiselect">
              {contractors && contractors.length > 1 && (
                <MultiSelect
                  style={{ width: '100%' }}
                  data={contractors}
                  value={defaultContractors}
                  autoClose={false}
                  textField="contractorName"
                  onChange={(e) => this.filterByContractor(e, match)}
                  disabled={contractors && contractors.length < 2}
                  placeholder={locale.orders._chooseContractor}
                  ref={this.multiSelectRef}
                />
              )}
            </div>
          </div>
        ) : (
          <div className="hidden-order-filter-row"></div>
        )}
        <div className="orders-grid-wrapper">
          <OrderDetailsDiagram
            orders={selected || filteredOrders}
            isLoading={isLoading}
          />
          {filteredOrders && (
            <OrdersGrid
              data={filteredOrders}
              onRowClick={this.setSelectedOrder}
              selected={selected}
              match={match}
              isLoading={false}
              updateOrder={this.updateOrder.bind(this)}
            />
          )}
          {!filteredOrders && !isLoading && (
            <div className="d-flex flex-row justify-content-center align-items-center h-75">
              <div className="alert alert-warning" role="alert">
                Ingen treff <FontAwesomeIcon icon="exclamation-triangle" />
              </div>
            </div>
          )}
        </div>
        {this.state.updateDialog.show && (
          <UpdateOrderDialog
            order={this.state.updateDialog.order}
            fractions={allFractions}
            show={this.state.updateDialog.show}
            onHide={this.hideUpdateOrderDialog.bind(this)}
          />
        )}
      </div>
    );
  }
}

Orders.propTypes = {
  orderActions: PropTypes.object.isRequired,
  orders: PropTypes.array,
  filterDate: PropTypes.object,
  selected: PropTypes.object,
  isLoading: PropTypes.bool,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  vehicleTrackingActions: PropTypes.object.isRequired,
  cacheRefresherActions: PropTypes.object.isRequired,
  countdownEnd: PropTypes.number.isRequired,
  orderTypes: PropTypes.array.isRequired,
  customerId: PropTypes.number,
  fractions: PropTypes.array,
  allFractions: PropTypes.array,
};

export default Orders;
