import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import {
  loadTrackInfos,
} from '../../redux/modules/trackInfo/trackInfoCreators';
import TrackInfoLayer from './TrackInfoLayer';
import { IRootState } from '../../redux/state';
import {
  getTrackInfos,
} from '../../redux/modules/trackInfo/trackInfoSelectors';

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      loadTrackInfos,
    },
    dispatch
  );
}

function mapStateToProps(state: IRootState) {
  return {
    trackInfo: getTrackInfos(state),
    order: state.order.selected,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TrackInfoLayer);
