import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWrench } from '@fortawesome/free-solid-svg-icons';
import Spinner from '../shared/spinner/Spinner';
import { locale } from '../../common/localization';
import ServiceOrdersGrid from './ServiceOrdersGrid';
import PaneHeadline from '../shared/paneHeadline';
import routes from '../../common/routes';

class ServiceOrders extends Component {
  constructor(props) {
    super(props);

    this.setSelectedServiceOrder = this.setSelectedServiceOrder.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
  }

  static propTypes = {
    match: PropTypes.object.isRequired,
    serviceOrderActions: PropTypes.object.isRequired,
    serviceOrders: PropTypes.array,
    isLoading: PropTypes.bool,
    selectedOrderId: PropTypes.number,
  }

  static getTitle(serviceOrders) {
    return serviceOrders
      && serviceOrders[0]
      && serviceOrders[0].listName
      || '';
  }

  componentDidMount() {
    this.getServiceOrders();
  }

  getServiceOrders(ignoreCache = false) {
    const { match } = this.props;
    const listId = parseInt(match.params.listId, 10);

    this.props.serviceOrderActions.getServiceOrders(listId, ignoreCache);
  }

  setSelectedServiceOrder(row) {
    if (row.dataItem.listItemId === this.props.selectedOrderId) {
      this.props.serviceOrderActions.setSelectedServiceOrderId(row.dataItem.listId, null);
    } else {
      this.props.serviceOrderActions.setSelectedServiceOrderId(row.dataItem.listId, row.dataItem.listItemId);
    }
  }

  deleteItem(serviceOrder) {
    if (confirm(`${locale.serviceOrders._deleteConfirm}: ${serviceOrder.externalOrderId}`)) {
      this.props.serviceOrderActions.deleteServiceListItem(serviceOrder.listItemId, serviceOrder.agreementPASystem);
    }
  }

  render() {
    const {
      serviceOrders,
      isLoading,
      match,
      selectedOrderId,
    } = this.props;

    return (
      <div className="service-orders-view">
        <PaneHeadline
          titleText={ServiceOrders.getTitle(serviceOrders)}
          titleIcon={faWrench}
          backUrl={routes.serviceListsPath}
          backTitle={locale.general._serviceList}
          className="header"
        >
          <button
            className="btn btn-outline-dark mr-2"
            onClick={() => this.grid.excelExport()}
          >
            {locale.serviceOrders._excelExportButton}
          </button>
          <button
            className="btn btn-outline-dark"
            onClick={() => this.grid.pdfExport()}
          >
            {locale.serviceOrders._pdfExportButton}
          </button>
        </PaneHeadline>
        <div className="service-orders-grid-wrapper">
          {isLoading && <Spinner />}
          {serviceOrders &&
            <ServiceOrdersGrid
              serviceOrders={serviceOrders}
              currentUrl={match.url}
              onRowClick={this.setSelectedServiceOrder}
              deleteItem={this.deleteItem}
              selectedOrderId={selectedOrderId}
              ref={(ref) => this.grid = ref}
            />
          }
          {!serviceOrders && !isLoading &&
            <div className="d-flex flex-row justify-content-center align-items-center h-75">
              <div className="alert alert-warning" role="alert">
                {locale.serviceOrders._noOrders} <FontAwesomeIcon icon="exclamation-triangle" />
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default ServiceOrders;
