export interface IPoiAgreement {
  id: number;
  name: string;
}
export namespace IPoiAgreement {
  export function toString({ name, id }: IPoiAgreement): string {
    return `${name} (${id})`;
  }

  export function equals(a1: IPoiAgreement, a2: IPoiAgreement): boolean {
    return toString(a1) === toString(a2);
  }
}
