import { LatLngBounds, LatLngExpression } from 'leaflet';
import { isEqual, isNil } from 'lodash';
import moment from 'moment';
import React, { Component, ReactNode } from 'react';
import { Pane, Polyline } from 'react-leaflet';
import { mapSettings } from '../../appConfig';
import {
  ISelectedOrder,
  ITrackInfo,
  ITrackInfoDisplay,
  ITrackInfoLines,
  ITrackInfoPolyLine,
} from '../../models';
import { AsyncAction } from '../../utils';
import { ClusteredMapLayer } from '../shared/customMapLayer';
import TrackInfoLinePopup from './TrackInfoLinePopup';

export interface ITrackInfoLineLayerProps {
  clusteringActive: boolean;
  zoomLevel: number;
  bounds: LatLngBounds;
  trackInfo: Array<ITrackInfo>;
  trackInfoLines: ITrackInfoLines;
  order: ISelectedOrder;
  loadTrackInfos: (ignoreCache: boolean, orderId: number) => AsyncAction;
}

export interface ITrackInfoLineLayerState {
  trackInfoLineToDraw: ITrackInfoLines;
  reDrawn: boolean;
}
export class TrackInfoLineLayer extends Component<
  ITrackInfoLineLayerProps,
  ITrackInfoLineLayerState
> {
  public readonly state: ITrackInfoLineLayerState = {
    trackInfoLineToDraw: null,
    reDrawn: false,
  };

  public componentDidMount(): void {
    if (this.props.order?.orderId) {
      this.props.loadTrackInfos(false, this.props.order?.orderId);
    }
  }

  public shouldComponentUpdate(
    prevProps: ITrackInfoLineLayerProps,
    prevState: ITrackInfoLineLayerState
  ): boolean {
    //Low effort useMemo()
    if (isEqual(prevProps, this.props) && isEqual(prevState, this.state)) {
      return false;
    } else {
      return true;
    }
  }

  public componentDidUpdate(
    prevProps: Readonly<ITrackInfoLineLayerProps>,
    prevState: Readonly<ITrackInfoLineLayerState>
  ): void {
    if (
      !isEqual(prevProps.order?.orderId, this.props.order?.orderId) &&
      !isNil(this.props.order?.orderId)
    ) {
      this.props.loadTrackInfos(false, this.props.order?.orderId);
    }

    this.updateTrackInfoLineToDrawByBoundsAndOrderChecking(prevState);
  }

  private updateTrackInfoLineToDrawByBoundsAndOrderChecking(
    state: Readonly<ITrackInfoLineLayerState>
  ): void {
    const newLines: ITrackInfoLines = {};
    Object.keys(this.props.trackInfoLines).forEach((driver: string) => {
      const polylinesInBounds = this.props.trackInfoLines[
        driver
      ].polyLines.filter((polyline: ITrackInfoPolyLine) => {
        return polyline.lines.some((line: ITrackInfoDisplay) => {
          return this.props.bounds.contains([line.latitude, line.longitude]);
        });
      });
      newLines[driver] = {
        polyLines: polylinesInBounds,
        boundaries: this.props.trackInfoLines[driver].boundaries,
      };
      if (newLines[driver].polyLines.length === 0) {
        delete newLines[driver];
      }
    });

    if (!isEqual(newLines, state.trackInfoLineToDraw)) {
      this.setState({
        ...this.state,
        trackInfoLineToDraw: newLines,
        reDrawn: true,
      });
    }
  }

  private getColor(date: Date): string {
    const colors = mapSettings.objectColoring;
    if (!date) return colors.default;

    const now = moment();
    const duration = moment.duration(now.diff(date));
    const diffInHours = duration.asHours();

    if (diffInHours < 6) {
      return colors.firstInterval;
    } else if (diffInHours < 12) {
      return colors.secondInterval;
    } else if (diffInHours < 24) {
      return colors.thirdInterval;
    } else {
      return colors.fourthInterval;
    }
  }

  public render(): ReactNode {
    const { clusteringActive } = this.props;
    const { trackInfoLineToDraw } = this.state;

    if (
      !this.props.trackInfo?.length ||
      !trackInfoLineToDraw ||
      !Object.keys(trackInfoLineToDraw).length
    ) {
      return <></>;
    } else {
      return (
        <ClusteredMapLayer isClusteringActive={clusteringActive}>
          <Pane name="trackinfoline-layer" style={{ zIndex: 301 }}>
            {Object.keys(trackInfoLineToDraw).map((driver: string) => {
              return trackInfoLineToDraw[driver].polyLines.map(
                (polyline: ITrackInfoPolyLine) => {
                  return polyline.lines.map(
                    (
                      line: ITrackInfoDisplay,
                      index: number,
                      arr: ITrackInfoDisplay[]
                    ) => {
                      const color = this.getColor(line.createdAtUtc);
                      const next = arr[index + 1];
                      if (!next) return null;
                      const pos1 = [
                        line.latitude,
                        line.longitude,
                      ] as LatLngExpression;
                      const pos2 = [
                        next.latitude,
                        next.longitude,
                      ] as LatLngExpression;

                      return (
                        <Polyline
                          key={line.id}
                          positions={[pos1, pos2]}
                          weight={4}
                          color={color}
                        >
                          <TrackInfoLinePopup trackInfo={line} />
                        </Polyline>
                      );
                    }
                  );
                }
              );
            })}
          </Pane>
        </ClusteredMapLayer>
      );
    }
  }
}

export default TrackInfoLineLayer;
