import React from 'react';
import PropTypes from 'prop-types';
import PaneHeadline from '../shared/paneHeadline';
import { faWrench } from '@fortawesome/free-solid-svg-icons';
import routes from '../../common/routes';
import { locale } from '../../common/localization';
import ServiceOrderDetailsGrid from "./ServiceOrderDetailsGrid";
import OrderComment from './OrderComment';
import OrderDeviations from './OrderDeviations';
import OrderPictures from './OrderPictures';
import { processServiceOrders } from '../shared/serviceOrderUtility';
import OrderDetailsTable from './OrderDetailsTable';

function getTitle(serviceOrders) {
  return serviceOrders
    && serviceOrders[0]
    && serviceOrders[0].placeEstate
    || '';
}

function ServiceOrderDetails({ serviceOrders, match }) {
  const listId = parseInt(match.params.listId, 10);
  const backUrl = `${routes.serviceListsPath}/${listId}`

  const orderDetails = processServiceOrders(serviceOrders);

  return (
    <div className="service-order-details-view">
      <PaneHeadline
        titleText={getTitle(serviceOrders)}
        titleIcon={faWrench}
        backUrl={backUrl}
        backTitle={locale.serviceOrderDetails._serviceOrders}
      />
      <OrderDetailsTable orderDetails={orderDetails} />
      <ServiceOrderDetailsGrid serviceOrders={serviceOrders} />
      <OrderComment orderDetails={orderDetails} />
      <OrderDeviations orderDetails={orderDetails} />
      <OrderPictures orderDetails={orderDetails} />
    </div>
  );
}

ServiceOrderDetails.propTypes = {
  match: PropTypes.object.isRequired,
  serviceOrders: PropTypes.array,
}

export default ServiceOrderDetails;
