import React, { Component, ReactNode } from 'react';
import { IUser, IVehicle } from '../../models';
import { ICustomerMessage } from '../../models/CustomerMessage.model';
import yup from '../../common/validation';
import { Formik } from 'formik';
import { Button, Form, Modal } from 'react-bootstrap';
import { locale } from '../../common/localization';
import { debounceEventHandler } from '../shared/submitFormUtility';
import TextField from '../shared/formikFields/TextField';
import { DatePicker } from '../shared/formikFields';
import { filterVehicles } from '../vehicles/vehicleUtils';
import VehicleSelector, { IVehicleOption } from './VehicleSelector';
import moment from 'moment';
import MessageService from '../../services/MessageService';
import { Spinner2 } from '../shared/spinner2';
import { Toastr } from '../../utils/Toastr';

export interface IMessageDialogProps {
  customer: any;
  vehicles: Array<IVehicle>;
  show: boolean;
  user: IUser;
  onHide: () => void;
}

export interface IMessageDialogState {
  selectedVehicleId: string;
  message: string;
  isLoading: boolean;
}

export default class MessageDialog extends Component<
  IMessageDialogProps,
  IMessageDialogState
> {
  public readonly state: IMessageDialogState = {
    selectedVehicleId: null,
    message: '',
    isLoading: false,
  };
  private async onSubmit(message: ICustomerMessage): Promise<void> {
    const messageObject = { ...message };
    messageObject.text = this.state.message;
    messageObject.vehicleId = this.state.selectedVehicleId;
    messageObject.expires = this.formatDate(message.expires as Date);
    this.setState({ ...this.state, isLoading: true });
    try {
      await MessageService.sendMessage(messageObject);
      Toastr.success(locale.customerRegister.message._messageSent);
    } catch (error) {
      Toastr.error(error);
    } finally {
      this.setState({ ...this.state, isLoading: false });
      this.props.onHide();
    }
  }
  private getSchema() {
    return yup.object({
      expires: yup
        .date()
        .required()
        .min(moment().startOf('day').toDate())
        .label(locale.customerRegister.message._expires),
    });
  }
  private getVehicles(vehicles: Array<IVehicle>, user: IUser): Array<IVehicle> {
    let filteredVehicles: Array<IVehicle> = [];
    if (vehicles?.length) {
      if(user.impersonatedUser){
        return vehicles;
      }

      const customerId = user.customerId;
      if (user.isAdmin) {
        filteredVehicles = filterVehicles(vehicles, { customerId });
      } else {
        const contractorId = user.contractorId;
        filteredVehicles = filterVehicles(vehicles, {
          customerId,
          contractorId,
        });
      }
      return filteredVehicles;
    } else {
      return filteredVehicles;
    }
  }

  private getNewMessage(customer: any, user: IUser): ICustomerMessage {
    const message: ICustomerMessage = {
      address: customer.estate,
      agreementId: customer.agreementId,
      customerId: customer.customerId,
      expires: new Date(),
      paSystem: customer.paSystem,
      sender: user.name,
      text: '',
      title: '',
      vehicleId: '',
    };
    return message;
  }

  private vehicleChanged(vehicle: IVehicleOption): void {
    this.setState({ ...this.state, selectedVehicleId: vehicle.value });
  }

  private handleMessageChange(event: any) {
    this.setState({ ...this.state, message: event.target.value });
  }

  private formatDate(date: Date): string {
    const offset = date.getTimezoneOffset() * 60000;
    const localISOTime = new Date(date.getTime() - offset)
      .toISOString()
      .slice(0, 19);

    return localISOTime;
  }

  public render(): ReactNode {
    const { customer, show, onHide, user, vehicles } = this.props;

    const message = this.getNewMessage(customer, user);
    const filteredVehicles = this.getVehicles(vehicles, user);

    return (
      <>
        <Formik
          onSubmit={(message: ICustomerMessage) => this.onSubmit(message)}
          initialValues={{ ...message }}
          validationSchema={this.getSchema()}
        >
          {({ handleSubmit }) => (
            <Modal
              show={show}
              onHide={() => onHide()}
              className="customer-message-modal"
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  {locale.customerRegister.message._directMessageToCustomer}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Spinner2 show={this.state.isLoading} />
                <Form noValidate onSubmit={handleSubmit}>
                <TextField
                    label={locale.customerRegister.message._address}
                    name="address"
                    placeholder={locale.customerRegister.message._address}
                    disabled={true}
                  />
                  <TextField
                    label={locale.customerRegister.message._sender}
                    name="sender"
                    placeholder={locale.customerRegister.message._sender}
                    disabled={true}
                  />
                  <DatePicker
                    label={locale.customerRegister.message._expires}
                    name="expires"
                    popupClass="date-picker-popup-above-modal"
                    className="message-dialog-input"
                  />
                  <VehicleSelector
                    vehicles={filteredVehicles}
                    handleChange={this.vehicleChanged.bind(this)}
                    className="col-sm-12"
                  />
                  <div className="form-group row">
                    <div className="col-sm-12">
                      <label className="form-label">
                        {locale.customerRegister.message._message}
                      </label>
                    </div>
                    <textarea
                      value={this.state.message}
                      onChange={this.handleMessageChange.bind(this)}
                      rows={5}
                      className="customer-message-box"
                    />
                  </div>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => onHide()}>
                  {locale.vehicles._cancel}
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  onClick={debounceEventHandler(handleSubmit, 250)}
                  disabled={
                    !this.state.selectedVehicleId || !this.state.message.length
                  }
                >
                  {locale.customerRegister.message._send}
                </Button>
              </Modal.Footer>
            </Modal>
          )}
        </Formik>
      </>
    );
  }
}
