import customerConstants from './customerConstants';

const initialState = {
  customers: [],
  searchedCustomers: [],
  isLoading: false,
};

export default function customersReducer(state = initialState, action) {
  switch (action.type) {
    case customerConstants.GET_CUSTOMERS_SUCCESS:
      return {
        ...state,
        customers: action.customers,
      };
    case customerConstants.GET_CUSTOMERS_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case customerConstants.SEARCH_CUSTOMERS:
      return {
        ...state,
        isLoading: true,
      };
    case customerConstants.SEARCH_CUSTOMERS_SUCCESS:
      return {
        ...state,
        searchedCustomers: action.customers,
        isLoading: false,
      };
    case customerConstants.SEARCH_CUSTOMERS_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    default:
      return state;
  }
}
