import React, { FunctionComponent } from 'react';
import {
  GridColumnMenuFilter,
  GridColumnMenuProps,
} from '@progress/kendo-react-grid';

export const RouteAdminMenu: FunctionComponent<GridColumnMenuProps> = (
  props: GridColumnMenuProps
) => {
  return (
    <div>
      <GridColumnMenuFilter {...props} expanded={true} />
    </div>
  );
};
