import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import routes from '../../common/routes';
import {
  getSelectedServiceList,
  getSelectedServiceOrder,
} from '../../redux/modules/serviceOrders/serviceOrderSelectors';
import ZoomManager from '../zoomManager';

function mapServiceOrderToCoordinates(serviceOrder) {
  return serviceOrder
    ? [serviceOrder.agreementGpsX, serviceOrder.agreementGpsY]
    : [];
}

function mapServiceListToCoordinates(serviceList) {
  return serviceList && serviceList.orders
    ? serviceList.orders.map(mapServiceOrderToCoordinates)
    : [];
}

function serviceListsViewActive(location) {
  return location.pathname.startsWith(routes.serviceListsPath);
}

function serviceOrdersViewActive(location) {
  return !!location.pathname.match(routes.serviceOrders.pattern);
}

function serviceListToIdString(list) {
  return list
    ? list.listId
    : '';
}

function serviceOrderToIdString(order) {
  return order
    ? [order.listId, order.listItemId].join('#')
    : '';
}

function ServiceListZoomManager({
  location,
  selectedServiceList,
  selectedServiceOrder,
}) {
  return (
    <>
      <ZoomManager
        name="SELECTED_SERVICE_LIST"
        isActive={serviceListsViewActive(location)}
        objectId={serviceListToIdString(selectedServiceList)}
        priority={10}
        positions={mapServiceListToCoordinates(selectedServiceList)}
      />
      <ZoomManager
        name="SELECTED_SERVICE_ORDER"
        isActive={serviceOrdersViewActive(location)}
        objectId={serviceOrderToIdString(selectedServiceOrder)}
        priority={20}
        positions={[mapServiceOrderToCoordinates(selectedServiceOrder)]}
      />
    </>
  );
}

ServiceListZoomManager.propTypes = {
  selectedServiceList: PropTypes.object,
  selectedServiceOrder: PropTypes.object,
  location: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    selectedServiceList: getSelectedServiceList(state),
    selectedServiceOrder: getSelectedServiceOrder(state),
    location: state.router.location,
  }
}

export default connect(mapStateToProps)(ServiceListZoomManager);
