import { getStatisticsForOrders } from "./orderStatisticsUtility";
import memoizeOne from 'memoize-one';

const pieChartColors = [
  '#0275d8',
  '#5bc0de',
  '#5cb85c',
  '#f0ad4e',
  '#e67d4a',
  '#d9534f',
]

function processDeviations(deviations) {
  const deviationSeries = [];
  let currentColor = 0;
  let totalDeviations = 0;
  for (let [name, count] of deviations) {
    deviationSeries.push({
      category: name,
      value: count,
      color: pieChartColors[currentColor]
    });

    totalDeviations += count;
    currentColor = (currentColor + 1) % pieChartColors.length;
  }

  return { deviationSeries, totalDeviations };
}

function processStatistics(statistics) {
  if (!statistics) {
    return null;
  }

  const { deviationSeries, totalDeviations } = processDeviations(statistics.deviations)

  const executionRate = statistics.executionStatus.yes
    / (statistics.executionStatus.yes + statistics.executionStatus.no) * 100;

  return {
    executionStatus: statistics.executionStatus,
    deviations: deviationSeries,
    totalDeviations,
    executionRate,
    dataButtons: statistics.dataButtons,
  }
}

function getChartData(orders, userDataButtons) {
  let ordersArray = orders;
  if (orders && !Array.isArray(orders)) {
    ordersArray = [orders];
  }
  const orderStatistics = getStatisticsForOrders(ordersArray, userDataButtons);
  return processStatistics(orderStatistics);
}

const memoizeGetChartData = memoizeOne(getChartData);

export default memoizeGetChartData;
