import React from 'react';
import PropTypes from 'prop-types';
import OrderItemTypes from '../serviceLists/OrderItemTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLongArrowAltLeft,
  faLongArrowAltRight,
  faExchangeAlt,
  faWrench,
  faSearch,
  faToolbox,
} from '@fortawesome/free-solid-svg-icons';
import './order-item-type-icon.scss';

function OrderItemTypeIcon({ orderItemType }) {
  const type = getType(orderItemType);
  
  switch (type) {
    case OrderItemTypes.in: {
      return <FontAwesomeIcon icon={faLongArrowAltLeft} className="order-item-type-icon" />;
    }
    case OrderItemTypes.out: {
      return <FontAwesomeIcon icon={faLongArrowAltRight} className="order-item-type-icon" />;
    }
    case OrderItemTypes.replace: {
      return <FontAwesomeIcon icon={faExchangeAlt} className="order-item-type-icon" />;
    }
    case OrderItemTypes.rep: {
      return <FontAwesomeIcon icon={faWrench} className="order-item-type-icon" />;
    }
    case OrderItemTypes.inspection: {
      return <FontAwesomeIcon icon={faSearch} className="order-item-type-icon" />;
    }
    case OrderItemTypes.service: {
      return <FontAwesomeIcon icon={faToolbox} className="order-item-type-icon" />;
    }
    default: {
      return null;
    }
  }
}

function getType(orderItemType) {
  if (!Array.isArray(orderItemType)) {
    return orderItemType;
  }

  if (
    orderItemType.includes(OrderItemTypes.in)
    && orderItemType.includes(OrderItemTypes.out)
  ) {
    return OrderItemTypes.replace;
  }

  return orderItemType[0];
}

OrderItemTypeIcon.propTypes = {
  orderItemType: PropTypes.any.isRequired,
}

export default OrderItemTypeIcon;
