import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ActionButtonCell({ dataItem, action, icon }) {
  return (
    <td className="action-button">
      <button onClick={() => action(dataItem)}>
        <FontAwesomeIcon icon={icon} />
      </button>
    </td>
  );
}

ActionButtonCell.propTypes = {
  dataItem: PropTypes.object.isRequired,
  action: PropTypes.func.isRequired,
  icon: PropTypes.any.isRequired,
}

export default ActionButtonCell;
