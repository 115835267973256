import { withAuth0, WithAuth0Props } from '@auth0/auth0-react';
import './ProfileMenu.scss';
import { Dispatch } from 'redux';
import { IUser } from '../../models';
import React, { Component, ReactNode } from 'react';
import { locale } from '../../common/localization';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCog } from '@fortawesome/free-solid-svg-icons';
import EventBus from '../../common/EventBus';
import { Link } from 'react-router-dom';
import routes from '../../common/routes';

export interface IProfileMenuProps extends WithAuth0Props {
  authentication: IUser;
  logout: (auth0: any) => (dispatch: Dispatch) => Promise<void>;
}

export interface IProfileMenuState {
  isMenuOpen: boolean;
  isLanguageMenuOpen: boolean;
}

class ProfileMenu extends Component<IProfileMenuProps, IProfileMenuState> {
  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  constructor(props: any) {
    super(props);
    this.checksMenuElements = this.checksMenuElements.bind(this);
  }
  public readonly state: IProfileMenuState = {
    isMenuOpen: false,
    isLanguageMenuOpen: false,
  };
  public componentDidMount() {
    this.setState({ ...this.state, isMenuOpen: false });
    EventBus.subscribe('onProfileMenu', this.onProfileMenu);
    EventBus.subscribe('onLogout', this.logout);
  }

  public componentWillUnmount() {
    EventBus.unsubscribe('onProfileMenu');
  }

  private logout = (onUnauthorized: boolean)  => {
    if (onUnauthorized){
      this.props.logout(this.props.auth0)
    }    
  }

  private onProfileMenu = (data: any) => {
    this.setState({ ...this.state, isMenuOpen: data.isOpen });
    if (data.isOpen) {
      setTimeout(() => {
        document
          .getElementById('profileMenuOverlay')
          .addEventListener('click', this.closeProfileMenu);
        const mainElements = document.querySelectorAll('.main-element');
        mainElements.forEach((element) => {
          element.addEventListener('mouseenter', this.checksMenuElements);
        });
      }, 50);
    }
  };

  private checksMenuElements(event: any) {
    const element = event.currentTarget;
    if (element.classList.contains('languages')) {
      this.toggleLangMenu(true);
    } else {
      this.toggleLangMenu(false);
    }
  }

  private toggleLangMenu(toggle: boolean) {
    this.setState({ ...this.state, isLanguageMenuOpen: toggle });
  }

  private closeProfileMenu = () => {
    setTimeout(() => {
      this.setState({
        ...this.state,
        isMenuOpen: false,
        isLanguageMenuOpen: false,
      });
    }, 50);
  };
  public render(): ReactNode {
    const auth0 = this.props.auth0;
    const { isMenuOpen } = this.state;
    const { isAdmin, isLocalAdmin } = this.props.authentication;
    const className = isAdmin || isLocalAdmin ? 'admin-profile-menu ' : '';
    return (
      <>
        {isMenuOpen && (
          <div id="profileMenuOverlay">
            <div className={'profile-menu-wrapper'}>
              {this.state.isLanguageMenuOpen && (
                <div className="language-selector">
                  <span className="profile-menu-element">Norsk</span>
                  <span className="profile-menu-element">English</span>
                  <span className="profile-menu-element">Română</span>
                </div>
              )}
              <div className={className + 'profile-menu'}>
                {/* <div className="profile-menu-element main-element languages">
                  <FontAwesomeIcon icon={faChevronLeft} />{' '}
                  {locale.general._selectLanguage}
                </div> */}
                {(isAdmin || isLocalAdmin) && (
                  <Link
                    to={routes.administration.base}
                    className="profile-menu-element main-element"
                  >
                    <FontAwesomeIcon icon={faUserCog} />
                    {locale.general._administration}
                  </Link>
                )}
                <div
                  className="profile-menu-element main-element"
                  onClick={() => this.props.logout(auth0)}
                >
                  <FontAwesomeIcon icon="sign-out-alt" />
                  {locale.general._logOut}
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default withAuth0(ProfileMenu);
