import { ITrackInfo, ITrackInfoLines } from '../../../models';
import {
  TrackInfoConstants,
  TrackInfoLineConstants,
} from './trackInfoConstants';
import * as Actions from './trackInfoActions';
import { Dispatch } from 'redux';
import TrackInfoService from '../../../services/trackInfoService';

export function createTrackInfosListRequest(): Actions.SetTrackInfosList {
  return {
    type: TrackInfoConstants.GET_TRACK_INFO_REQUEST,
  };
}

export function createTrackInfosListSuccess(
  payload: Array<ITrackInfo>
): Actions.SetTrackInfosList.Success {
  return {
    type: TrackInfoConstants.GET_TRACK_INFO_SUCCESS,
    payload,
  };
}
export function createTrackInfosListFailure(
  error: Error
): Actions.SetTrackInfosList.Failure {
  return {
    type: TrackInfoConstants.GET_TRACK_INFO_FAILURE,
    error,
  };
}

export function createTrackInfoLinesListSuccess(
  payload: ITrackInfoLines
): Actions.SetTrackInfoLinesList.Success {
  return {
    type: TrackInfoLineConstants.GET_TRACK_INFO_LINES_SUCCESS,
    payload,
  };
}

export function createTrackInfoLinesListFailure(
  error: Error
): Actions.SetTrackInfoLinesList.Failure {
  return {
    type: TrackInfoLineConstants.GET_TRACK_INFO_LINES_FAILURE,
    error,
  };
}

export function createResetState(): Actions.ResetState {
  return {
    type: TrackInfoConstants.RESET_STATE,
  };
}

export function loadTrackInfos(
  ignoreCache = false,
  orderId: number
): (dispatch: Dispatch<Actions.All>) => Promise<void> {
  return async function (dispatch: Dispatch<Actions.All>): Promise<void> {
    dispatch(createTrackInfosListRequest());
    try {
      const result: Array<ITrackInfo> = await TrackInfoService.getTrackInfo(
        ignoreCache,
        orderId
      );
      dispatch(createTrackInfosListSuccess(result));
      const trackInfoLines =
        TrackInfoService.convertTrackInfoToTrackInfoLines(result);
      dispatch(createTrackInfoLinesListSuccess(trackInfoLines));
    } catch (error) {
      dispatch(createTrackInfosListFailure(error));
    }
  };
}

export function resetState(): Actions.ResetState {
  return createResetState();
}
