import { LatLngTuple } from 'leaflet';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICoords extends LatLngTuple {}
export namespace ICoords {
  export function hash(coords: ICoords): string {
    return `${coords[0]}#${coords[1]}`;
  }

  export function has(array: Array<ICoords>, coords: ICoords): boolean {
    return array.map((coords) => hash(coords)).includes(hash(coords));
  }

  export function arrayEquals(a: Array<ICoords>, b: Array<ICoords>): boolean {
    return a.map(hash).join(',') === b.map(hash).join(',');
  }

  export function equals(a: ICoords, b: ICoords): boolean {
    return hash(a) === hash(b);
  }

  export function indexOf(array: Array<ICoords>, coords: ICoords): number {
    return array.map(hash).indexOf(hash(coords));
  }
}
