import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  GridColumn as Column,
  GridColumnMenuFilter,
  GridNoRecords,
} from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import { locale } from '../../common/localization';
import ColumnMenu from '../shared/ColumnMenu';
import CheckboxCell from '../shared/checkboxCell/CheckboxCell';
import CommandCell from './CommandCell';
import ModulesCell from './ModulesCell';
import ContractorCell from './ContractorCell';

class UserAdmin extends PureComponent {
  constructor(props) {
    super(props);

    this.dataStateChange = this.dataStateChange.bind(this);
    this.CommandCell = this.CommandCell.bind(this);
  }

  static propTypes = {
    users: PropTypes.array.isRequired,
    startEditingUser: PropTypes.func.isRequired,
    contractors: PropTypes.array.isRequired,
  }

  state = {
    dataState: {
      sort: [
        {
          dir: "asc",
          field: "userName",
        }
      ]
    }
  }

  dataStateChange(event) {
    this.setState({ dataState: event.data });
  }

  columnProps(field, filterColumnMenu) {
    return {
      field: field,
      columnMenu: filterColumnMenu || ColumnMenu,
      headerClassName: this.isColumnActive(field, this.state.dataState) ? 'active' : '',
      title: locale.userAdmin['_' + field],
    };
  }

  isColumnActive(field, dataState) {
    return GridColumnMenuFilter.active(field, dataState.filter);
  }

  CommandCell(props) {
    return <CommandCell {...props} edit={this.props.startEditingUser} />
  }

  render() {
    const { users, contractors } = this.props;
    const result = process(users, this.state.dataState);

    const CustomContractorCell = (props) => <ContractorCell {...props} contractors={contractors} />;

    return (
      <Grid
        {...this.state.dataState}
        className="mb-3"
        data={result}
        selectedField="selected"
        scrollable={"none"}
        sortable
        onDataStateChange={this.dataStateChange}
      >
        <GridNoRecords>
          {locale.userAdmin._noUsers}
        </GridNoRecords>
        <Column {...this.columnProps('userName')} />
        <Column {...this.columnProps('system')} width={80} />
        <Column {...this.columnProps('contractorId')} cell={CustomContractorCell} width={120} />
        <Column {...this.columnProps('localAdmin')} cell={CheckboxCell} width={100} />
        <Column {...this.columnProps('admin')} cell={CheckboxCell} width={75} />
        <Column {...this.columnProps('modules')} cell={ModulesCell} width={260} />
        <Column cell={this.CommandCell} width={26} sortable={false} />
      </Grid>
    );
  }
}

export default UserAdmin;
