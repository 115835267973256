import { IAgreementLine } from './AgreementLine.model';
import { IRouteLine } from './RouteLine.model';

export enum ReportDetailsMessage {
  YES = 'Ja',
  NO = 'Nei',
}
export interface IReportDetailsView extends Record<string, any> {
  message: ReportDetailsMessage;
}
export interface IRouteStop {
  accuracy: number;
  createdAtUTC: string;
  latitude: number;
  longitude: number;
  orderId: number;
  remoteAddress: string;
  speed: number;
  updatedAtUTC: string;
  userAgent: string;
  username: string;
  customerId: number;
  routeLineId: number;
  paSystem: string;
  agreementLineId: number;
  extId: number;
  extOrderNr: number;
  inserted: string;
  updated: string;
  routeLine: IRouteLine;
  reportDetailsView: IReportDetailsView;
  agreementLine: IAgreementLine;
}
