import { bindActionCreators, Dispatch } from 'redux';
import { IRootState } from '../../redux/state';
import { IUser } from '../../models';
import Customers from './Customers';
import { connect } from 'react-redux';
import customerActions from '../../redux/modules/customer/customerActions';
import { fetchVehicles } from '../../redux/modules/vehicles/vehiclesCreators';
import { IVehiclesState } from '../vehicles/Vehicles';

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      searchCustomers: customerActions.searchCustomers,
      fetchVehicles: fetchVehicles,
    },
    dispatch
  );
}

function mapStateToProps(state: IRootState) {
  return {
    impersonatedUser: state.authentication.impersonatedUser,
    filteredCustomers: state.customer.searchedCustomers,
    authentication: state.authentication as unknown as IUser,
    isLoading: state.customer.isLoading,
    vehicles: state.vehicles as IVehiclesState,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Customers);
