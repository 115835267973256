export function processAnalysisResult(analysisResult, importedRoutes, depots) {
  const worklists = convertResultsToWorklists(analysisResult, importedRoutes);
  const unassignedStops = getUnassignedStops(analysisResult, importedRoutes);
  const depotStops = convertResultsToDepotStopLists(analysisResult, depots);

  return { worklists, unassignedStops, depotStops };
}

function convertResultsToWorklists(analysisResult, importedRoutes) {
  return analysisResult.routes
    .map((route) =>
      analysisResult.stops
        .filter((stop) => stop.routeId === route.id)
        .map((stop) => convertStopToWorklistItem(stop, importedRoutes))
    )
    .filter((list) => list.length > 0);
}

function convertStopToWorklistItem(stop, importedRoutes) {
  const importedRoute = importedRoutes.find((r) => r.gln == stop.stopId);

  return {
    sequence: stop.sequence,
    arrivalTime: stop.arrivalTimeUtc,
    gln: stop.stopId,
    customerName: importedRoute.customerName,
    description: importedRoute.description,
    serial: importedRoute.serial,
    latitude: importedRoute.latitude,
    longitude: importedRoute.longitude,
  };
}

function getUnassignedStops(analysisResult, importedRoutes) {
  const assignedStopIds = new Set(
    analysisResult.stops.map((stop) => stop.stopId)
  );

  return importedRoutes.filter(
    (route) => !assignedStopIds.has(Number(route.gln))
  );
}

function convertResultsToDepotStopLists(analysisResult, depots) {
  return analysisResult.routes
    .map((route) =>
      analysisResult.depotStops
        .filter((depotStop) => depotStop.routeId === route.id)
        .map((depotStop) => convertDepotStop(depotStop, depots))
    )
    .filter((list) => list.length > 0);
}

function convertDepotStop(depotStop, depots) {
  const depot = depots.find((d) => d.depotId === depotStop.stopId);

  return {
    ...depot,
    sequence: depotStop.sequence,
    arrivalTime: depotStop.arrivalTimeUtc,
  };
}
