import { latLngBounds } from 'leaflet';
import { MapUtility } from '../components/map/MapUtility';
import { ZoomableObjectTypes } from '../components/map/MapWrapperConstants';
import { LeafletMapSettings } from '../sharedConfig';
import { ICoords, IZoomableObject } from '../models';
import { selectedRegion } from '../appConfig';

export interface IMappingOptions {
  name?: string;
  priority?: number;
  ignoreUpdate?: boolean;
}
export namespace IMappingOptions {
  export function Empty(): IMappingOptions {
    return { name: '', priority: 0, ignoreUpdate: false };
  }
}

export abstract class ZoomableUtility {
  public static mapToZoomable(
    positions: Array<ICoords> = [],
    options: IMappingOptions = IMappingOptions.Empty()
  ): IZoomableObject {
    const { name, priority } = { ...IMappingOptions.Empty(), ...options };
    if (!positions?.length) {
      console.error('positions array is not present');
      return null;
    }

    const validPositions = positions.filter(([lat, lng]) => {
      return MapUtility.isInRegion(lat, lng, selectedRegion);
    });

    if (validPositions?.length === 0) {
      console.error('positions array does not have valid elements');
      return null;
    }

    if (validPositions.length === 1) {
      return {
        name,
        priority,
        type: ZoomableObjectTypes.POINT,
        zoomLevel: LeafletMapSettings.routeStopSelectionZoomLevel,
        point: validPositions[0],
      };
    }

    return {
      name,
      priority,
      type: ZoomableObjectTypes.BOUNDS,
      bounds: latLngBounds(validPositions),
      ignoreUpdate: false,
    };
  }
}
