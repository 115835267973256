import { vehicleTrackingConstants } from './vehicleTrackingConstants';
import VehicleService from '../../../components/vehicleTrackingLayer/VehicleService';
import loadingActions from "../loading/loadingActions";
import cacheRefresherActions from "../cacheRefresher/cacheRefresherActions";

export function getVehicles(ignoreCache = false, silent = false) {
  return dispatch => {
    dispatch(cacheRefresherActions.addRequestInProgress(vehicleTrackingConstants.GET_VEHICLES));
    if (!silent) {
      dispatch(loadingActions.addLoadingInProgress(vehicleTrackingConstants.GET_VEHICLES));
    }

    VehicleService.getVehicles(ignoreCache)
      .then(vehicles => dispatch(success(vehicles)))
      .catch(error => {
        let errorString = JSON.stringify(error);
        dispatch(failure(errorString));
      }).finally(() => {
        dispatch(cacheRefresherActions.removeRequestInProgress(vehicleTrackingConstants.GET_VEHICLES));
        dispatch(loadingActions.removeLoadingInProgress(vehicleTrackingConstants.GET_VEHICLES));
      });
  };

  function success(vehicles) { return { type: vehicleTrackingConstants.GET_VEHICLES_SUCCESS, vehicles } }
  function failure(error) { return { type: vehicleTrackingConstants.GET_VEHICLES_FAILURE, error } }
}
