import axios, { AxiosRequestConfig } from 'axios';
import { wasteManagementUrls } from '../../appConfig';
import { store } from '../../redux/store';

const CancelToken = axios.CancelToken;
const cancelTokenSource = CancelToken.source();

const impersonationBlacklist = [
  wasteManagementUrls.users,
  wasteManagementUrls.logout,
  wasteManagementUrls.auth,
];

export const requestInterceptor = (
  config: AxiosRequestConfig
): AxiosRequestConfig => {
  const {
    authentication: { impersonatedUser, token },
  } = store.getState();
  if (!token) {
    return config;
  }

  if (!impersonationBlacklist.includes(config.url) && impersonatedUser) {
    config.headers.common['Impersonate-User'] = impersonatedUser;
  }

  config.headers.common['Authorization'] = `Bearer ${token}`;
  config.cancelToken = cancelTokenSource.token;
  return config;
};
