import React, { ReactNode, Component } from 'react';
import './Spinner2.scss';
import { SpinnerPosition } from '../../../models/Spinner.model';
export interface ISpinner2Props {
  show: boolean;
  position?: SpinnerPosition /** @todo Only CenterCenter and TopCenter is defined in CSS, define other possibilities as well */;
}

export class Spinner2 extends Component<ISpinner2Props> {
  public render(): ReactNode {
    const { show } = this.props;
    if (!show) return null;
    return (
      <div className={`spinner2-wrapper ${this.props.position}`}>
        <div className="spinner text-center">
          <div className="cube1"></div>
          <div className="cube2"></div>
        </div>
      </div>
    );
  }
}
