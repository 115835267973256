import { Component } from 'react';
import { Connect } from 'react-redux';
import { Action, Dispatch } from 'redux';
import { IRootState } from '../redux/state';

export function empty(): void {
  /** do nothing */
}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IEmptyProps {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SyncAction extends Action {}

export type AsyncAction<T = void> = (
  dispatch: Dispatch,
  getState: () => IRootState
) => Promise<T>;

export interface HOCComponentRef<T extends Component> extends Connect<unknown> {
  getWrappedInstance: () => T;
}
