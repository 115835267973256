import { All as Actions } from './trackingLineActions';
import { TrackingLineConstants } from './trackingLineConstants';
import { ITrackingLineState } from './trackingLineState';

const initialState: ITrackingLineState = {
  trackingLineList: [],
  errorMessage: null,
  isTrackingLineListLoading: false,
};

export function trackingLine(
  state = initialState,
  action: Actions
): ITrackingLineState {
  switch (action.type) {
    case TrackingLineConstants.GET_ORDER_LINES_REQUEST: {
      return {
        ...state,
        isTrackingLineListLoading: true,
      };
    }
    case TrackingLineConstants.GET_ORDER_LINES_SUCCESS: {
      return {
        ...state,
        isTrackingLineListLoading: false,
        trackingLineList: action.payload,
      };
    }
    case TrackingLineConstants.GET_ORDER_LINES_FAILURE: {
      return {
        ...state,
        isTrackingLineListLoading: false,
        errorMessage: action.error.message,
      };
    }
    case TrackingLineConstants.RESET_STATE: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
}
