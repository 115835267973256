import { createSelector } from 'reselect';
import qs from 'qs';

export const makeGetOrderByPathParams = () => createSelector(
  [
    (state) => state.order.data,
    (_state, props) => Number(props.match.params.orderId),
  ],
  (orders, orderId) => orders.find(o => o.orderId === orderId),
);

function parseQueryString(search) {
  const params = qs.parse(search, { ignoreQueryPrefix: true });
  return {
    routeLineId: Number(params.routeLineId),
    paSystem: params.paSystem,
    agreementLineId: Number(params.agreementLineId),
  }
}

const getRouteStopParameters = createSelector(
  (state) => state.router.location.search,
  parseQueryString,
);

function findRouteStop(order, params) {
  if (order && order.generatedRoutes) {
    return order.generatedRoutes.find(routeStop =>
      routeStop.routeLineId === params.routeLineId
      && routeStop.paSystem === params.paSystem
      && routeStop.agreementLineId === params.agreementLineId
    );
  }

  return null;
}

export const makeGetRouteStopByQueryString = (orderSelector) => createSelector(
  [orderSelector, getRouteStopParameters],
  findRouteStop,
);
