import qs from 'qs';
import axios, { AxiosRequestConfig } from 'axios';
import localforage from 'localforage';
import memoryDriver from 'localforage-memoryStorageDriver';
import { setupCache } from 'axios-cache-adapter';
import { Toastr } from '../utils/Toastr';
import { mapServiceUrls, cacheSettings } from '../appConfig';
import { store } from '../redux/store';
import { locale } from '../common/localization';
import { requestInterceptor } from './interceptors';

const CancelToken = axios.CancelToken;
let cancelTokenSource = CancelToken.source();

const forageStore = localforage.createInstance({
  driver: [
    localforage.INDEXEDDB,
    localforage.LOCALSTORAGE,
    memoryDriver._driver,
  ],
  name: 'waste-management-cache',
});

const cache = setupCache({
  maxAge: cacheSettings.maxAgeSeconds * 1000,
  exclude: { query: false },
  store: forageStore,
  limit: cacheSettings.limit as any,
  key: generateCacheKey,
});

const mapServiceClient = axios.create({
  baseURL: mapServiceUrls.baseUrl,
  headers: { 'Content-Type': 'application/json' },
  adapter: cache.adapter,
  paramsSerializer: (params) => {
    return qs.stringify(params);
  },
});

mapServiceClient.interceptors.request.use(requestInterceptor);

mapServiceClient.interceptors.response.use(
  (response) => response,
  (error) => {
    Toastr.error(locale.general._backendError);
    throw error;
  }
);

function generateCacheKey(request: AxiosRequestConfig): string {
  const key = request.url + qs.stringify(request.params);
  const impersonatedUser = store.getState().authentication.impersonatedUser;

  return impersonatedUser ? `${impersonatedUser}@${key}` : key;
}

export function resetClient(): void {
  forageStore.clear();
  cancelTokenSource.cancel('WasteManagement requests cancelled');
  cancelTokenSource = CancelToken.source();
}

export default mapServiceClient;
export { mapServiceClient };
