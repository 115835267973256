import { IVehiclesState } from './vehiclesState';
import { All as Actions } from './vehiclesActions';
import { VehiclesConstants } from './vehiclesConstants';

const initialState: IVehiclesState = {
  vehiclesList: [],
  isLoading: false,
  error: undefined,
};

export function vehicles(
  state = initialState,
  action: Actions
): IVehiclesState {
  switch (action.type) {
    case VehiclesConstants.GET_VEHICLES_LIST_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case VehiclesConstants.GET_VEHICLES_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        vehiclesList: action.payload,
      };
    }
    case VehiclesConstants.GET_VEHICLES_LIST_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error.message,
      };
    }
    case VehiclesConstants.RESET_STATE: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
}
