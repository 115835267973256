/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { wasteManagementUrls } from '../appConfig';
import { ICustomerMessage } from '../models/CustomerMessage.model';
import wasteManagementClient from './wasteManagementClient';

export default class MessageService {
  static sendMessage(message: ICustomerMessage): Promise<ICustomerMessage> {
    return wasteManagementClient
      .post(wasteManagementUrls.message, message)
      .then((resp) => {
        if (resp?.status === 200 && resp.data) {
          return resp.data;
        } else {
          return [];
        }
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }
}
