import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

export default function CommandCell({ dataItem, edit, icon = faEdit }) {
  return (
    <td>
      <button
        className="user-edit-button"
        onClick={() => edit(dataItem)}
      >
        <FontAwesomeIcon icon={icon} />
      </button>
    </td>
  );
}

CommandCell.propTypes = {
  dataItem: PropTypes.object.isRequired,
  edit: PropTypes.func.isRequired,
}
