import { MapType, RegionSetting } from './models';

export const stalkitServerUrl =
  'https://core.dev.stalkit.net/core-api-dev/api/v1';
export const testDeviceGroupPublicIdentifier =
  '30f87d5a-bcc2-4f3c-9c04-0f1469d4a211';
export const filterPositionDays = 4;
export const emailValidationEnabled = true;

export const esriGeodataToken =
  'H_V9sTjl4cKffp_m3CdkVZquEdTFx0aw-QOD7cwoFB_QBmNqF4y0PjXHXsB0I62o2YBs3F5WCeQUrDRphjf0YXoqBiO-wuYwKk8ovQryBZ8.';

export const cacheSettings = {
  limit: 20,
  maxAgeSeconds: 600,
};

// Used to automatically determine the unit of a series of weights.
export const autoWeightUnitSettings = {
  bound: 10,
  treshold: 0.5,
};
export const autoRefreshInterval = 60 * 1000;
export const reportsGridPageSize = 20;
export const reportsContactMailto = 'support@fieldata.no';

export const orderDetailsGridPageSize = 26;

export const defaultAnimatedNumberAnimationDuration = 1;

export const analyticsCode = 'UA-164795413-2';

export const selectedRegion = RegionSetting.Norway; //0 = Norway, 1 = Europe

export const serviceUrls = {
  baseUrl: '/api',
  trackingLatest: '/tracking/latest',
  devices: '/device',
  stalkitAuth: '/stalkitAuth',
  geodataToken: '/geodataToken',
};

export const wasteManagementUrls = {
  baseUrl: 'https://wmrestapi.azurewebsites.net/api/v1',
  orders: '/orders',
  serviceOrders: '/serviceOrders',
  serviceLists: '/serviceLists',
  containerOrders: '/containerOrders',
  route: '/routes',
  dataButtons: 'userSettings/dataButtons',
  visibleFields: 'userSettings/visibleFields',
  detailDataConfiguration: 'userSettings/detailDataConfiguration',
  users: '/users',
  customers: '/customers',
  modules: '/modules',
  contractors: '/contractors',
  auth: '/auth',
  auth0Auth: '/auth/auth0',
  logout: '/auth/logout',
  reports: '/reports',
  agreements: '/agreements',
  depots: '/depots',
  routeAnalysis: '/routeAnalysis',
  routes: '/routes',
  vehicles: {
    base: '/vehicles',
    positions: '/vehicles/positions',
    all: '/vehicles/all',
    settingTemplates: '/vehicles/settings',
    settings: '/settings',
    bulkSettings: '/settings/bulk',
  },
  deviations: {
    base: '/deviations',
  },
  fractions: '/fractions',
  message: '/message',
};

export const mapServiceUrls = {
  baseUrl: 'https://tracklog-mapservices.azurewebsites.net/api/v1',
  wms: 'https://wms.gisline.no/wms-stavanger',
};

export const mapServiceSettings = {
  trackingLinesRequestCacheInMilliseconds: 60 * 1000,
  maxSegmentLengthMeters: 100,
  trackingLineDebugPopupsEnabled: false,
  trackingLineProdPopupsEnabled: true,
  maxTrackInfoLinePointDistance: 150,
  minTrackInfoLinePointDistance: 1,
};

export const mapSettings = {
  mapType: MapType.UTM33N, //WGS84, UTM33N
  objectColoring: {
    default: 'gray',
    firstInterval: '#00dc00',
    secondInterval: '#0000dc',
    thirdInterval: '#fffb00',
    fourthInterval: '#dc0000',
  },
};
