import React, { useState } from 'react';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { locale } from '../../common/localization';
import ContractorAdmin from '../contractorAdmin';
import Vehicles from '../vehicles';
import Deviations from '../deviations';
import DeviationCategories from '../deviationCategories';
import { isNil } from 'lodash';
import { IUser } from '../../models';
import UserAdmin from '../userAdmin';

export interface IAdministrationPageProps {
  authentication: IUser;
}

const AdministrationPage: React.FC<IAdministrationPageProps> = ({
  // eslint-disable-next-line react/prop-types
  authentication,
}) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div>
      <TabStrip selected={activeTab} onSelect={(e) => setActiveTab(e.selected)}>
        <TabStripTab title={locale.general._vehicles}>
          {activeTab === 0 && <Vehicles />}
        </TabStripTab>
        {(authentication.isAdmin || isNil(authentication.contractorId)) && (
          <TabStripTab title={locale.contractors._contractors}>
            {activeTab === 1 && <ContractorAdmin />}
          </TabStripTab>
        )}
        <TabStripTab title={locale.deviations._deviations}>
          {activeTab ===
            (authentication.isAdmin || isNil(authentication.contractorId)
              ? 2
              : 1) && <Deviations />}
        </TabStripTab>
        <TabStripTab title={locale.deviations._deviationCategory}>
          {activeTab ===
            (authentication.isAdmin || isNil(authentication.contractorId)
              ? 3
              : 2) && <DeviationCategories />}
        </TabStripTab>
        {(authentication.isAdmin || isNil(authentication.contractorId)) && (
          <TabStripTab title={locale.general._userAdmin}>
            {activeTab === 4 && <UserAdmin />}
          </TabStripTab>
        )}
      </TabStrip>
    </div>
  );
};

export default AdministrationPage;
