import React from 'react';
import PropTypes from 'prop-types';
import { getContainerOrderDetailData } from '../containerOrders/ContainerOrdersUtility';
import { locale } from '../../common/localization';
import '../../styles/detailsView.scss';

function getOrderDetailLabel(orderDetail) {
  return {
    ...orderDetail,
    label: locale.containerOrderDetails[orderDetail.key],
  }
}

function ServiceOrderDetailsGrid({ containerOrder, visibleFields }) {
  if (!containerOrder) {
    return null;
  }

  const orderDetails = getContainerOrderDetailData(containerOrder, visibleFields)
    .map(getOrderDetailLabel);
  const unitName = containerOrder.agreementLine
    && containerOrder.agreementLine.unit
    && containerOrder.agreementLine.unit.name;

  return (
    <div>
      <div className="row">
        <div className="col-12">
          <div className="card mb-3">
            <div className="card-body details-container">
              {orderDetails.map((detail) => (
                <div className="cell" key={detail.key}>
                  <div className="label">{detail.label}</div>
                  <div className="value">{detail.value}</div>
                </div>
              ))
              }
              <div className="cell">
                <div className="label">{locale.containerOrderDetails._unitName}</div>
                <div className="value">{unitName}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

ServiceOrderDetailsGrid.propTypes = {
  containerOrder: PropTypes.object,
  visibleFields: PropTypes.array.isRequired,
}

export default ServiceOrderDetailsGrid;
