import { Formik } from 'formik';
import React, { Component, ReactNode } from 'react';
import { Form, Modal, Button } from 'react-bootstrap';
import { IAddVehicle, IVehicle } from '../../models/Vehicle.model';
import VehicleService from '../../services/vehicleService';
import TextField from '../shared/formikFields/TextField';
import { locale } from '../../common/localization';
import { Toastr } from '../../utils/Toastr';
import { debounceEventHandler } from '../shared/submitFormUtility';
import yup from '../../common/validation';

export interface IVehicleEditDialogProps {
  customerId: number;
  contractorId: number;
  vehicle: IVehicle;
  vehicleIdsExisting: string[];
  show: boolean;
  onHide: (rerender: boolean) => void;
}

export default class VehicleEditDialog extends Component<IVehicleEditDialogProps> {
  private async onSubmit(
    vehicle: IVehicle | IAddVehicle,
    isNew: boolean
  ): Promise<void> {
    if (isNew) {
      try {
        const vehicleToAdd: IVehicle = {
          ...vehicle,
          customerId: this.props.customerId,
          contractorId: this.props.contractorId,
        };
        await VehicleService.addVehicle(vehicleToAdd);
        Toastr.success(locale.vehicles._addMessageSuccessful);
      } catch (error) {
        console.log(error);
        Toastr.error(locale.vehicles._addMessageFailed, true);
      } finally {
        this.props.onHide(true);
      }
    } else {
      try {
        await VehicleService.updateVehicle(vehicle);
        Toastr.success(locale.vehicles._editMessageSuccessful);
      } catch (error) {
        console.log(error);
        Toastr.error(locale.vehicles._editMessageFailed, true);
      } finally {
        this.props.onHide(true);
      }
    }
  }

  private getSchema(isNew: boolean) {
    return yup.object({
      vehicleId: isNew
        ? yup
            .string()
            .required()
            .lowercase()
            .notOneOf(
              this.props.vehicleIdsExisting,
              locale.vehicles._vehicleAlreadyExistingError
            )
            .max(10)
            .matches(/^[a-zA-Z0-9]+$/, locale.vehicles._validationError)
            .label(locale.vehicles._vehicleId)
        : yup
            .string()
            .required()
            .max(10)
            .matches(/^[a-zA-Z0-9]+$/, locale.vehicles._validationError)
            .label(locale.vehicles._vehicleId),
      extVehicleId: yup.string().max(20).label(locale.vehicles._externalId),
      password: isNew
        ? yup
            .string()
            .required()
            .min(6)
            .max(100)
            .label(locale.vehicles._password)
        : yup.string().min(6).max(100).label(locale.vehicles._password),
      confirmPassword: isNew
        ? yup
            .string()
            .required()
            .max(50)
            .oneOf(
              [yup.ref('password'), null],
              locale.userAdmin._confirmPasswordError
            )
            .label(locale.vehicles._repeatPassword)
        : yup
            .string()
            .max(50)
            .oneOf(
              [yup.ref('password'), null],
              locale.userAdmin._confirmPasswordError
            )
            .label(locale.vehicles._repeatPassword),
      description: yup.string().max(50).label(locale.vehicles._description),
      cellular: yup.string().max(50).label(locale.vehicles._phoneNr),
    });
  }

  public render(): ReactNode {
    const isNew = !this.props.vehicle;
    const vehicle = this.props.vehicle || VehicleService.getInitVehicle(isNew);
    const show = this.props.show;
    const onHide = this.props.onHide;

    return (
      <Formik
        onSubmit={(vehicle: IVehicle | IAddVehicle) =>
          this.onSubmit(vehicle, isNew)
        }
        initialValues={{ ...vehicle }}
        validationSchema={this.getSchema(isNew)}
      >
        {({ handleSubmit }) => (
          <Modal
            show={show}
            onHide={() => onHide(false)}
            className="user-edit-modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {vehicle.extVehicleId
                  ? locale.vehicles._edit + ' ' + vehicle.extVehicleId
                  : locale.vehicles._addNewVehicle}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form noValidate onSubmit={handleSubmit}>
                <TextField
                  name="vehicleId"
                  label={locale.vehicles._vehicleId}
                  placeholder={locale.vehicles._vehicleId}
                  disabled={!isNew}
                />
                <TextField
                  name="extVehicleId"
                  label={locale.vehicles._externalId}
                  placeholder={locale.vehicles._externalId}
                />
                <TextField
                  name="password"
                  label={locale.vehicles._password}
                  placeholder={locale.vehicles._password}
                  type="password"
                />
                <TextField
                  name="confirmPassword"
                  label={locale.vehicles._repeatPassword}
                  placeholder={locale.vehicles._repeatPassword}
                  type="password"
                />
                <TextField
                  name="description"
                  label={locale.vehicles._description}
                  placeholder={locale.vehicles._description}
                />
                <TextField
                  name="cellular"
                  label={locale.vehicles._phoneNr}
                  placeholder={locale.vehicles._phoneNr}
                />
                <TextField
                  name="driver"
                  label={locale.vehicles._driver}
                  placeholder={locale.vehicles._driver}
                />
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => onHide(false)}>
                {locale.vehicles._cancel}
              </Button>
              <Button
                type="submit"
                variant="primary"
                onClick={debounceEventHandler(handleSubmit, 250)}
              >
                {isNew ? locale.vehicles._submit : locale.vehicles._update}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    );
  }
}
