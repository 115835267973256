import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import memoizeOne from 'memoize-one';
import { locale } from '../../common/localization';

function getCustomerOptions(customers) {
  return customers && customers.map((customer) => ({
    label: customer.name,
    value: customer.customerId,
  }));
}

const getCustomerOptionsMemoized = memoizeOne(getCustomerOptions);

export default function CustomerSelector({
  customers,
  handleChange,
  selectedCustomerId,
  className
}) {
  const options = getCustomerOptionsMemoized(customers);
  const selectedOption = options
    .find((option) => option.value === selectedCustomerId);

  return (
    <Select
      options={options}
      onChange={handleChange}
      value={selectedOption}
      placeholder={locale.userAdmin._customerSelectorPlaceholder}
      noOptionsMessage={() => locale.userAdmin._noCustomerOptionsMessage}
      className={className + ' customer-selector'}
    />
  );
}

CustomerSelector.propTypes = {
  customers: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  selectedCustomerId: PropTypes.number,
  className: PropTypes.string,
}
