import {
  ITrackingLine
} from '../../../models';
import { TrackingLineConstants } from './trackingLineConstants';
import * as Actions from './trackingLineActions';
import { Dispatch } from 'redux';
import TrackingLineService from '../../../services/trackingLineService';

export function createTrackingLinesListRequest(): Actions.SetTrackingLinesList {
  return {
    type: TrackingLineConstants.GET_ORDER_LINES_REQUEST,
  };
}
export function createTrackingLinesListSuccess(
  payload: Array<ITrackingLine>
): Actions.SetTrackingLinesList.Success {
  return {
    type: TrackingLineConstants.GET_ORDER_LINES_SUCCESS,
    payload,
  };
}
export function createTrackingLinesListFailure(
  error: Error
): Actions.SetTrackingLinesList.Failure {
  return {
    type: TrackingLineConstants.GET_ORDER_LINES_FAILURE,
    error,
  };
}

export function createResetState(): Actions.ResetState {
  return {
    type: TrackingLineConstants.RESET_STATE,
  };
}

export function loadTrackingLines(ignoreCache = false): (
  dispatch: Dispatch<Actions.All>
) => Promise<void> {
  return async function (dispatch: Dispatch<Actions.All>): Promise<void> {
    dispatch(createTrackingLinesListRequest());
    try {
      const result: Array<ITrackingLine> =
        await TrackingLineService.getAllTrackingLines(ignoreCache);
      dispatch(createTrackingLinesListSuccess(result));
    } catch (error) {
      dispatch(createTrackingLinesListFailure(error));
    }
  };
}

export function resetState(): Actions.ResetState {
  return createResetState();
}
