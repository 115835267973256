import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createLoadingSelector } from '../../../redux/modules/loading/loadingSelectors';
import depotConstants from '../../../redux/modules/depot/depotConstants';
import depotActions from '../../../redux/modules/depot/depotActions';
import RouteAnalysisModal from './RouteAnalysisModal';

const isDepotsLoading = createLoadingSelector(depotConstants.GET_DEPOTS);

function mapStateToProps(state) {
  return {
    isDepotsLoading: isDepotsLoading(state),
    depots: state.depot.depots,
    importedRoutes: state.routeOptimalization.importedRoutes,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    depotActions: bindActionCreators(depotActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RouteAnalysisModal);
