import { IZoomableObject } from '../../../models';
import { Actions } from './mapActions';
import { MapConstants } from './mapConstants';
import { IMapState } from './mapState';

const initialState: IMapState = {
  zoomableObjectList: [],
};

export function map(state = initialState, action: Actions): IMapState {
  switch (action.type) {
    case MapConstants.SET_ZOOMABLE_OBJECTS: {
      const zoomableObjectList = [action.payload];
      return {
        ...state,
        zoomableObjectList,
      };
    }
    case MapConstants.ADD_ZOOMABLE_OBJECT: {
      const name = action.payload.name;
      const array = state.zoomableObjectList;
      return {
        ...state,
        zoomableObjectList: [
          ...array.filter((o: IZoomableObject) => o.name !== name),
          action.payload,
        ],
      };
    }
    case MapConstants.REMOVE_ZOOMABLE_OBJECT: {
      const name = action.payload;
      const array = state.zoomableObjectList;
      return {
        ...state,
        zoomableObjectList: [
          ...array.filter((o: IZoomableObject) => o.name !== name),
        ],
      };
    }
    default:
      return state;
  }
}
