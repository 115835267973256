import React from 'react';
import PropTypes from 'prop-types';
import { locale } from '../../common/localization';
import SimpleReactLightBox, { SRLWrapper } from 'simple-react-lightbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faMinus } from '@fortawesome/free-solid-svg-icons';
import { extractRouteStopDetails } from '../shared/routeStopUtility';
import { connect } from 'react-redux';
import Spinner from '../shared/spinner/Spinner';
import moment from 'moment';
import '../../styles/detailsView.scss';
import { createLoadingSelector } from '../../redux/modules/loading/loadingSelectors';
import { orderConstants } from '../../redux/modules/order/orderConstants';

function getExecutedIcon(statusMessage) {
  switch (statusMessage) {
    case 'Ja':
      return faCheck;
    case 'Nei':
      return faTimes;
    default:
      return faMinus;
  }
}

function RouteStopDetailsGrid({ routeStop, dataButtons, isLoading }) {
  if (!routeStop) {
    return <div>{locale.routeStopDetails._routeStopDoesNotExist}</div>;
  }

  const result = extractRouteStopDetails(routeStop, dataButtons);
  const formattedDepartureTime =
    result && result.time ? moment(result.time).format('DD.MM HH:mm:ss') : '';
  const formattedArrivedTime =
    result && result.arrivedTime
      ? moment(result.arrivedTime).format('DD.MM HH:mm:ss')
      : '';
  const executedIcon = result && getExecutedIcon(result.executed);
  const lightboxOptions = {
    settings: {
      autoplaySpeed: 0,
      disableWheelControls: true,
    },
    buttons: {
      showAutoplayButton: false,
      showDownloadButton: false,
    },
  };

  return (
    <div className="route-stop-details-grid">
      {isLoading && <Spinner />}
      <div className="row">
        <div className="col-12">
          <div className="card mb-3">
            <div className="card-body details-container">
              <div className="cell">
                <div className="label">
                  {locale.routeStopDetails._departureTime}
                </div>
                <div className="value">{formattedDepartureTime}</div>
              </div>
              <div className="cell">
                <div className="label">
                  {locale.routeStopDetails._arrivalTime}
                </div>
                <div className="value">{formattedArrivedTime}</div>
              </div>
              <div className="cell">
                <div className="label">{locale.routeStopDetails._address}</div>
                <div className="value">{result && result.address}</div>
              </div>
              <div className="cell">
                <div className="label">
                  {locale.routeStopDetails._agreementId}
                </div>
                <div className="value">{result && result.agreementId}</div>
              </div>
              <div className="cell">
                <div className="label">{locale.routeStopDetails._unit}</div>
                <div className="value">{result && result.unit}</div>
              </div>
              <div className="cell">
                <div className="label">
                  {locale.routeStopDetails._agreementLineId}
                </div>
                <div className="value">
                  {routeStop && routeStop.agreementLineId}
                </div>
              </div>
              <div className="cell">
                <div className="label">{locale.routeStopDetails._executed}</div>
                <div className="value executed-status">
                  <FontAwesomeIcon icon={executedIcon} />
                </div>
              </div>
              <div className="cell">
                <div className="label">
                  {locale.routeStopDetails._externalOrderId}
                </div>
                <div className="value">{result && result.externalOrderId}</div>
              </div>
              <div className="cell">
                <div className="label">
                  {locale.routeStopDetails._vehicleId}
                </div>
                <div className="value">{result && result.vehicleId}</div>
              </div>
              <div className="cell">
                <div className="label">{locale.routeStopDetails._driver}</div>
                <div className="value">{result && result.driver}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {result && result.comment && (
        <div className="card mb-3">
          <div className="card-header">
            <h3>{locale.routeStopDetails._comment}</h3>
          </div>
          <div className="card-body">
            <p className="card-text">{result.comment}</p>
          </div>
        </div>
      )}

      {result && result.dataButtonValues && result.dataButtonValues.length > 0 && (
        <div className="card mb-3">
          <div className="card-header">
            <h3>{locale.routeStopDetails._orderData}</h3>
          </div>
          <ul className="list-group list-group-flush">
            {result.dataButtonValues.map((dataButton) => (
              <li className="list-group-item" key={dataButton.tag}>
                {dataButton.text}: {dataButton.value}
              </li>
            ))}
          </ul>
        </div>
      )}

      {result && result.unitDeviations && result.unitDeviations.length > 0 && (
        <div className="card mb-3">
          <div className="card-header">
            <h3>{locale.routeStopDetails._deviation}</h3>
          </div>
          <ul className="list-group list-group-flush">
            {result.unitDeviations &&
              result.unitDeviations.map((deviation, i) => (
                <li className="list-group-item" key={i}>
                  {deviation}
                </li>
              ))}
          </ul>
        </div>
      )}

      {result && result.pictures && (
        <div className="card">
          <div className="card-header">
            <h3>{locale.routeStopDetails._pictures}</h3>
          </div>
          <div className="card-body">
            <SimpleReactLightBox>
              <SRLWrapper options={lightboxOptions}>
                <div className="row pictures-row">
                  {result.pictures.map((url) => (
                    <div key={url} className="col-md-2 picture">
                      <a href={url} target="_blank" rel="noopener noreferrer">
                        <img src={url} />
                      </a>
                    </div>
                  ))}
                </div>
              </SRLWrapper>
            </SimpleReactLightBox>
          </div>
        </div>
      )}
    </div>
  );
}

RouteStopDetailsGrid.propTypes = {
  routeStop: PropTypes.object,
  dataButtons: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const isOrdersLoading = createLoadingSelector(orderConstants.GET_ORDER_DETAILS);

function mapStateToProps(state) {
  return {
    dataButtons: state.settings.dataButtons,
    isLoading: isOrdersLoading(state),
  };
}

export default connect(mapStateToProps)(RouteStopDetailsGrid);
