import { MapLayer, MapLayerProps, withLeaflet } from 'react-leaflet';
import * as esri from 'esri-leaflet';
import { RefObject } from 'react';

export interface ITiledMapLayerProps extends MapLayerProps {
  url: string;
  ref?: RefObject<MapLayer>;
}
export class TiledMapLayer extends MapLayer<
  ITiledMapLayerProps,
  esri.TiledMapLayer
> {
  public createLeafletElement(props: ITiledMapLayerProps): esri.TiledMapLayer {
    return esri.tiledMapLayer(this.getOptions(props));
  }

  public updateLeafletElement(
    fromProps: ITiledMapLayerProps,
    toProps: ITiledMapLayerProps
  ): void {
    super.updateLeafletElement(fromProps, toProps);
    if (toProps.url !== fromProps.url) {
      this.leafletElement.setUrl(toProps.url);
    }
  }
}

export default withLeaflet(TiledMapLayer);
