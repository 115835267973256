import api from '../services/wasteManagementClient';
import { wasteManagementUrls as urls } from '../appConfig';
import {
  IChangeSequenceList,
  IChangeSequenceBody,
  IRoute,
  IRoutePoi,
  PASystem,
} from '../models';
import { LatLngTuple } from 'leaflet';

export default abstract class RouteService {
  public static async getAllRoutesForUser(
    ignoreCache: boolean = false
  ): Promise<Array<IRoute>> {
    try {
      const params = { cache: { ignoreCache, maxAge: 1000 * 60 * 60 * 12 } };
      const response = await api.get(urls.routes, params);
      return response.status === 200 ? response.data?.routes : [];
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  public static async getRouteDetails(
    routeIds: Array<string>,
    ignoreCache: boolean = false
  ): Promise<Array<IRoutePoi>> {
    try {
      const params = {
        params: { routeIds },
        cache: { ignoreCache, maxAge: 1000 * 60 * 60 * 12 },
      };
      const response = await api.get(`${urls.routes}/details`, params);
      return response.status === 200
        ? IRoutePoi.mapConnectionInfos(
            response.data.pois.map((poi: IRoutePoi) => IRoutePoi.createId(poi)),
            response.data.agreementConnectionInfos
          )
        : [];
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  public static async changePosition(
    poiId: number,
    customerId: number,
    [latitude, longitude]: LatLngTuple
  ): Promise<boolean> {
    try {
      const params = {
        poiId,
        customerId,
        latitude,
        longitude,
      };
      /** @todo: read from appConfig */
      const response = await api.put(`${urls.baseUrl}/poi/position`, params);
      return response.status === 200;
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  public static async changeSequence(
    params: IChangeSequenceBody
  ): Promise<void> {
    try {
      await api.post(`${urls.routes}/change-sequence`, params);
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  public static async validateSequence(
    payload: IChangeSequenceBody
  ): Promise<Array<IChangeSequenceList>> {
    try {
      const params = payload;
      const response = await api.post(
        `${urls.routes}/calculate-change-sequence`,
        params
      );
      return response.status === 200 ? response.data : [];
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  public static mockRequest(...args: any[]): Promise<void> {
    return new Promise((resolve, reject) =>
      setTimeout(async () => {
        try {
          /** @todo: change when API is ready */
          console.log(...args);
          await api.get(`${urls.routes}/mock-non-implemented`);
          resolve();
        } catch (error) {
          reject(error);
        }
      }, 1000 + Math.random() * 2000)
    );
  }

  public static async mergePoints(
    targetRouteLineId: number,
    agreementLines: Array<{
      agreementLineId: number;
      routeLineId: number;
      paSystem: PASystem;
    }>
  ): Promise<void> {
    try {
      const params = {
        targetRouteLineId,
        agreementLines,
      };
      await api.post(`${urls.baseUrl}/routes/merge`, params);
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  public static async splitPoints(
    routeId: string,
    newRouteStops: Array<any>
  ): Promise<void> {
    try {
      const params = {
        routeId,
        newRouteStops,
      };
      await api.post(`${urls.baseUrl}/routes/split`, params);
    } catch (error) {
      console.error('split error: ', error);
      throw new Error(error);
    }
  }
}
