import React from 'react';
import PropTypes from 'prop-types';
import { selectDate } from '../../../services/listService';
import { formatTime } from '../../shared/dateUtility';
import { locale } from '../../../common/localization';

const worklistSummaryLocale = locale.routeOptimalizationWizard.worklistResults.worklistSummary;

function getTotalTime(depotStops) {
  const arrivalTimes = depotStops.map(x => x.arrivalTime);

  const startTime = selectDate(arrivalTimes, Math.min);
  const endTime = selectDate(arrivalTimes, Math.max);

  return formatTime((endTime - startTime) / 1000);
}
export default function WorklistSummary({ worklist, depotStops }) {
  const totalTime = getTotalTime(depotStops);

  return (
    <div className="worklist-summary">
      <div>
        <label>
          {worklistSummaryLocale._totalTime}:
        </label>
        {totalTime}
      </div>
    </div>
  );
}

WorklistSummary.propTypes = {
  worklist: PropTypes.array.isRequired,
  depotStops: PropTypes.array.isRequired,
}

WorklistSummary.defaultProps = {
  worklist: [],
  depotStops: [],
}