export interface IRowData {
  field: string;
  value: string;
  isExpanded: boolean;
}
export namespace IRowData {
  export function hash(id: IRowData): string {
    return `${id.field}===${id.value}`;
  }

  export function has(array: Array<IRowData>, id: IRowData): boolean {
    return index(array, id) > -1;
  }

  export function index(array: Array<IRowData>, id: IRowData): number {
    const str = hash(id);
    return array.findIndex((id2) => hash(id2) === str);
  }

  export function equals(one: IRowData, other: IRowData): boolean {
    return hash(one) === hash(other);
  }
}
