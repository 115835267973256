import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';


function HeaderStep({ title, buttonText, buttonClick, disabled, icon }) {
  const stepElementClasses = classNames(
    'step-element',
    {
      'active': !disabled,
    },
  );

  return (
    <div className={stepElementClasses}>
      <div className="step-indicator">
        <div className="step-bubble">
          <FontAwesomeIcon icon={icon} />
        </div>
      </div>
      <p className="step-title">{title}</p>
      <button
        onClick={buttonClick}
        className="btn btn btn-outline-dark btn-slim step-action"
        disabled={disabled}
      >
        {buttonText}
      </button>
    </div>
  );
}

HeaderStep.propTypes = {
  title: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  icon: PropTypes.object.isRequired,
}

HeaderStep.defaultProps = {
  title: '',
  buttonText: '',
  buttonClick: () => { },
  icon: faTimesCircle,
}

export default HeaderStep;
