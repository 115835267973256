import React, { useCallback, useEffect, useRef } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { FormikContextType, useFormikContext } from 'formik';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ToggleButton from 'react-toggle-button';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface IToggleFieldProps {
  label: string;
  name: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function ToggleField(props: IToggleFieldProps): JSX.Element {
  const { onChange, label, name, ...restProps } = props;
  const { values, handleChange, handleBlur }: FormikContextType<any> =
    useFormikContext();
  const myRef = useRef<any>(null);

  useEffect(() => {
    if (myRef.current) {
      const htmlEl = myRef.current as HTMLInputElement;
      const hasDNoneClass = htmlEl.classList.contains('d-none');
      if (!hasDNoneClass) {
        htmlEl.classList.add('d-none');
      }
    }
  });

  const customHandleChange = useCallback(
    (args) => {
      onChange && onChange(args);
      handleChange(args);
    },
    [handleChange, onChange]
  );

  return (
    <Form.Group as={Row} className="checkbox-row">
      <Form.Label column sm={6}>
        {label}
      </Form.Label>
      <Col className="checkbox-wrapper" sm={6}>
        <ToggleButton
          value={values[name]}
          onToggle={() => {
            myRef.current.click();
          }}
          activeLabel={
            <FontAwesomeIcon icon={faCheck} className="icon-place" />
          }
          inactiveLabel={
            <FontAwesomeIcon icon={faTimes} className="icon-place" />
          }
          trackStyle={{
            height: '22px',
          }}
          colors={{
            active: {
              base: '#007bff',
            },
          }}
          {...restProps}
        />
        <Form.Check
          type="checkbox"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          checked={values[name]}
          name={name}
          value="true"
          onChange={customHandleChange}
          onBlur={handleBlur}
          label=""
          ref={myRef}
        />
      </Col>
    </Form.Group>
  );
}
