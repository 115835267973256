import modulesConstants from './moduleConstants';
import loadingActions from '../loading/loadingActions';
import ModulesService from '../../../services/ModulesService';

function getModules(customerId, ignoreCache = false, silent = false) {
  return dispatch => {
    if (!silent) {
      dispatch(loadingActions.addLoadingInProgress(modulesConstants.GET_MODULES));
    }

    return ModulesService.getModules(customerId, ignoreCache)
      .then(
        (modules) => dispatch(success(modules, customerId)),
        (error) => dispatch(failure(JSON.stringify(error))),
      ).finally(() => {
        dispatch(loadingActions.removeLoadingInProgress(modulesConstants.GET_MODULES));
      });
  }

  function success(modules, customerId) {
    return { type: modulesConstants.GET_MODULES_SUCCESS, modules, customerId };
  }

  function failure(error) {
    return { type: modulesConstants.GET_MODULES_FAILURE, error }
  }
}

export default {
  getModules,
}
