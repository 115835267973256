export function clamp(number, min, max) {
  return Math.min(Math.max(number, min), max);
}

export function random(from, to) {
  return from + Math.floor(Math.random() * to);
}

export function sum(values) {
  return values.reduce((total, current) => total + current, 0);
}

export function average(values) {
  return sum(values) / values.length;
}

/**
 * Parses a string to an int. 
 * Accepts both "." and "," as a decimal separator.
 * @param {string} stringToParse 
 */
export function parseIntMultipleLocale(stringToParse) {
  if (typeof stringToParse === 'number') {
    return stringToParse;
  }

  if (typeof stringToParse !== 'string') {
    return NaN;
  }

  if (/^[+-]?\d+([.,]\d+)?$/.test(stringToParse)) {
    return Number(stringToParse.replace(',', '.'));
  }
  return NaN;
}
