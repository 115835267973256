import usersConstants from './userConstants';
import loadingActions from '../loading/loadingActions';
import UsersService from '../../../services/UsersService';
import { groupBy } from '../../../services/listService';

function getUsers(customerId, includeModules = false) {
  return dispatch => {
    dispatch(loadingActions.addLoadingInProgress(usersConstants.GET_USERS));

    return UsersService.getUsers(customerId, includeModules)
      .then(
        (users) => {
          const groupedUsersMap = groupBy(users, u => u.customerId);
          const groupedUsers = Object.fromEntries(groupedUsersMap);

          dispatch(success(groupedUsers));
        },
        (error) => dispatch(failure(JSON.stringify(error))),
      ).finally(() => {
        dispatch(loadingActions.removeLoadingInProgress(usersConstants.GET_USERS));
      });
  }

  function success(groupedUsers) {
    return { type: usersConstants.GET_USERS_SUCCESS, groupedUsers };
  }

  function failure(error) {
    return { type: usersConstants.GET_USERS_FAILURE, error }
  }
}

function createUser(user) {
  return dispatch => {
    dispatch(loadingActions.addLoadingInProgress(usersConstants.CREATE_USER));

    return UsersService.createUser(user)
      .then(
        (user) => dispatch(success(user)),
        (error) => dispatch(failure(JSON.stringify(error))),
      ).finally(() => {
        dispatch(loadingActions.removeLoadingInProgress(usersConstants.CREATE_USER));
      });
  }

  function success(user) {
    return { type: usersConstants.CREATE_USER_SUCCESS, user };
  }

  function failure(error) {
    return { type: usersConstants.CREATE_USER_FAILURE, error }
  }
}

function updateUser(user) {
  return dispatch => {
    dispatch(loadingActions.addLoadingInProgress(usersConstants.UPDATE_USER));

    return UsersService.updateUser(user)
      .then(
        (user) => dispatch(success(user)),
        (error) => dispatch(failure(JSON.stringify(error))),
      ).finally(() => {
        dispatch(loadingActions.removeLoadingInProgress(usersConstants.UPDATE_USER));
      });
  }

  function success(user) {
    return { type: usersConstants.UPDATE_USER_SUCCESS, user };
  }

  function failure(error) {
    return { type: usersConstants.UPDATE_USER_FAILURE, error }
  }
}

export default {
  getUsers,
  createUser,
  updateUser,
}
