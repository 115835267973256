export enum WasteTypes {
  HOUSEHOLD = 'pr',
  COMMERCIAL = 'næ',
  SLUDGE = 'sl',
  CABIN = 'hy',
}

export enum WasteClasses {
  HOUSEHOLD = 'household-waste',
  COMMERCIAL = 'commercial-waste',
  ALTCOMMERCIAL = 'alt-commercial-waste',
  SLUDGE = 'sludge-waste',
  CABIN = 'cabin',
}

export enum WasteSystems {
  H = 'H',
  N = 'N',
  PA = 'PA',
}
