import React from 'react';
import memoizeOne from 'memoize-one';
import { IVehicle } from '../../models';
import Select from 'react-select';
import { locale } from '../../common/localization';
import { filterVehicles } from '../vehicles/vehicleUtils';

export interface IVehicleSelectorProps {
  vehicles: Array<IVehicle>;
  selectedVehicleId: string;
  handleChange: (...args: any) => any;
  contractorId?: number;
  customerId?: number;
  [key: string]: any;
}

export interface IVehicleOption {
  label: string;
  value: IVehicle;
}

export const getVehicleOptions = (
  vehicles: Array<IVehicle>
): Array<IVehicleOption> => {
  return (
    (vehicles &&
      vehicles.map((vehicle) => ({
        label: vehicle.vehicleId,
        value: vehicle,
      }))) ||
    []
  );
};

export const getCustomVehicleOptionsMemoized = memoizeOne(getVehicleOptions);

const VehicleSelector = (props: IVehicleSelectorProps): React.ReactElement => {
  const {
    vehicles,
    handleChange,
    selectedVehicleId,
    contractorId,
    customerId,
    ...restProps
  } = props;
  const filteredVehicles = vehicles.length
    ? filterVehicles(vehicles, {
        customerId,
        contractorId,
      })
    : [];
  const options = getCustomVehicleOptionsMemoized(filteredVehicles);
  const selectedOption = options.find(
    (option) => option.label === selectedVehicleId
  );

  return (
    <Select
      options={options}
      onChange={handleChange}
      value={selectedOption}
      placeholder={locale.vehicles._vehicles}
      noOptionsMessage={() => locale.vehicles._noVehicles}
      {...restProps}
    />
  );
};

export default VehicleSelector;
