export interface IColor {
  red: number;
  green: number;
  blue: number;
}
export namespace IColor {
  export function toHex(rgb: IColor): number {
    const { red, green, blue } = rgb;
    return (1 << 24) + (red << 16) + (green << 8) + blue;
  }

  export function toRGB(hex: number): IColor {
    const red = (hex >> 16) & 255;
    const green = (hex >> 8) & 255;
    const blue = hex & 255;

    return { red, green, blue };
  }

  export function darken(hex: number, amount = 0.5): number {
    const color = toRGB(hex);
    color.red = Math.floor(color.red * (1 - amount));
    color.green = Math.floor(color.green * (1 - amount));
    color.blue = Math.floor(color.blue * (1 - amount));
    return toHex(color);
  }

  export function complementer(hex: number): number {
    const { red, green, blue } = toRGB(hex);
    const mean = (red + green + blue) / 3;
    return mean < 127.5 ? 0xffffff : 0;
  }
}

export interface ITheme {
  color: number;
  font: number;
}

export interface IPoiTheme {
  color: string;
  font: string;
}
export abstract class PoiTheme {
  private static themeMap: Map<string, ITheme> = new Map();

  private static readonly colorblind: Array<number> = [
    0xa5a7d, 0xc8c88e, 0x8b8aa7, 0x7a7abb, 0x78781, 0x5c5c5f,
  ];
  private static readonly themes: Array<number> = [
    0x6aefed, 0x002eff, 0xff0000, 0x8a1cda, 0xffae00, 0x28a745,
  ];
  private static readonly fonts: Array<number> = [
    0, 16777215, 16777215, 16777215, 0, 16777215,
  ];

  public static resetState(routeNames: Array<string>): void {
    PoiTheme.themeMap = new Map();
    routeNames.forEach((routeName) => {
      const hash = PoiTheme.hash(routeName);
      const index = PoiTheme.themeMap.size % PoiTheme.themes.length;
      const color = PoiTheme.themes[index];
      const font = PoiTheme.fonts[index];
      PoiTheme.themeMap.set(hash, { color, font });
    });
  }

  public static get(routeName: string): IPoiTheme {
    const { getColor, toColor } = PoiTheme;
    const { color, font } = getColor(routeName);
    return { color: toColor(color), font: toColor(font) };
  }

  public static darken(routeName: string): IPoiTheme {
    const { getColor, toColor } = PoiTheme;
    const { color, font } = getColor(routeName);
    return {
      color: toColor(IColor.darken(color)),
      font: toColor(IColor.darken(font)),
    };
  }

  private static getColor(routeName: string): ITheme {
    const { themeMap, themes, fonts } = PoiTheme;
    const hash = PoiTheme.hash(routeName);
    if (!themeMap.has(hash)) {
      const index = themeMap.size % themes.length;
      const color = themes[index];
      const font = fonts[index];
      themeMap.set(hash, { color, font });
    }
    return themeMap.get(hash);
  }

  private static toColor(hex: number): string {
    const base = '000000';
    const string = `${base}${hex.toString(16)}`.slice(-6);
    return `#${string}`;
  }

  private static hash(routeName: string): string {
    return `${routeName}`;
  }
}
