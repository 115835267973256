import { Form, Formik } from 'formik';
import React, { Component, ReactNode } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { locale } from '../../common/localization';
import { debounceEventHandler } from '../shared/submitFormUtility';
import { DatePicker } from '../shared/formikFields';
import yup from '../../common/validation';
import moment from 'moment';
import { IFraction, SelectOption } from '../../models/Fraction.model';
import FractionsMultiselect from './FractionsMulitselect';
import OrdersService from './ordersService';
import { Toastr } from '../../utils/Toastr';
import { Spinner2 } from '../shared/spinner2';

export interface UpdatedOrder {
  customerId: number;
  orderId: number;
  date: string;
  ordinaryDate: string;
  expires: string;
  fractions: Array<IFraction>;
}

export interface IUpdateOrderDialogProps {
  order: any;
  fractions: Array<IFraction>;
  show: boolean;
  onHide: (renderer: boolean) => void;
}

export interface IUpdateOrderDialogState {
  selectedFractions: Array<IFraction>;
  isLoading: boolean;
}

export default class UpdateOrderDialog extends Component<
  IUpdateOrderDialogProps,
  IUpdateOrderDialogState
> {
  public readonly state: IUpdateOrderDialogState = {
    selectedFractions: null,
    isLoading: false,
  };

  private async onSubmit(order: any): Promise<void> {
    this.setState({ ...this.state, isLoading: true });
    const updatedOrder: UpdatedOrder = {
      date: this.formatDate(order.date),
      ordinaryDate: this.formatDate(order.ordinaryDate),
      expires: this.formatDate(order.expires),
      fractions: this.state.selectedFractions,
      customerId: order.customerId,
      orderId: order.orderId,
    };
    try {
      await OrdersService.updateOrder(updatedOrder);
      Toastr.success(locale.orders._updateSuccess);
    } catch (error) {
      Toastr.error(error);
    } finally {
      this.setState({ ...this.state, isLoading: false });
      this.props.onHide(true);
    }
  }

  private getSchema() {
    return yup.object().shape({
      date: yup
        .date()
        .required()
        .min(moment().startOf('day').toDate())
        .label(locale.orders._date),
      ordinaryDate: yup
        .date()
        .required()
        .min(moment().startOf('day').toDate())
        .label(locale.orders._ordinaryDate),
      expires: yup
        .date()
        .required()
        .min(moment().startOf('day').toDate())
        .label(locale.orders._expires)
        .when('date', (date: any, schema: any) => {
          return date
            ? schema.min(date, locale.orders._earlierDateValidation)
            : schema;
        }),
    });
  }

  private fractionsChange(fractions: Array<SelectOption>): void {
    const selectedFractions: Array<IFraction> = fractions?.length
      ? fractions.map((f) => {
          const fraction: IFraction = {
            fractionId: f.value,
            name: f.label,
          };
          return fraction;
        })
      : [];
    this.setState({ ...this.state, selectedFractions: selectedFractions });
  }

  private formatDate(date: Date): string {
    const offset = date.getTimezoneOffset() * 60000;
    const localISOTime = new Date(date.getTime() - offset)
      .toISOString()
      .slice(0, 19);

    return localISOTime;
  }

  private checkOrderStarted(order: any): boolean {
    let statusId = -1;
    if (order?.orderStatus) {
      statusId = order.orderStatus.orderStatusId;
    }
    return statusId !== 0;
  }

  public render(): ReactNode {
    const { order, show, onHide, fractions } = this.props;
    order.ordinaryDate = new Date(order.ordinaryDate);
    order.expires = new Date(order.expires);
    const isStarted = this.checkOrderStarted(order);
    return (
      <Formik
        initialValues={order}
        onSubmit={(updatedOrder: any) => this.onSubmit(updatedOrder)}
        validationSchema={this.getSchema()}
      >
        {({ handleSubmit }) => (
          <Modal show={show} onHide={() => onHide(false)}>
            <Modal.Header closeButton>
              <Modal.Title>{order.orderDisplayName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Spinner2 show={this.state.isLoading} />
              <Form
                noValidate
                onSubmit={handleSubmit}
                className="update-order-dialog-form"
              >
                {isStarted && (
                  <h3 className="not-editable-warning">
                    {locale.orders._notEditableWarning}
                  </h3>
                )}
                <DatePicker
                  label={locale.orders._date}
                  name="date"
                  popupClass="date-picker-popup-above-modal"
                  disabled={isStarted}
                />
                <DatePicker
                  label={locale.orders._ordinaryDate}
                  name="ordinaryDate"
                  popupClass="date-picker-popup-above-modal"
                  disabled={isStarted}
                />
                <DatePicker
                  label={locale.orders._expires}
                  name="expires"
                  popupClass="date-picker-popup-above-modal"
                  disabled={isStarted}
                />
                <FractionsMultiselect
                  fractions={fractions}
                  selectedFractions={order.fractions as Array<IFraction>}
                  onFractionOptionChange={this.fractionsChange.bind(this)}
                  disabled={isStarted}
                  key={'fractionId'}
                />
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => onHide(false)}>
                {locale.vehicles._cancel}
              </Button>
              <Button
                type="submit"
                variant="primary"
                disabled={isStarted}
                onClick={debounceEventHandler(handleSubmit, 250)}
              >
                {locale.vehicles._update}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    );
  }
}
