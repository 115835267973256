import wasteManagementClient from './wasteManagementClient';
import { wasteManagementUrls } from '../appConfig';

export default class ContractorsService {
  static getContractors(customerId, ignoreCache = false) {
    return wasteManagementClient
      .get(wasteManagementUrls.contractors, {
        params: {
          customerId,
        },
        cache: { ignoreCache },
      })
      .then((response) => {
        if (response.data && response.status === 200) {
          return response.data;
        } else {
          return [];
        }
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  static getSelfContractors(ignoreCache = false) {
    return wasteManagementClient
      .get(wasteManagementUrls.contractors + '/self', {
        cache: { ignoreCache },
      })
      .then((response) => {
        if (response.data && response.status === 200) {
          return response.data;
        } else {
          return [];
        }
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  static async addContractor(newContractor, ignoreCache = false) {
    try {
      const url = wasteManagementUrls.contractors;
      const response = await wasteManagementClient.post(url, newContractor, {
        cache: { ignoreCache },
      });

      if (response.status === 200 && response.data && response.data.length) {
        const result = response.data;
        return result;
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  static async updateContractor(contractor, ignoreCache = false) {
    try {
      const url = wasteManagementUrls.contractors;
      const response = await wasteManagementClient.put(url, contractor, {
        cache: ignoreCache,
      });

      if (response.status === 200 && response.data && response.data.length) {
        const result = response.data;
        return result;
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  static async deleteContractor(customerId, contractorId, ignoreCache = false) {
    try {
      const url = wasteManagementUrls.contractors;
      const response = await wasteManagementClient.delete(
        url,
        {
          params: {
            customerId,
            contractorId,
          },
        },
        {
          cache: ignoreCache,
        }
      );

      if (response.status === 200 && response.data && response.data.length) {
        const result = response.data;
        return result;
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }
}
