import React from 'react';
import PropTypes from 'prop-types';
import { locale } from '../../common/localization';
import TemplateDownloadButton from './TemplateDownloadButton';
import './uploadFileWarning.scss';

function UploadFileWarning({ onSelectFile }) {
  return (
    <div className="upload-route-file-warning">
      <h3>{locale.routeOptimalizationWizard._noFileSelectedTitle}</h3>
      <p>
        {locale.routeOptimalizationWizard._noFileSelectedDescription}
        &nbsp;
        <TemplateDownloadButton />
      </p>

      <button
        onClick={onSelectFile}
        className="btn btn btn-outline-dark"
      >
        {locale.routeOptimalizationWizard._uploadFileButton}
      </button>
    </div>
  )
}

UploadFileWarning.propTypes = {
  onSelectFile: PropTypes.func.isRequired,
}

UploadFileWarning.defaultProps = {
  onSelectFile: () => { },
}

export default UploadFileWarning;
