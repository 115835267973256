import React, { useEffect, useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { IUser, IVehicle, SelectOption } from '../../models';
import Select from 'react-select';
import { locale } from '../../common/localization';
import { getVehicleDropdownOptions } from '../vehicles/vehicleUtils';

export interface IVehicleMultiSelectProps {
  onVehicleOptionsChange: (vehicleIds: Array<string>) => void;
  authentication: IUser;
  vehicles: Array<IVehicle>;
  preselectedVehicleId: string;
}

export default function VehicleMultiSelect(
  props: IVehicleMultiSelectProps
): JSX.Element {
  const [vehicleOptions, setVehicleOptions] = useState<Array<SelectOption>>([]);
  const [selectedOptions, setSelectedOptions] = useState<Array<SelectOption>>(
    []
  );

  const handleVehicleOptionsChange = (
    selectedOptions: Array<SelectOption>
  ): void => {
    setSelectedOptions(selectedOptions);
  };

  useEffect(() => {
    const vehicles = props.vehicles;
    setVehicleOptions(getVehicleDropdownOptions(vehicles));
    if (!selectedOptions.length && vehicles.length) {
      const { preselectedVehicleId } = props;
      const preselectedVehicle = vehicles.find(
        (v) => v.vehicleId === preselectedVehicleId
      );
      setSelectedOptions([
        {
          value: preselectedVehicle,
          label: preselectedVehicleId,
        },
      ]);
    }
  }, [props.vehicles]);

  useEffect(() => {
    const selectedVehicleIds = selectedOptions?.map((o) => o.label) || [];
    props.onVehicleOptionsChange(selectedVehicleIds);
  }, [selectedOptions]);

  return (
    <Form.Group as={Row}>
      <Col sm={12}>
        <Select
          options={vehicleOptions}
          isMulti
          value={selectedOptions}
          onChange={(options) => {
            handleVehicleOptionsChange(options as Array<SelectOption>);
          }}
          placeholder={locale.vehicleSettings._vehicleSelectorPlaceholder}
          noOptionsMessage={() => locale.vehicles._noVehicles}
          loadingMessage={() => locale.vehicles._vehicleLoadingMessage}
        />
      </Col>
    </Form.Group>
  );
}
