import proj4 from 'proj4';
import moment from 'moment';

export function getOrderTypeName(orderType) {
  switch (orderType) {
    default:
    case 0:
      return 'Normal ordre';
    case 1:
      return 'Anropsordre';
    case 2:
      return 'Flytteordre';
    case 3:
      return 'Tilgang / Utsett';
    case 4:
      return 'Tømming';
    case 5:
      return 'Avslutning / Innhent';
    case 6:
      return 'Dynamisk ordre';
  }
}

export function getContainerOrderRouteName(order) {
  return order.place
    && order.place.name
    || order.agreementLine
    && order.agreementLine.agreement
    && order.agreementLine.agreement.name
    || order.name;
}

export function getContainerOrderAddress(order) {
  const agreement = order.agreementLine
    && order.agreementLine.agreement;
  const place = order.place;

  return place
    && (
      place.address
      || place.name
      || agreement
      && agreement.companyName
    )
    || agreement
    && (
      agreement.address1
      || agreement.name
    );
}

export function getContainerOrderDetailData(order, filterParams, filterEmpty = true) {
  const details = [];
  details.push(...getCallOrderDetails(order));
  details.push(...getAgreementLineDetails(order));
  details.push(...getAgreementDetails(order));
  details.push(...getRouteLineDetails(order));

  return details
    .filter(detail =>
      (
        !filterParams
        || filterParams.includes(detail.key)
      ) && (
        !filterEmpty
        || detail.value != null
        && detail.value !== ''
      )
    );
}

function getCallOrderDetails(order) {
  return [
    { key: 'DetailData_OrderId', value: order.orderId },
    { key: 'DetailData_ExtOrderId', value: order.externalOrderId },
    { key: 'DetailData_ExtOrderNr', value: order.extOrderNr },
    { key: 'DetailData_Route', value: order.route },
    { key: 'DetailData_RouteName', value: getContainerOrderRouteName(order) },
    { key: 'DetailData_OrderDate', value: formatDateIfNotEmpty(order.date) },
    { key: 'DetailData_Expires', value: formatDateIfNotEmpty(order.expires) },
    { key: 'DetailData_Status', value: order.orderStatus },
    { key: 'DetailData_Message', value: order.message },
    { key: 'DetailData_OrderTypeText', value: getOrderTypeName(order.orderType) },
    { key: 'DetailData_PASystem', value: order.pASystem },
    { key: 'DetailData_AgreementLines', value: order.agreementLineId },
    { key: 'DetailData_RoutelineId', value: order.routelineId },
    { key: 'DetailData_VehicleId', value: order.vehicleId },
    { key: 'DetailData_PlaceNr', value: order.placeNr },
  ];
}

function getAgreementLineDetails(order) {
  const agreementLine = order.agreementLine;
  if (!agreementLine) {
    return [];
  }

  const [lat, long] = getAgreementLineLatLong(agreementLine);

  return [
    { key: 'AgreementLineDetailData_OrderId', value: order.orderId },
    { key: 'AgreementLineDetailData_PASystem', value: agreementLine.pASystem },
    { key: 'AgreementLineDetailData_AgreementId', value: agreementLine.agreementId },
    { key: 'AgreementLineDetailData_AgreementLineId', value: agreementLine.agreementLineId },
    { key: 'AgreementLineDetailData_UnitId', value: agreementLine.unitId },
    { key: 'AgreementLineDetailData_Amount', value: agreementLine.amount },
    { key: 'AgreementLineDetailData_PhysicalAmount', value: agreementLine.physicalAmount },
    { key: 'AgreementLineDetailData_Message', value: agreementLine.message },
    { key: 'AgreementLineDetailData_ExtOrderId', value: order.externalOrderId },
    { key: 'AgreementLineDetailData_LastChanged', value: agreementLine.lastChanged },
    { key: 'AgreementLineDetailData_DegLat', value: lat },
    { key: 'AgreementLineDetailData_DegLon', value: long },
    { key: 'AgreementLineDetailData_ContainerId', value: agreementLine.containerId },
    { key: 'AgreementLineDetailData_Fraction', value: agreementLine.fraction },
    { key: 'AgreementLineDetailData_PlaceNr', value: agreementLine.placeNr },
  ];
}

function getAgreementDetails(order) {
  const agreementLine = order
    && order.agreementLine;
  const agreement = agreementLine
    && order.agreementLine.agreement;
  const place = agreementLine
    && order.agreementLine.place;

  if (!agreement) {
    return [];
  }

  const other = place
    && place.phone
    || agreement.phone
    || agreement.cellular;

  return [
    { key: 'AgreementDetailData_OrderId', value: order.orderId },
    { key: 'AgreementDetailData_PASystem', value: agreement.pASystem },
    { key: 'AgreementDetailData_AgreementId', value: agreement.agreementId },
    { key: 'AgreementDetailData_PlaceNr', value: order.placeNr },
    { key: 'AgreementDetailData_Route', value: agreement.route },
    { key: 'AgreementDetailData_CustomerId', value: agreement.gPSLSCustomerId },
    { key: 'AgreementDetailData_Name', value: agreement.name },
    { key: 'AgreementDetailData_ContactName', value: agreement.contactName },
    { key: 'AgreementDetailData_Address1', value: agreement.address1 },
    { key: 'AgreementDetailData_Address2', value: agreement.address2 },
    { key: 'AgreementDetailData_PostalCode', value: agreement.postalCode },
    { key: 'AgreementDetailData_City', value: agreementLine.city },
    { key: 'AgreementDetailData_Other', value: other },
    { key: 'AgreementDetailData_Message', value: agreement.message },
    { key: 'AgreementDetailData_Type', value: agreement.type },
    { key: 'AgreementDetailData_LastChanged', value: agreement.lastChanged },
  ];
}

function getRouteLineDetails(order) {
  const routeLine = order
    && order.routeLine;
  const agreementLine = order
    && order.agreementLine;

  if (!routeLine) {
    return [];
  }

  return [
    { key: 'RouteLineDetailData_Route', value: routeLine.route },
    { key: 'RouteLineDetailData_RouteLineId', value: routeLine.routeLineId },
    { key: 'RouteLineDetailData_DegLat', value: routeLine.degLat },
    { key: 'RouteLineDetailData_DegLong', value: routeLine.degLong },
    { key: 'RouteLineDetailData_Sequence', value: routeLine.sequence },
    { key: 'RouteLineDetailData_PASystem', value: routeLine.pASystem },
    { key: 'RouteLineDetailData_AgreementLines', value: order.agreementLineId },
    { key: 'RouteLineDetailData_Fractions', value: agreementLine && agreementLine.fraction },
    // { key: 'RouteLineDetailData_Sound', value: routeLine... },
    { key: 'RouteLineDetailData_Type', value: agreementLine && agreementLine.type },
    { key: 'RouteLineDetailData_History', value: routeLine.lastChanged },
  ];
}

function getAgreementLineLatLong(agreementLine) {
  if (
    agreementLine.gpsX > 90
    && agreementLine.gpsY > 90
  ) {
    return proj4(
      '+proj=utm +zone=32',
      '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs',
      [agreementLine.gpsX, agreementLine.gpsY]
    );
  }

  return [
    agreementLine.gpsX,
    agreementLine.gpsY,
  ];
}

function formatDateIfNotEmpty(date) {
  if (date) {
    return moment(date).format('DD.MM.YYYY');
  }
  return null;
}
