import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as orderActions from '../../redux/modules/order/orderActions';
import OrderDetails from './OrderDetails';
import { createLoadingSelector } from '../../redux/modules/loading/loadingSelectors';
import { orderConstants } from '../../redux/modules/order/orderConstants';
import { makeGetOrderByPathParams } from '../../redux/modules/order/orderSelectors';

const isOrdersLoading = createLoadingSelector(orderConstants.GET_ORDER_DETAILS);
const getOrderByPath = makeGetOrderByPathParams();

function mapStateToProps(state, props) {
  return {
    order: getOrderByPath(state, props),
    isLoading: isOrdersLoading(state),
    lastOverviewType: state.order.lastOverviewType,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    orderActions: bindActionCreators(orderActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails);
