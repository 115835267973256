import wasteManagementClient from './wasteManagementClient';
import { wasteManagementUrls } from '../appConfig';
import {cloneDeep, flatMap} from 'lodash';
import moment from 'moment';

class ServiceOrderService {
  static getServiceOrders(listId, ignoreCache = false) {
    return wasteManagementClient.get(`${wasteManagementUrls.serviceOrders}/list/${listId}`, {
      cache: { ignoreCache },
    }).then(response => {
      if (response.data && response.status === 200) {
        return response.data;
      } else {
        return [];
      }
    }).catch(error => {
      console.error(error);
      throw error;
    });
  }

  static getServiceOrdersBulk(listIds, ignoreCache = false) {
    return wasteManagementClient.get(wasteManagementUrls.serviceOrders + '/details/bulk', {
      params: {
        listIds
      },
      cache: { ignoreCache },
    }).then(response => {
      if (response.data && response.status === 200) {
        return response.data;
      } else {
        return [];
      }
    }).catch(error => {
      console.error(error);
      throw error;
    });
  }

  static getServiceLists(fromDate, toDate, ignoreCache = false) {
    return wasteManagementClient.get(wasteManagementUrls.serviceLists, {
      params: {
        fromDate,
        toDate,
      },
      cache: { ignoreCache },
    }).then(response => {
      if (response.data && response.status === 200) {
        return response.data;
      } else {
        return [];
      }
    }).catch(error => {
      console.error(error);
      throw error;
    });
  }

  static extendServiceListsWithArrivalTime = (serviceLists) => {
    const serviceListsClone = cloneDeep(serviceLists);
    serviceListsClone.forEach(list => {
      //If every element has arrivalTime, skip the list
      if (!list.listItems.some(el => !el.hasOwnProperty('arrivalTime'))) return;
      //Get arrivalTime from an element inside the list
      const itemWithArrivalTimeInList = list.listItems.find(el => {
        if (!el.arrivalTime) return false;
        return true;        
      });
      let arrivalTime = null;
      //When possible, choose arrivalTime from the same list, if not, choose the first arrivalTime that can be found in any list
      if (typeof itemWithArrivalTimeInList !== 'undefined'){
        arrivalTime = moment(itemWithArrivalTimeInList.arrivalTime).startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
      } else {
        const flatMapped = flatMap(serviceListsClone, (childArray) => childArray.listItems);
        const foundArrivalTime = flatMapped.find(list2 => list2.hasOwnProperty('arrivalTime'));
        arrivalTime = moment(foundArrivalTime.arrivalTime).startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
      }
      //Adding the property
      list.listItems.forEach(el => {
        el.arrivalTime = el.arrivalTime || arrivalTime;
      });
    });
    return serviceListsClone;
  }

  static saveServiceLists(serviceLists) {
    //Add arrivalTime property to serviceLists.listItems element to those that doesn't already have
    const serviceListsWithArrivalTime = this.extendServiceListsWithArrivalTime(serviceLists);
    return wasteManagementClient.post(
      wasteManagementUrls.serviceLists,
      serviceListsWithArrivalTime,
    ).then(response => {
      if (response.data && response.status === 200) {
        return response.data;
      } else {
        return [];
      }
    }).catch(error => {
      console.error(error);
      throw error;
    });
  }

  static deleteServiceList(listId) {
    return wasteManagementClient.delete(`${wasteManagementUrls.serviceLists}/${listId}`)
      .then(response => {
        if (response.data && response.status === 200) {
          return response.data;
        } else {
          return [];
        }
      }).catch(error => {
        console.error(error);
        throw error;
      });
  }

  static deleteServiceListItem(listItemId, paSystem) {
    return wasteManagementClient.delete(`${wasteManagementUrls.serviceOrders}/${listItemId}/${paSystem}`)
      .then(response => {
        if (response.data && response.status === 200) {
          return response.data;
        } else {
          return [];
        }
      }).catch(error => {
        console.error(error);
        throw error;
      });
  }
}

export default ServiceOrderService;
