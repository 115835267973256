import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import createHistory from 'history/createBrowserHistory';
// 'routerMiddleware': the new way of storing route changes with redux middleware since rrV4.
import { connectRouter, routerMiddleware } from 'connected-react-router';
import rootReducer from './rootReducer';
import {
  persistStore,
  persistReducer,
  createTransform,
  PersistConfig,
} from 'redux-persist';
import localForage from 'localforage';
import { resetToEmptyState } from './migration';
import reactGAMiddleware from './middlewares/analytics/reactGAMiddleware';
import { IRootState } from './state';
import appVersion from '../appVersion';

const blacklist = [
  'order',
  'routeOptimalization',
  'route.validationResult',
  'map',
  'authentication.isLoading',
  'authentication.errorMessage',
  'geodatatoken.isLoading',
  'geodatatoken.errorMessage',
];

const persistConfig: PersistConfig = {
  key: 'root',
  storage: localForage,
  // order store blacklisted for performance reasons
  blacklist,
  version: appVersion as any,
  migrate: resetToEmptyState,
  timeout: null,
  transforms: [
    // nested blacklist-paths require a custom transform to be applied
    createTransform((inboundState, key) => {
      const blacklistPaths_forKey = blacklist
        .filter((path) => path.startsWith(`${key}.`))
        .map((path) => path.substr(key.length + 1));
      return Object.keys(inboundState).reduce((memo, key) => {
        if (blacklistPaths_forKey.includes(key)) return memo;
        return { ...memo, [key]: inboundState[key] };
      }, {});
    }, null),
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const history = createHistory();
const connectRouterHistory = connectRouter(history);

function configureStoreProd(initialState?: IRootState) {
  const reactRouterMiddleware = routerMiddleware(history);
  const middlewares = [
    // Add other middleware on this line...

    // thunk middleware can also accept an extra argument to be passed to each thunk action
    // https://github.com/reduxjs/redux-thunk#injecting-a-custom-argument
    thunk,
    reactRouterMiddleware,
    reactGAMiddleware,
  ];

  const store = createStore(
    connectRouterHistory(persistedReducer),
    initialState,
    compose(applyMiddleware(...middlewares))
  );

  return {
    store,
    persistor: persistStore(store),
  };
}

function configureStoreDev(initialState?: IRootState) {
  const reactRouterMiddleware = routerMiddleware(history);
  const middlewares = [
    // Add other middleware on this line...

    // thunk middleware can also accept an extra argument to be passed to each thunk action
    // https://github.com/reduxjs/redux-thunk#injecting-a-custom-argument
    thunk,
    reactRouterMiddleware,
  ];

  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // add support for Redux dev tools
  const store = createStore(
    connectRouterHistory(persistedReducer),
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('redux', () => {
      // eslint-disable-line global-require
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const nextRootReducer = require('./rootReducer').default;
      store.replaceReducer(persistReducer(persistConfig, nextRootReducer));
    });
  }

  return {
    store,
    persistor: persistStore(store),
  };
}

const configureStore =
  process.env.NODE_ENV === 'production'
    ? configureStoreProd
    : configureStoreDev;

export default configureStore;
