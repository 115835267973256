import { IVehicle } from '../../../models';
import { VehiclesConstants } from './vehiclesConstants';
import * as Actions from './vehiclesActions';
import { Dispatch } from 'redux';
import VehiclesService from '../../../services/vehicleService';

export function createVehiclessListRequest(): Actions.SetVehiclesList {
  return {
    type: VehiclesConstants.GET_VEHICLES_LIST_REQUEST,
  };
}
export function createVehiclesListSuccess(
  payload: Array<IVehicle>
): Actions.SetVehiclesList.Success {
  return {
    type: VehiclesConstants.GET_VEHICLES_LIST_SUCCESS,
    payload,
  };
}
export function createVehiclesListFailure(
  error: Error
): Actions.SetVehiclesList.Failure {
  return {
    type: VehiclesConstants.GET_VEHICLES_LIST_FAILURE,
    error,
  };
}

export function createResetState(): Actions.ResetState {
  return {
    type: VehiclesConstants.RESET_STATE,
  };
}

export function fetchVehicles(
  ignoreCache = false
): (dispatch: Dispatch<Actions.All>) => Promise<void> {
  return async function (dispatch: Dispatch<Actions.All>): Promise<void> {
    dispatch(createVehiclessListRequest());
    try {
      const result: Array<IVehicle> = await VehiclesService.getVehicleInfos(
        ignoreCache
      );
      dispatch(createVehiclesListSuccess(result));
    } catch (error) {
      dispatch(createVehiclesListFailure(error));
    }
  };
}

export function resetState(): Actions.ResetState {
  return createResetState();
}
