import React, { Component, ReactNode } from 'react';
import { locale } from '../../common/localization';
import { faTruck } from '@fortawesome/free-solid-svg-icons';
import PaneHeadline from '../shared/paneHeadline';
import { IContractor, ICustomer, IUser, IVehicle } from '../../models';
import CustomerSelector from '../userAdmin/CustomerSelector';
import ImpersonationWarning from '../shared/ImpersonationWarning';
import VehiclesGrid from './VehiclesGrid';
import './Vehicles.scss';
import VehicleEditDialog from './VehicleEditDialog';
import ContractorSelector from '../userAdmin/ContractorSelector';
import routes from '../../common/routes';
import { History } from 'history';
import { isNil } from 'lodash';
import { filterVehicles } from './vehicleUtils';
import { IVehiclesState as IVehiclesListState } from '../../redux/modules/vehicles/vehiclesState';
export interface IVehiclesProps {
  impersonatedUser: any;
  customers: Array<ICustomer>;
  authentication: IUser;
  history: History;
  vehicles: IVehiclesListState;
  getContractors: (
    customerId: number,
    ignoreCache?: boolean
  ) => Promise<Record<string, any>>;
  getCustomers: () => void;
  fetchVehicles: (ignoreCache?: boolean) => Promise<void>;
}

export interface IVehiclesState {
  selectedContractorId: number;
  selectedCustomerId: number;
  contractorsList: Array<IContractor>;
  vehicles: Array<IVehicle>;
  isEditEnabled: boolean;
  editDialog: {
    show: boolean;
    vehicle: IVehicle;
  };
}
export class Vehicles extends Component<IVehiclesProps, IVehiclesState> {
  public readonly state: IVehiclesState = {
    selectedContractorId: this.props.authentication.contractorId ?? -1,
    selectedCustomerId: this.props.authentication.customerId,
    vehicles: [],
    isEditEnabled:
      this.props.authentication.isAdmin ||
      this.props.authentication.isLocalAdmin,
    editDialog: {
      show: false,
      vehicle: null,
    },
  } as IVehiclesState;

  public componentDidMount(): void {
    this.initComponent();
  }

  private initComponent = async (): Promise<void> => {
    this.loadCustomers();

    await this.loadContractors(this.props.authentication.customerId);

    this.loadVehicles(
      this.state.selectedCustomerId,
      this.state.selectedContractorId
    );
  };

  private loadCustomers = (): void => {
    if (!this.props.impersonatedUser && this.props.authentication.isAdmin) {
      this.props.getCustomers();
    }
  };

  private loadVehicles = async (
    customerId: number,
    contractorId: number,
    ignoreCache = false
  ): Promise<void> => {
    await this.props.fetchVehicles(ignoreCache);
    const vehicles = filterVehicles(this.props.vehicles.vehiclesList, {
      customerId,
      contractorId,
    });
    this.setState({ ...this.state, vehicles });
  };

  private loadContractors = async (
    customerId: number,
    ignoreCache = false
  ): Promise<void> => {
    if (
      !isNil(this.props.authentication.contractorId) &&
      !this.props.authentication.isAdmin
    )
      return new Promise((resolve) => resolve(null));
    const contractorsReq = await this.props.getContractors(
      customerId,
      ignoreCache
    );
    const contractors: Array<IContractor> = contractorsReq.contractors
      ? contractorsReq.contractors.sort((a: IContractor, b: IContractor) =>
          a.contractorName.localeCompare(b.contractorName)
        )
      : [];
    const selectedContractorId =
      (Array.isArray(contractors) && contractors[0]?.contractorId) || -1;

    await new Promise((resolve) => {
      this.setState(
        {
          ...this.state,
          contractorsList: contractors,
          selectedContractorId,
        },
        () => resolve(null)
      );
    });
  };

  private handleCustomerChanged = async (
    selectedCustomerId: Record<string, any>
  ) => {
    this.setState({
      ...this.state,
      selectedCustomerId: selectedCustomerId.value,
    });
    await this.loadContractors(selectedCustomerId.value);
    this.loadVehicles(
      selectedCustomerId?.value,
      this.state.selectedContractorId
    );
  };

  private handleContractorChanged = (
    selectedContractorId: Record<string, any>
  ) => {
    this.setState({
      ...this.state,
      selectedContractorId: selectedContractorId.value,
    });
    this.loadVehicles(
      this.state.selectedCustomerId,
      selectedContractorId?.value
    );
  };

  private hideVehicleEditDialog(rerender = false): void {
    this.setState({
      ...this.state,
      editDialog: { show: false, vehicle: null },
    });
    if (rerender) {
      this.loadVehicles(
        this.state.selectedCustomerId,
        this.state.selectedContractorId,
        true
      );
    }
  }

  private editVehicle(vehicle: IVehicle) {
    this.openVehicleEditDialog(vehicle);
  }

  private openVehicleEditDialog(vehicle: IVehicle) {
    this.setState({ ...this.state, editDialog: { show: true, vehicle } });
  }

  private getDynamicClassOfComponent = (
    component:
      | 'CustomerSelector'
      | 'ContractorSelector'
      | 'DropdownsRow'
      | 'AddNewButton'
  ): string => {
    const isAdmin = this.props.authentication.isAdmin;
    const noContractorId = isNil(this.props.authentication.contractorId);
    const isEditEnabled = this.state.isEditEnabled;

    switch (component) {
      case 'CustomerSelector':
        return isAdmin ? 'col-sm-6' : 'd-none';

      case 'ContractorSelector':
        if (isAdmin) {
          return 'col-sm-6';
        }
        return noContractorId ? (isAdmin ? 'col-sm-6' : 'col-sm-12') : 'd-none';

      case 'DropdownsRow':
        return isEditEnabled ? 'col-md-9' : 'col-md-12';

      case 'AddNewButton':
        return isEditEnabled ? 'col-md-3 text-right' : 'd-none';

      default:
        return '';
    }
  };

  public render(): ReactNode {
    const { customers, impersonatedUser } = this.props;
    if (impersonatedUser) {
      return (
        <>
          <ImpersonationWarning
            impersonatedUserName={impersonatedUser}
            stopImpersonation={() =>
              this.props.history.push(routes.impersonationManagerPath)
            }
          />
        </>
      );
    }
    return (
      <div className="vehicles">
        <PaneHeadline
          titleText={locale.general._vehicles}
          titleIcon={faTruck}
        />
        <>
          <div className="row">
            <div className={this.getDynamicClassOfComponent('DropdownsRow')}>
              <div className="row">
                <div
                  className={this.getDynamicClassOfComponent(
                    'CustomerSelector'
                  )}
                >
                  {this.props.authentication.isAdmin && (
                    <CustomerSelector
                      customers={customers}
                      handleChange={this.handleCustomerChanged}
                      selectedCustomerId={this.state.selectedCustomerId}
                      className="mb-3"
                    />
                  )}
                </div>
                <div
                  className={this.getDynamicClassOfComponent(
                    'ContractorSelector'
                  )}
                >
                  {(this.props.authentication.isAdmin ||
                    isNil(this.props.authentication.contractorId)) && (
                    <ContractorSelector
                      contractors={this.state.contractorsList}
                      handleChange={this.handleContractorChanged}
                      selectedContractorId={this.state.selectedContractorId}
                      className="mb-3"
                    />
                  )}
                </div>
              </div>
            </div>
            {this.state.isEditEnabled && (
              <div className={this.getDynamicClassOfComponent('AddNewButton')}>
                <button
                  className="btn btn-outline-dark mb-3"
                  onClick={() => this.openVehicleEditDialog(null)}
                >
                  {locale.vehicles._addNewVehicle}
                </button>
              </div>
            )}
          </div>
          <div className="d-flex">
            <VehiclesGrid
              data={this.state.vehicles}
              isEditEnabled={this.state.isEditEnabled}
              editVehicle={this.editVehicle.bind(this)}
              className="mb-3"
            />
          </div>
        </>
        {this.state.editDialog.show && (
          <VehicleEditDialog
            customerId={this.state.selectedCustomerId}
            contractorId={this.state.selectedContractorId}
            show={this.state.editDialog.show}
            vehicle={this.state.editDialog.vehicle}
            vehicleIdsExisting={this.state.vehicles.map(
              (v: IVehicle) => v.vehicleId?.toLocaleLowerCase() || ''
            )}
            onHide={this.hideVehicleEditDialog.bind(this)}
          />
        )}
      </div>
    );
  }
}

export default Vehicles;
