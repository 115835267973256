import { IAgreement } from './Agreement.model';
import { IPlace } from './Place.model';
import { IUnit } from './Unit.model';

export enum PASystem {
  H = 'H',
  N = 'N',
}
export interface IAgreementLine {
  customerId: number;
  paSystem: PASystem;
  agreementLineId: number;
  agreementId: number;
  regDate: string;
  commune: number;
  unitId: string;
  amount: number;
  physicalAmount: number;
  fromDate: string;
  toDate: string;
  exceptFrom: string;
  exceptTo: string;
  free: string;
  termin: string;
  year: string;
  message: string;
  frequence: number;
  frequenceUnit: string;
  route1: string;
  priority1: string;
  route2: string;
  priority2: string;
  route3: string;
  priority3: string;
  route4: string;
  priority4: string;
  route5: string;
  priority5: string;
  route6: string;
  priority6: string;
  route7: string;
  priority7: string;
  callRoute: string;
  callPriority: string;
  x: number;
  y: number;
  orderNr: number;
  gpsX: number;
  gpsY: number;
  gpsSubSekvens: string;
  ajour: number;
  contractor: string;
  company: string;
  placeNr: number;
  returnSystem: string;
  lastChanged: string;
  kobGroup: string;
  s_Placement: string;
  s_Material: string;
  s_Volume: string;
  s_NrOfChambers: string;
  s_TubeLength: string;
  s_LengthFromRoad: string;
  s_MessageSeptic: string;
  s_NextDate: string;
  s_LastDate: string;
  s_Status: string;
  s_Year: string;
  s_LastVolume: string;
  type: string;
  containerId: string;
  fraction: string;
  onePrice: string;
  rfid: string;
  f_BurningBan: string;
  f_LastSootQuantity: string;
  s_TypeContent: string;
  s_BackingRequired: string;
  s_SmallestRoadWidth: string;
  status: string;
  labelId: string;
  place: IPlace;
  unit: IUnit;
  agreement: IAgreement;
}
