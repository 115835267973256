import { SettingsConstants } from './settingsConstants';
import { SettingsService } from '../../../services/SettingsService';
import { Actions, GetDataButtons, GetVisibleFields, GetCompanySettings } from './settingsActions';
import { ICompanySettings, IDataButton } from '../../../models';
import { Dispatch } from 'redux';

export function createDataButtonsRequest(): GetDataButtons {
  return {
    type: SettingsConstants.GET_DATABUTTONS_REQUEST,
  };
}

export function createDataButtonsSuccess(
  payload: Array<IDataButton>
): GetDataButtons.Success {
  return {
    type: SettingsConstants.GET_DATABUTTONS_SUCCESS,
    payload,
  };
}

export function createDataButtonsFailure(error: Error): GetDataButtons.Failure {
  return {
    type: SettingsConstants.GET_DATABUTTONS_FAILURE,
    error,
  };
}

export function createCompanySettingsRequest(): GetCompanySettings {
  return {
    type: SettingsConstants.GET_COMPANY_SETTINGS_REQUEST,
  };
}

export function createCompanySettingsSuccess(
  payload: Array<ICompanySettings>
): GetCompanySettings.Success {
  return {
    type: SettingsConstants.GET_COMPANY_SETTINGS_SUCCESS,
    payload,
  };
}

export function createCompanySettingsFailure(error: Error): GetCompanySettings.Failure {
  return {
    type: SettingsConstants.GET_COMPANY_SETTINGS_FAILURE,
    error,
  };
}

export function createVisibleFieldsRequest(): GetVisibleFields {
  return {
    type: SettingsConstants.GET_VISIBLE_FIELDS_REQUEST,
  };
}

export function createVisibleFieldsSuccess(
  payload: Array<string>
): GetVisibleFields.Success {
  return {
    type: SettingsConstants.GET_VISIBLE_FIELDS_SUCCESS,
    payload,
  };
}

export function createVisibleFieldsFailure(
  error: Error
): GetVisibleFields.Failure {
  return {
    type: SettingsConstants.GET_VISIBLE_FIELDS_FAILURE,
    error,
  };
}

export function getDataButtons(
  ignoreCache: boolean = false
): (dispatch: Dispatch<Actions>) => Promise<void> {
  return async function (dispatch) {
    dispatch(createDataButtonsRequest());
    try {
      const response = await SettingsService.getDataButtons(ignoreCache);
      dispatch(createDataButtonsSuccess(response));
    } catch (error) {
      console.error(error);
      dispatch(createDataButtonsFailure(error));
    }
  };
}

export function getCompanySettings(
): (dispatch: Dispatch<Actions>) => void {
  return function (dispatch) {
    dispatch(createCompanySettingsRequest());
    const settings = SettingsService.getCompanySettings();
    dispatch(createCompanySettingsSuccess(settings));
  };
}

export function getVisibleFields(
  ignoreCache: boolean = false
): (dispatch: Dispatch<Actions>) => Promise<void> {
  return async function (dispatch) {
    dispatch(createVisibleFieldsRequest());
    try {
      const response = await SettingsService.getVisibleFields(ignoreCache);
      dispatch(createVisibleFieldsSuccess(response));
    } catch (error) {
      console.error(error);
      dispatch(createVisibleFieldsFailure(error));
    }
  };
}
