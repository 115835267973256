import { connect } from 'react-redux';
import { IRootState } from '../../redux/state';
import SelectedRouteLayer from './SelectedRouteLayer';

function mapStateToProps(state: IRootState) {
  return {
    selectedOrder: state.order.selected,
    location: state.router.location,
  };
}

export default connect(mapStateToProps)(SelectedRouteLayer);
