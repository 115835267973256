export function routeStopEquals(routeStop, routeStopKeys) {
  if (!routeStop || !routeStopKeys) {
    return false;
  }

  return routeStop.customerId === routeStopKeys.customerId
    && routeStop.orderId === routeStopKeys.orderId
    && routeStop.routeLineId === routeStopKeys.routeLineId
    && routeStop.paSystem === routeStopKeys.paSystem
    && routeStop.agreementLineId === routeStopKeys.agreementLineId;
}
