import React from 'react';
import PropTypes from 'prop-types';
import './checkbox-cell.scss';

function handleChange(onChange, dataItem, field, event) {
  onChange({
    dataItem: dataItem,
    field: field,
    syntheticEvent: event,
    value: event.target.checked,
  });
}

export default function CheckboxCell(props) {
  const { dataItem, field, onClick, onChange } = props;
  const value = !!dataItem[field];

  return (
    <td className="checkbox-cell executed-status" onClick={() => onClick && onClick(props)}>
      <input
        type="checkbox"
        checked={value}
        disabled={!dataItem.inEdit}
        onChange={handleChange.bind(null, onChange, dataItem, field)}
      />
    </td>
  );
}

CheckboxCell.propTypes = {
  dataItem: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func,
}