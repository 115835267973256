import React, { Component } from 'react';
import PropTypes from 'prop-types';
import WorklistSelector from './WorklistSelector';
import UnassignedStopsWarning from './UnassignedStopsWarning';
import WorklistGrid from './WorklistGrid';
import WorklistSummary from './WorklistSummary';
import './worklistResults.scss';
import MoveWorklistItemModal from './MoveWorklistItemModal';

class WorklistResults extends Component {
  constructor(props) {
    super(props);

    this.setSelectedWorklist = this.setSelectedWorklist.bind(this);
    this.moveWorklistItem = this.moveWorklistItem.bind(this);
    this.hideMoveWorklistItemModalModal =
      this.hideMoveWorklistItemModalModal.bind(this);
    this.handleMoveWorklistItem = this.handleMoveWorklistItem.bind(this);
  }

  state = {
    moveItemModalVisible: false,
    itemIdToMove: null,
  };

  setSelectedWorklist(selectedWorklist) {
    this.props.routeOptimalizationActions.setSelectedWorklist(selectedWorklist);
  }

  moveWorklistItem(worklistItem) {
    this.setState({
      moveItemModalVisible: true,
      itemIdToMove: worklistItem.gln,
    });
  }

  hideMoveWorklistItemModalModal() {
    this.setState({
      moveItemModalVisible: false,
      itemIdToMove: null,
    });
  }

  handleMoveWorklistItem(moveOptions) {
    const { worklists, routeOptimalizationActions, selectedWorklist } =
      this.props;

    const { itemIdToMove } = this.state;

    const newWorklists = worklists.slice();

    const currentWorklist = newWorklists[selectedWorklist];
    const itemToMove = currentWorklist.find((x) => x.gln === itemIdToMove);

    newWorklists[selectedWorklist] = currentWorklist
      .filter((x) => x.gln !== itemIdToMove)
      .map((item) => {
        if (item.sequence < itemToMove.sequence) {
          return item;
        }

        return {
          ...item,
          sequence: item.sequence - 1,
        };
      });

    const targetWorklist = newWorklists[moveOptions.targetList].slice();
    newWorklists[moveOptions.targetList] = targetWorklist;

    const movedItem = { ...itemToMove };
    delete movedItem.sequence;
    delete movedItem.arrivalTime;

    targetWorklist.push(movedItem);

    routeOptimalizationActions.setWorklists(newWorklists);
    this.setState({
      moveItemModalVisible: false,
      itemIdToMove: null,
    });
  }

  render() {
    const { worklists, depotStopLists, unassignedStops, selectedWorklist } =
      this.props;

    const { moveItemModalVisible } = this.state;
    const worklist = worklists[selectedWorklist];
    const depotStops = depotStopLists[selectedWorklist];
    return (
      <div className="work-list-results">
        <div className="work-list-selector-row">
          <WorklistSelector
            worklistsCount={(worklists && worklists.length) || 0}
            selectedWorklist={selectedWorklist}
            onChange={this.setSelectedWorklist}
          />
          <UnassignedStopsWarning unassignedStops={unassignedStops} />
        </div>
        <WorklistGrid worklist={worklist} moveItem={this.moveWorklistItem} />
        <WorklistSummary worklist={worklist} depotStops={depotStops} />
        {moveItemModalVisible && (
          <MoveWorklistItemModal
            worklists={worklists}
            currentWorklist={selectedWorklist}
            onClose={this.hideMoveWorklistItemModalModal}
            onSubmit={this.handleMoveWorklistItem}
          />
        )}
      </div>
    );
  }
}

WorklistResults.propTypes = {
  worklists: PropTypes.array.isRequired,
  depotStopLists: PropTypes.array.isRequired,
  unassignedStops: PropTypes.array,
  selectedWorklist: PropTypes.number,
  routeOptimalizationActions: PropTypes.object.isRequired,
};

WorklistResults.defaultProps = {
  worklists: [],
  depotStopLists: [],
  unassignedStops: [],
  selectedWorklist: 0,
  routeOptimalizationActions: {},
};

export default WorklistResults;
