import ReactGA from 'react-ga';
import { analyticsCode } from '../../../appConfig';

ReactGA.initialize(analyticsCode);
const options = {};

const trackPage = (page) => {
  ReactGA.set({
    page,
    ...options
  });
  ReactGA.pageview(page);
}

let currentPage = '';

const googleAnalytics = _store => next => action => {
  if (action.type === '@@router/LOCATION_CHANGE') {
    const nextPage = `${action.payload.location.pathname}${action.payload.location.search}`;

    if (currentPage !== nextPage) {
      currentPage = nextPage;
      trackPage(nextPage);
    }
  }

  return next(action);
}

export default googleAnalytics;
