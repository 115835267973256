import wasteManagementClient from '../../services/wasteManagementClient';
import { wasteManagementUrls } from '../../appConfig';

class ContainerOrderService {
  static getContainerOrders(expires, toDate, ignoreCache = false) {
    return wasteManagementClient
      .get(wasteManagementUrls.containerOrders, {
        params: {
          expires,
          toDate,
        },
        cache: { ignoreCache },
      })
      .then((response) => {
        if (response.data && response.status === 200) {
          return response.data;
        } else {
          return [];
        }
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  static addVehicleToContainerOrder(
    customerId,
    orderId,
    vehicleId,
    contractorId
  ) {
    return wasteManagementClient
      .put(wasteManagementUrls.containerOrders + '/assign-vehicle', {
        customerId,
        orderId,
        vehicleId,
        contractorId,
      })
      .then((response) => {
        if (response.status === 200 && response.data !== undefined && response.data !== null) {
          return response.data;
        } else {
          return {};
        }
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }
}

export default ContainerOrderService;
