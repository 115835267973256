import React from 'react';
import { Component, ReactNode } from 'react';
import { ICustomer, IUser } from '../../models';
import { IDeviation, IDeviationCategory } from '../../models/Deviation';
import PaneHeadline from '../shared/paneHeadline';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import CustomerSelector from '../userAdmin/CustomerSelector';
import { isNil } from 'lodash';
import './deviations.scss';
import { DeviationGrid } from './DeviationGrid';
import DeviationDialog from './DeviationDialog';
import DeviationsService from '../../services/deviationsService';
import { Toastr } from '../../utils/Toastr';
import { locale } from '../../common/localization';

export interface IDeviationProps {
  getCustomers: () => void;
  getDeviations: (
    customerId: number,
    ignoreCache?: boolean
  ) => Promise<Record<string, any>>;
  getDeviationCategories: (
    customerId: number,
    ignoreCache?: boolean
  ) => Promise<Record<string, any>>;
  impersonatedUser: any;
  authentication: IUser;
  customers: Array<ICustomer>;
  deviationList: Array<IDeviation>;
  deviationCategoryList: Array<IDeviationCategory>;
}

export interface IDeviationState {
  selectedCustomerId: number;
  deviationDialog: {
    show: boolean;
    deviation: IDeviation;
    deviations: Array<IDeviation>;
    deviationCategories: Array<IDeviationCategory>;
    customerId: number;
  };
}

export class Deviations extends Component<IDeviationProps, IDeviationState> {
  public readonly state: IDeviationState = {
    selectedCustomerId: this.props.authentication.customerId,
    deviationDialog: {
      show: false,
      deviation: null,
      deviations: null,
      deviationCategories: null,
      customerId: null,
    },
  } as IDeviationState;

  public componentDidMount(): void {
    this.initComponent();
  }

  private initComponent = (): void => {
    this.loadCustomers();
    this.loadDeviations(this.state.selectedCustomerId);
    this.loadDeviationCategories(this.state.selectedCustomerId);
  };

  private loadCustomers = (): void => {
    if (!this.props.impersonatedUser && this.props.authentication.isAdmin) {
      this.props.getCustomers();
    }
  };

  private handleCustomerChanged = (selectedCustomerId: Record<string, any>) => {
    this.setState({
      ...this.state,
      selectedCustomerId: selectedCustomerId.value,
    });
    this.loadDeviations(selectedCustomerId.value, true);
    this.loadDeviationCategories(selectedCustomerId.value, true);
  };

  private loadDeviations = async (
    customerId: number,
    ignoreCache = false
  ): Promise<void> => {
    if (
      !isNil(this.props.authentication.contractorId) &&
      !this.props.authentication.isAdmin &&
      !this.props.authentication.isLocalAdmin
    )
      return new Promise((resolve) => resolve(null));
    await this.props.getDeviations(customerId, ignoreCache);
  };

  private loadDeviationCategories = async (
    customerId: number,
    ignoreCache = false
  ): Promise<void> => {
    if (
      !isNil(this.props.authentication.contractorId) &&
      !this.props.authentication.isAdmin &&
      !this.props.authentication.isLocalAdmin
    )
      return new Promise((resolve) => resolve(null));
    await this.props.getDeviationCategories(customerId, ignoreCache);
  };

  private addNewDeviation(): void {
    this.setState({
      ...this.state,
      deviationDialog: {
        customerId: this.state.selectedCustomerId,
        deviation: null,
        deviations: this.props.deviationList,
        deviationCategories: this.props.deviationCategoryList,
        show: true,
      },
    });
  }

  private editDeviation(devaition: IDeviation): void {
    this.setState({
      ...this.state,
      deviationDialog: {
        customerId: this.state.selectedCustomerId,
        deviation: devaition,
        deviations: this.props.deviationList,
        deviationCategories: this.props.deviationCategoryList,
        show: true,
      },
    });
  }

  private hideDeviationDialog(rerender = false): void {
    this.setState({
      ...this.state,
      deviationDialog: {
        customerId: null,
        deviation: null,
        deviations: null,
        deviationCategories: null,
        show: false,
      },
    });
    if (rerender) {
      this.loadDeviations(this.state.selectedCustomerId, true);
    }
  }

  private async deleteDeviation(devaition: IDeviation): Promise<void> {
    if (
      confirm(
        locale.deviations._deleteConfirm +
          `${devaition.value}/${devaition.exceptionId}`
      )
    ) {
      try {
        await DeviationsService.deleteDeviaton(
          this.state.selectedCustomerId,
          devaition.exceptionId
        );
        Toastr.success(locale.deviations._deleteSuccess);
      } catch (error) {
        Toastr.error(error);
      } finally {
        this.loadDeviations(this.state.selectedCustomerId, true);
      }
    }
  }

  public render(): ReactNode {
    const { customers, deviationList, deviationCategoryList } = this.props;
    const filteredDeviations = deviationList.filter(
      (deviation) => deviation.enabled
    );
    return (
      <div className="deviations">
        <>
          <PaneHeadline
            titleText={locale.deviations._deviations}
            titleIcon={faExclamationCircle}
          />

          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-sm-6">
                  {this.props.authentication.isAdmin && (
                    <CustomerSelector
                      customers={customers}
                      handleChange={this.handleCustomerChanged}
                      selectedCustomerId={this.state.selectedCustomerId}
                      className="mb-3"
                    />
                  )}
                </div>
                <div className="col-sm-6 text-right mb-3">
                  <button
                    className="btn btn-outline-dark"
                    onClick={() => this.addNewDeviation()}
                  >
                    {locale.deviations._addNew}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex">
            {filteredDeviations && (
              <DeviationGrid
                data={filteredDeviations}
                editDeviation={this.editDeviation.bind(this)}
                deleteDeviation={this.deleteDeviation.bind(this)}
                deviationCategoryList={deviationCategoryList}
                className="mb-3"
              />
            )}
          </div>
        </>
        {this.state.deviationDialog.show && (
          <DeviationDialog
            deviation={this.state.deviationDialog.deviation}
            deviations={this.state.deviationDialog.deviations}
            deviationCategories={this.state.deviationDialog.deviationCategories}
            customerId={this.state.deviationDialog.customerId}
            show={this.state.deviationDialog.show}
            onHide={this.hideDeviationDialog.bind(this)}
          />
        )}
      </div>
    );
  }
}

export default Deviations;
