import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Col } from 'react-bootstrap';
import { locale } from '../../common/localization';
import { useFormikContext } from 'formik';

export default function UserCheckBox({ name, onChange, ...props }) {
  const {
    values,
    handleChange,
    handleBlur,
  } = useFormikContext();

  const customHandleChange = useCallback(
    (args) => {
      onChange && onChange(args);
      handleChange(args);
    },
    [handleChange, onChange],
  );

  return (
    <Form.Group as={Row} className="checkbox-row">
      <Form.Label column sm={4}>
        {locale.userAdmin['_' + name]}
      </Form.Label>
      <Col className="checkbox-wrapper" sm={8}>
        <Form.Check
          type="checkbox"
          checked={values[name]}
          name={name}
          value="true"
          onChange={customHandleChange}
          onBlur={handleBlur}
          {...props}
        />
      </Col>
    </Form.Group>
  );
}

UserCheckBox.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
}
