import React, { Component, ReactNode } from 'react';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { LeafletMapSettings } from '../../../sharedConfig';

export interface IClusteredMapLayerProps {
  isClusteringActive: boolean;
  children: ReactNode | Array<ReactNode>;
}

export class ClusteredMapLayer extends Component<IClusteredMapLayerProps> {
  public render(): ReactNode {
    const { isClusteringActive, children } = this.props;
    if (!isClusteringActive) {
      return children;
    }

    return (
      <MarkerClusterGroup
        disableClusteringAtZoom={LeafletMapSettings.clustering.maxZoom}
        spiderfyOnMaxZoom={false}
      >
        {children}
      </MarkerClusterGroup>
    );
  }
}
