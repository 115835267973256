import { connect } from 'react-redux';
import {
  getSelectedDepotStopList,
  getSelectedWorklist,
} from '../../../redux/modules/routeOptimalization/routeOptimalizationSelectors';
import worklistsLayer from './WorklistsLayer';

function mapStateToProps(state) {
  return {
    selectedWorklist: getSelectedWorklist(state),
    selectedDepotStopList: getSelectedDepotStopList(state),
    location: state.router.location,
    activeTab: state.routeOptimalization.activeTab,
  }
}

export default connect(mapStateToProps)(worklistsLayer);
