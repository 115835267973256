import http from './containerHttpClient';
import { serviceUrls } from '../appConfig';

export abstract class GeomapService {
  public static async getGeomapToken(): Promise<string> {
    const url = serviceUrls.geodataToken;
    const response = await http.get(url);
    if (response.status !== 200 || !response.data) {
      throw new Error(
        `Failed: ${url}, with the respose: ${JSON.stringify(response)}`
      );
    }
    return response.data;
  }
}
