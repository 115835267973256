import { createSelector } from 'reselect';
import { IRoute, IRoutePoi } from '../../../models';
import { IRootState } from '../../state';

export const getRoutes = createSelector(
  [(state: IRootState) => state.route.routeList],
  (list: Array<IRoute>) => list
);

export const getSelectedRouteIds = createSelector(
  [(state: IRootState) => state.route.selectedRouteIds],
  (ids: Array<string>) => ids
);

export const getSelectedRoutes = createSelector(
  [
    (state: IRootState) => state.route.routeList,
    (state: IRootState) => state.route.selectedRouteIds,
  ],
  (list: Array<IRoute>, ids: Array<string>) => {
    return ids.map((id) => list.find((route) => route.routeNumbder === id));
  }
);

export const getPois = createSelector(
  [(state: IRootState) => state],
  (state: IRootState) => {
    const routes: Array<IRoute> = state.route.routeList;
    const nameMap = new Map(
      routes.map((route) => [
        route.routeNumbder.toLocaleLowerCase(),
        route.name,
      ])
    );
    const hash = (poi: IRoutePoi) => poi.id;
    const pois = IRoutePoi.squash(state.route.poiList, hash);
    return pois.map((poi) => ({
      ...poi,
      routeName: nameMap.get(poi.route.toLocaleLowerCase()),
    }));
  }
);

export const getMarkedRouteSequenceIds = createSelector(
  [(state: IRootState) => state.route.markedRouteSequences],
  (ids: Array<string>) => ids
);

export const getMarkedRouteSequences = createSelector(
  [(state: IRootState) => state],
  (state: IRootState) => {
    const ids = getMarkedRouteSequenceIds(state);
    const pois = getPois(state);
    const result = IRoutePoi.squash(
      pois.filter((poi) => ids.includes(poi.id)),
      (poi) => poi.description
    );
    return result;
  }
);

export const getEditMode = createSelector(
  [(state: IRootState) => state.route.editMode],
  (mode) => mode
);

export const getRowData = createSelector(
  [(state: IRootState) => state.route.rowData],
  (list) => list
);

export const getGridState = createSelector(
  [(state: IRootState) => state.route.gridState],
  (dataState) => dataState
);

export const getValidationResults = createSelector(
  [(state: IRootState) => state.route.validationResult],
  (results) => results
);

export const getIsChangePositionLoading = createSelector(
  [
    (state: IRootState) =>
      state.route.isChangePositionLoading || state.route.isPoiListLoading,
  ],
  (isLoading) => isLoading
);

export const getIsChangeSequenceLoading = createSelector(
  [
    (state: IRootState) =>
      state.route.isChangeSequenceLoading ||
      state.route.isValidateSequenceLoading,
  ],
  (isLoading) => isLoading
);

export const getIsPoiListLoading = createSelector(
  [(state: IRootState) => state.route.isPoiListLoading],
  (isLoading) => isLoading
);
