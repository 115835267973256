import { createSelector } from 'reselect';

export const getSelectedWorklist = createSelector(
  [
    (state) => state.routeOptimalization.worklists,
    (state) => state.routeOptimalization.selectedWorklist,
  ],
  (worklists, selectedWorklist) => (
    worklists != null
    && selectedWorklist != null
    && worklists[selectedWorklist]
  )
);

export const getSelectedDepotStopList = createSelector(
  [
    (state) => state.routeOptimalization.depotStopLists,
    (state) => state.routeOptimalization.selectedWorklist,
  ],
  (depotStopLists, selectedWorklist) => (
    depotStopLists != null
    && selectedWorklist != null
    && depotStopLists[selectedWorklist]
  )
);
