import { Action } from 'redux';
import React, { Component, ReactNode } from 'react';
import { create } from 'react-modal-promise';
import routes from '../../common/routes';
import { locale } from '../../common/localization';
import {
  IRoutePoi,
  PASystem,
  RouteEditMode,
  RouteEditButtonRole,
  ICoords,
} from '../../models';
import { AsyncAction, SyncAction } from '../../utils';
import SequenceModal from '../sequenceModal';
import { SplitModal } from '../splitModal';
import { MergeModal } from '../mergeModal';
import { RouteAdminButton } from './RouteAdminButton';
import './RouteAdminMenu.scss';
import { LatLngTuple, Map } from 'leaflet';
import { MapUtility } from '../map/MapUtility';
import { selectedRegion } from '../../appConfig';
import { cloneDeep, isEqual } from 'lodash';

export interface IRouteAdminMenuProps {
  markedRouteSequences: Array<IRoutePoi>;
  markedRouteSequenceIds: Array<string>;
  mode: RouteEditMode;
  setMode: (mode: RouteEditMode) => Action;
  splitPoints: (payload: {
    routeId: string;
    newRouteStops: Array<any>;
  }) => AsyncAction;
  mergePoints: (payload: {
    targetRouteLineId: number;
    agreementLines: Array<{
      agreementLineId: number;
      routeLineId: number;
      paSystem: PASystem;
    }>;
  }) => AsyncAction;
  markRouteSequences: (ids: Array<string>) => SyncAction;
  pois: IRoutePoi[];
  allPois: IRoutePoi[];
  onSubmit: (
    result: Array<{ entity: IRoutePoi; position: LatLngTuple }>
  ) => AsyncAction;
  onRestore: () => AsyncAction;
  leafletElement: Map;
  moveRouteSequences: (
    payload: Array<{
      id: string;
      coords: ICoords;
    }>
  ) => SyncAction;
}

export interface IRouteAdminMenuState {
  previousPois: IRoutePoi[];
  areButtonsDisabled: boolean;
  selectedUnitPois: IRoutePoi[];
  previousSelectedUnitPois: IRoutePoi[];
  arePoisTheSameRouteStop: boolean;
}
export class RouteAdminMenu extends Component<IRouteAdminMenuProps> {
  public readonly state = {
    previousPois: [...this.props.pois],
    areButtonsDisabled: true,
    previousSelectedUnitPois: [...this.getSelectedUnitPois()],
    selectedUnitPois: this.getSelectedUnitPois(),
    arePoisTheSameRouteStop: false,
  };

  public componentDidUpdate(prevProps: IRouteAdminMenuProps): void {
    if (
      !isEqual(this.state.previousSelectedUnitPois, this.getSelectedUnitPois())
    ) {
      this.setState({
        ...this.state,
        selectedUnitPois: this.getSelectedUnitPois(),
        previousSelectedUnitPois: cloneDeep(this.getSelectedUnitPois()),
        arePoisTheSameRouteStop: this.arePoisTheSameRouteStop(
          this.getSelectedUnitPois()
        ),
      });
    }

    //This should happen only when setting the initial position for an invalid positioned POI while having the move button toggled
    if (!isEqual(this.props.pois, prevProps.pois)) {
      if (
        this.props.mode === RouteEditMode.Move &&
        !prevProps.pois?.length &&
        !this.arePoisInRegion(this.props.pois)
      ) {
        this.props.pois.forEach((poi) => this.onMarkerMove(poi.id));
      }
    }
  }

  public componentDidMount(): void {
    this.setState({
      ...this.state,
      areButtonsDisabled: false,
      selectedUnitPois: this.getSelectedUnitPois(),
      arePoisTheSameRouteStop: this.arePoisTheSameRouteStop(
        this.getSelectedUnitPois()
      ),
    });
  }
  public getSelectedUnitPois(): IRoutePoi[] {
    const ids = this.props.markedRouteSequenceIds;
    const allPois = this.props.allPois;

    const unitPois: IRoutePoi[] = allPois.filter((poi) => ids.includes(poi.id));
    return unitPois;
  }

  /** @todo: this check should not be here */
  public isRouteAdminViewActive(location: Location): boolean {
    return location.pathname.startsWith(routes.routeAdminPagePath);
  }

  public onMarkerMove(id: string): void {
    const center = this.props.leafletElement.getCenter();
    const coords: ICoords = [center.lat, center.lng];
    this.props.moveRouteSequences([{ id, coords }]);
  }

  public async onClear(): Promise<void> {
    this.props.markRouteSequences(this.props.markedRouteSequenceIds);
  }

  public async onPosition(): Promise<void> {
    if (this.props.mode === RouteEditMode.Move) {
      await this.onPositionRestore();
      this.props.setMode(RouteEditMode.Default);
    } else {
      this.setState({
        ...this.state,
        previousPois: [...this.props.pois],
      });

      if (!this.arePoisInRegion(this.props.pois)) {
        this.props.pois.forEach((poi) => this.onMarkerMove(poi.id));
      }

      this.props.setMode(RouteEditMode.Move);
    }
  }

  public getPositionMoveResult(): Array<{
    entity: IRoutePoi;
    position: LatLngTuple;
  }> {
    return this.props.pois.map((entity) => {
      const { degLat, degLong } = entity;
      return {
        entity,
        position: [degLat, degLong],
      };
    });
  }

  private async onPositionSubmit(): Promise<any> {
    if (!isEqual(this.props.pois, this.state.previousPois)) {
      try {
        const result = this.getPositionMoveResult();
        this.setState({ ...this.state, areButtonsDisabled: true });
        await this.props.onSubmit(result);
        await this.onClear();
        this.setState({
          ...this.state,
          areButtonsDisabled: false,
          previousPois: this.props.pois,
        });
      } catch (error) {
        console.log(error);
      }
    }
  }

  private async onPositionRestore(): Promise<void> {
    this.setState({ ...this.state, areButtonsDisabled: true });

    if (!isEqual(this.props.pois, this.state.previousPois)) {
      try {
        await this.props.onRestore();
      } catch (error) {
        console.log(error);
      }
    }

    if (!this.arePoisInRegion(this.state.previousPois)) {
      await this.state.previousPois.forEach(async (poi: IRoutePoi) => {
        const coords: ICoords = [poi.degLat, poi.degLong];
        await this.props.moveRouteSequences([{ id: poi.id, coords }]);
      });
    }

    this.onClear();
    this.setState({ ...this.state, areButtonsDisabled: false });
  }

  public async onSequence(): Promise<void> {
    const modal = create(SequenceModal);
    try {
      await modal();
    } catch (error) {
      if (error === undefined) return;
      console.error(error);
    }
  }

  public async onSplit(): Promise<void> {
    const modal = create(SplitModal);
    try {
      const pois = this.state.selectedUnitPois;
      await modal({
        pois,
        onSubmit: (result) => this.props.splitPoints(result),
      });
    } catch (error) {
      if (error === undefined) return;
      console.error(error);
    }
  }

  public async onMerge(): Promise<void> {
    const modal = create(MergeModal);
    try {
      const pois = this.props.markedRouteSequences;
      await modal({
        pois,
        onSubmit: (result) => this.props.mergePoints(result),
      });
    } catch (error) {
      if (error === undefined) return;
      console.error(error);
    }
  }

  public arePoisInRegion(pois: IRoutePoi[]): boolean {
    return pois.every((poi) =>
      MapUtility.isInRegion(poi.degLat, poi.degLong, selectedRegion)
    );
  }

  public arePoisTheSameRouteStop(pois: IRoutePoi[]): boolean {
    if (pois.length) {
      const routeLineId = pois.shift().routeLineId;
      return !pois.some((poi) => poi.routeLineId !== routeLineId);
    }
    return false;
  }

  public render(): ReactNode {
    if (!this.isRouteAdminViewActive(location)) return null;
    const amount = this.state.selectedUnitPois.length;
    const uniqueAmount = IRoutePoi.squash(
      this.props.markedRouteSequences,
      (poi) => poi.description
    ).length;
    const arePoisTheSameRouteStop = this.state.arePoisTheSameRouteStop;
    const mode = this.props.mode;
    const isDefaultMode = mode === RouteEditMode.Default;
    const buttonsDisabled = this.state.areButtonsDisabled;
    const disabled = {
      deselect: !isDefaultMode || uniqueAmount < 1 || buttonsDisabled,
      position: uniqueAmount > 1 || buttonsDisabled,
      sequence: !isDefaultMode || uniqueAmount !== 1 || buttonsDisabled,
      split:
        !isDefaultMode ||
        amount < 2 ||
        !arePoisTheSameRouteStop ||
        buttonsDisabled,
      merge: !isDefaultMode || uniqueAmount < 2 || buttonsDisabled,
    };
    const isPoiValid = this.arePoisInRegion(this.props.pois);

    let positionLabel: string;

    if (uniqueAmount === 0) {
      positionLabel = locale.routeAdminPage._changePosition;
    } else {
      if (isPoiValid) {
        positionLabel = locale.routeAdminPage._changePosition;
      } else {
        positionLabel = locale.routeAdminPage._setPosition;
      }
    }

    let positionLabelTooltip: string;

    if (uniqueAmount === 0) {
      positionLabelTooltip = locale.routeAdminPage._changePositionTooltip;
    } else {
      if (isPoiValid) {
        positionLabelTooltip = locale.routeAdminPage._changePositionTooltip;
      } else {
        positionLabelTooltip = locale.routeAdminPage._setPositionTooltip;
      }
    }

    return (
      <div>
        <div className={'route-admin-menu active'}>
          <ul>
            <li className={'menu-item-fix-width'}>
              <RouteAdminButton
                title={
                  disabled.deselect
                    ? isDefaultMode
                      ? locale.routeAdminPage._selectAtLeastOne
                      : ''
                    : `${locale.routeAdminPage._remove} (${uniqueAmount}) ${locale.routeAdminPage._selection}`
                }
                label={`${locale.routeAdminPage._remove} (${uniqueAmount}) ${locale.routeAdminPage._selection}`}
                onClick={() => this.onClear()}
                disabled={disabled.deselect}
              />
            </li>
            <li
              className={`menu-item-fix-width ${
                this.props.mode === RouteEditMode.Move ? 'position-toggled' : ''
              }`}
            >
              <RouteAdminButton
                title={
                  uniqueAmount > 1
                    ? locale.routeAdminPage._selectOnlyOne
                    : positionLabelTooltip
                }
                label={positionLabel}
                onClick={() => this.onPosition()}
                disabled={disabled.position}
              />
              <ul
                className={`change-position-sub-menu-container ${
                  this.props.mode === RouteEditMode.Move &&
                  this.state.selectedUnitPois.length
                    ? ''
                    : 'd-none'
                }`}
              >
                <li>
                  <RouteAdminButton
                    title={locale.routeAdminPage._restoreButton}
                    label={locale.routeAdminPage._restoreButton}
                    onClick={() => this.onPositionRestore()}
                    disabled={this.state.areButtonsDisabled}
                    role={RouteEditButtonRole.Cancel}
                  />
                </li>
                <li>
                  <RouteAdminButton
                    title={locale.routeAdminPage._submitButton}
                    label={locale.routeAdminPage._submitButton}
                    onClick={() => this.onPositionSubmit()}
                    disabled={this.state.areButtonsDisabled && isPoiValid}
                    role={RouteEditButtonRole.Submit}
                  />
                </li>
              </ul>
            </li>
            <li className={'menu-item-fix-width'}>
              <RouteAdminButton
                title={
                  disabled.sequence
                    ? isDefaultMode
                      ? locale.routeAdminPage._selectAtLeastOne
                      : ''
                    : locale.routeAdminPage._sequenceEdit
                }
                label={locale.routeAdminPage._sequenceEdit}
                onClick={() => this.onSequence()}
                disabled={disabled.sequence}
              />
            </li>
            <li className={'menu-item-fix-width'}>
              {
                <RouteAdminButton
                  title={
                    disabled.split
                      ? isDefaultMode
                        ? locale.routeAdminPage._selectOnlyOne
                        : ''
                      : locale.routeAdminPage._splitStoppoint
                  }
                  label={locale.routeAdminPage._splitStoppoint}
                  onClick={() => this.onSplit()}
                  disabled={disabled.split}
                />
              }
            </li>
            <li className={'menu-item-fix-width'}>
              <RouteAdminButton
                title={
                  disabled.merge
                    ? isDefaultMode
                      ? locale.routeAdminPage._selectAtLeastTwo
                      : ''
                    : locale.routeAdminPage._mergeStoppoints
                }
                label={locale.routeAdminPage._mergeStoppoints}
                onClick={() => this.onMerge()}
                disabled={disabled.merge}
              />
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
