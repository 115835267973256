import toastr from 'toastr';
import { remove } from 'lodash';
import { calculateTimestampDifferenceInMs } from '../../components/shared/dateUtility';
import { toastrSettings } from './index';

toastr.options = {
  closeButton: true,
  newestOnTop: false,
  positionClass: 'toast-bottom-right',
  hideEasing: 'linear',
  timeOut: toastrSettings.toastrTimeOutMs,
};

interface IToastrError {
  timestamp: Date;
  message: string;
}

export abstract class Toastr {
  private static toastrErrors: Array<IToastrError> = [];

  private static isDuplicateWithinExpirationTime(
    message: string,
    expirationTime: number
  ): boolean {
    const isDuplicate = Toastr.toastrErrors.some((errorMessage) => {
      const isMessageSame = errorMessage.message === message;
      const diff = calculateTimestampDifferenceInMs(
        new Date(),
        errorMessage.timestamp
      );
      return isMessageSame && diff <= expirationTime;
    });
    return isDuplicate;
  }

  private static removeExpiredErrors(expirationTime: number): void {
    Toastr.toastrErrors = remove(Toastr.toastrErrors, (err) => {
      const diff = calculateTimestampDifferenceInMs(new Date(), err.timestamp);
      return !(diff > expirationTime);
    });
  }

  public static error(message: string, ignoreCache?: boolean): void {
    if (ignoreCache) {
      toastr.error(message);
      return;
    }
    Toastr.removeExpiredErrors(toastrSettings.toastrErrorDuplicateExpirationMs);
    if (
      !Toastr.isDuplicateWithinExpirationTime(
        message,
        toastrSettings.toastrErrorDuplicateExpirationMs
      )
    ) {
      Toastr.toastrErrors.push({ timestamp: new Date(), message: message });
      toastr.error(message);
    }
  }

  public static info(message: string): void {
    toastr.info(message);
  }

  public static success(message: string): void {
    toastr.success(message);
  }

  public static warning(message: string): void {
    toastr.warning(message);
  }
}
