import { GeodataConstants } from './geodataConstants';
import { GeomapService } from '../../../services/geomapService';
import { Actions, GeoTokenRequest } from './geodataActions';
import { Dispatch } from 'redux';

export function createGeoTokenRequest(): GeoTokenRequest {
  return {
    type: GeodataConstants.TOKEN_REQUEST,
  };
}

export function createGeoTokenSuccess(
  payload: string
): GeoTokenRequest.Success {
  return {
    type: GeodataConstants.TOKEN_SUCCESS,
    payload,
  };
}

export function createGeoTokenFailure(error: Error): GeoTokenRequest.Failure {
  return {
    type: GeodataConstants.TOKEN_FAILURE,
    error,
  };
}

export function getGeodataToken(): (
  dispatch: Dispatch<Actions>
) => Promise<void> {
  return async function (dispatch: Dispatch<Actions>): Promise<void> {
    dispatch(createGeoTokenRequest());
    try {
      const token: string = await GeomapService.getGeomapToken();
      dispatch(createGeoTokenSuccess(token));
    } catch (error) {
      dispatch(createGeoTokenFailure(error));
    }
  };
}
