import { latLng, LatLngBounds, LatLngTuple } from 'leaflet';
import React, { Component, createRef, ReactNode, RefObject } from 'react';
import { Rectangle } from 'react-leaflet';
import { IAgreementConnectionInfo, RouteEditMode } from '../../models';
import { PropertyTooltip } from './PropertyTooltip';

export interface IPropertyMarkerProps {
  info: IAgreementConnectionInfo;
  isPropertyLayerVisible: boolean;
  zoomLevel: number;
  mode: RouteEditMode;
}
export class PropertyMarker extends Component<IPropertyMarkerProps> {
  public readonly tooltip: RefObject<PropertyTooltip> = createRef();

  public getSize(base: LatLngTuple): number {
    const { zoomLevel } = this.props;

    const metresPerPixel =
      (40075016.686 * Math.abs(Math.cos((base[0] * Math.PI) / 180))) /
      Math.pow(2, zoomLevel + 8);
    return Math.min(5 * metresPerPixel, 4652);
  }

  public getBounds(): LatLngBounds {
    const { info } = this.props;
    const base: LatLngTuple = [info.agreementDegLat, info.agreementDegLon];
    const size = this.getSize(base);
    return latLng(base).toBounds(size);
  }

  public onClick(): void {
    this.tooltip.current.close();
  }

  public render(): ReactNode {
    const { info, mode, isPropertyLayerVisible } = this.props;
    if (!isPropertyLayerVisible) return null;

    const bounds = this.getBounds();

    return (
      <Rectangle
        className="link"
        bounds={bounds}
        onClick={() => this.onClick()}
        color={'black'}
        fillColor={'black'}
      >
        <PropertyTooltip
          ref={this.tooltip}
          info={info}
          disabled={mode !== RouteEditMode.Default}
        />
      </Rectangle>
    );
  }
}
