import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { locale } from '../../common/localization';
import { Formik } from 'formik';
import { FieldWithFeedback, DatePicker, Select } from '../shared/formikFields';
import yup from '../../common/validation';
import moment from 'moment';
import memoize from 'memoize-one';
import { connect } from 'react-redux';
import './addCustomerModal.scss';

function getSchema(currentAgreements) {
  return yup.object().shape({
    date: yup.date()
      .required()
      .min(moment().startOf('day').toDate())
      .label(locale.routeOptimalizationWizard.addCustomerModal._dateLabel),
    agreement: yup.object()
      .nullable()
      .required()
      .test(
        'is-unique',
        'Customer is already in the route list.',
        (agreementOption) => (
          !currentAgreements
          || !agreementOption
          || !currentAgreements.includes(agreementOption.value)
        ),
      )
      .label(locale.routeOptimalizationWizard.addCustomerModal._agreementLabel),
    description: yup.string()
      .required()
      .label(locale.routeOptimalizationWizard.addCustomerModal._descriptionLabel),
    serial: yup.string()
      .required()
      .label(locale.routeOptimalizationWizard.addCustomerModal._serialLabel),
  })
}

function getOptionsFromAgreements(agreements) {
  return agreements.map((agreement) => ({
    label: agreement.companyName,
    value: agreement.agreementId,
  }))
}
const getOptionsFromAgreementsMemoized = memoize(getOptionsFromAgreements);

const initialValues = {
  agreement: null,
  description: '',
  serial: '',
};

function AddCustomerModal({
  onClose,
  onSubmit,
  show,
  defaultDate,
  currentAgreements,
  agreements,
}) {
  const date = defaultDate || new Date();
  const agreementOptions = getOptionsFromAgreementsMemoized(agreements);

  return (
    <Formik
      validationSchema={getSchema(currentAgreements)}
      onSubmit={onSubmit}
      initialValues={{
        ...initialValues,
        date,
      }}
    >
      {({ handleSubmit }) => (
        <Modal onHide={onClose} show={show} centered dialogClassName="add-customer-modal">
          <Modal.Header closeButton >
            <Modal.Title>
              {locale.routeOptimalizationWizard.addCustomerModal._title}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form noValidate onSubmit={handleSubmit}>
              <DatePicker
                label={locale.routeOptimalizationWizard.addCustomerModal._dateLabel}
                name="date"
                popupClass="date-picker-popup-above-modal"
              />
              <Select
                name="agreement"
                options={agreementOptions}
                label={locale.routeOptimalizationWizard.addCustomerModal._agreementSelector}
                noOptionsMessage={locale.routeOptimalizationWizard.addCustomerModal._noOptionsMessage}
                loadingMessage={locale.routeOptimalizationWizard.addCustomerModal._loadingMessage}
                placeholder={locale.routeOptimalizationWizard.addCustomerModal._agreementSelectorPlaceholder}
              />
              <FieldWithFeedback
                label={locale.routeOptimalizationWizard.addCustomerModal._descriptionLabel}
                name="description"
              />
              <FieldWithFeedback
                label={locale.routeOptimalizationWizard.addCustomerModal._serialLabel}
                name="serial"
                type="number"
              />
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="light" onClick={onClose}>
              {locale.routeOptimalizationWizard.addCustomerModal._closeButton}
            </Button>
            <Button variant="primary" type="submit" onClick={handleSubmit}>
              {locale.routeOptimalizationWizard.addCustomerModal._addButton}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Formik>
  );
}

AddCustomerModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  show: PropTypes.bool,
  defaultDate: PropTypes.object,
  currentAgreements: PropTypes.array.isRequired,
  agreements: PropTypes.array.isRequired,
}

AddCustomerModal.defaultProps = {
  onClose: () => { },
  onSubmit: () => { },
  show: true,
  currentAgreements: [],
  agreements: [],
}

function mapStateToProps(state) {
  return {
    agreements: state.agreement.agreements,
  }
}

export default connect(mapStateToProps)(AddCustomerModal);
