import { bindActionCreators, Dispatch } from 'redux';
import { IRootState } from '../../redux/state';
import { logout } from '../../redux/modules/auth/authCreators';
import { connect } from 'react-redux';

import ProfileMenu from './ProfileMenu';

function mapStateToProps(state: IRootState) {
  const authentication = state.authentication;
  return {
    authentication,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    logout: bindActionCreators(logout, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileMenu);
