import React, { Component, ReactNode } from 'react';
import { IEmptyProps } from '../../utils';

export interface IMapErrorBoundaryState {
  hasError: boolean;
}
export default class MapErrorBoundary extends Component<
  IEmptyProps,
  IMapErrorBoundaryState
> {
  public static getDerivedStateFromError(): { hasError: boolean } {
    return { hasError: true };
  }

  public constructor(props: IEmptyProps) {
    super(props);
    this.state = { hasError: false };
  }

  public render(): ReactNode {
    if (this.state.hasError) {
      return <div></div>;
    }

    return this.props.children;
  }
}
