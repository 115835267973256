import { selectDate, groupBy } from '../../services/listService';

export function getAddress(order) {
  if (order.placeEstate) {
    return `${order.placeEstate}`;
  }

  if (order.agreementAddress) {
    return `${order.agreementAddress}`;
  }

  return '';
}

function getOrderItemTypes(orders) {
  if (!orders || orders.length === 0) {
    return [];
  }

  return [...new Set(orders.map((order) => order.orderItemType))].sort();
}

function getDeviations(orders) {
  if (!orders || orders.length === 0) {
    return [];
  }

  return orders
    .filter(o => o.deviations && o.deviations.length > 0)
    .flatMap(o => o.deviations);
}

export function getVehicleIds(serviceOrders) {
  if (!serviceOrders || serviceOrders.length === 0) {
    return null;
  }

  return [...new Set(serviceOrders.map((order) => order.vehicleId))].join(' ');
}

export function processServiceOrders(serviceOrders) {
  const order = serviceOrders[0];
  return {
    ...order,
    address: getAddress(order),
    updated: selectDate(serviceOrders.map((order) => order.updated), Math.max),
    executed: serviceOrders.length === serviceOrders.filter((item) => item.executed).length,
    orderItemType: getOrderItemTypes(serviceOrders),
    deviation: getDeviations(serviceOrders),
    pictures: order.picURL && order.picURL.split('|'),
    vehicleId: getVehicleIds(serviceOrders),
  }
}

export function groupAndProcessServiceOrders(serviceOrders) {
  const groupedOrders = groupBy(serviceOrders, (order) => order.listItemId);
  return Array.from(groupedOrders, ([, orders]) => ({
    ...orders[0],
    ...processServiceOrders(orders),
  }));
}
