import React from 'react';
import PropTypes from 'prop-types';

export default function ContractorCell({ dataItem, contractors }) {
  let result = '-';
  if (dataItem.contractor && dataItem.contractorId != null) {
    const contractor = contractors.find(c => c.contractorId === dataItem.contractorId)
    if (contractor) {
      result = contractor.contractorName;
    }
  }

  return <td>{result}</td>;
}

ContractorCell.propTypes = {
  dataItem: PropTypes.object.isRequired,
  contractors: PropTypes.array.isRequired,
}
