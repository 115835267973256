import { bindActionCreators, Dispatch } from 'redux';
import customerActions from '../../redux/modules/customer/customerActions';
import {
  loadDeviationCategories,
  loadDeviations,
} from '../../redux/modules/deviaton/deviatonCreators';
import { IRootState } from '../../redux/state';
import { IUser } from '../../models';
import { connect } from 'react-redux';
import Deviations from './Deviations';

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getCustomers: customerActions.getCustomers,
      getDeviations: loadDeviations,
      getDeviationCategories: loadDeviationCategories,
    },
    dispatch
  );
}

function mapStateToProps(state: IRootState) {
  return {
    impersonatedUser: state.authentication.impersonatedUser,
    customers: state.customer.customers,
    authentication: state.authentication as unknown as IUser,
    deviationList: state.deviation.deviationList,
    deviationCategoryList: state.deviation.deviationCategoryList,
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(Deviations);
