import React from "react";
import CacheRefreshCounter from "./CacheRefreshCounter";
import CacheRefreshToggle from "./CacheRefreshToggle";
import Control from 'react-leaflet-control';
import './cache-refresher-controls.scss';

function CacheRefreshControls() {
  return (
    <Control
      position="topright"
      className="leaflet-bar"
    >
      <CacheRefreshToggle />
      <CacheRefreshCounter />
    </Control >
  );
}

export default CacheRefreshControls;
