import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { locale } from '../../../common/localization';
import { Formik } from 'formik';
import yup from '../../../common/validation';
import WorklistSelector from './WorklistSelector';

const moveWorklistItemModalLocale = locale.routeOptimalizationWizard.worklistResults.moveWorklistItemModal;

const schema = yup.object().shape({
  targetList: yup.number()
    .required()
    .label(moveWorklistItemModalLocale._targetListLabel),
});

function MoveWorklistItemModal({
  onClose,
  onSubmit,
  worklists,
  currentWorklist,
}) {
  const initialTargetList = currentWorklist === 0 ? 1 : 0;

  return (
    <Formik
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={{
        targetList: initialTargetList,
      }}
    >
      {({
        handleSubmit,
        values,
        setFieldValue,
      }) => (
        <Modal onHide={onClose} centered show>
          <Modal.Header closeButton>
            <Modal.Title>
              {moveWorklistItemModalLocale._title}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form noValidate onSubmit={handleSubmit}>
              <WorklistSelector
                worklistsCount={worklists && worklists.length || 0}
                selectedWorklist={values.targetList}
                name="targetList"
                onChange={(option) => setFieldValue('targetList', option)}
                skip={[currentWorklist]}
              />
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="light" onClick={onClose}>
              {moveWorklistItemModalLocale._closeButton}
            </Button>
            <Button variant="primary" type="submit" onClick={handleSubmit}>
              {moveWorklistItemModalLocale._moveButton}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Formik>
  );
}

MoveWorklistItemModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  worklists: PropTypes.array,
  currentWorklist: PropTypes.number,
}

MoveWorklistItemModal.defaultProps = {
  onClose: () => { },
  onSubmit: () => { },
  worklists: [],
  currentWorklist: 0,
}

export default MoveWorklistItemModal;
