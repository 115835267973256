import routeOptimalizationConstants from './routeOptimalizationConstants';

function setImportedRoutes(importedRoutes) {
  return { type: routeOptimalizationConstants.SET_IMPORTED_ROUTES, importedRoutes };
}

function addImportedRoute(route) {
  return { type: routeOptimalizationConstants.ADD_IMPORTED_ROUTE, route };
}

function removeImportedRoute(routeGln) {
  return { type: routeOptimalizationConstants.REMOVE_IMPORTED_ROUTE, routeGln };
}

function setUnassignedStops(unassignedStops) {
  return { type: routeOptimalizationConstants.SET_UNASSIGNED_STOPS, unassignedStops };
}

function setWorklists(worklists) {
  return { type: routeOptimalizationConstants.SET_WORKLISTS, worklists };
}

function setSelectedWorklist(selectedWorklist) {
  return { type: routeOptimalizationConstants.SET_SELECTED_WORKLIST, selectedWorklist };
}

function setActiveTab(activeTab) {
  return { type: routeOptimalizationConstants.SET_ACTIVE_ROUTE_OPTIMALIZATION_TAB, activeTab };
}

function clear() {
  return { type: routeOptimalizationConstants.CLEAR_IMPORTED_ROUTE_STORE };
}

function setDepotStopLists(depotStopLists) {
  return { type: routeOptimalizationConstants.SET_DEPOT_STOP_LISTS, depotStopLists };
}

export default {
  setImportedRoutes,
  addImportedRoute,
  clear,
  setWorklists,
  setSelectedWorklist,
  setActiveTab,
  removeImportedRoute,
  setUnassignedStops,
  setDepotStopLists,
}
