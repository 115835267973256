import { locale } from '../../common/localization';
import {
  IAddVehicleSetting,
  IAddVehicleSettingBulk,
  IVehicle,
  SelectOption,
} from '../../models';
import VehicleSettingService from '../../services/vehicleSettingService';

export const getVehicleDropdownOptions = (
  vehicles: Array<IVehicle>
): Array<SelectOption> => {
  const options = vehicles.map((vehicle: IVehicle) => {
    return {
      value: vehicle,
      label: vehicle.vehicleId,
    };
  });
  return options;
};

export const mapAddVehicleSettingToBulkArray = (
  vehicleSetting: IAddVehicleSetting,
  selectedVehicleIds: Array<string>
): Array<IAddVehicleSettingBulk> => {
  const vehicleSettingsList = selectedVehicleIds.map((vehicleId) => {
    return {
      key: vehicleSetting.key,
      value: vehicleSetting.value,
      vehicleId: vehicleId,
    } as IAddVehicleSettingBulk;
  });
  return vehicleSettingsList;
};

export const copyVehicleSettings = async (
  sourceVehicleId: string,
  targetVehicleId: string
): Promise<void> => {
  const targetVehicleSettings = await VehicleSettingService.getVehicleSettings(
    targetVehicleId
  );
  const sourceVehicleSettings = await VehicleSettingService.getVehicleSettings(
    sourceVehicleId
  );
  if (targetVehicleSettings.length || !sourceVehicleSettings.length) {
    throw new Error(locale.vehicleSettings._copyFromVehicleError);
  }

  const vehicleSettingsList = sourceVehicleSettings.map((vehicleSetting) => {
    return {
      key: vehicleSetting.key,
      value: vehicleSetting.value,
      vehicleId: targetVehicleId,
    } as IAddVehicleSettingBulk;
  });

  await VehicleSettingService.addBulkVehicleSetting(vehicleSettingsList);
};

export const filterVehicles = (
  vehicles: Array<IVehicle>,
  filterOptions: {
    customerId?: number;
    contractorId?: number;
  }
): Array<IVehicle> => {
  const filteredVehicles = vehicles.filter(
    (vehicle) =>
      (!filterOptions.customerId ||
        vehicle.customerId === filterOptions.customerId) &&
      (!filterOptions.contractorId ||
        vehicle.contractorId === filterOptions.contractorId)
  );
  return filteredVehicles;
};

export const getVehicleById = (
  vehicles: Array<IVehicle>,
  vehicleId: string
): IVehicle | undefined => {
  return vehicles.find((vehicle) => vehicle.vehicleId === vehicleId);
};
