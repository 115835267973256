/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { debounce, DebounceSettings } from 'lodash';

/**
 * Lodash debounce wrapper
 * @param {any} func It is the function that has to be debounced.
 * @param {number} wait It is the number of milliseconds for which the calls are to be delayed. It is an optional parameter. The default value is 0.
 * @param {DebounceSettings} options It is the options object that can be used for changing the behaviour of the method. It is an optional parameter.
 * @returns {DebouncedFunc<any>} options The formatted time in MM:SS format.
 */
export function debounceEventHandler(
  func: any,
  wait?: number,
  options?: DebounceSettings
) {
  const debounced = debounce(func, wait, options);
  return function (e: any) {
    e.persist();
    return debounced(e);
  };
}
