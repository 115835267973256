import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

function InfoButtonCell(props) {
  const { linkToUrl, className } = props;

  return (
    <td className={`action-button ${className}`}>
      <Link to={linkToUrl}>
        <FontAwesomeIcon icon="info-circle" />
      </Link>
    </td>
  );
}

InfoButtonCell.propTypes = {
  linkToUrl: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default InfoButtonCell;
