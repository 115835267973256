import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { IRootState } from '../../redux/state';
import {
  getPois,
  getRoutes,
  getSelectedRoutes,
  getMarkedRouteSequenceIds,
  getMarkedRouteSequences,
  getEditMode,
} from '../../redux/modules/route/routeSelectors';
import {
  markRouteSequences,
  moveRouteSequences,
} from '../../redux/modules/route/routeCreators';
import { RouteAdminLayer } from './RouteAdminLayer';

function mapStateToProps(state: IRootState) {
  return {
    routes: getRoutes(state),
    pois: getPois(state),
    selectedRoutes: getSelectedRoutes(state),
    markedRouteSequenceIds: getMarkedRouteSequenceIds(state),
    markedRouteSequences: getMarkedRouteSequences(state),
    mode: getEditMode(state),
    location: state.router.location,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      moveRouteSequences,
      markRouteSequences,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(RouteAdminLayer);
