import proj4 from 'proj4';
import { RegionSetting } from '../../models';

export abstract class MapUtility {
  private static readonly utm33N =
    '+proj=utm +zone=33 +ellps=WGS84 +datum=WGS84 +units=m +no_defs';

  public static convertToUTM33(lat: number, long: number): Array<number> {
    return proj4(MapUtility.utm33N, [long, lat]);
  }

  public static isInNorway(lat: number, long: number): boolean {
    return lat < 72 && lat > 58 && long < 32 && long > 5;
  }

  public static isInEurope(lat: number, long: number): boolean {
    return lat < 72 && lat > 38 && long < 32 && long > -8;
  }

  public static isInRegion(lat: number, long: number, region: string = RegionSetting.Norway): boolean {
    switch (region) {
      case RegionSetting.Europe:
        return MapUtility.isInEurope(lat, long);
      case RegionSetting.Norway:
        return MapUtility.isInNorway(lat, long);
      default:
        return false;
    }
  }

  //Attribution/reference: https://www.geodatasource.com/developers/javascript
  public static getDistanceInMeters(lat1: number, lon1: number, lat2: number, lon2: number): number {
    if ((lat1 == lat2) && (lon1 == lon2)) {
      return 0;
    }
    else {
      const radlat1 = Math.PI * lat1/180;
      const radlat2 = Math.PI * lat2/180;
      const theta = lon1-lon2;
      const radtheta = Math.PI * theta/180;
      let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = dist * 180/Math.PI;
      dist = dist * 60 * 1.1515;
      return dist * 1.609344 * 1000;
    }
  }
}
