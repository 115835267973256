export default {
  START_CACHE_REFRESH_TIMER: 'START_CACHE_REFRESH_TIMER',
  STOP_CACHE_REFRESH_TIMER: 'STOP_CACHE_REFRESH_TIMER',

  ADD_REQUEST_IN_PROGRESS_CACHE_REFRESH:
    'ADD_REQUEST_IN_PROGRESS_CACHE_REFRESH',
  REMOVE_REQUEST_IN_PROGRESS_CACHE_REFRESH:
    'REMOVE_REQUEST_IN_PROGRESS_CACHE_REFRESH',

  SET_CACHE_REFRESH_FILTER: 'SET_CACHE_REFRESH_FILTER',

  REQUEST_TYPE_ORDERS: 'REQUEST_TYPE_ORDERS',
  REQUEST_TYPE_ORDER_DETAILS: 'REQUEST_TYPE_ORDER_DETAILS',
  REQUEST_TYPE_SERVICE_LISTS: 'REQUEST_TYPE_SERVICE_LISTS',
  REQUEST_TYPE_SERVICE_ORDER: 'REQUEST_TYPE_SERVICE_ORDER',
  REQUEST_TYPE_CONTAINER_ORDERS: 'REQUEST_TYPE_CONTAINER_ORDERS',
};
