import { createSelector } from 'reselect';

export const getSelectedServiceList = createSelector(
  [
    (state) => state.serviceOrders.serviceLists,
    (state) => state.serviceOrders.selectedListId,
  ],
  (serviceLists, selectedListId) => serviceLists
    && selectedListId
    && serviceLists.find((list) => list.listId === selectedListId)
);

export const getSelectedServiceOrder = createSelector(
  [
    getSelectedServiceList,
    (state) => state.serviceOrders.selectedOrderId,
  ],
  (selectedServiceList, selectedOrderId) => selectedServiceList
    && selectedOrderId
    && selectedServiceList.orders
    && selectedServiceList.orders.find((order) => order.listItemId === selectedOrderId)
);

const makeGetServiceListByPathParams = () => createSelector(
  [
    (state) => state.serviceOrders.serviceLists,
    (_state, props) => Number(props.match.params.listId),
  ],
  (serviceLists, listId) => serviceLists.find((list) => list.listId === listId),
);

export const makeGetServiceOrdersByPathParams = () => {
  const getServiceListByPathParams = makeGetServiceListByPathParams();
  return createSelector(
    [
      getServiceListByPathParams,
      (_state, props) => Number(props.match.params.listItemId),
    ],
    (serviceList, listItemId) => serviceList
      && serviceList.orders
      && serviceList.orders.filter((order) => order.listItemId === listItemId),
  );
}

export const makeGetServiceListOrdersByPathParams = () => {
  const getServiceListByPathParams = makeGetServiceListByPathParams();
  return createSelector(
    getServiceListByPathParams,
    (serviceList) => serviceList
      && serviceList.orders,
  );
}
