import { uniqBy as _uniqBy } from 'lodash';
import { IPoiAgreement } from './PoiAgreement.model';
import { IRoute } from './Route.model';
import { IAgreementConnectionInfo } from './AgreementConnectionInfo.model';
import { ICoords } from './Coords.model';

export interface IRoutePoi {
  id?: string;
  agrLnLastChanged: string;
  agreementId: number;
  agreements?: Array<IPoiAgreement>;
  agreementConnectionInfo?: IAgreementConnectionInfo;
  infos?: Array<IAgreementConnectionInfo>;
  agreementLineId: number;
  containerId: string;
  customerId: number;
  degLat: number;
  degLong: number;
  description: string;
  e: number;
  enabled: boolean;
  estate: string;
  externalId: number;
  fromDate: string;
  lastChanged: string;
  n: number;
  name: string;
  paSystem: string;
  physicalAmount: number;
  poiId: number;
  routeName?: string;
  route: string;
  route1: string;
  route2: string;
  route3: string;
  route4: string;
  route5: string;
  routeLineId: number;
  sequence: number;
  termin: string;
  toDate: string;
  unitId: string;
  unitName: string;
}
export namespace IRoutePoi {
  export function squash(
    pois: Array<IRoutePoi>,
    hash: (poi: IRoutePoi) => string = (poi) => `${poi.poiId}`
  ): Array<IRoutePoi> {
    const hashMap = new Map<string, IRoutePoi>();
    pois.forEach((poi: IRoutePoi) => {
      const id = hash(poi);
      const agreement = { id: poi.agreementId, name: poi.name };
      const info = { ...poi.agreementConnectionInfo };
      if (!hashMap.has(id)) {
        poi.agreements = poi.agreements || [agreement];
        poi.infos = poi.infos || [info];
        hashMap.set(id, poi);
      } else {
        const old = hashMap.get(id);
        hashMap.set(id, {
          ...poi,
          name: join([old, poi], 'name'),
          estate: join([old, poi], 'estate'),
          unitName: join([old, poi], 'unitName'),
          agreements: _uniqBy(
            [...old.agreements, agreement],
            IPoiAgreement.toString
          ),
          infos: _uniqBy(
            [...old.infos, info],
            IAgreementConnectionInfo.toString
          ),
          /** @todo: average lat/lng */
          degLong: poi.degLong,
          degLat: poi.degLat,
          physicalAmount: old.physicalAmount + poi.physicalAmount,
        });
      }
    });

    return Array.from(hashMap.values());
  }

  export function join(pois: Array<IRoutePoi>, field: string): string {
    return pois.reduce((memo, poi) => {
      const value = `${(poi as any)[field]}`;
      if (memo.includes(value)) return memo;
      if (memo === '') return value;
      return `${memo}, ${value}`;
    }, '');
  }

  export function mapNames(
    routes: Array<IRoute>,
    pois: Array<IRoutePoi>
  ): Array<IRoutePoi> {
    const nameMap = new Map(
      routes.map((route) => [route.routeNumbder, route.name])
    );
    return pois.map((poi) => ({ ...poi, routeName: nameMap.get(poi.route) }));
  }

  export function mapConnectionInfos(
    pois: Array<IRoutePoi>,
    infos: Array<IAgreementConnectionInfo>
  ): Array<IRoutePoi> {
    return pois.map((poi) => {
      const info = infos.find((info) => info.agreementId === poi.agreementId);
      if (poi.agreementConnectionInfo && info) {
        console.warn(
          'mapping tried to update an already existing agreement connection info',
          poi,
          info
        );
        return poi;
      }
      return {
        ...poi,
        agreementConnectionInfo: info,
      };
    });
  }

  export function toCoords(poi: IRoutePoi): ICoords {
    return [poi.degLat, poi.degLong];
  }

  export function createId(poi: IRoutePoi): IRoutePoi {
    return {
      ...poi,
      id: `${poi.route}#${poi.paSystem}#${poi.agreementLineId}#${poi.customerId}`,
    };
  }
}
