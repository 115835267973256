import React, { Component, ReactNode } from 'react';
import { mapSettings } from '../../appConfig';
import { locale } from '../../common/localization';
import './TrackingLine.scss';

interface ITrackingLineLegendProps {
  visible: boolean;
}

export class TrackingLineLegend extends Component<ITrackingLineLegendProps> {
  public render(): ReactNode {
    const isNotTrackedLegendEnabled = false;
    const colors = mapSettings.objectColoring;

    if (!this.props.visible) {
      return <></>;
    } else {
      return (
        <div className="tracking-line-legend">
          <div className="tracking-line-legend-item">
            <div
              className="tracking-line-legend-item-color"
              style={{ backgroundColor: colors.firstInterval }}
            ></div>
            <div className="tracking-line-legend-item-text">
              <span>{locale.map._trackingLineLegend._belowSix}</span>
            </div>
          </div>
          <div className="tracking-line-legend-item">
            <div
              className="tracking-line-legend-item-color"
              style={{ backgroundColor: colors.secondInterval }}
            ></div>
            <div className="tracking-line-legend-item-text">
              <span>{locale.map._trackingLineLegend._sixToTwelve}</span>
            </div>
          </div>
          <div className="tracking-line-legend-item">
            <div
              className="tracking-line-legend-item-color"
              style={{ backgroundColor: colors.thirdInterval }}
            ></div>
            <div className="tracking-line-legend-item-text">
              <span>{locale.map._trackingLineLegend._twelveToTwentyFour}</span>
            </div>
          </div>
          <div className="tracking-line-legend-item">
            <div
              className="tracking-line-legend-item-color"
              style={{ backgroundColor: colors.fourthInterval }}
            ></div>
            <div className="tracking-line-legend-item-text">
              <span>{locale.map._trackingLineLegend._overTwentyFour}</span>
            </div>
          </div>
          {isNotTrackedLegendEnabled && (
            <div className="tracking-line-legend-item">
              <div
                className="tracking-line-legend-item-color"
                style={{ backgroundColor: colors.default }}
              ></div>
              <div className="tracking-line-legend-item-text">
                <span>{locale.map._trackingLineLegend._gray}</span>
              </div>
            </div>
          )}
        </div>
      );
    }
  }
}

export default TrackingLineLegend;
