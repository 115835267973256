import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as vehicleTrackingActions from '../../redux/modules/vehicleTracking/vehicleTrackingActions';
import VehicleTrackingLayer from './VehicleTrackingLayer';

function mapStateToProps(state) {
  return {
    vehicleTracking: state.vehicleTracking,
    orders: state.order.data,
    selectedOrder: state.order.selected,
    location: state.router.location,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    vehicleTrackingActions: bindActionCreators(vehicleTrackingActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VehicleTrackingLayer);
