import { LatLngBounds, LatLng } from 'leaflet';
import { isEqual, isNil } from 'lodash';
import moment from 'moment';
import React, { Component, ReactNode } from 'react';
import { CircleMarker, Pane } from 'react-leaflet';
import { mapSettings } from '../../appConfig';
import { ISelectedOrder, ITrackInfo, ITrackInfoDisplay } from '../../models';
import { AsyncAction } from '../../utils';
import { ClusteredMapLayer } from '../shared/customMapLayer';
import TrackInfoPopup from './TrackInfoPopup';

export interface ITrackInfoLayerProps {
  clusteringActive: boolean;
  zoomLevel: number;
  bounds: LatLngBounds;
  trackInfo: Array<ITrackInfo>;
  order: ISelectedOrder;
  loadTrackInfos: (ignoreCache: boolean, orderId: number) => AsyncAction;
}

export interface ITrackInfoLayerState {
  trackInfoToDraw: Array<ITrackInfo>;
}
export class TrackInfoLayer extends Component<
  ITrackInfoLayerProps,
  ITrackInfoLayerState
> {
  public readonly state: ITrackInfoLayerState = {
    trackInfoToDraw: this.props.trackInfo || [],
  };

  public componentDidMount(): void {
    if (this.props.order?.orderId) {
      this.props.loadTrackInfos(false, this.props.order?.orderId);
    }
  }

  public componentDidUpdate(
    prevProps: Readonly<ITrackInfoLayerProps>,
    prevState: Readonly<ITrackInfoLayerState>
  ): void {
    if (
      !isEqual(prevProps.order?.orderId, this.props.order?.orderId) &&
      !isNil(this.props.order?.orderId)
    ) {
      this.props.loadTrackInfos(false, this.props.order?.orderId);
    }

    this.updateTrackInfoToDrawByBoundsAndOrderChecking(prevState);
  }

  private updateTrackInfoToDrawByBoundsAndOrderChecking(
    state: Readonly<ITrackInfoLayerState>
  ): void {
    const inBoundTrackInfos = this.props.trackInfo
      .filter((trackInfo: ITrackInfo) => {
        const point = new LatLng(trackInfo.latitude, trackInfo.longitude);
        return this.props.bounds.contains(point);
      })
      .slice(0, 500);

    const formattedDateTrackInfos: Array<ITrackInfoDisplay> =
      inBoundTrackInfos.map((trackInfo: ITrackInfo) => {
        return {
          ...trackInfo,
          displayDate: moment(trackInfo.createdAtUtc).format(
            'DD.MM.YYYY HH:mm:ss'
          ),
        };
      });

    if (!isEqual(formattedDateTrackInfos, state.trackInfoToDraw)) {
      this.setState({
        trackInfoToDraw: formattedDateTrackInfos,
      });
    }
  }

  private getRadius() {
    const zoomLevel = this.props.zoomLevel;
    const fallBackRadius = 5;
    const radius = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 2, 3, 4, 4];

    return radius[zoomLevel - 1] ?? fallBackRadius;
  }

  private getColor(date: Date): string {
    const colors = mapSettings.objectColoring;
    if (!date) return colors.default;

    const now = moment();
    const duration = moment.duration(now.diff(date));
    const diffInHours = duration.asHours();

    if (diffInHours < 6) {
      return colors.firstInterval;
    } else if (diffInHours < 12) {
      return colors.secondInterval;
    } else if (diffInHours < 24) {
      return colors.thirdInterval;
    } else {
      return colors.fourthInterval;
    }
  }

  public render(): ReactNode {
    const { clusteringActive } = this.props;
    const { trackInfoToDraw } = this.state;
    const radius = this.getRadius();

    if (!this.props.trackInfo?.length) {
      return <></>;
    } else {
      return (
        <Pane name="trackinfo-layer" style={{ zIndex: 302 }}>
          {trackInfoToDraw.map((trackInfo: ITrackInfoDisplay) => {
            const color = this.getColor(trackInfo.createdAtUtc);
            return (
              <CircleMarker
                key={trackInfo.id}
                center={[trackInfo.latitude, trackInfo.longitude]}
                radius={radius}
                color={color}
                fill={true}
                fillColor={color}
                fillOpacity={0.95}
                stroke={false}
              >
                <TrackInfoPopup trackInfo={trackInfo} />
              </CircleMarker>
            );
          })}
        </Pane>
      );
    }
  }
}

export default TrackInfoLayer;
