import React from 'react';
import PropTypes from 'prop-types';
import { faCheck, faMinus } from '@fortawesome/free-solid-svg-icons';
import DetailCell from './DetailCell';
import { locale } from '../../common/localization';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

export default function OrderDetailsTable({ orderDetails }) {
  if (!orderDetails) {
    return null;
  }

  const executedIcon = orderDetails.executed ? faCheck : faMinus;
  return (
    <div className="card mb-3">
      <div className="card-body details-container">
        <DetailCell
          label={locale.serviceOrderDetailsGrid._sequence}
          value={orderDetails.sequence}
        />
        <DetailCell
          label={locale.serviceOrderDetailsGrid._externalOrderId}
          value={orderDetails.externalOrderId}
        />
        <DetailCell
          label={locale.serviceOrderDetailsGrid._agreementCompanyName}
          value={orderDetails.agreementCompanyName}
        />
        <DetailCell
          label={locale.serviceOrderDetailsGrid._address}
          value={orderDetails.address}
        />
        <DetailCell
          label={locale.serviceOrderDetailsGrid._listDescription}
          value={orderDetails.listDescription}
        />
        <DetailCell label={locale.serviceOrderDetailsGrid._executed}>
          <div className="executed-status">
            <FontAwesomeIcon icon={executedIcon} />
          </div>
        </DetailCell>
        <DetailCell
          label={locale.serviceOrderDetailsGrid._vehicleId}
          value={orderDetails.vehicleId}
        />
        <DetailCell
          label={locale.serviceOrderDetailsGrid._updated}
          value={!!orderDetails.updated && moment(orderDetails.updated).format('DD.MM.YY HH:mm')}
        />
        <DetailCell
          label={locale.serviceOrderDetailsGrid._estimatedArrivalTime}
          value={!!orderDetails.estimatedArrivalTime && moment(orderDetails.estimatedArrivalTime).format('HH:mm')}
        />
      </div>
    </div>
  );
}

OrderDetailsTable.propTypes = {
  orderDetails: PropTypes.object,
}
