import React, { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';

export interface IBreadcrumb {
  name: string;
  link?: string;
}

interface IBreadcrumbItemProps {
  breadcrumb: IBreadcrumb;
  isLast: boolean;
  separator?: string;
}

const BreadcrumbItem = (props: IBreadcrumbItemProps): ReactElement => {
  if (!props.isLast) {
    return (
      <>
        <NavLink to={props.breadcrumb.link} className="breadcrumb-item">
          {props.breadcrumb.name}
        </NavLink>
        <span className="breadcrumb-separator">
          &nbsp;{props.separator || '/'}&nbsp;
        </span>
      </>
    );
  } else {
    return (
      <>
        <span className="breadcrumb-item">{props.breadcrumb.name}</span>
      </>
    );
  }
};

export default BreadcrumbItem;
