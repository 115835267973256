import wasteManagementClient from '../../services/wasteManagementClient';
import { wasteManagementUrls, filterPositionDays } from '../../appConfig';
import LocalTimeExtensionService from '../../services/localTimeExtensionService';
import moment from 'moment';

export default class VehicleService {
  static filterRecentVehiclePositions(vehicles) {
    const now = moment();
    return vehicles.filter((vehicle) =>
      VehicleService.isDataVehicleRecent(vehicle, now)
    );
  }

  static isDataVehicleRecent(vehicle, now) {
    if (!vehicle.updatedAtLocal) {
      return false;
    }

    var updatedAt = moment(vehicle.updatedAtLocal);
    return now.diff(updatedAt, 'days', true) < filterPositionDays;
  }

  static getVehicles(ignoreCache = false) {
    return wasteManagementClient
      .get(wasteManagementUrls.vehicles.positions, {
        cache: { ignoreCache },
      })
      .then((response) => {
        if (response.data && response.status === 200) {
          const vehicles = response.data;
          LocalTimeExtensionService.extendVehiclesWithLocalTime(vehicles);
          return this.filterRecentVehiclePositions(vehicles);
        } else {
          return [];
        }
      })
      .catch((error) => console.error(error));
  }
}
