import React from "react";
import PropTypes from 'prop-types';
import { locale } from '../../common/localization';
import { DatePicker } from '@progress/kendo-react-dateinputs';

const commonDatePickerProps = {
  format: 'dd.MM.yyyy',
  className: 'date-picker',
  popupSettings: { popupClass: 'date-picker-popup' },
}

function DateSelector({
  from,
  to,
  handleChange,
}) {
  return (
    <div>
      <div className="form-group row">
        <label
          htmlFor="from-date"
          className="col col-form-label"
        >
          {locale.reports._fromDate}:
        </label>
        <DatePicker
          {...commonDatePickerProps}
          id="from-date"
          name="from"
          value={from}
          onChange={handleChange}
        />
      </div>
      <div className="form-group row">
        <label
          htmlFor="to-date"
          className="col col-form-label"
        >
          {locale.reports._toDate}:
        </label>
        <DatePicker
          {...commonDatePickerProps}
          id="to-date"
          name="to"
          value={to}
          onChange={handleChange}
          {...commonDatePickerProps}
        />
      </div>
    </div>
  );
}

DateSelector.propTypes = {
  handleChange: PropTypes.func.isRequired,
  from: PropTypes.instanceOf(Date),
  to: PropTypes.instanceOf(Date),
}

export default DateSelector;
