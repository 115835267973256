import React, { Component, createRef, ReactNode, RefObject } from 'react';
import { Tooltip } from 'react-leaflet';
import { locale } from '../../common/localization';
import { IRoutePoi } from '../../models';

import './PoiTooltip.scss';

export interface IPoiTooltipProps {
  poi: IRoutePoi;
  disabled: boolean;
}

export class PoiTooltip extends Component<IPoiTooltipProps> {
  public readonly tooltip: RefObject<Tooltip> = createRef();

  public close(): void {
    if (!this.tooltip.current) return;
    const options: any = this.tooltip.current.leafletElement.options;
    options.leaflet.map.closeTooltip(this.tooltip.current.leafletElement);
  }

  public render(): ReactNode {
    const { poi, disabled } = this.props;

    if (disabled) return null;

    /** @description */
    /** this workaround is needed, because we lose
     *  reference of the original element on rerender */
    this.close();

    return (
      <Tooltip direction="top" interactive={false} ref={this.tooltip}>
        <table className="info-table">
          <tbody>
            <tr>
              <td>{locale.routeAdminPage._sequence}</td>
              <td>{poi.sequence}</td>
            </tr>
            <tr>
              <td>{locale.routeAdminPage._agreementId}</td>
              <td>{poi.agreementId}</td>
            </tr>
            <tr>
              <td>{locale.routeAdminPage._agreementLineId}</td>
              <td>{poi.agreementLineId}</td>
            </tr>
            <tr>
              <td>{locale.routeAdminPage._name}</td>
              <td>{poi.name}</td>
            </tr>
            <tr>
              <td>{locale.routeAdminPage._estate}</td>
              <td>{poi.estate}</td>
            </tr>
            <tr>
              <td>{locale.routeAdminPage._physicalAmount}</td>
              <td>{poi.physicalAmount}</td>
            </tr>
            <tr>
              <td>{locale.routeAdminPage._unitName}</td>
              <td>{poi.unitName}</td>
            </tr>
            <tr>
              <td>{locale.routeAdminPage._route}</td>
              <td>{poi.routeName}</td>
            </tr>
            <tr>
              <td>{locale.routeAdminPage._routeLineId}</td>
              <td>{poi.routeLineId}</td>
            </tr>
          </tbody>
        </table>
      </Tooltip>
    );
  }
}
