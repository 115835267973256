import { wasteManagementUrls } from '../appConfig';
import { IVehicle, IAddVehicle } from '../models';
import wasteManagementClient from './wasteManagementClient';

export default abstract class VehicleService {
  public static getInitVehicle(isNew = false): IVehicle | IAddVehicle {
    const initVehicle: IVehicle | IAddVehicle = !isNew
      ? {
          vehicleId: '',
          customerId: null,
          contractorId: null,
          extVehicleId: '',
          description: '',
          cellular: '',
          driver: '',
          dta: true,
          ajour: false,
        }
      : {
          vehicleId: '',
          password: '',
          confirmPassword: '',
          customerId: null,
          contractorId: null,
          extVehicleId: '',
          description: '',
          cellular: '',
          driver: '',
          dta: true,
          ajour: false,
        };
    return { ...initVehicle };
  }

  public static async getVehicleInfos(
    ignoreCache = false
  ): Promise<Array<IVehicle>> {
    try {
      const url = wasteManagementUrls.vehicles.all;
      const response = await wasteManagementClient.get(url, {
        cache: { ignoreCache },
      });

      if (response.status === 200 && response.data?.length) {
        const result: Array<IVehicle> = response.data;
        return result;
      } else {
        return [];
      }
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  public static async updateVehicle(
    vehicle: IVehicle,
    ignoreCache = false
  ): Promise<IVehicle> {
    try {
      const url = wasteManagementUrls.vehicles.base;
      const response = await wasteManagementClient.put(url, vehicle, {
        cache: { ignoreCache },
      });

      if (response.status === 200 && response.data?.length) {
        const result: IVehicle = response.data;
        return result;
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  public static async addVehicle(
    vehicle: IVehicle,
    ignoreCache = false
  ): Promise<IVehicle> {
    try {
      const url = wasteManagementUrls.vehicles.base;
      const response = await wasteManagementClient.post(url, vehicle, {
        cache: { ignoreCache },
      });

      if (response.status === 200 && response.data?.length) {
        const result: IVehicle = response.data;
        return result;
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }
}
