import React, { Component, ReactNode } from 'react';
import { IRouteAdminPageProps } from '.';
import { RouteAdmin } from './RouteAdmin';

export class RouteAdminPage extends Component<IRouteAdminPageProps> {
  public static getDerivedStateFromError(): undefined {
    return undefined;
  }

  public componentDidCatch(): void {
    this.props.resetState();
  }

  public render(): ReactNode {
    return <RouteAdmin {...this.props} />;
  }
}
