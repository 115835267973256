import loadingConstants from './loadingConstants';

const initialState = {
  loadingInProgress: [],
}

export default function loading(state = initialState, action) {
  switch (action.type) {
    case loadingConstants.ADD_LOADING_IN_PROGRESS:
      return {
        ...state,
        containerOrders: action.containerOrders,
        loadingInProgress: [...state.loadingInProgress, action.name],
      }
    case loadingConstants.REMOVE_LOADING_IN_PROGRESS:
      return {
        ...state,
        loadingInProgress: state.loadingInProgress.filter((name) => name !== action.name),
      }
    default:
      return state;
  }
}
