import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function StatusBar({ value, target, isLoading, isHidden }) {
  const widthInPercentage = !target ? 0 : Math.round((value / target) * 100);
  const style = {
    width: widthInPercentage + '%',
  };
  const progressbarClasses = classNames(
    'progress-bar',
    'progress-bar-striped',

    {
      'bg-warning': 0 <= widthInPercentage && value < target,
      'bg-success': value >= target,
    }
  );

  return (
    <div>
      {!isHidden ? (
        <div className="progress custom-statusbar-height">
          <div
            className={progressbarClasses}
            role="progressbar"
            aria-valuenow={value}
            aria-valuemin="0"
            aria-valuemax={target}
            style={style}
          >
            {isLoading && <span className="value-label">...</span>}
            {!isLoading && (
              <span className="value-label">
                {value}/{target}
              </span>
            )}
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}

StatusBar.propTypes = {
  value: PropTypes.number.isRequired,
  target: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isHidden: PropTypes.bool,
};

export default StatusBar;
