import contractorsConstants from './contractorConstants';
import loadingActions from '../loading/loadingActions';
import ContractorsService from '../../../services/ContractorsService';

function getContractors(customerId, ignoreCache = false, silent = false) {
  return dispatch => {
    if (!silent) {
      dispatch(loadingActions.addLoadingInProgress(contractorsConstants.GET_CONTRACTORS));
    }

    return ContractorsService.getContractors(customerId, ignoreCache)
      .then(
        (contractors) => dispatch(success(contractors, customerId)),
        (error) => dispatch(failure(JSON.stringify(error))),
      ).finally(() => {
        dispatch(loadingActions.removeLoadingInProgress(contractorsConstants.GET_CONTRACTORS));
      });
  }

  function success(contractors, customerId) {
    return { type: contractorsConstants.GET_CONTRACTORS_SUCCESS, contractors, customerId };
  }

  function failure(error) {
    return { type: contractorsConstants.GET_CONTRACTORS_FAILURE, error }
  }
}

export default {
  getContractors,
}
