import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faMinus } from '@fortawesome/free-solid-svg-icons';

const iconList = {
  'Ja': faCheck,
  'Nei': faTimes,
  '-': faMinus,
};

function CheckmarkCell(props) {
  const { dataItem, field, onClick } = props;
  const value = dataItem[field];
  const icon = iconList[value];

  return (
    <td className="icon-cell executed-status" onClick={() => onClick(props)}>
      {icon && <FontAwesomeIcon icon={icon} />}
    </td>
  );
}

CheckmarkCell.propTypes = {
  dataItem: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}

export default CheckmarkCell;
