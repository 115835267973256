import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {MapUtility} from '../map/MapUtility';
import ServiceOrderMarker from './ServiceOrderMarker';
import routes from '../../common/routes';
import { groupAndProcessServiceOrders } from '../shared/serviceOrderUtility';
import { ClusteredMapLayer } from '../shared/customMapLayer';
import ServiceListZoomManager from './ServiceListZoomManager';
import { selectedRegion } from '../../appConfig';

class SelectedServiceListLayer extends Component {
  static mapServiceOrderToMarker(serviceOrder, zoomLevel) {
    if (
      !serviceOrder
      || !MapUtility.isInRegion(serviceOrder.agreementGpsX, serviceOrder.agreementGpsY, selectedRegion)
    ) {
      return null;
    }

    return (
      <ServiceOrderMarker
        key={`${serviceOrder.externalOrderNumber}_${serviceOrder.listItemId}`}
        serviceOrder={serviceOrder}
        zoomLevel={zoomLevel}
      />
    );
  }

  serviceOrdersViewActive() {
    const { location } = this.props;
    return location.pathname.startsWith(routes.serviceListsPath);
  }

  getMarkers() {
    const { selectedServiceList, zoomLevel } = this.props;
    if (
      !this.serviceOrdersViewActive()
      || !selectedServiceList
      || !selectedServiceList.orders
    ) {
      return null;
    }

    return groupAndProcessServiceOrders(selectedServiceList.orders)
      .map((order) => SelectedServiceListLayer.mapServiceOrderToMarker(order, zoomLevel));
  }

  render() {
    const { clusteringActive } = this.props;

    return (
      <ClusteredMapLayer isClusteringActive={clusteringActive}>
        <ServiceListZoomManager />
        {this.getMarkers()}
      </ClusteredMapLayer>
    );
  }
}

SelectedServiceListLayer.propTypes = {
  selectedServiceList: PropTypes.object,
  clusteringActive: PropTypes.bool,
  zoomLevel: PropTypes.number.isRequired,
  location: PropTypes.object.isRequired,
};

export default SelectedServiceListLayer;
