import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Field from './Field';
import ErrorMessage from './ErrorMessage';

export default function FieldWithFeedback({
  label,
  name,
  type,
  labelCol,
  disabled,
}) {
  return (
    <Form.Group as={Row}>
      <Form.Label column sm={labelCol}>
        {label}
      </Form.Label>
      <Col sm={12 - labelCol}>
        <Field name={name} type={type} disabled={disabled} />
        <ErrorMessage name={name} />
      </Col>
    </Form.Group>
  );
}

FieldWithFeedback.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  labelCol: PropTypes.number,
  disabled: PropTypes.bool,
}

FieldWithFeedback.defaultProps = {
  label: '',
  name: '',
  type: 'text',
  labelCol: 5,
  disabled: false,
}
