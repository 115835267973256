import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { IRootState } from '../../redux/state';
import {
  getPois,
  getRoutes,
  getEditMode,
  getSelectedRoutes,
  getMarkedRouteSequenceIds,
  getRowData,
  getGridState,
  getIsPoiListLoading,
} from '../../redux/modules/route/routeSelectors';
import {
  loadRoutes,
  loadPois,
  markRouteSequences,
  toggleRouteCollapse,
  setGridState,
  resetState,
} from '../../redux/modules/route/routeCreators';
import { setZoomableObjects } from '../../redux/modules/map/mapCreators';
import { RouteAdminPage } from './RouteAdminPage';

function mapStateToProps(state: IRootState) {
  return {
    routes: getRoutes(state),
    pois: getPois(state),
    selectedRoutes: getSelectedRoutes(state),
    markedRouteSequenceIds: getMarkedRouteSequenceIds(state),
    mode: getEditMode(state),
    rowData: getRowData(state),
    gridState: getGridState(state),
    isLoading: getIsPoiListLoading(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      loadRoutes,
      loadPois,
      markRouteSequences,
      toggleRouteCollapse,
      setGridState,
      resetState,
      setZoomableObjects,
    },
    dispatch
  );
}

export type IRouteAdminPageProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(RouteAdminPage);
