import wasteManagementClient from './wasteManagementClient';
import { wasteManagementUrls } from '../appConfig';
import { error } from 'toastr';

export default class CustomersService {
  static getCustomers(ignoreCache = false) {
    return wasteManagementClient
      .get(wasteManagementUrls.customers, {
        cache: { ignoreCache },
      })
      .then((response) => {
        if (response.data && response.status === 200) {
          return response.data;
        } else {
          return [];
        }
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  static searchCustomers(searchWord) {
    return wasteManagementClient
      .get(`${wasteManagementUrls.customers}/search?searchWord=${searchWord}`)
      .then((response) => {
        if (response.data && response.status === 200) {
          return response.data;
        } else {
          return [];
        }
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }
}
