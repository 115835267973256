// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import WMSCapabilities from 'wms-capabilities';
import { flatMap } from 'lodash';

export default abstract class WMSService {
    public static async getWMSLayers(url: string): Promise<Array<string>> {
        const xml = await fetch(url);
        const xmlString = await xml.text();
        const capabilities = new WMSCapabilities(xmlString).toJSON();
    
        if (!capabilities?.Capability?.Layer?.Layer) return [];
    
        const layers = flatMap(capabilities.Capability.Layer.Layer.map((layer: any) => {
            const subLayers = layer.Layer?.map((subLayer: any) => {
                return subLayer.Name;
            }) || [];
            return [layer.Name, ...subLayers];
        })) as Array<string>;

        return layers;
    }
    public static layerDisplayNameFiltering(layers: Array<string>, nameStartsWith: string): Array<{layerDisplayName: string, layerValue: string}> {
        if (!layers?.length) return [];
        if (!nameStartsWith) return layers.map((layer: string) => {
            return {layerDisplayName: layer, layerValue: layer};
        });

        const filteredLayers = layers.filter((layer: string) => {
            return layer.toUpperCase().startsWith(nameStartsWith.toUpperCase());
        });

        if (layers.length && !filteredLayers.length) return layers.map((layer: string) => {
            return {layerDisplayName: layer, layerValue: layer};
        })

        const nameAndStructureModifiedLayers = filteredLayers.map((layer: string) => {
            return {
                layerDisplayName: layer.replace(nameStartsWith, ''),
                layerValue: layer
            };
        });

        return nameAndStructureModifiedLayers;
    }
}