import React from 'react';
import { ArcGauge } from '@progress/kendo-react-gauges';
import PropTypes from 'prop-types';
import AnimatedNumber from '../shared/AnimatedNumber';

function ExecutionStatusGauge({ executionStatus, executionRate }) {
  const executionStatusYes = executionStatus.yes;
  const executionStatusNo = executionStatus.no;
  const executionStatusLength =
    executionStatusYes.toString().length + executionStatusNo.toString().length;
  let animatedNumberSize = 'normal-size';
  if (executionStatusLength > 7) {
    animatedNumberSize = 'medium-small-size';
  } else if (animatedNumberSize > 9) {
    animatedNumberSize = 'small-size';
  }
  return (
    <ArcGauge
      value={executionRate}
      color="green"
      arcCenterRender={() => (
        <h3 className={animatedNumberSize}>
          <span style={{ color: '#5cb85c' }}>
            <AnimatedNumber number={executionStatusYes} />
          </span>
          <span>/</span>
          <span style={{ color: '#d9534f' }}>
            <AnimatedNumber number={executionStatusNo} />
          </span>
        </h3>
      )}
      scale={{
        rangePlaceholderColor: '#d9534f',
        rangeLineCap: 'butt',
        rangeSize: 20,
      }}
      style={{
        width: '190px',
        height: '110px',
      }}
    />
  );
}

ExecutionStatusGauge.propTypes = {
  executionStatus: PropTypes.object.isRequired,
  executionRate: PropTypes.number.isRequired,
};

export default ExecutionStatusGauge;
