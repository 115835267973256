import wasteManagementClient from '../../services/wasteManagementClient';
import { wasteManagementUrls } from '../../appConfig';

export default class ReportsService {
  static getReports() {
    return wasteManagementClient.get(wasteManagementUrls.reports, {
    }).then(response => {
      if (response.data && response.status === 200) {
        return response.data;
      } else {
        return [];
      }
    }).catch(error => console.error(error));
  }

  static executeReport(reportId, from, to) {
    return wasteManagementClient.get(`${wasteManagementUrls.reports}/${reportId}`, {
      params: {
        from,
        to,
      }
    }).then(response => {
      if (response.data && response.status === 200) {
        return response.data;
      } else {
        return [];
      }
    }).catch(error => console.error(error));
  }
}
