import { State as DataState } from '@progress/kendo-data-query';
import {
  IChangeSequenceList,
  IChangeSequenceBody,
  ICoords,
  IRoute,
  IRoutePoi,
  IRowData,
  PASystem,
} from '../../../models';
import { RouteConstants } from './routeConstants';
import * as Actions from './routeActions';
import { Dispatch } from 'redux';
import RouteService from '../../../services/routeService';
import { IRootState } from '../../state';
import { getSelectedRouteIds } from './routeSelectors';
import { RouteEditMode } from '../../../models';
import { LatLngTuple } from 'leaflet';

export function createRouteListRequest(): Actions.SetRouteList {
  return {
    type: RouteConstants.ROUTE_LIST_REQUEST,
  };
}
export function createRouteListSuccess(
  payload: Array<IRoute>
): Actions.SetRouteList.Success {
  return {
    type: RouteConstants.ROUTE_LIST_SUCCESS,
    payload,
  };
}
export function createRouteListFailure(
  error: Error
): Actions.SetRouteList.Failure {
  return {
    type: RouteConstants.ROUTE_LIST_FAILURE,
    error,
  };
}

export function createPoisRequest(
  payload: Array<string>
): Actions.SetRouteAgreementLines {
  return {
    type: RouteConstants.ROUTE_AGREEMENT_LINE_REQUEST,
    payload,
  };
}
export function createPoisSuccess(
  payload: Array<IRoutePoi>
): Actions.SetRouteAgreementLines.Success {
  return {
    type: RouteConstants.ROUTE_AGREEMENT_LINE_SUCCESS,
    payload,
  };
}
export function createPoisFailure(
  error: Error
): Actions.SetRouteAgreementLines.Failure {
  return {
    type: RouteConstants.ROUTE_AGREEMENT_LINE_FAILURE,
    error,
  };
}

export function createChangePositionRequest(): Actions.ChangePosition {
  return {
    type: RouteConstants.CHANGE_POSITION_REQUEST,
  };
}
export function createChangePositionSuccess(): Actions.ChangePosition.Success {
  return {
    type: RouteConstants.CHANGE_POSITION_SUCCESS,
  };
}
export function createChangePositionFailure(
  error: Error
): Actions.ChangePosition.Failure {
  return {
    type: RouteConstants.CHANGE_POSITION_FAILURE,
    error,
  };
}

export function createMergeRequest(): Actions.MergeRouteStops {
  return {
    type: RouteConstants.MERGE_REQUEST,
  };
}
export function createMergeSuccess(): Actions.MergeRouteStops.Success {
  return {
    type: RouteConstants.MERGE_SUCCESS,
  };
}
export function createMergeFailure(
  error: Error
): Actions.MergeRouteStops.Failure {
  return {
    type: RouteConstants.MERGE_FAILURE,
    error,
  };
}

export function createSplitRequest(): Actions.SplitRouteStops {
  return {
    type: RouteConstants.SPLIT_REQUEST,
  };
}
export function createSplitSuccess(): Actions.SplitRouteStops.Success {
  return {
    type: RouteConstants.SPLIT_SUCCESS,
  };
}
export function createSplitFailure(
  error: Error
): Actions.SplitRouteStops.Failure {
  return {
    type: RouteConstants.SPLIT_FAILURE,
    error,
  };
}

export function createValidateSequenceRequest(
  payload: IChangeSequenceBody
): Actions.ValidateSequence {
  return {
    type: RouteConstants.VALIDATE_SEQUENCE_REQUEST,
    payload,
  };
}
export function createValidateSequenceSuccess(
  payload: Array<IChangeSequenceList>
): Actions.ValidateSequence.Success {
  return {
    type: RouteConstants.VALIDATE_SEQUENCE_SUCCESS,
    payload,
  };
}
export function createValidateSequenceFailure(
  error: Error
): Actions.ValidateSequence.Failure {
  return {
    type: RouteConstants.VALIDATE_SEQUENCE_FAILURE,
    error,
  };
}
export function createValidateSequenceClear(): Actions.ValidateSequence.Clear {
  return {
    type: RouteConstants.VALIDATE_SEQUENCE_CLEAR,
  };
}

export function createChangeSequenceRequest(
  payload: IChangeSequenceBody
): Actions.ChangeSequence {
  return {
    type: RouteConstants.CHANGE_SEQUENCE_REQUEST,
    payload,
  };
}

export function createChangeSequenceSuccess(): Actions.ChangeSequence.Success {
  return {
    type: RouteConstants.CHANGE_SEQUENCE_SUCCESS,
  };
}

export function createChangeSequenceFailure(
  error: Error
): Actions.ChangeSequence.Failure {
  return {
    type: RouteConstants.CHANGE_SEQUENCE_FAILURE,
    error,
  };
}

export function createMoveRouteSequences(
  payload: Array<{ id: string; coords: ICoords }>
): Actions.MoveRouteSequences {
  return {
    type: RouteConstants.ROUTE_SEQUENCE_MOVE,
    payload,
  };
}

export function createMarkRouteSequences(
  payload: Array<string>
): Actions.MarkRouteSequences {
  return {
    type: RouteConstants.ROUTE_SEQUENCE_MARK,
    payload,
  };
}

export function createSetRouteEditMode(
  payload: RouteEditMode
): Actions.SetRouteEditMode {
  return {
    type: RouteConstants.ROUTE_SET_EDIT_MODE,
    payload,
  };
}

export function createToggleRouteCollapse(
  payload: Array<IRowData>
): Actions.ToggleRouteCollapse {
  return {
    type: RouteConstants.TOGGLE_ROUTE_COLLAPSE,
    payload,
  };
}

export function createSetGridState(payload: DataState): Actions.SetGridState {
  return {
    type: RouteConstants.SET_GRID_STATE,
    payload,
  };
}

export function createResetState(): Actions.ResetState {
  return {
    type: RouteConstants.RESET_STATE,
  };
}

export function loadRoutes(ignoreCache: boolean = false): (
  dispatch: Dispatch<Actions.All>
) => Promise<void> {
  return async function (dispatch: Dispatch<Actions.All>): Promise<void> {
    dispatch(createRouteListRequest());
    try {
      const result: Array<IRoute> = await RouteService.getAllRoutesForUser(ignoreCache);
      dispatch(createRouteListSuccess(result));
    } catch (error) {
      dispatch(createRouteListFailure(error));
    }
  };
}

export function loadPois(
  routeIds: Array<string>, ignoreCache: boolean = false
): (
  dispatch: Dispatch<Actions.All>,
  getState: () => IRootState
) => Promise<void> {
  return async function (
    dispatch: Dispatch<Actions.All>,
    getState: () => IRootState
  ): Promise<void> {
    dispatch(createPoisRequest(routeIds));
    try {
      const result: Array<IRoutePoi> = await RouteService.getRouteDetails(
        routeIds,
        ignoreCache
      );
      const state = getState();
      const pois = IRoutePoi.mapNames(state.route.routeList, result);
      dispatch(createPoisSuccess(pois));
    } catch (error) {
      dispatch(createPoisFailure(error));
    }
  };
}

export function moveRouteSequences(
  payload: Array<{ id: string; coords: ICoords }>
): Actions.MoveRouteSequences {
  return createMoveRouteSequences(payload);
}

export function markRouteSequences(
  ids: Array<string>
): Actions.MarkRouteSequences {
  return createMarkRouteSequences(ids);
}

export function setRouteEditMode(
  payload: RouteEditMode
): Actions.SetRouteEditMode {
  return createSetRouteEditMode(payload);
}

export function changePosition(
  payload: Array<{ entity: IRoutePoi; position: LatLngTuple }>
): (
  dispatch: Dispatch<Actions.All>,
  getState: () => IRootState
) => Promise<void> {
  return async function (
    dispatch: Dispatch<Actions.All>,
    getState: () => IRootState
  ): Promise<void> {
    dispatch(createChangePositionRequest());
    try {
      await Promise.all(
        payload.map(({ entity, position }) => {
          return RouteService.changePosition(
            entity.poiId,
            entity.customerId,
            position
          );
        })
      );
      dispatch(createChangePositionSuccess());
      await reloadRoutes()(dispatch, getState);
    } catch (error) {
      dispatch(createChangePositionFailure(error));
    }
  };
}

export function validateSequence(
  payload: IChangeSequenceBody
): (dispatch: Dispatch<Actions.All>) => Promise<void> {
  return async function (dispatch: Dispatch<Actions.All>): Promise<void> {
    dispatch(createValidateSequenceRequest(payload));
    try {
      const result = await RouteService.validateSequence(payload);
      dispatch(createValidateSequenceSuccess(result));
    } catch (error) {
      dispatch(createValidateSequenceFailure(error));
    }
  };
}

export function changeSequence(
  payload: IChangeSequenceBody
): (
  dispatch: Dispatch<Actions.All>,
  getState: () => IRootState
) => Promise<void> {
  return async function (
    dispatch: Dispatch<Actions.All>,
    getState: () => IRootState
  ): Promise<void> {
    dispatch(createChangeSequenceRequest(payload));
    try {
      await RouteService.changeSequence(payload);
      dispatch(createChangeSequenceSuccess());
      await reloadRoutes()(dispatch, getState);
    } catch (error) {
      dispatch(createChangeSequenceFailure(error));
    }
  };
}

export function mockRequest(
  ...args: any[]
): (
  dispatch: Dispatch<Actions.All>,
  getState: () => IRootState
) => Promise<void> {
  return function (): Promise<void> {
    /** @todo: change when API is ready */
    return RouteService.mockRequest(...args);
  };
}

export function mergePoints(payload: {
  targetRouteLineId: number;
  agreementLines: Array<{
    agreementLineId: number;
    routeLineId: number;
    paSystem: PASystem;
  }>;
}): (
  dispatch: Dispatch<Actions.All>,
  getState: () => IRootState
) => Promise<void> {
  return async function (
    dispatch: Dispatch<Actions.All>,
    getState: () => IRootState
  ): Promise<void> {
    dispatch(createMergeRequest());
    try {
      await RouteService.mergePoints(
        payload.targetRouteLineId,
        payload.agreementLines
      );
      dispatch(createMergeSuccess());
      await reloadRoutes()(dispatch, getState);
    } catch (error) {
      dispatch(createMergeFailure(error));
    }
  };
}

export function splitPoints(payload: {
  routeId: string;
  newRouteStops: Array<any>;
}): (
  dispatch: Dispatch<Actions.All>,
  getState: () => IRootState
) => Promise<void> {
  return async function (
    dispatch: Dispatch<Actions.All>,
    getState: () => IRootState
  ): Promise<void> {
    dispatch(createSplitRequest());
    try {
      await RouteService.splitPoints(payload.routeId, payload.newRouteStops);
      dispatch(createSplitSuccess());
      await reloadRoutes()(dispatch, getState);
    } catch (error) {
      dispatch(createSplitFailure(error));
    }
  };
}

export function reloadRoutes(): (
  dispatch: Dispatch<Actions.All>,
  getState: () => IRootState
) => Promise<void> {
  return async function (
    dispatch: Dispatch<Actions.All>,
    getState: () => IRootState
  ): Promise<void> {
    const state = getState();
    const routeIds = getSelectedRouteIds(state);
    dispatch(createPoisRequest(routeIds));
    try {
      const result: Array<IRoutePoi> = await RouteService.getRouteDetails(
        routeIds,
        true
      );
      const state = getState();
      const pois = IRoutePoi.mapNames(state.route.routeList, result);
      dispatch(createPoisSuccess(pois));
    } catch (error) {
      dispatch(createPoisFailure(error));
    }
  };
}

export function toggleRouteCollapse(
  payload: Array<IRowData>
): Actions.ToggleRouteCollapse {
  return createToggleRouteCollapse(payload);
}

export function setGridState(payload: DataState): Actions.SetGridState {
  return createSetGridState(payload);
}

export function resetState(): Actions.ResetState {
  return createResetState();
}

export function clearValidationResult(): Actions.ValidateSequence.Clear {
  return createValidateSequenceClear();
}
