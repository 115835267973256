import { Actions } from './geodataActions';
import { GeodataConstants } from './geodataConstants';
import { IGeodataState } from './geodataState';

export const initialState: IGeodataState = {
  isLoading: false,
  token: null,
  errorMessage: '',
};

export function geodatatoken(
  state = initialState,
  action: Actions
): IGeodataState {
  switch (action.type) {
    case GeodataConstants.TOKEN_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GeodataConstants.TOKEN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        token: action.payload,
      };
    case GeodataConstants.TOKEN_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.error.message,
      };
    default:
      return state;
  }
}
