import React, { Component, ReactNode } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { InstanceProps } from 'react-modal-promise';
import { Spinner2 } from '../shared/spinner2';
import { locale } from '../../common/localization';
import { IRoutePoi, PASystem } from '../../models';
import { AsyncAction } from '../../utils';
import { Toastr } from '../../utils/Toastr';
import Select from 'react-select';
import './MergeModal.scss';

export interface IMergeModalProps extends InstanceProps<void> {
  pois: Array<IRoutePoi>;
  onSubmit: (result: {
    targetRouteLineId: number;
    agreementLines: Array<{
      agreementLineId: number;
      routeLineId: number;
      paSystem: PASystem;
    }>;
  }) => AsyncAction;
}

export interface IMergeModalState {
  isLoading: boolean;
  selectedMergeTarget: IRoutePoi;
  isSubmitDisabled: boolean;
}

export class MergeModal extends Component<IMergeModalProps, IMergeModalState> {
  public readonly state = {
    isLoading: false,
    selectedMergeTarget: this.props.pois[0],
    isSubmitDisabled: true,
  };

  private isAllAgreementsDuplicateRoutes(pois: IRoutePoi[]): boolean {
    const first: number = pois[0].routeLineId;
    const sameAsFirst = pois.filter((poi) => poi.routeLineId === first);
    const isDuplicate = sameAsFirst.length === pois.length;
    return isDuplicate;
  }

  public onCancel(): void {
    if (this.state.isLoading) return;
    this.props.onReject();
  }

  public async onSubmit(): Promise<void> {
    this.setState({ ...this.state, isLoading: true });
    const pois = [...this.props.pois];
    const target = this.state.selectedMergeTarget;

    if (this.isAllAgreementsDuplicateRoutes(pois)) {
      this.props.onResolve();
      this.setState({ ...this.state, isLoading: false });
      Toastr.info(locale.routeAdminPage._alreadyMerged);
      return;
    }
    const result = {
      targetRouteLineId: target.routeLineId,
      agreementLines: pois
        .filter((poi) => poi.routeLineId !== target.routeLineId)
        .map((poi) => ({
          agreementLineId: poi.agreementLineId,
          routeLineId: poi.routeLineId,
          paSystem: poi.paSystem as PASystem,
        })),
    };

    try {
      await this.props.onSubmit(result);
      this.props.onResolve();
    } catch (error) {
      console.error(error);
    }
    this.setState({ ...this.state, isLoading: false });
  }

  private sortByKey(array: any[], key: string) {
    return array.sort(function (a, b) {
      const x: any = a[key];
      const y: any = b[key];
      return x < y ? -1 : x > y ? 1 : 0;
    });
  }

  public handleDropdownChange(e: { value: IRoutePoi; label: string }): void {
    this.setState({
      ...this.state,
      selectedMergeTarget: e.value,
      isSubmitDisabled: false,
    });
  }

  public render(): ReactNode {
    const { isLoading, isSubmitDisabled } = this.state;
    const { isOpen, pois } = this.props;

    const uniquePois: IRoutePoi[] = IRoutePoi.squash(
      pois,
      (poi) => `${poi.routeLineId}`
    );

    const mergeTargetOptions = uniquePois.map((poi) => {
      return {
        value: poi,
        label: poi.description,
      };
    });

    const renderPois: IRoutePoi[] = this.sortByKey(
      IRoutePoi.squash(pois, (poi) => `${poi.routeLineId}`),
      'description'
    );
    return (
      <Modal show={isOpen} onHide={() => this.onCancel()}>
        <Spinner2 show={isLoading} />
        <Modal.Header>
          <Modal.Title>{locale.routeAdminPage._mergeModalTitle}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>{locale.routeAdminPage._mergeModalContent}</p>
          <ul>
            {renderPois.map((poi, i) => (
              <li key={i}>{`${poi.description} (${poi.physicalAmount} vare${
                poi.physicalAmount > 1 ? 'r' : ''
              })`}</li>
            ))}
          </ul>
          <p>
            <strong>{locale.routeAdminPage._mergeModalTargetText}</strong>
          </p>
          {
            <Select
              options={this.sortByKey(mergeTargetOptions, 'label')}
              placeholder={locale.routeAdminPage._mergeModalTargetDropdownText}
              isClearable={false}
              onChange={this.handleDropdownChange.bind(this)}
            />
          }
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => this.onCancel()}
            disabled={isLoading}
          >
            {locale.routeAdminPage._cancelButton}
          </Button>
          <Button
            variant="primary"
            onClick={() => this.onSubmit()}
            disabled={isLoading || isSubmitDisabled}
          >
            {locale.routeAdminPage._submitButton}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
