export interface IVehicleSetting {
  key: string;
  value: boolean | number | string;
  description: string;
}

export interface IAddVehicleSetting {
  key: string;
  value: boolean | number | string;
}

export interface IAddVehicleSettingBulk {
  key: string;
  value: boolean | number | string;
  vehicleId: string;
}

export interface IVehicleSettingTemplate {
  parameterKey: string;
  parameterName: string;
  parameterType: VehicleSettingType;
  description: string;
}

export enum VehicleSettingType {
  Int = 'int',
  Boolean = 'boolean',
  String = 'string',
  Double = 'double',
}
