import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { locale } from '../../../common/localization';
import { FieldWithFeedback, Select, Field, ErrorMessage } from '../../shared/formikFields';

function RouteAnalysisForm({
  handleSubmit,
  analysisInProgress,
  isDepotsLoading,
  depotOptions,
}) {

  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Select
        name="startStop"
        options={depotOptions}
        label={locale.routeOptimalizationWizard.routeAnalysisModal._selectStartAndStop}
        noOptionsMessage={locale.routeOptimalizationWizard.routeAnalysisModal._noDepotOptionsMessage}
        loadingMessage={locale.routeOptimalizationWizard.routeAnalysisModal._depotsLoadingMessage}
        placeholder={locale.routeOptimalizationWizard.routeAnalysisModal._depotSelectorPlaceholder}
        disabled={analysisInProgress}
        loading={isDepotsLoading}
      />
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          {locale.routeOptimalizationWizard.routeAnalysisModal._workTime}
        </Form.Label>
        <Col sm={7}>
          <div className="time-input">
            <div>
              <Field name="workHours" type="number" disabled={analysisInProgress} />
            </div>
            <div className="divider"></div>
            <div>
              <Field name="workMinutes" type="number" disabled={analysisInProgress} />
            </div>
          </div>
          <ErrorMessage name="workHours" />
          <ErrorMessage name="workMinutes" />
        </Col>
      </Form.Group>
      <FieldWithFeedback
        label={locale.routeOptimalizationWizard.routeAnalysisModal._timePerStop}
        name="timePerStop"
        type="number"
        disabled={analysisInProgress}
      />
      <FieldWithFeedback
        label={locale.routeOptimalizationWizard.routeAnalysisModal._maximumCars}
        name="maximumCars"
        type="number"
        disabled={analysisInProgress}
      />
    </Form>
  );
}

RouteAnalysisForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  analysisInProgress: PropTypes.bool.isRequired,
  isDepotsLoading: PropTypes.bool.isRequired,
  depotOptions: PropTypes.array.isRequired,
}

RouteAnalysisForm.defaultProps = {
  handleSubmit: () => { },
  analysisInProgress: false,
  isDepotsLoading: false,
  depotOptions: [],
}

export default RouteAnalysisForm;
