export const datePattern = '\\d{4}-[01]\\d-[0-3]\\d';

export function isDate(value) {
  if (typeof value !== 'string') {
    return false;
  }

  const match = value.match(/(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?)/);
  if (match) {
    return !isNaN(new Date(value));
  }
  return false;
}

/**
 * Pads the input number to be at least length long.
 * @param {number} number The number to be padded.
 * @param {number} length The expected minimum length to pad the number to.
 * @returns {string} The padded string.
 */
export function zeroPad(number, length) {
  const numberString = number.toString();
  const paddingLength = Math.max(length - numberString.length, 0);

  return '0'.repeat(paddingLength) + numberString;
}

/**
 * Formats a time span given in seconds to MM:SS format.
 * @param {number} totalSeconds The time to be formatted in seconds.
 * @param {boolean} hideHoursIfZero If true, hour part if hidden while zero.
 * @returns {string} The formatted time in MM:SS format.
 */
export function formatTime(totalSeconds, hideHoursIfZero = true) {
  const seconds = zeroPad(Math.floor(totalSeconds % 60), 2);
  const minutes = zeroPad(Math.floor(totalSeconds / 60 % 60), 2);
  const hours = zeroPad(Math.floor(totalSeconds / 3600), 2);

  if (!hideHoursIfZero || hours > 0) {
    return `${hours}:${minutes}:${seconds}`;
  }

  return `${minutes}:${seconds}`;
}

/**
 * Calculate the difference between the later and the earlier date.
 * @param {Date} laterTimestamp Later timestamp. Usually the time of the current moment, if we want to calculate the elapsed time since an earlier timestamp.
 * @param {Date} earlierTimestamp Earlier timestamp.
 * @returns {number} Time between two dates in milliseconds.
 */
export function calculateTimestampDifferenceInMs(
  laterTimestamp,
  earlierTimestamp
) {
  return laterTimestamp.valueOf() - earlierTimestamp.valueOf();
}
