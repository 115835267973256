import React from 'react';
import { IContractor } from '../../models';
import Select from 'react-select';
import memoizeOne from 'memoize-one';
import { locale } from '../../common/localization';

export interface IContractorSelectorProps {
  contractors: Array<IContractor>;
  handleChange: (...args: any) => any;
  selectedContractorId: number;
  [key: string]: any;
}

export interface IContractorOption {
  label: string;
  value: number;
}

export const getContractorOptions = (
  contractors: Array<IContractor>
): Array<IContractorOption> => {
  return (
    (contractors &&
      contractors.map((contractor) => ({
        label: contractor.contractorName,
        value: contractor.contractorId,
      }))) ||
    []
  );
};

export const getCustomContractionOptionsMemoized =
  memoizeOne(getContractorOptions);

const ContractorSelector = (
  props: IContractorSelectorProps
): React.ReactElement => {
  const { contractors, handleChange, selectedContractorId, ...restProps } =
    props;
  const options = getCustomContractionOptionsMemoized(contractors);
  const selectedOption = options.find(
    (option) => option.value === selectedContractorId
  );

  return (
    <Select
      options={options}
      onChange={handleChange}
      value={selectedOption}
      placeholder={locale.vehicles._contractorSelectorPlaceholder}
      noOptionsMessage={() => locale.vehicles._noContractorFound}
      {...restProps}
    />
  );
};

export default ContractorSelector;
