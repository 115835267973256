import moduleConstants from './moduleConstants';

const initialState = {
  modules: {},
}

export default function modulesReducer(state = initialState, action) {
  switch (action.type) {
    case moduleConstants.GET_MODULES_SUCCESS:
      return {
        modules: {
          ...state.modules,
          [action.customerId]: action.modules,
        },
      }
    case moduleConstants.GET_MODULES_FAILURE:
      return {
        ...state,
        error: action.error,
      }
    default:
      return state;
  }
}
