import React, { PureComponent } from 'react';
import { Marker } from 'react-leaflet';
import PropTypes from 'prop-types';
import truckIcon from '../../images/truck-marker.png';
import truckIconSelected from '../../images/truck-marker-selected.png';
import VehicleTooltip from './VehicleTooltip';
import * as L from 'leaflet';

class VehicleMarker extends PureComponent {
  static defaultIcon = L.icon({
    iconUrl: truckIcon,
    iconAnchor: [12, 32],
    popupAnchor: [0, -32],
    tooltipAnchor: [0, -32],
  })

  static selectedIcon = L.icon({
    iconUrl: truckIconSelected,
    iconAnchor: [12, 32],
    popupAnchor: [0, -32],
    tooltipAnchor: [0, -32],
  })

  render() {
    const { 
      username, 
      orderId, 
      updatedAt, 
      position, 
      routeName,
      isSelected,
    } = this.props; 

    const icon = isSelected
      ? VehicleMarker.selectedIcon
      : VehicleMarker.defaultIcon;

    return (
      <Marker
        position={position}
        icon={icon}
      >
        <VehicleTooltip 
          username={username}
          orderId={orderId}
          updatedAt={updatedAt}
          routeName={routeName}
        />
      </Marker>
    );
  }
}

VehicleMarker.propTypes = {
  username: PropTypes.string.isRequired,
  orderId: PropTypes.any,
  updatedAt: PropTypes.string.isRequired,
  position: PropTypes.array.isRequired,
  routeName: PropTypes.string,
  isSelected: PropTypes.bool.isRequired,
}

export default VehicleMarker;
