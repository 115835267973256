import React, { Component, ReactNode } from 'react';
import { locale } from '../../../common/localization';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

export interface IImpersonationWarningProps {
  impersonatedUserName: string;
  stopImpersonation?: () => void;
  isButtonHidden?: boolean;
}

export class ImpersonationWarning extends Component<IImpersonationWarningProps> {
  public render(): ReactNode {
    return (
      <div className="user-admin">
        <div className="row mb-1">
          <div className="col-8">
            <h2>
              <FontAwesomeIcon icon={faExclamationTriangle} />
              {locale.general._warning}
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col">
            {locale.userAdmin._impersonationWarningPart1}
            <strong>{this.props.impersonatedUserName.toString().trim()}</strong>
            {locale.userAdmin._impersonationWarningPart2}
          </div>
        </div>
        <div className="row mt-2">
          <div className="col text-right">
            {!this.props.isButtonHidden && this.props.stopImpersonation && (
              <button
                className="btn btn-outline-dark"
                onClick={() => this.props.stopImpersonation()}
              >
                {locale.impersonationManager._stopImpersonation}
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ImpersonationWarning;
