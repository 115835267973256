import ServiceOrders from "./ServiceOrders";
import { bindActionCreators } from 'redux';
import { connect } from "react-redux";
import serviceOrderActions from "../../redux/modules/serviceOrders/serviceOrderActions";
import { createLoadingSelector } from '../../redux/modules/loading/loadingSelectors';
import serviceOrderConstants from '../../redux/modules/serviceOrders/serviceOrderConstants';
import { makeGetServiceListOrdersByPathParams } from '../../redux/modules/serviceOrders/serviceOrderSelectors';

const isServiceOrdersLoading = createLoadingSelector(
  serviceOrderConstants.GET_SERVICEORDERS,
  serviceOrderConstants.DELETE_SERVICE_LIST_ITEM,
);
const getServiceListOrdersByPathParams = makeGetServiceListOrdersByPathParams();

function mapStateToProps(state, props) {
  return {
    isLoading: isServiceOrdersLoading(state),
    serviceOrders: getServiceListOrdersByPathParams(state, props),
    selectedOrderId: state.serviceOrders.selectedOrderId,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    serviceOrderActions: bindActionCreators(serviceOrderActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceOrders);
