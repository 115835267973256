import React from 'react';
import PropTypes from 'prop-types';
import {MapUtility} from '../../map/MapUtility';
import WorklistItemMarker from './WorklistItemMarker';
import routes from '../../../common/routes';
import { ClusteredMapLayer } from '../../shared/customMapLayer';
import WorklistZoomManager from './WorklistZoomManager';
import { groupBy } from '../../../services/listService';
import { selectedRegion } from '../../../appConfig';

function getDepotMarkers(selectedDepotStopList, zoomLevel) {
  if (!selectedDepotStopList) return [];

  const uniqueDepots = Array.from(
    groupBy(selectedDepotStopList, x => x.depotId).values(),
    depots => depots[0],
  );

  return uniqueDepots.map((depot) => mapDepotToMarker(depot, zoomLevel));
}

function mapDepotToMarker(depot, zoomLevel) {
  if (
    !depot
    || !MapUtility.isInRegion(depot.latitude, depot.longitude, selectedRegion)
  ) {
    return null;
  }

  return (
    <WorklistItemMarker
      key={depot.depotId}
      label="S"
      name={depot.description}
      zoomLevel={zoomLevel}
      position={[depot.latitude, depot.longitude]}
      color="blue"
    />
  );
}

function mapListItemToMarker(item, zoomLevel) {
  if (
    !item
    || !MapUtility.isInRegion(item.latitude, item.longitude, selectedRegion)
  ) {
    return null;
  }

  return (
    <WorklistItemMarker
      key={item.gln}
      id={item.gln}
      label={String(item.sequence)}
      name={item.customerName}
      zoomLevel={zoomLevel}
      position={[item.latitude, item.longitude]}
    />
  );
}

function routeOptimalizationViewActive(location) {
  return location.pathname.startsWith(routes.routeOptimalizationWizardPath);
}

function getMarkers(selectedWorklist, selectedDepotStopList, zoomLevel) {
  if (!selectedWorklist) {
    return null;
  }

  const stopMarkers = selectedWorklist
    .map((item) => mapListItemToMarker(item, zoomLevel));

  const depotMarkers = getDepotMarkers(selectedDepotStopList, zoomLevel);

  return stopMarkers.concat(depotMarkers);
}

function WorklistsLayer({
  clusteringActive,
  selectedWorklist,
  selectedDepotStopList,
  zoomLevel,
  location,
  activeTab,
}) {
  const markers = routeOptimalizationViewActive(location) && activeTab === 1
    ? getMarkers(selectedWorklist, selectedDepotStopList, zoomLevel)
    : null;

  return (
    <ClusteredMapLayer isClusteringActive={clusteringActive}>
      <WorklistZoomManager />
      {markers}
    </ClusteredMapLayer>
  );
}

WorklistsLayer.propTypes = {
  selectedWorklist: PropTypes.array,
  selectedDepotStopList: PropTypes.bool,
  clusteringActive: PropTypes.bool,
  zoomLevel: PropTypes.number.isRequired,
  location: PropTypes.object.isRequired,
  activeTab: PropTypes.number.isRequired,
};

export default WorklistsLayer;
