import { State as DataState } from '@progress/kendo-data-query';
import { IRowData } from './RowData.model';
import { IRoutePoi } from './RoutePoi.model';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IRowMap extends Record<string, IRowData> {}
export namespace IRowMap {
  export function Empty(): IRowMap {
    return {} as IRowMap;
  }
  export function persistCollapseState(
    prevData: IRowMap,
    state: DataState,
    pois: Array<IRoutePoi>
  ): IRowMap {
    const rowData: IRowMap = {};
    const groups = state.group.map((group) => group.field);
    pois.forEach((poi) => {
      for (const field of groups) {
        const value = (poi as any)[field];
        const id = { field, value, isExpanded: field !== 'description' };
        const hash = IRowData.hash(id);
        rowData[hash] = prevData[hash] || id;
      }
    });
    return rowData;
  }
}
