import { hot } from 'react-hot-loader';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import { logout } from '../redux/modules/auth';
import {
  getDataButtons,
  getVisibleFields,
  getCompanySettings
} from '../redux/modules/settings/settingsCreators';
import { IRootState } from '../redux/state';
import { IAppProps } from './App';
import App from './App';

export function mapStateToProps(state: IRootState): Partial<IAppProps> {
  return {
    token: state.authentication.token,
  };
}

export function mapDispatchToProps(dispatch: Dispatch): Partial<IAppProps> {
  return {
    getCompanySettings: bindActionCreators(getCompanySettings, dispatch),
    getDataButtons: bindActionCreators(getDataButtons, dispatch),
    getVisibleFields: bindActionCreators(getVisibleFields, dispatch),
    logout: bindActionCreators(logout, dispatch),
  };
}

export default hot(module)(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(App))
);
