import agreementsConstants from './agreementConstants';
import loadingActions from '../loading/loadingActions';
import AgreementsService from '../../../services/AgreementsService';

function getAgreements(ignoreCache = false, silent = false) {
  return dispatch => {
    if (!silent) {
      dispatch(loadingActions.addLoadingInProgress(agreementsConstants.GET_AGREEMENTS));
    }

    return AgreementsService.getAgreements(ignoreCache)
      .then(
        (agreements) => {
          dispatch(success(agreements));
          return agreements;
        },
        (error) => {
          dispatch(failure(JSON.stringify(error)));
          return error;
        },
      ).finally(() => {
        dispatch(loadingActions.removeLoadingInProgress(agreementsConstants.GET_AGREEMENTS));
      });
  }

  function success(agreements) {
    return { type: agreementsConstants.GET_AGREEMENTS_SUCCESS, agreements };
  }

  function failure(error) {
    return { type: agreementsConstants.GET_AGREEMENTS_FAILURE, error }
  }
}

export default {
  getAgreements,
}
