import { connect } from 'react-redux';
import SelectedServiceListLayer from './SelectedServiceListLayer';
import { getSelectedServiceList } from '../../redux/modules/serviceOrders/serviceOrderSelectors';

function mapStateToProps(state) {
  return {
    selectedServiceList: getSelectedServiceList(state),
    location: state.router.location,
  }
}

export default connect(mapStateToProps)(SelectedServiceListLayer);
