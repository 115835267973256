import wasteManagementClient from './wasteManagementClient';
import { wasteManagementUrls } from '../appConfig';

const finishedJobStatuses = [
  'esriJobSucceeded',
  'esriJobFailed',
  'esriJobTimedOut',
  'esriJobCancelled',
];

export function runRouteAnalysis(analysisParams, cancelToken) {
  return startRouteAnalysis(analysisParams, cancelToken)
    .then((job) => {
      if (
        job.jobStatus == null
        || job.jobStatus == null
      ) {
        return Promise.reject('Error starting analysis job');
      }

      return pollRouteAnalysisResult(job.jobId, cancelToken);
    })
    .then((jobStatus) => {
      if (jobStatus.jobStatus !== 'esriJobSucceeded') {
        return Promise.reject('Job failed');
      }

      return getRouteAnalysisResult(jobStatus.jobId, cancelToken);
    })
    .then((jobResult) => {
      if (!jobResult.isSuccessful) {
        return Promise.reject(jobResult);
      }

      return jobResult;
    });
}

function pollRouteAnalysisResult(jobId, cancelToken) {
  let interval = null;
  return new Promise((resolve, reject) => {
    interval = setInterval(() => {
      if (cancelToken.reason) {
        reject('Cancelled');
      }

      getRouteAnalysisStatus(jobId).then((jobStatus) => {
        if (isFinishedStatus(jobStatus.jobStatus)) {
          resolve(jobStatus);
        } else if (
          jobStatus.jobStatus == null
          || jobStatus.jobStatus == null
        ) {
          reject('Error getting analysis job status');
        }
      }).catch(reject);
    }, 2000);
  }).finally(() => clearInterval(interval));
}

function startRouteAnalysis(analysisParams, cancelToken) {
  return wasteManagementClient.post(
    wasteManagementUrls.routeAnalysis,
    analysisParams,
    { cancelToken },
  ).then(response => {
    if (response.data && response.status === 200) {
      return response.data;
    } else {
      return {};
    }
  }).catch(error => {
    console.error(error);
    throw error;
  });
}

function getRouteAnalysisStatus(jobId, cancelToken) {
  return wasteManagementClient.get(
    `${wasteManagementUrls.routeAnalysis}/${jobId}/status`,
    {
      cancelToken,
      cache: { ignoreCache: true }
    },
  ).then(response => {
    if (response.data && response.status === 200) {
      return response.data;
    } else {
      return {};
    }
  }).catch(error => {
    console.error(error);
    throw error;
  });
}

function getRouteAnalysisResult(jobId, cancelToken) {
  return wasteManagementClient.get(
    `${wasteManagementUrls.routeAnalysis}/${jobId}/result`,
    {
      cancelToken,
      cache: { ignoreCache: true }
    },
  ).then(response => {
    if (response.data && response.status === 200) {
      return response.data;
    } else {
      return {};
    }
  }).catch(error => {
    console.error(error);
    throw error;
  });
}

function isFinishedStatus(jobStatus) {
  return finishedJobStatuses.includes(jobStatus);
}
