import { DeviationConstants } from './deviationConstants';
import { IDeviationState } from './deviationState';
import { All as Actions } from './deviatonActions';

const initialState: IDeviationState = {
  deviationList: [],
  deviationCategoryList: [],
  errorMessage: null,
};

export function deviation(
  state = initialState,
  action: Actions
): IDeviationState {
  switch (action.type) {
    case DeviationConstants.GET_DEVIATIONS_REQUEST: {
      return {
        ...state,
      };
    }
    case DeviationConstants.GET_DEVIATIONS_SUCCESS: {
      return {
        ...state,
        deviationList: action.payload,
      };
    }
    case DeviationConstants.GET_DEVIATIONS_FAILURE: {
      return {
        ...state,
        errorMessage: action.error.message,
      };
    }
    case DeviationConstants.GET_DEVIATIONS_CATEGORY_REQUEST: {
      return {
        ...state,
      };
    }
    case DeviationConstants.GET_DEVIATIONS_CATEGORY_SUCCESS: {
      return {
        ...state,
        deviationCategoryList: action.payload,
      };
    }
    case DeviationConstants.GET_DEVIATIONS_CATEGORY_FAILURE: {
      return {
        ...state,
        errorMessage: action.error.message,
      };
    }
    default:
      return state;
  }
}
