import ContainerOrderDetails from "./ContainerOrderDetails";
import { bindActionCreators } from 'redux';
import { connect } from "react-redux";
import containerOrderActions from "../../redux/modules/containerOrder/containerOrderActions";
import { createLoadingSelector } from '../../redux/modules/loading/loadingSelectors';
import containerOrderConstants from '../../redux/modules/containerOrder/containerOrderConstants';
import { makeGetContainerOrdersByPathParams } from '../../redux/modules/containerOrder/containerOrderSelectors';

const isContainerOrdersLoading = createLoadingSelector(containerOrderConstants.GET_CONTAINERORDER);
const getContainerOrdersByPathParams = makeGetContainerOrdersByPathParams();

function mapStateToProps(state, props) {
  return {
    isLoading: isContainerOrdersLoading(state),
    containerOrder: getContainerOrdersByPathParams(state, props),
    visibleFields: state.settings.visibleFields,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    containerOrderActions: bindActionCreators(containerOrderActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContainerOrderDetails);
