import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { logout } from '../../redux/modules/auth/authCreators';
import { IRootState } from '../../redux/state';
import NavBar from './Navbar';

function mapStateToProps(state: IRootState) {
  return {
    authentication: state.authentication,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    logout: bindActionCreators(logout, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
