import { Dispatch } from 'redux';
import { IAuth, IUser } from '../../../models';
import { AuthService } from '../../../services/authService';
import { resetClient } from '../../../services/wasteManagementClient';
import { getGeodataToken } from '../geodata/geodataCreators';
import { Actions as GeoActions } from '../geodata/geodataActions';
import { resetState as resetRouteState } from '../route/routeCreators';
import { AuthConstants } from './authConstants';
import { Auth0ContextInterface, User } from '@auth0/auth0-react';

import {
  Actions,
  LoginRequest,
  LogoutRequest,
  SetImpersonatedUser,
} from './authActions';

export function createLoginRequest(payload: IAuth): LoginRequest {
  return {
    type: AuthConstants.USERS_LOGIN_REQUEST,
    payload,
  };
}

export function createLoginSuccess(payload: IUser): LoginRequest.Success {
  return {
    type: AuthConstants.USERS_LOGIN_SUCCESS,
    payload,
  };
}

export function createLoginFailure(error: Error): LoginRequest.Failure {
  return {
    type: AuthConstants.USERS_LOGIN_FAILURE,
    error,
  };
}

export function createLogoutRequest(): LogoutRequest {
  return {
    type: AuthConstants.USERS_LOGOUT_REQUEST,
  };
}

export function createLogoutSuccess(): LogoutRequest.Success {
  return {
    type: AuthConstants.USERS_LOGOUT_SUCCESS,
  };
}

export function createLogoutFailure(error: Error): LogoutRequest.Failure {
  return {
    type: AuthConstants.USERS_LOGOUT_FAILURE,
    error,
  };
}

export function createSetAccessToken(payload: string): LoginRequest.SetToken {
  return {
    type: AuthConstants.SET_ACCESS_TOKEN,
    payload,
  };
}

export function createSetImpersonatedUser(
  payload: string
): SetImpersonatedUser {
  return {
    type: AuthConstants.SET_IMPERSONATED_USER,
    payload,
  };
}

export function loginWithAuth0(
  authResult: any
): (dispatch: Dispatch<Actions | GeoActions>) => Promise<void> {
  return async function (
    dispatch: Dispatch<Actions | GeoActions>
  ): Promise<void> {
    const accessToken = await authResult.getAccessTokenSilently();

    await new Promise((resolve) => {
      dispatch(createSetAccessToken(accessToken));
      resolve(null);
    });

    const user: IAuth = {
      username: authResult.user.email,
      password: accessToken,
    };

    try {
      const result: IUser = await AuthService.loginWithAuth0(user);
      dispatch(createLoginSuccess(result));
      return getGeodataToken()(dispatch);
    } catch (error) {
      dispatch(createLoginFailure(error));
    }
  };
}

export function logout(auth0: Auth0ContextInterface<User>): (dispatch: Dispatch) => Promise<void> {
  return async function (dispatch: Dispatch): Promise<void> {
    dispatch(createLogoutRequest());
    try {
      dispatch(createLogoutSuccess());
      AuthService.logout(auth0);
    } catch (error) {
      console.error(error);
      dispatch(createLogoutFailure(error));
    }
    resetClient();
  };
}

export function setImpersonatedUser(
  user: string
): (dispatch: Dispatch) => Promise<void> {
  return async function (dispatch: Dispatch): Promise<void> {
    /** @todo: reset all relevant states when possible */
    dispatch(resetRouteState());
    dispatch(createSetImpersonatedUser(user));
  };
}

export function resetImpersonatedUser(): ReturnType<
  typeof createSetImpersonatedUser
> {
  return createSetImpersonatedUser(null);
}
