import { initialState } from './rootReducer';
import { IRootState } from './state';

export async function resetToEmptyState(
  state: IRootState,
  currentVersion: number
): Promise<IRootState> {
  const prevVersion = `${state?._persist?.version}`;
  const nextVersion = `${currentVersion}`;

  if (prevVersion !== nextVersion) return initialState;
  return state;
}
