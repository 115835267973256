const customerConstants = {
  GET_CUSTOMERS: 'GET_CUSTOMERS',
  GET_CUSTOMERS_SUCCESS: 'GET_CUSTOMERS_SUCCESS',
  GET_CUSTOMERS_FAILURE: 'GET_CUSTOMERS_FAILURE',

  SEARCH_CUSTOMERS: 'SEARCH_CUSTOMERS',
  SEARCH_CUSTOMERS_SUCCESS: 'SEARCH_CUSTOMERS_SUCCESS',
  SEARCH_CUSTOMERS_FAILURE: 'SEARCH_CUSTOMERS_FAILURE',
};

export default customerConstants;
