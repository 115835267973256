import { SettingsConstants } from './settingsConstants';
import { ISettingsState } from './settingsState';
import { Actions } from './settingsActions';

export const initialState: ISettingsState = {
  visibleFields: [],
  dataButtons: [],
  companySettings: [],
};

export function settings(
  state: ISettingsState = initialState,
  action: Actions
): ISettingsState {
  switch (action.type) {
    case SettingsConstants.GET_DATABUTTONS_SUCCESS:
      return {
        ...state,
        dataButtons: action.payload,
      };
    case SettingsConstants.GET_VISIBLE_FIELDS_SUCCESS:
      return {
        ...state,
        visibleFields: action.payload,
      };
    case SettingsConstants.GET_COMPANY_SETTINGS_SUCCESS:
      return {
        ...state,
        companySettings: action.payload,
      };
    default:
      return state;
  }
}
