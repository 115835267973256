import { connect } from 'react-redux';
import RouteStopDetails from './RouteStopDetails';
import * as orderActions from '../../redux/modules/order/orderActions';
import orderDetailsActions from '../../redux/modules/orderDetails/orderDetailsActions';
import { bindActionCreators } from 'redux';
import { 
  makeGetOrderByPathParams, 
  makeGetRouteStopByQueryString,
 } from '../../redux/modules/order/orderSelectors';

const getOrderByPath = makeGetOrderByPathParams();
const getRouteStopByQueryString = makeGetRouteStopByQueryString(getOrderByPath);

function mapStateToProps(state, props) {
  return {
    order: getOrderByPath(state, props),
    routeStop: getRouteStopByQueryString(state, props),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    orderActions: bindActionCreators(orderActions, dispatch),
    orderDetailsActions: bindActionCreators(orderDetailsActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RouteStopDetails);
