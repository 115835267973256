import { Component } from 'react';
import { Action } from 'redux';
import { latLngBounds, LatLngTuple, LatLngBounds } from 'leaflet';
import { MapUtility } from '../map/MapUtility';
import { ZoomableObjectTypes } from '../map/MapWrapperConstants';
import { LeafletMapSettings } from '../../sharedConfig';
import { selectedRegion } from '../../appConfig';

export interface IZoomableBounds {
  name: string;
  priority: number;
  type: ZoomableObjectTypes.BOUNDS;
  bounds: LatLngBounds;
  ignoreUpdate: boolean;
}
export interface IZoomableSingle {
  name: string;
  priority: number;
  type: ZoomableObjectTypes;
  zoomLevel: number;
  point: LatLngTuple;
}
export type IZoomable = IZoomableBounds | IZoomableSingle;
export interface IZoomManagerProps {
  name: string;
  isActive: boolean;
  objectId: string | number | Array<string> | Array<number>;
  removeZoomableObject: (name: string) => Action;
  addZoomableObject: (zoomable: IZoomable) => Action;
  priority: number;
  positions: Array<LatLngTuple>;
  ignoreUpdate?: boolean;
}
export class ZoomManager extends Component<IZoomManagerProps> {
  public componentDidMount(): void {
    this.handleZoom();
  }

  public componentDidUpdate(prevProps?: IZoomManagerProps): void {
    this.handleZoom(prevProps);
  }

  public getZoomable(): IZoomable {
    const { positions, priority, name, ignoreUpdate } = this.props;

    if (positions === null) {
      return null;
    }

    const validPositions = positions.filter(([lat, lng]) => {
      return MapUtility.isInRegion(lat, lng, selectedRegion);
    });

    if (validPositions.length === 0) {
      return null;
    }

    if (validPositions.length === 1) {
      return {
        name,
        priority,
        type: ZoomableObjectTypes.POINT,
        zoomLevel: LeafletMapSettings.routeStopSelectionZoomLevel,
        point: validPositions[0],
      };
    }

    return {
      name,
      priority,
      type: ZoomableObjectTypes.BOUNDS,
      bounds: latLngBounds(validPositions),
      ignoreUpdate,
    };
  }

  public handleZoom(prevProps?: IZoomManagerProps): void {
    const { name, isActive, objectId } = this.props;

    if (!isActive) {
      if (prevProps?.isActive) {
        this.props.removeZoomableObject(name);
      }
      return;
    }

    if (
      !prevProps ||
      objectId !== prevProps.objectId ||
      isActive !== prevProps.isActive
    ) {
      const zoomable = this.getZoomable();
      if (zoomable === null) {
        this.props.removeZoomableObject(name);
      } else {
        this.props.addZoomableObject(zoomable);
      }
    }
  }

  public render(): null {
    return null;
  }
}
