import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

function getDeviationContent(deviations) {
  if (!deviations || deviations.length === 0) {
    return '';
  }

  return deviations.map((deviation) => (
    <p key={deviation.exceptionId}>{deviation.exceptionId}: {deviation.value}</p>
  ));
}

function renderTooltip(props, tooltipContent) {
  return (
    <Tooltip id="deviation-tooltip" {...props}>
      {tooltipContent}
    </Tooltip>
  );
}

function DeviationCell(props) {
  const { dataItem, field, onClick } = props;
  const deviations = dataItem[field];
  const hasDeviation = !!deviations && deviations.length > 0;
  const tooltipContent = getDeviationContent(deviations);

  return (
    <td className="icon-cell deviation-cell" onClick={() => onClick(props)}>
      {hasDeviation &&
        <OverlayTrigger overlay={(props) => renderTooltip(props, tooltipContent)}>
          <FontAwesomeIcon icon={faExclamationCircle} />
        </OverlayTrigger>
      }
    </td>
  );
}

DeviationCell.propTypes = {
  dataItem: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}

export default DeviationCell;
