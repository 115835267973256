import React, { Component, ReactNode } from 'react';
import Select from 'react-select';
import memoizeOne from 'memoize-one';
import { IVehicleSettingTemplate } from '../../models';
import { locale } from '../../common/localization';

export interface IVehicleSettingTemplateSelectorProps {
  templates: Array<IVehicleSettingTemplate>;
  selectedTemplateId?: string;
  vehicleSettingIdsExisting: string[];
  className?: string;
  handleChange: (event: any) => void;
}

export class VehicleSettingTemplateSelector extends Component<IVehicleSettingTemplateSelectorProps> {
  private getVehicleSettingTemplateOptionsMemoized = memoizeOne(
    this.getVehicleSettingTemplateOptions
  );

  private getVehicleSettingTemplateOptions(
    templates: Array<IVehicleSettingTemplate>
  ) {
    return (
      templates &&
      templates.map((template: IVehicleSettingTemplate) => {
        return {
          label: template.parameterName,
          value: template.parameterKey,
          isDisabled: this.props.vehicleSettingIdsExisting.includes(
            template.parameterKey.toLocaleLowerCase()
          )
            ? true
            : false,
        };
      })
    );
  }
  public render(): ReactNode {
    const options = this.getVehicleSettingTemplateOptionsMemoized(
      this.props.templates
    );
    const selectedOption = options.find(
      (option) => option.value === this.props.selectedTemplateId
    );

    return (
      <Select
        options={options}
        onChange={this.props.handleChange}
        value={selectedOption}
        placeholder={locale.vehicleSettings._settingTemplateSelectorPlaceholder}
        noOptionsMessage={() =>
          locale.vehicleSettings._noSettingTemplateOptionsMessage
        }
        className={this.props.className + ' setting-template-selector'}
      />
    );
  }
}

export default VehicleSettingTemplateSelector;
