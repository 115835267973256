import { IZoomableObject } from '../../../models';
import {
  AddZoomableObject,
  RemoveZoomableObject,
  SetZoomableObjects,
} from './mapActions';
import { MapConstants } from './mapConstants';

export function addZoomableObject(payload: IZoomableObject): AddZoomableObject {
  return { type: MapConstants.ADD_ZOOMABLE_OBJECT, payload };
}

export function setZoomableObjects(
  payload: IZoomableObject
): SetZoomableObjects {
  return { type: MapConstants.SET_ZOOMABLE_OBJECTS, payload };
}

export function removeZoomableObject(payload: string): RemoveZoomableObject {
  return { type: MapConstants.REMOVE_ZOOMABLE_OBJECT, payload };
}
