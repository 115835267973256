import React from 'react';
import Select from 'react-select';
import memoizeOne from 'memoize-one';
import { IDeviationCategory } from '../../models/Deviation';
import { locale } from '../../common/localization';

export interface IDeviationSelectorProps {
  categories: Array<IDeviationCategory>;
  selectedCategory: number;
  handleChange: (...args: any) => any;
  [key: string]: any;
}

export interface ICategoryOption {
  label: string;
  value: number;
}

export const getCategoryOptions = (
  categories: Array<IDeviationCategory>
): Array<ICategoryOption> => {
  return (
    (categories &&
      categories.map((category) => ({
        label: category.categoryText,
        value: category.categoryId,
      }))) ||
    []
  );
};

export const getCustomCategoryOptionsMemoized = memoizeOne(getCategoryOptions);

const DeviationCategorySelector = (
  props: IDeviationSelectorProps
): React.ReactElement => {
  const { categories, selectedCategory, handleChange, ...restProps } = props;
  const options = getCustomCategoryOptionsMemoized(categories);
  const selectedOption = options.find(
    (option) => option.value === selectedCategory
  );
  return (
    <Select
      options={options}
      onChange={handleChange}
      value={selectedOption}
      placeholder={locale.deviations._selectCategory}
      noOptionsMessage={() => locale.deviations._noCategory}
      {...restProps}
    />
  );
};

export default DeviationCategorySelector;
