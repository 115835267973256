import React from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Col } from 'react-bootstrap';
import ReactSelect from 'react-select';
import { useFormikContext } from 'formik';
import { ErrorMessage } from '.';

function handleBlur(name, setTouched, touched) {
  setTouched({
    ...touched,
    [name]: true,
  });
}

function Select({
  options,
  name,
  label,
  placeholder,
  noOptionsMessage,
  loadingMessage,
  disabled,
  labelCol,
  loading,
}) {
  const {
    errors,
    touched,
    values,
    setTouched,
    setFieldValue,
  } = useFormikContext();

  const hasError = touched[name] && errors[name];

  return (
    <Form.Group as={Row}>
      <Form.Label column sm={labelCol}>
        {label}
      </Form.Label>
      <Col sm={12 - labelCol}>
        <ReactSelect
          onChange={(option) => setFieldValue(name, option)}
          onBlur={handleBlur.bind(undefined, name, setTouched, touched)}
          name={name}
          value={values[name]}
          options={options}
          placeholder={placeholder}
          className={hasError ? 'is-invalid' : null}
          noOptionsMessage={() => noOptionsMessage}
          loadingMessage={() => loadingMessage}
          isDisabled={disabled}
          isLoading={loading}
        />
        <ErrorMessage name={name} />
      </Col>
    </Form.Group>
  );
}

Select.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  noOptionsMessage: PropTypes.string.isRequired,
  loadingMessage: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  labelCol: PropTypes.number,
  loading: PropTypes.bool,
}

Select.defaultProps = {
  name: '',
  options: [],
  label: '',
  placeholder: '',
  noOptionsMessage: '',
  loadingMessage: '',
  labelCol: 5,
}

export default Select;
