import _ from 'lodash';

export class OrderFilterService {
    static filter(orders) {
        if (orders.length === 0) {
            return orders;
        }

        const customerId = orders[0].customerId;
        switch (customerId) {
            case "4": { //returaTRV
                return _.reject(orders, { 'orderType': 1 })
            }
            default: {
                return orders;
            }
        }
    }
}
