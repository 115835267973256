import { orderConstants } from './orderConstants';
import OrdersService from '../../../components/orders/ordersService';
import cacheRefresherActions from '../cacheRefresher/cacheRefresherActions';
import cacheRefresherConstants from '../cacheRefresher/cacheRefresherConstants';
import loadingActions from '../loading/loadingActions';
import FractionService from '../../../services/fractionService';

export function getOrders(
  date,
  ignoreCache = false,
  silent = false,
  customerIds = [],
  fractionIds = []
) {
  return (dispatch) => {
    const timestamp = Date.now();

    dispatch(
      cacheRefresherActions.addRequestInProgress(orderConstants.GET_ORDERS)
    );
    if (!silent) {
      dispatch(loadingActions.addLoadingInProgress(orderConstants.GET_ORDERS));
    }

    dispatch(
      cacheRefresherActions.setCacheRefreshFilter({
        date,
        requestType: cacheRefresherConstants.REQUEST_TYPE_ORDERS,
      })
    );

    const headPromise = OrdersService.getOrderHeadsByAndDate(
      date,
      ignoreCache,
      customerIds,
      fractionIds
    );
    const detailsPromise = OrdersService.getOrderDetailsByDate(
      date,
      ignoreCache,
      customerIds,
      fractionIds
    );
    let allRequestsPromise;

    if (!silent) {
      allRequestsPromise = Promise.all([
        headPromise.then((orderHeads) =>
          dispatch(orderHeadsLoaded(orderHeads, timestamp))
        ),
        detailsPromise.then((orderDetails) =>
          dispatch(orderDetailsLoaded(orderDetails, timestamp))
        ),
      ]);
    } else {
      allRequestsPromise = Promise.all([headPromise, detailsPromise]).then(
        ([orderHeads, orderDetails]) => {
          dispatch(orderHeadsLoaded(orderHeads, timestamp));
          dispatch(orderDetailsLoaded(orderDetails, timestamp));
        }
      );
    }

    allRequestsPromise
      .catch((error) => dispatch(failure(JSON.stringify(error))))
      .finally(() => {
        dispatch(
          cacheRefresherActions.removeRequestInProgress(
            orderConstants.GET_ORDERS
          )
        );
        dispatch(
          loadingActions.removeLoadingInProgress(orderConstants.GET_ORDERS)
        );
      });
  };

  function orderHeadsLoaded(orderHeads, timestamp) {
    return {
      type: orderConstants.GET_ORDERS_ORDERHEADS_LOADED,
      orderHeads,
      timestamp,
    };
  }
  function orderDetailsLoaded(orderDetails, timestamp) {
    return {
      type: orderConstants.GET_ORDERS_DETAILS_LOADED,
      orderDetails,
      timestamp,
    };
  }
  function failure(error) {
    return { type: orderConstants.GET_ORDERS_FAILURE, error };
  }
}

export function getOrderDetails(orderId, ignoreCache = false, silent = false) {
  const timestamp = Date.now();

  return (dispatch) => {
    dispatch(
      cacheRefresherActions.addRequestInProgress(
        orderConstants.GET_ORDER_DETAILS
      )
    );
    if (!silent) {
      dispatch(
        loadingActions.addLoadingInProgress(orderConstants.GET_ORDER_DETAILS)
      );
    }

    dispatch(
      cacheRefresherActions.setCacheRefreshFilter({
        orderId,
        requestType: cacheRefresherConstants.REQUEST_TYPE_ORDER_DETAILS,
      })
    );
    return OrdersService.getOrderDetails(orderId, ignoreCache)
      .then(
        (order) => dispatch(success(order, timestamp)),
        (error) => dispatch(failure(JSON.stringify(error)))
      )
      .finally(() => {
        dispatch(
          cacheRefresherActions.removeRequestInProgress(
            orderConstants.GET_ORDER_DETAILS
          )
        );
        dispatch(
          loadingActions.removeLoadingInProgress(
            orderConstants.GET_ORDER_DETAILS
          )
        );
      });
  };

  function success(order) {
    return { type: orderConstants.GET_ORDER_DETAILS_SUCCESS, order, timestamp };
  }

  function failure(error) {
    return { type: orderConstants.GET_ORDER_DETAILS_FAILURE, error };
  }
}

export function setSelectedOrder(order) {
  return (dispatch) => {
    dispatch(success(order));
  };

  function success(order) {
    return { type: orderConstants.SET_SELECTED_ORDER, order };
  }
}

export function getAllFractions(ignoreCache = false) {
  return (dispatch) => {
    return FractionService.getAllFractions(ignoreCache).then(
      (fractions) => dispatch(success(fractions)),
      (error) => dispatch(failure(error))
    );
  };
  function success(fractions) {
    return { type: orderConstants.GET_ALL_FRACTIONS_SUCCESS, fractions };
  }

  function failure(error) {
    return { type: orderConstants.GET_ALL_FRACTIONS_FAILED, error };
  }
}
