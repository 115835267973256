import React from 'react';
import PropTypes from 'prop-types';
import DeviationsChart from './DeviationsChart';
import ExecutionStatusGauge from './ExecutionStatusGauge';
import DataButtonsStatistics from './DataButtonsStatistics';
import { locale } from '../../common/localization';
import { connect } from 'react-redux';
import getChartData from '../orderDetailsDiagram/getChartData';
import DiagramPlaceholder from '../orderDetailsDiagram/DiagramPlaceholder';

function renderExecutionStatus(executionStatus, executionRate) {
  if (executionStatus.yes === 0 && executionStatus.no === 0) {
    return null;
  }

  return (
    <div className="col orders-arc-gauge">
      <div className="card diagram-card">
        <div className="card-header">
          <h3>{locale.orderDetailsDiagram._executionStatusHeader}</h3>
        </div>
        <div className="card-body">
          <ExecutionStatusGauge
            executionStatus={executionStatus}
            executionRate={executionRate}
          />
        </div>
      </div>
    </div>
  );
}

function renderDeviationsChart(deviations, totalDeviations) {
  return (
    <div className="col orders-deviation-chart">
      <div className="card diagram-card">
        <div className="card-header">
          <h3>{locale.orderDetailsDiagram._deviationsHeader}</h3>
        </div>
        <div className="card-body">
          <DeviationsChart
            totalDeviations={totalDeviations}
            deviations={deviations}
          />
        </div>
      </div>
    </div>
  );
}

function renderDataButtonsStatistics(dataButtons) {
  if (!dataButtons || dataButtons.length === 0) {
    return null;
  }

  return (
    <div className="col databuttons-statistic-card">
      <DataButtonsStatistics dataButtons={dataButtons} />
    </div>
  );
}

function OrderDetailsDiagram({ orders, userDataButtons, isLoading }) {
  const chartData = getChartData(orders, userDataButtons);

  if (!chartData) {
    if (isLoading) {
      return <DiagramPlaceholder />;
    } else {
      return null;
    }
  }

  const {
    executionStatus,
    deviations,
    dataButtons,
    totalDeviations,
    executionRate,
  } = chartData;

  return (
    <div className="row diagram-row">
      {renderExecutionStatus(executionStatus, executionRate)}
      {renderDeviationsChart(deviations, totalDeviations)}
      {renderDataButtonsStatistics(dataButtons)}
    </div>
  );
}

OrderDetailsDiagram.propTypes = {
  orders: PropTypes.any.isRequired,
  userDataButtons: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    userDataButtons: state.settings.dataButtons,
  };
}

export default connect(mapStateToProps)(OrderDetailsDiagram);
