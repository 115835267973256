import serviceOrderConstants from './serviceOrderConstants';
import ServiceOrdersService from '../../../services/ServiceOrderService';
import cacheRefresherActions from "../cacheRefresher/cacheRefresherActions";
import cacheRefresherConstants from "../cacheRefresher/cacheRefresherConstants";
import loadingActions from "../loading/loadingActions";

function getServiceOrders(listId, ignoreCache = false, silent = false) {
  return dispatch => {
    dispatch(cacheRefresherActions.addRequestInProgress(serviceOrderConstants.GET_SERVICEORDERS));
    if (!silent) {
      dispatch(loadingActions.addLoadingInProgress(serviceOrderConstants.GET_SERVICEORDERS));
    }

    dispatch(cacheRefresherActions.setCacheRefreshFilter({
      listId,
      requestType: cacheRefresherConstants.REQUEST_TYPE_SERVICE_ORDER,
    }));
    return ServiceOrdersService.getServiceOrders(listId, ignoreCache)
      .then(
        (serviceOrders) => dispatch(success(serviceOrders)),
        (error) => dispatch(failure(JSON.stringify(error))),
      ).finally(() => {
        dispatch(cacheRefresherActions.removeRequestInProgress(serviceOrderConstants.GET_SERVICEORDERS));
        dispatch(loadingActions.removeLoadingInProgress(serviceOrderConstants.GET_SERVICEORDERS));
      });
  }

  function success(serviceOrders) {
    return { type: serviceOrderConstants.GET_SERVICEORDERS_SUCCESS, serviceOrders };
  }

  function failure(error) {
    return { type: serviceOrderConstants.GET_SERVICEORDERS_FAILURE, error }
  }
}

function getServiceLists(fromDate, toDate, ignoreCache = false, silent = false) {
  return dispatch => {
    dispatch(cacheRefresherActions.addRequestInProgress(serviceOrderConstants.GET_SERVICELISTS));
    if (!silent) {
      dispatch(loadingActions.addLoadingInProgress(serviceOrderConstants.GET_SERVICELISTS));
    }

    dispatch(cacheRefresherActions.setCacheRefreshFilter({
      fromDate,
      toDate,
      requestType: cacheRefresherConstants.REQUEST_TYPE_SERVICE_LISTS,
    }));
    return ServiceOrdersService.getServiceLists(fromDate, toDate, ignoreCache)
      .then((serviceLists) => {
        if (!silent) {
          dispatch(listsLoaded(serviceLists));
        }
        return ServiceOrdersService.getServiceOrdersBulk(
          serviceLists.map((list) => list.listId),
          ignoreCache,
        ).then((serviceOrders) => {
          if (silent) {
            dispatch(listsLoaded(serviceLists));
          }
          dispatch(ordersLoaded(serviceOrders))
        });
      }).then(
        (error) => dispatch(failure(JSON.stringify(error))),
      ).finally(() => {
        dispatch(cacheRefresherActions.removeRequestInProgress(serviceOrderConstants.GET_SERVICELISTS));
        dispatch(loadingActions.removeLoadingInProgress(serviceOrderConstants.GET_SERVICELISTS));
      });
  }

  function listsLoaded(serviceLists) {
    return { type: serviceOrderConstants.GET_SERVICELISTS_LISTS_LOADED, serviceLists };
  }

  function ordersLoaded(serviceOrders) {
    return { type: serviceOrderConstants.GET_SERVICELISTS_ORDERS_LOADED, serviceOrders };
  }

  function failure(error) {
    return { type: serviceOrderConstants.GET_SERVICELISTS_FAILURE, error }
  }
}

function deleteServiceList(listId) {
  return dispatch => {
    dispatch(loadingActions.addLoadingInProgress(serviceOrderConstants.DELETE_SERVICE_LIST));

    return ServiceOrdersService.deleteServiceList(listId)
      .then(
        (serviceList) => dispatch(success(serviceList)),
        (error) => dispatch(failure(JSON.stringify(error))),
      ).finally(() => {
        dispatch(loadingActions.removeLoadingInProgress(serviceOrderConstants.DELETE_SERVICE_LIST));
      });
  }

  function success(serviceList) {
    return { type: serviceOrderConstants.DELETE_SERVICE_LIST_SUCCESS, serviceList };
  }

  function failure(error) {
    return { type: serviceOrderConstants.DELETE_SERVICE_LIST_FAILURE, error }
  }
}

function deleteServiceListItem(listItemId, paSystem) {
  return dispatch => {
    dispatch(loadingActions.addLoadingInProgress(serviceOrderConstants.DELETE_SERVICE_LIST_ITEM));

    return ServiceOrdersService.deleteServiceListItem(listItemId, paSystem)
      .then(
        (listItem) => dispatch(success(listItem)),
        (error) => dispatch(failure(JSON.stringify(error))),
      ).finally(() => {
        dispatch(loadingActions.removeLoadingInProgress(serviceOrderConstants.DELETE_SERVICE_LIST_ITEM));
      });
  }

  function success(listItem) {
    return { type: serviceOrderConstants.DELETE_SERVICE_LIST_ITEM_SUCCESS, listItem };
  }

  function failure(error) {
    return { type: serviceOrderConstants.DELETE_SERVICE_LIST_ITEM_FAILURE, error }
  }
}

function setDateRangeFilter(filter) {
  return { type: serviceOrderConstants.SET_SERVICEORDERS_DATE_RANGE_FILTER, filter }
}

function setSelectedServiceListId(serviceListId) {
  return { type: serviceOrderConstants.SET_SELECTED_SERVICELIST, serviceListId }
}

function setSelectedServiceOrderId(serviceListId, serviceOrderId) {
  return { type: serviceOrderConstants.SET_SELECTED_SERVICEORDER, serviceListId, serviceOrderId }
}

export default {
  getServiceOrders,
  getServiceLists,
  setDateRangeFilter,
  setSelectedServiceListId,
  setSelectedServiceOrderId,
  deleteServiceList,
  deleteServiceListItem,
}
