import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import ImpersonationWarning from './ImpersonationWarning';

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({}, dispatch);
}

function mapStateToProps() {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImpersonationWarning);
