export interface IAgreementConnectionInfo {
  agreementDegLat: number;
  agreementDegLon: number;
  agreementGid: string;
  agreementId: number;
  name: string;
  placeId: number;
  placeName: string;
}
export namespace IAgreementConnectionInfo {
  export function toString({ agreementId }: IAgreementConnectionInfo): string {
    return `${agreementId}`;
  }
}
