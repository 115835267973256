import { Component } from 'react';
import PropTypes from 'prop-types';
import cacheRefresherConstants from '../../redux/modules/cacheRefresher/cacheRefresherConstants';

class CacheRefresher extends Component {
  static propTypes = {
    filter: PropTypes.object,
    countdownEnd: PropTypes.number.isRequired,
    order: PropTypes.object,
    companySettings: PropTypes.object,
    orderActions: PropTypes.object.isRequired,
    vehicleTrackingActions: PropTypes.object.isRequired,
    cacheRefresherActions: PropTypes.object.isRequired,
    serviceOrderActions: PropTypes.object.isRequired,
    containerOrderActions: PropTypes.object.isRequired,
    loadTrackingLines: PropTypes.func.isRequired,
    loadTrackInfos: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.refreshItems = this.refreshItems.bind(this);
  }

  componentDidMount() {
    this.setupTimer();
  }

  componentDidUpdate(prevProps) {
    const { countdownEnd, isLoading } = this.props;

    if (
      prevProps.countdownEnd !== countdownEnd ||
      (!isLoading && !this.timeoutId)
    ) {
      this.clearTimer();
      this.setupTimer();
    }
  }

  componentWillUnmount() {
    this.clearTimer();
  }

  clearTimer() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }
  }

  setupTimer() {
    const { countdownEnd, cacheRefresherActions } = this.props;

    if (!countdownEnd) {
      return;
    }

    const now = Date.now();
    if (countdownEnd <= now) {
      cacheRefresherActions.startCacheRefreshTimer();
    } else {
      const timeout = countdownEnd - now;
      this.timeoutId = setTimeout(this.refreshItems, timeout);
    }
  }

  refreshItems() {
    const {
      filter,
      orderActions,
      vehicleTrackingActions,
      containerOrderActions,
      serviceOrderActions,
      companySettings,
      order,
      loadTrackingLines,
      loadTrackInfos,
    } = this.props;

    this.timeoutId = null;

    if (filter) {
      switch (filter.requestType) {
        case cacheRefresherConstants.REQUEST_TYPE_ORDERS:
          orderActions.getOrders(filter.date, true, true);
          break;
        case cacheRefresherConstants.REQUEST_TYPE_ORDER_DETAILS:
          orderActions.getOrderDetails(filter.orderId, true, true);
          break;
        case cacheRefresherConstants.REQUEST_TYPE_CONTAINER_ORDERS:
          containerOrderActions.getContainerOrders(
            filter.fromDate,
            filter.toDate,
            true,
            true
          );
          break;
        case cacheRefresherConstants.REQUEST_TYPE_SERVICE_ORDER:
          serviceOrderActions.getServiceOrders(filter.listId, true, true);
          break;
        case cacheRefresherConstants.REQUEST_TYPE_SERVICE_LISTS:
          serviceOrderActions.getServiceLists(
            filter.fromDate,
            filter.toDate,
            true,
            true
          );
          break;
      }
    }

    vehicleTrackingActions.getVehicles(true, true);

    if (
      companySettings &&
      companySettings.trackingLineLayer &&
      companySettings.trackingLineLayer.enabled
    ) {
      loadTrackingLines(true);
    }

    if (
      order &&
      order.orderId &&
      companySettings &&
      ((companySettings.trackInfoLayer &&
        companySettings.trackInfoLayer.enabled) ||
        (companySettings.trackInfoLineLayer &&
          companySettings.trackInfoLineLayer.enabled))
    ) {
      loadTrackInfos(true, order.orderId);
    }
  }

  render() {
    return null;
  }
}

export default CacheRefresher;
