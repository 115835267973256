import userConstants from './userConstants';

const initialState = {
  users: {},
}

function updateUser(users, newUser) {
  if (!newUser) {
    return users;
  }

  const updatedUsers = { ...users };
  updatedUsers[newUser.customerId] = updatedUsers[newUser.customerId].map(user =>
    user.userName === newUser.userName
      ? newUser
      : user
  );

  return updatedUsers;
}

export default function usersReducer(state = initialState, action) {
  switch (action.type) {
    case userConstants.GET_USERS_SUCCESS:
      return {
        users: {
          ...state.users,
          ...action.groupedUsers
        },
      }
    case userConstants.GET_USERS_FAILURE:
      return {
        ...state,
        error: action.error,
      }
    case userConstants.CREATE_USER_SUCCESS:
      return {
        users: {
          ...state.users,
          [action.user.customerId]: [
            ...state.users[action.user.customerId],
            action.user,
          ],
        },
      }
    case userConstants.CREATE_USER_FAILURE:
      return {
        ...state,
        error: action.error,
      }
    case userConstants.UPDATE_USER_SUCCESS:
      return {
        users: updateUser(state.users, action.user),
      }
    case userConstants.UPDATE_USER_FAILURE:
      return {
        ...state,
        error: action.error,
      }
    default:
      return state;
  }
}
