import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import {
  addZoomableObject,
  removeZoomableObject,
} from '../../redux/modules/map/mapCreators';
import { ZoomManager } from './ZoomManager';

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    addZoomableObject: bindActionCreators(addZoomableObject, dispatch),
    removeZoomableObject: bindActionCreators(removeZoomableObject, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(ZoomManager);
