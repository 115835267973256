import { Input } from '@progress/kendo-react-inputs';
import React, { Component, ReactNode } from 'react';
import { locale } from '../../common/localization';

export interface ICustomerSearchBarProps {
  searchCustomer: (searchWord: string) => void;
}

export interface ICustomerSearchBarState {
  value: string;
}

export class CustomerSearchBar extends Component<
  ICustomerSearchBarProps,
  ICustomerSearchBarState
> {
  public readonly state: ICustomerSearchBarState = {
    value: '',
  };

  private handleInputChange(event: any) {
    this.setState({ value: event.target.value });
  }

  private handleKeyPress(event: any) {
    if (event.key === 'Enter') {
      this.onSearch();
    }
  }

  private onSearch() {
    const { value } = this.state;
    this.props.searchCustomer(value);
  }
  public render(): ReactNode {
    const { value } = this.state;
    return (
      <>
        <div className="customer-search-input-wrapper">
          <Input
            className="customer-search-input"
            placeholder={locale.general._customerRegister}
            value={value}
            onChange={this.handleInputChange.bind(this)}
            onKeyPress={this.handleKeyPress.bind(this)}
          />
        </div>
      </>
    );
  }
}
