import React, { Component } from "react";
import PropTypes from 'prop-types';
import { faStopwatch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import cacheRefresherActions from '../../redux/modules/cacheRefresher/cacheRefresherActions';
import classNames from 'classnames';

class CacheRefreshToggle extends Component {
  constructor(props) {
    super(props);

    this.toggleAutoRefresh = this.toggleAutoRefresh.bind(this);
  }

  toggleAutoRefresh(event) {
    event.preventDefault();

    const {
      countdownEnd,
      cacheRefresherActions,
    } = this.props;

    if (!countdownEnd) {
      cacheRefresherActions.startCacheRefreshTimer();
    } else {
      cacheRefresherActions.stopCacheRefreshTimer();
    }
  }

  render() {
    const { countdownEnd } = this.props;

    const buttonClass = classNames(
      'auto-refresh-button',
      { 'auto-refresh-active': !!countdownEnd }
    );

    return (
      <a
        href="#"
        onClick={this.toggleAutoRefresh}
        className={buttonClass}
      >
        <FontAwesomeIcon icon={faStopwatch} />
      </a>
    );
  }
}

CacheRefreshToggle.propTypes = {
  cacheRefresherActions: PropTypes.object.isRequired,
  countdownEnd: PropTypes.number.isRequired,
}

function mapStateToProps(state) {
  return {
    countdownEnd: state.cacheRefresher.countdownEnd,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    cacheRefresherActions: bindActionCreators(cacheRefresherActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CacheRefreshToggle);
