import Control from 'react-leaflet-control';
import React, { Component, ReactNode } from 'react';

export interface IMapControlProps {
  className?: string;
  position: 'topleft' | 'topright' | 'bottomright' | 'bottomleft';
  children: ReactNode;
}

export class MapControl extends Component<IMapControlProps> {
  public render(): ReactNode {
    return <Control {...this.props} />;
  }
}
