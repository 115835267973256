import React, { Component, ReactNode } from 'react';
import { groupBy } from '../../services/listService';
import { MapUtility } from '../map/MapUtility';
import { RouteStopMarker } from './RouteStopMarker';
import routes from '../../common/routes';
import { ClusteredMapLayer } from '../shared/customMapLayer';
import RouteZoomManager from './RouteZoomManager';
import { IRouteStop, ISelectedOrder } from '../../models';
import { selectedRegion } from '../../appConfig';

export interface ISelectedRouteLayerProps {
  selectedOrder: ISelectedOrder;
  clusteringActive: boolean;
  zoomLevel: number;
  location: Location;
}

export class SelectedRouteLayer extends Component<ISelectedRouteLayerProps> {
  public filterRoutes(generatedRoutes: Array<IRouteStop>): Array<IRouteStop> {
    const groupedValues = groupBy(
      generatedRoutes,
      (group: any) => group.routeLineId
    ).values();
    return Array.from(
      groupedValues,
      (routes) => routes.filter((route: any) => route.routeLine)[0]
    );
  }

  public mapRouteStopToMarker(
    routeStop: IRouteStop,
    zoomLevel: number
  ): ReactNode {
    if (
      !routeStop ||
      !routeStop.routeLine ||
      !MapUtility.isInRegion(
        routeStop.routeLine.degLat,
        routeStop.routeLine.degLong,
        selectedRegion
      )
    ) {
      return null;
    }

    return (
      <RouteStopMarker
        key={routeStop.routeLineId}
        routeStop={routeStop}
        zoomLevel={zoomLevel}
      />
    );
  }

  /** @todo: this check should not be here */
  public ordersViewActive(location: Location): boolean {
    return location.pathname.startsWith(routes.orders.base);
  }

  public getMarkers(
    selectedOrder: ISelectedOrder,
    zoomLevel: number
  ): Array<ReactNode> {
    if (!selectedOrder || !selectedOrder.generatedRoutes) {
      return null;
    }

    return this.filterRoutes(selectedOrder.generatedRoutes).map((route) =>
      this.mapRouteStopToMarker(route, zoomLevel)
    );
  }

  public render(): ReactNode {
    const { clusteringActive, selectedOrder, zoomLevel, location } = this.props;
    const markers = this.ordersViewActive(location)
      ? this.getMarkers(selectedOrder, zoomLevel)
      : null;

    return (
      <ClusteredMapLayer isClusteringActive={clusteringActive}>
        <RouteZoomManager />
        {markers}
      </ClusteredMapLayer>
    );
  }
}

export default SelectedRouteLayer;
