import containerOrderConstants from './containerOrderConstants';

const initialState = {
  containerOrders: [],
  selectedOrder: null,
}

export default function containerOrder(state = initialState, action) {
  switch (action.type) {
    case containerOrderConstants.GET_CONTAINERORDER_SUCCESS:
      return {
        ...state,
        containerOrders: action.containerOrders,
        error: null,
      }
    case containerOrderConstants.GET_CONTAINERORDER_FAILURE:
      return {
        ...state,
        error: action.error,
      }
    case containerOrderConstants.SET_CONTAINERORDER_DATE_RANGE_FILTER:
      return {
        ...state,
        dateFilterValue: action.filter,
      }
    case containerOrderConstants.SET_SELECTED_CONTAINERORDER:
      return {
        ...state,
        selectedOrderId: action.containerOrderId,
      }
    default:
      return state;
  }
}
