import React, { Component, FormEvent, ReactNode } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { InstanceProps } from 'react-modal-promise';
import { ListBox, ListBoxDragEvent } from '@progress/kendo-react-listbox';
import { locale } from '../../common/localization';
import { IPoiAgreement, IRoutePoi } from '../../models';
import { Spinner2 } from '../shared/spinner2';
import { AsyncAction, empty } from '../../utils';
import { flatMap as _flatMap } from 'lodash';

import './SplitModal.scss';

const { Header, Title, Body, Footer } = Modal;

export interface ISplitModalProps extends InstanceProps<void> {
  pois: Array<IRoutePoi>;
  onSubmit: (result: {
    routeId: string;
    newRouteStops: Array<any>;
  }) => AsyncAction;
}

export interface ISplitModalState {
  isValid: boolean;
  isDirty: boolean;
  fieldErrors: Set<string>;
  draggedItem?: IExtendedAgreement;
  prevIndex?: number;
  id: string;
  address: string;
  agreements: Array<any>;
  isLoading: boolean;
}

interface IExtendedAgreement extends IPoiAgreement {
  display: string;
}

export class SplitModal extends Component<ISplitModalProps, ISplitModalState> {
  public readonly state: ISplitModalState = {
    isValid: true,
    isDirty: false,
    isLoading: false,
    fieldErrors: new Set<string>(),
    id: null,
    address: '',
    agreements: [],
  };

  public componentDidUpdate(prevProps?: ISplitModalProps): void {
    if (prevProps?.isOpen === this.props.isOpen) return;
    if (!this.props.isOpen) return;
    const [poi] = this.props.pois;
    if (!poi) return;
    const agreements = [
      this.props.pois
        .map((a) => ({
          ...a,
          display: `${a.estate}: ${a.unitName} (${locale.routeAdminPage._agreementId}: ${a.agreementId} - ${locale.routeAdminPage._agreementLineId}: ${a.agreementLineId})`,
        }))
        .sort((a, b) => a.display.localeCompare(b.display)),
    ];
    this.setState({
      ...this.state,
      id: poi.id,
      address: poi.estate,
      agreements,
    });
  }

  public onCancel(): void {
    if (this.state.isLoading) return;
    this.props.onReject();
  }

  public async onSubmit(event: FormEvent<HTMLFormElement>): Promise<void> {
    event.preventDefault();

    await this.setLoading(true);

    try {
      await this.validate();
      if (!this.state.isValid) throw new Error('Invalid form');
      await this.props.onSubmit(this.getResult());
      this.props.onResolve();
    } catch (error) {
      console.error(error);
    }

    await this.setLoading(false);
  }

  public onDragStart(event: ListBoxDragEvent, prevIndex: number): void {
    this.setState({
      ...this.state,
      draggedItem: event.dataItem,
      prevIndex,
    });
  }

  public onDrop(event: ListBoxDragEvent, nextIndex: number): void {
    const { prevIndex, draggedItem } = this.state;
    const agreements = [
      ...this.state.agreements.map((agreement) => [...agreement]),
    ];
    if (nextIndex === agreements.length) agreements.push([]);
    agreements[prevIndex] = agreements[prevIndex].filter(
      (id) => !IPoiAgreement.equals(id, draggedItem)
    );
    agreements[nextIndex].push(draggedItem);
    agreements[nextIndex] = agreements[nextIndex].sort((a, b) =>
      a.display.localeCompare(b.display)
    );
    this.setState({
      ...this.state,
      isDirty: true,
      prevIndex: null,
      draggedItem: null,
      agreements: agreements.filter((agreement) => agreement.length > 0),
    });
  }

  public validate(): Promise<void> {
    return new Promise((resolve) => {
      const fieldErrors = new Set<string>();

      if (this.state.agreements.length < 2) fieldErrors.add('agreements');
      this.setState(
        {
          ...this.state,
          fieldErrors,
          isValid: fieldErrors.size === 0,
        },
        () => resolve()
      );
    });
  }

  public getResult(): {
    routeId: string;
    newRouteStops: Array<any>;
  } {
    const [, ...agreements] = [this.state.agreements].shift();

    const pois: IRoutePoi[] = _flatMap(agreements, (childArray) => childArray);

    const newRouteStops = pois.map((poi) => {
      const routeStop = {
        unitId: poi.unitId,
        latitude: poi.degLat,
        longitude: poi.degLong,
        agreementLines: [
          {
            agreementLineId: poi.agreementLineId,
            routeLineId: poi.routeLineId,
            paSystem: poi.paSystem,
          },
        ],
      };
      return routeStop;
    });

    const routeId = pois.shift().route;

    return {
      routeId,
      newRouteStops,
    };
  }

  private setLoading(isLoading: boolean): Promise<void> {
    return new Promise((resolve) => {
      this.setState(
        {
          ...this.state,
          isLoading,
        },
        () => resolve()
      );
    });
  }

  public render(): ReactNode {
    const { isLoading } = this.state;
    const { isOpen, pois } = this.props;

    if (!pois.length) return null;

    const title = `${locale.routeAdminPage._splitStoppoint} - (${this.state.address})`;

    return (
      <Modal
        size="lg"
        autoFocus={false}
        className="split-modal"
        show={isOpen}
        onHide={() => this.onCancel()}
      >
        <Spinner2 show={isLoading} />
        <form onSubmit={(e) => this.onSubmit(e)}>
          <Header>
            <Title title={title}>{title}</Title>
          </Header>

          <Body>
            {this.state.agreements.map((agreements, index) => {
              return (
                <ListBox
                  key={index}
                  data={agreements}
                  textField="display"
                  onItemClick={empty}
                  onDragStart={(e) => this.onDragStart(e, index)}
                  onDrop={(e) => this.onDrop(e, index)}
                  draggable={true}
                />
              );
            })}
            <ListBox
              className="placeholder-list"
              data={[locale.routeAdminPage._splitModalPlaceholder]}
              textField=""
              draggable={false}
              onDragStart={empty}
              onDrop={(e) => this.onDrop(e, this.state.agreements.length)}
            />
          </Body>
          <div
            hidden={!this.state.fieldErrors.has('agreements')}
            className="alert alert-danger mb-2"
            role="alert"
          >
            {locale.routeAdminPage._splitModalErrorMessage}
          </div>

          <Footer>
            <Button
              variant="secondary"
              onClick={() => this.onCancel()}
              disabled={isLoading}
            >
              {locale.routeAdminPage._cancelButton}
            </Button>
            <Button
              disabled={!this.state.isDirty || isLoading}
              variant="primary"
              type="submit"
            >
              {locale.routeAdminPage._submitButton}
            </Button>
          </Footer>
        </form>
      </Modal>
    );
  }
}
