import React, { Component, createRef, ReactNode, RefObject } from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { renderToStaticMarkup } from 'react-dom/server';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { divIcon, DivIcon, LatLng } from 'leaflet';
import { Marker, Polyline } from 'react-leaflet';
import classNames from 'classnames';
import { ICoords, IRoutePoi, RouteEditMode } from '../../models';
import { PoiTheme } from '../../utils/PoiTheme';
import { clamp } from '../shared/mathUtility';
import { PulseIcon } from '../shared/pulseIcon';
import { PoiCaption } from './PoiCaption';
import { PoiTooltip } from './PoiTooltip';
import { PropertyMarker } from './PropertyMarker';
import { MapUtility } from '../map/MapUtility';
import { Toastr } from '../../utils/Toastr';
import { locale } from '../../common/localization';
import { selectedRegion } from '../../appConfig';

export interface IPoiMarkerProps {
  poi: IRoutePoi;
  isPropertyLayerVisible: boolean;
  zoomLevel: number;
  isSelected: boolean;
  isDraggable: boolean;
  defaultLinesVisible: boolean;
  mode: RouteEditMode;
  onClick: () => void;
  onDrag: (coords: LatLng) => void;
}
export class PoiMarker extends Component<IPoiMarkerProps> {
  public readonly icon: IconDefinition = faCircle;

  public readonly tooltip: RefObject<PoiTooltip> = createRef();

  public marker: RefObject<Marker> = createRef();

  public getSize(): number {
    const { zoomLevel } = this.props;
    const diameter = 20 - (17 - zoomLevel);
    const clampedDiameter = clamp(diameter, 6, 20);

    return clampedDiameter;
  }

  public getIcon(): DivIcon {
    const { poi, isSelected, zoomLevel } = this.props;
    const scale = 1;
    const size = this.getSize() * scale;
    const theme = PoiTheme.get(poi.routeName);
    const color = theme.color;
    const borderColor = PoiTheme.darken(poi.routeName).color;
    const fontColor = theme.font;
    const zIndex = 'initial';
    const text = `${poi.sequence}`;
    const iconHtml = renderToStaticMarkup(
      <PulseIcon color={color} show={isSelected}>
        <FontAwesomeIcon style={{ color, zIndex }} icon={this.icon} />
        <PoiCaption
          color={fontColor}
          borderColor={borderColor}
          zoomLevel={zoomLevel}
          text={text}
          scale={scale}
        />
      </PulseIcon>
    );

    return divIcon({
      html: iconHtml,
      className: classNames('circle-marker-icon', this.props.mode, {
        selected: isSelected,
      }),
      iconSize: [size, size],
    });
  }

  public getRouteLines(poi: IRoutePoi): Array<Array<ICoords>> {
    const visible = this.props.isPropertyLayerVisible;
    if (!poi.agreementConnectionInfo || !visible) return [];
    const base: ICoords = [poi.degLat, poi.degLong];
    return poi.infos
      .map((info) => {
        const lat = info.agreementDegLat;
        const lon = info.agreementDegLon;
        const tip: ICoords = [lat, lon];
        return [base, tip];
      })
      .filter(([, [tipLat, tipLon]]) => {
        return (
          this.props.defaultLinesVisible || (tipLat !== -1 && tipLon !== -1)
        );
      });
  }

  public getPropertyMarkers(poi: IRoutePoi): Array<ReactNode> {
    const { zoomLevel, isPropertyLayerVisible, mode } = this.props;
    return poi.infos.map((info, i) => (
      <PropertyMarker
        key={i}
        info={info}
        mode={mode}
        zoomLevel={zoomLevel}
        isPropertyLayerVisible={isPropertyLayerVisible}
      />
    ));
  }

  public onClick(): void {
    this.tooltip.current.close();
    this.props.onClick();
  }

  public onDragend(coords: LatLng): void {
    if (MapUtility.isInRegion(coords.lat, coords.lng, selectedRegion)) {
      this.props.onDrag(coords);
    } else {
      Toastr.error(locale.routeAdminPage._notInNorwayErrorMessage, true);
      this.props.onDrag({
        lat: this.props.poi.degLat,
        lng: this.props.poi.degLong,
      } as LatLng);
    }
  }

  public render(): ReactNode {
    const { poi, mode } = this.props;
    const icon = this.getIcon();
    const positions = this.getRouteLines(poi);
    const propertyMarkers = this.getPropertyMarkers(poi);

    return (
      <>
        <Polyline color={'black'} positions={positions} />
        {propertyMarkers}
        <Marker
          className="link"
          position={[poi.degLat, poi.degLong]}
          icon={icon}
          onClick={() => this.onClick()}
          onDragend={(e: any) => this.onDragend(e.target._latlng)}
          draggable={this.props.isDraggable}
        >
          <PoiTooltip
            ref={this.tooltip}
            poi={poi}
            disabled={mode !== RouteEditMode.Default}
          />
        </Marker>
      </>
    );
  }
}
