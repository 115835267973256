import React from 'react'
import { locale } from '../../common/localization';

function DiagramPlaceholder() {
  return (
    <div className="row diagram-row">
      <div className="col orders-arc-gauge execution-status-placeholder" >
        <div className="card diagram-card" >
          <div className="card-header">
            <h3>{locale.orderDetailsDiagram._executionStatusHeader}</h3>
          </div>
          <div className="card-body">
            <div className="mock-gauge"></div>
            <div className="text-placeholder"></div>
          </div>
        </div>
      </div>
      <div className="col orders-deviation-chart deviations-placeholder">
        <div className="card diagram-card" >
          <div className="card-header">
            <h3>{locale.orderDetailsDiagram._deviationsHeader}</h3>
          </div>
          <div className="card-body">
            <div className="row deviations-chart-row">
              <div className="col flex-grow-0">
                <div className="mock-pie"></div>
              </div>
              <div className="col">
                <div className="mock-legend-list">
                  <div className="mock-legend-item"></div>
                  <div className="mock-legend-item"></div>
                  <div className="mock-legend-item"></div>
                  <div className="mock-legend-item"></div>
                  <div className="mock-legend-item"></div>
                  <div className="mock-legend-item"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col databuttons-statistic-card databuttons-placeholder">
        <div className="card diagram-card" >
          <ul className="list-group list-group-flush">
            <li className="list-group-item databuttons-statistic">
              <div className="text-placeholder"></div>
            </li>
            <li className="list-group-item databuttons-statistic">
              <div className="text-placeholder"></div>
            </li>
            <li className="list-group-item databuttons-statistic">
              <div className="text-placeholder"></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default DiagramPlaceholder;
