import React, { Component, ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMask } from '@fortawesome/free-solid-svg-icons';

export interface IImpersonationMarkerProps {
  impersonatedUser: string;
}

export class ImpersonationMarker extends Component<IImpersonationMarkerProps> {
  public render(): ReactNode {
    const { impersonatedUser } = this.props;
    if (!impersonatedUser) {
      return null;
    }

    return (
      <span className="impersonation-marker">
        (
        <FontAwesomeIcon icon={faMask} />
        &nbsp;
        {impersonatedUser})
      </span>
    );
  }
}
