import React from 'react';
import { render } from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import localforage from 'localforage';
import memoryDriver from 'localforage-memoryStorageDriver';
import { history } from './redux/configureStore';
import { store, persistor } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import 'popper.js';
import 'hammerjs';
import { library } from '@fortawesome/fontawesome-svg-core';
import { Auth0Provider } from '@auth0/auth0-react';
import { loginWithAuth0 } from './redux/modules/auth'
import {
  faBars,
  faSignOutAlt,
  faList,
  faExclamationTriangle,
  faAngleLeft,
  faAngleRight,
  faInfoCircle,
  faSyncAlt,
} from '@fortawesome/free-solid-svg-icons';
import Root from './components/Root';
import './styles/general.scss';
import './favicon.png';

(async function init(): Promise<void> {
  await localforage.defineDriver(memoryDriver);

  library.add(
    faBars,
    faSignOutAlt,
    faList,
    faExclamationTriangle,
    faAngleLeft,
    faAngleRight,
    faInfoCircle,
    faSyncAlt
  );
 
  const onRedirectCallback = (appState) => {
    if (appState && appState.callback) {
      loginWithAuth0(appState.authResult);
    }
};


  const providerConfig = {
    domain: "fieldata.eu.auth0.com",
    clientId: "KI4KFxJXLtWvaJw8lPQYA5hFdg8Rgu9r",
    onRedirectCallback,
    authorizationParams: {
      redirect_uri: window.location.origin,
      audience: "https://webservices.fieldata.no"
    },    
    cacheLocation: 'localstorage' as const,
  };

  render(
    <Auth0Provider
    {...providerConfig}
  >
    <AppContainer>
      <PersistGate loading={null} persistor={persistor}>
        <Root store={store} history={history} />
      </PersistGate>
    </AppContainer>    
  </Auth0Provider>,

  document.getElementById('app')
  );

  if (module.hot) {
    module.hot.accept('./components/Root', () => {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const NewRoot = require('./components/Root').default;
      render(
        <AppContainer>
          <PersistGate loading={null} persistor={persistor}>
            <NewRoot store={store} history={history} />
          </PersistGate>
        </AppContainer>,
        document.getElementById('app')
      );
    });
  }
})();
