import serviceOrderConstants from './serviceOrderConstants';
import { groupBy } from '../../../services/listService';

const initialState = {
  serviceLists: [],
  selectedListId: null,
  error: null,
}

function updateServiceList(serviceLists, serviceOrders) {
  if (!serviceOrders || serviceOrders.length === 0) {
    return serviceLists;
  }

  const newServiceLists = serviceLists.slice();
  groupBy(serviceOrders, (order) => order.listId)
    .forEach((orders, listId) => {
      const listIndexToUpdate = serviceLists
        .findIndex((list) => list.listId === listId);

      if (listIndexToUpdate !== -1) {
        const listToUpdate = { ...newServiceLists[listIndexToUpdate] };
        newServiceLists[listIndexToUpdate] = listToUpdate;
        listToUpdate.orders = orders;
      }
    });

  return newServiceLists;
}

function removeListItem(serviceLists, listItem) {
  const serviceListIndex = serviceLists.findIndex(l => l.listId === listItem.listId);
  if (serviceListIndex === -1) {
    return serviceLists;
  }

  const newServiceLists = serviceLists.slice();
  const newServiceList = { ...newServiceLists[serviceListIndex] };
  newServiceLists[serviceListIndex] = newServiceList;

  newServiceList.orders = newServiceList.orders
    .filter(i => i.agreementPASystem !== listItem.pASystem
      && i.listItemId !== listItem.listItemId);

  return newServiceLists;
}

export default function serviceOrder(state = initialState, action) {
  switch (action.type) {
    case serviceOrderConstants.GET_SERVICEORDERS_SUCCESS:
      return {
        ...state,
        serviceLists: updateServiceList(state.serviceLists, action.serviceOrders),
        error: null,
      }
    case serviceOrderConstants.GET_SERVICEORDERS_FAILURE:
      return {
        ...state,
        error: action.error,
      }
    case serviceOrderConstants.GET_SERVICELISTS_LISTS_LOADED:
      return {
        ...state,
        serviceLists: action.serviceLists,
      }
    case serviceOrderConstants.GET_SERVICELISTS_ORDERS_LOADED:
      return {
        ...state,
        serviceLists: updateServiceList(state.serviceLists, action.serviceOrders),
        error: null,
      }
    case serviceOrderConstants.GET_SERVICELISTS_FAILURE:
      return {
        ...state,
        error: action.error,
      }
    case serviceOrderConstants.SET_SERVICEORDERS_DATE_RANGE_FILTER:
      return {
        ...state,
        dateFilterValue: action.filter,
      }
    case serviceOrderConstants.SET_SELECTED_SERVICELIST:
      return {
        ...state,
        selectedListId: action.serviceListId,
        selectedOrderId: state.selectedListId === action.serviceListId
          ? state.selectedOrderId
          : null,
      }
    case serviceOrderConstants.SET_SELECTED_SERVICEORDER:
      return {
        ...state,
        selectedListId: action.serviceListId,
        selectedOrderId: action.serviceOrderId,
      }
    case serviceOrderConstants.DELETE_SERVICE_LIST_SUCCESS:
      return {
        ...state,
        serviceLists: state.serviceLists.filter((l) => l.listId !== action.serviceList.listId),
        error: null,
      }
    case serviceOrderConstants.DELETE_SERVICE_LIST_FAILURE:
      return {
        ...state,
        error: action.error,
      }
    case serviceOrderConstants.DELETE_SERVICE_LIST_ITEM_SUCCESS:
      return {
        ...state,
        serviceLists: removeListItem(state.serviceLists, action.listItem),
        error: null,
      }
    case serviceOrderConstants.DELETE_SERVICE_LIST_ITEM_FAILURE:
      return {
        ...state,
        error: action.error,
      }
    default:
      return state;
  }
}
