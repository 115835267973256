import React, { Component, ReactNode } from 'react';
import classNames from 'classnames';
import './PulseIcon.scss';

export interface IPulseIconProps {
  color: string;
  show?: boolean;
}

export class PulseIcon extends Component<IPulseIconProps> {
  public render(): ReactNode {
    const { children, color, show = false } = this.props;
    const style = { '--color': color } as React.CSSProperties;
    const className = classNames('after', { show: show });
    return (
      <div className="leaflet-pulsing-icon">
        {children}
        <div className={className} style={style}></div>
      </div>
    );
  }
}
