import { wasteManagementUrls } from '../appConfig';
import {
  IAddNewDeviation,
  IAddNewDeviationCategory,
  IDeviation,
  IDeviationCategory,
} from '../models/Deviation';
import wasteManagementClient from './wasteManagementClient';

export default class DeviationsService {
  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  static getDeviations(
    customerId: number,
    ignoreCache = false
  ): Promise<IDeviation[]> {
    return wasteManagementClient
      .get(`${wasteManagementUrls.deviations.base}/customer/${customerId}`, {
        cache: { ignoreCache },
      })
      .then((response) => {
        if (response.status === 200 && response.data) {
          return response.data;
        } else {
          return [];
        }
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  static getDeviationCategories(
    customerId: number,
    ignoreCache = false
  ): Promise<IDeviationCategory[]> {
    return wasteManagementClient
      .get(
        `${wasteManagementUrls.deviations.base}/category/customer/${customerId}`,
        {
          cache: { ignoreCache },
        }
      )
      .then((response) => {
        if (response.status === 200 && response.data) {
          return response.data;
        } else {
          return [];
        }
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  static async addDeviaton(
    newDeviation: IAddNewDeviation,
    ignoreCache = false
  ): Promise<Array<void>> {
    try {
      const url = wasteManagementUrls.deviations.base;
      const response = await wasteManagementClient.post(url, newDeviation, {
        cache: { ignoreCache },
      });

      if (response.status === 200 && response?.data) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  static async updateDeviaton(
    updatedDeviation: IDeviation,
    ignoreCache = false
  ): Promise<Array<void>> {
    try {
      const url = wasteManagementUrls.deviations.base;
      const response = await wasteManagementClient.put(url, updatedDeviation, {
        cache: { ignoreCache },
      });

      if (response.status === 200 && response?.data) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  static async deleteDeviaton(
    customerId: number,
    deviationId: number,
    ignoreCache = false
  ): Promise<Array<void>> {
    try {
      const url =
        wasteManagementUrls.deviations.base +
        `/customer/${customerId}/deviation/${deviationId}`;
      const response = await wasteManagementClient.delete(url, {
        cache: { ignoreCache },
      });

      if (response.status === 200 && response?.data) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  static async addNewCategory(
    newCategory: IAddNewDeviationCategory,
    ignoreCache = false
  ): Promise<void> {
    try {
      const url = wasteManagementUrls.deviations.base + '/category';
      const response = await wasteManagementClient.post(url, newCategory, {
        cache: { ignoreCache },
      });
      if (response.status === 200 && response?.data) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  static async updateCategory(
    category: IDeviationCategory,
    ignoreCache = false
  ): Promise<void> {
    try {
      const url = wasteManagementUrls.deviations.base + '/category';
      const response = await wasteManagementClient.put(url, category, {
        cache: { ignoreCache },
      });
      if (response.status === 200 && response?.data) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  static async deleteCategory(
    customerId: number,
    categoryId: number,
    ignoreCache = false
  ): Promise<void> {
    try {
      const url =
        wasteManagementUrls.deviations.base +
        `/customer/${customerId}/category/${categoryId}`;
      await wasteManagementClient.delete(url, { cache: { ignoreCache } });
    } catch (error) {
      console.log(error);
      throw new Error(error);
    }
  }
}
