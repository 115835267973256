import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MapUtility } from '../map/MapUtility';
import VehicleMarker from './VehicleMarker.js';
import routes from '../../common/routes';
import VehicleZoomManager from './VehicleZoomManager';
import { selectedRegion } from '../../appConfig';

class VehicleTrackingLayer extends Component {
  mapVehicleToMarker(vehicle) {
    const position = [vehicle.latitude, vehicle.longitude];
    if (!MapUtility.isInRegion(vehicle.latitude, vehicle.longitude, selectedRegion)) {
      return null;
    }

    const {
      orders,
      selectedOrder,
    } = this.props;

    const order = orders && orders.find(o => o.orderId === vehicle.orderId);
    const routeName = order && order.routeData && order.routeData.name;
    const isSelected = this.ordersViewActive()
      && selectedOrder
      && selectedOrder.logHeadVehicles
      && selectedOrder.logHeadVehicles
        .some(lhv => lhv.vehicleId.toLowerCase() === vehicle.username.toLowerCase());

    return (
      <VehicleMarker
        key={vehicle.username}
        username={vehicle.username}
        orderId={vehicle.orderId}
        position={position}
        routeName={routeName}
        updatedAt={vehicle.updatedAtLocal}
        isSelected={!!isSelected}
      />
    );
  }

  ordersViewActive() {
    const { location } = this.props;
    return location.pathname.startsWith(routes.orders.base);
  }

  componentDidMount() {
    this.props.vehicleTrackingActions.getVehicles();
  }

  render() {
    if (
      this.props.vehicleTracking == null
      || this.props.vehicleTracking.vehicles == null
    ) {
      return null;
    }

    var vehicleMarkers = this.props.vehicleTracking.vehicles
      .map(this.mapVehicleToMarker, this);

    return (
      <>
        <VehicleZoomManager />
        {vehicleMarkers}
      </>
    );
  }
}

VehicleTrackingLayer.propTypes = {
  vehicleTrackingActions: PropTypes.object.isRequired,
  vehicleTracking: PropTypes.object.isRequired,
  orders: PropTypes.array.isRequired,
  selectedOrder: PropTypes.object,
  location: PropTypes.object.isRequired,
}

export default VehicleTrackingLayer;
