import ServiceLists from "./ServiceLists";
import { bindActionCreators } from 'redux';
import { connect } from "react-redux";
import serviceOrderActions from "../../redux/modules/serviceOrders/serviceOrderActions";
import { createLoadingSelector } from '../../redux/modules/loading/loadingSelectors';
import serviceOrderConstants from '../../redux/modules/serviceOrders/serviceOrderConstants';

const isServiceListsLoading = createLoadingSelector(
  serviceOrderConstants.GET_SERVICELISTS,
  serviceOrderConstants.DELETE_SERVICE_LIST,
);

function mapStateToProps(state) {
  return {
    isLoading: isServiceListsLoading(state),
    serviceLists: state.serviceOrders.serviceLists,
    selectedListId: state.serviceOrders.selectedListId,
    dateFilterValue: state.serviceOrders.dateFilterValue,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    serviceOrderActions: bindActionCreators(serviceOrderActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceLists);
