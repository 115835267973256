import React from 'react';
import PropTypes from 'prop-types';
import PaneHeadline from '../shared/paneHeadline';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import routes from '../../common/routes';
import { locale } from '../../common/localization';
import ContainerOrderDetailsGrid from './ContainerOrderDetailsGrid';
import { getContainerOrderRouteName } from '../containerOrders/ContainerOrdersUtility';
import './container-order-details.scss';

function ContainerOrderDetails({ containerOrder, visibleFields }) {
  return (
    <div className="container-order-details-view">
      <PaneHeadline
        titleText={getContainerOrderRouteName(containerOrder)}
        titleIcon={faPhone}
        backUrl={routes.callordersPath}
        backTitle={locale.general._callOrder}
      />
      <ContainerOrderDetailsGrid
        containerOrder={containerOrder}
        visibleFields={visibleFields}
      />
    </div>
  );
}

ContainerOrderDetails.propTypes = {
  containerOrder: PropTypes.object,
  match: PropTypes.object.isRequired,
  visibleFields: PropTypes.array.isRequired,
}

export default ContainerOrderDetails;
