export enum AuthConstants {
  SET_IMPERSONATED_USER = 'SET_IMPERSONATED_USER',
  SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN',
  USERS_LOGIN_REQUEST = 'USERS_LOGIN_REQUEST',
  USERS_LOGIN_SUCCESS = 'USERS_LOGIN_SUCCESS',
  USERS_LOGIN_FAILURE = 'USERS_LOGIN_FAILURE',
  USERS_LOGOUT_REQUEST = 'USERS_LOGOUT_REQUEST',
  USERS_LOGOUT_SUCCESS = 'USERS_LOGOUT_SUCCESS',
  USERS_LOGOUT_FAILURE = 'USERS_LOGOUT_FAILURE',
}
