import api from './wasteManagementClient';
import { wasteManagementUrls as urls } from '../appConfig';
import { ICompanySettings, IDataButton } from '../models';
import companySettings from '../companySettings';

export abstract class SettingsService {
  public static async getDataButtons(
    ignoreCache: boolean = false
  ): Promise<Array<IDataButton>> {
    try {
      const params = { cache: { ignoreCache } };
      const response = await api.get(urls.dataButtons, params);
      return response.status === 200 ? response.data : [];
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  public static getCompanySettings(): Array<ICompanySettings> {
   const settings = companySettings;
    return settings || [];
  }

  public static async getVisibleFields(
    ignoreCache: boolean = false
  ): Promise<Array<string>> {
    try {
      const params = { cache: { ignoreCache } };
      const response = await api.get(urls.visibleFields, params);
      return response.status === 200 ? response.data : [];
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }
}
