import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  GridColumn as Column,
  GridNoRecords,
  GridColumnMenuFilter,
} from '@progress/kendo-react-grid';
import { locale } from '../../../common/localization';
import ColumnMenu from '../../shared/ColumnMenu';
import { process } from '@progress/kendo-data-query';
import ActionButtonCell from '../../shared/ActionButtonCell';
import { faArrowsAlt } from '@fortawesome/free-solid-svg-icons';

class WorklistGrid extends Component {
  constructor(props) {
    super(props);

    this.dataStateChange = this.dataStateChange.bind(this);
  }

  state = {
    dataState: {},
  }

  dataStateChange(event) {
    this.setState({ dataState: event.data });
  }

  getColumnProps(fieldName, columnMenu) {
    return {
      field: fieldName,
      title: locale.routeOptimalizationWizard.worklistResults['_' + fieldName],
      columnMenu: columnMenu || ColumnMenu,
      headerClassName: GridColumnMenuFilter.active(fieldName, this.state.dataState.filter) ? 'active' : '',
    };
  }

  processWorklist(worklist, dataState) {
    const extendedRoutes = worklist && worklist.map((item) => ({
      ...item,
      arrivalTime: item.arrivalTime && new Date(item.arrivalTime),
    }));

    return process(extendedRoutes, dataState);
  }

  MoveItemButton = (props) => (
    <ActionButtonCell {...props} icon={faArrowsAlt} action={this.props.moveItem} />
  );

  render() {
    const { worklist } = this.props;
    const { dataState } = this.state;

    const processedWorklist = this.processWorklist(worklist, dataState);

    return (
      <Grid
        {...dataState}
        data={processedWorklist}
        onDataStateChange={this.dataStateChange}
        sortable
        resizable
      >
        <GridNoRecords>
          {locale.routeOptimalizationWizard._worklistEmpty}
        </GridNoRecords>
        <Column {...this.getColumnProps('sequence')} width={80} />
        <Column {...this.getColumnProps('arrivalTime')} width={70} filter="date" format="{0:HH:mm}" />
        <Column {...this.getColumnProps('gln')} width={120} />
        <Column {...this.getColumnProps('customerName')} />
        <Column {...this.getColumnProps('description')} width={76} />
        <Column {...this.getColumnProps('serial')} width={120} />
        <Column width="40px" cell={this.MoveItemButton} />
      </Grid>
    );
  }
}

WorklistGrid.propTypes = {
  worklist: PropTypes.array.isRequired,
  moveItem: PropTypes.func.isRequired,
}

WorklistGrid.defaultProps = {
  worklist: [],
  moveItem: () => { },
}

export default WorklistGrid;