import * as yup from 'yup';
import moment from 'moment';

const validationMessages = {
  mixed: {
    required: '${path} er påkrevd',
  },
  string: {
    max: '${path} må være på maksimalt ${max} tegn',
    email: '${path} er ikke en gyldig e-postadresse!',
  },
  number: {
    max: '${path} må være mindre enn eller lik ${max}',
    min: '${path} må være større enn eller lik ${min}',
  },
  date: {
    max: ({ max, path }) =>
      `${path} må være senere enn ${moment(max).format('DD.MM.YYYY')}`,
    min: ({ min, path }) =>
      `${path} må være tidligere enn ${moment(min).format('DD.MM.YYYY')}`,
  },
};

yup.setLocale(validationMessages);

export default yup;
