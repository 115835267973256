const serviceOrderConstants = {
  GET_SERVICEORDERS: "GET_SERVICEORDERS",
  GET_SERVICEORDERS_SUCCESS: "GET_SERVICEORDERS_SUCCESS",
  GET_SERVICEORDERS_FAILURE: "GET_SERVICEORDERS_FAILURE",
  
  GET_SERVICELISTS: "GET_SERVICELISTS",
  GET_SERVICELISTS_LISTS_LOADED: "GET_SERVICELISTS_LISTS_LOADED",
  GET_SERVICELISTS_ORDERS_LOADED: "GET_SERVICELISTS_ORDERS_LOADED",
  GET_SERVICELISTS_FAILURE: "GET_SERVICELISTS_FAILURE",

  SET_SERVICEORDERS_DATE_RANGE_FILTER: "SET_SERVICEORDERS_DATE_RANGE_FILTER",

  SET_SELECTED_SERVICELIST: "SET_SELECTED_SERVICELIST",
  SET_SELECTED_SERVICEORDER: "SET_SELECTED_SERVICEORDER",

  DELETE_SERVICE_LIST: 'DELETE_SERVICE_LIST',
  DELETE_SERVICE_LIST_SUCCESS: 'DELETE_SERVICE_LIST_SUCCESS',
  DELETE_SERVICE_LIST_FAILURE: 'DELETE_SERVICE_LIST_FAILURE',

  DELETE_SERVICE_LIST_ITEM: 'DELETE_SERVICE_LIST_ITEM',
  DELETE_SERVICE_LIST_ITEM_SUCCESS: 'DELETE_SERVICE_LIST_ITEM_SUCCESS',
  DELETE_SERVICE_LIST_ITEM_FAILURE: 'DELETE_SERVICE_LIST_ITEM_FAILURE',
}

export default serviceOrderConstants;
