import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteAdminMenu } from './RouteAdminMenu';
import { IRootState } from '../../redux/state';
import {
  getMarkedRouteSequences,
  getEditMode,
  getMarkedRouteSequenceIds,
  getPois,
} from '../../redux/modules/route/routeSelectors';
import {
  reloadRoutes,
  markRouteSequences,
  setRouteEditMode,
  mergePoints,
  changePosition,
  moveRouteSequences,
  splitPoints,
} from '../../redux/modules/route/routeCreators';

function mapStateToProps(state: IRootState) {
  return {
    markedRouteSequences: getMarkedRouteSequences(state),
    markedRouteSequenceIds: getMarkedRouteSequenceIds(state),
    mode: getEditMode(state),
    location: state.router.location,
    pois: getMarkedRouteSequences(state),
    allPois: getPois(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      setMode: setRouteEditMode,
      splitPoints: splitPoints,
      mergePoints: mergePoints,
      markRouteSequences,
      reloadRoutes,
      onSubmit: changePosition,
      onRestore: reloadRoutes,
      moveRouteSequences,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(RouteAdminMenu);
