import React from 'react';
import PropTypes from 'prop-types';
import { locale } from '../../common/localization';
import Select from 'react-select';
import memoize from 'memoize-one';

function getUserOptions(users) {
  return users.map((user) => ({
    label: user.userName,
    value: user.userName,
  }));
}

function getCustomerName(customers, customerId) {
  const customer = customers.find(c => c.customerId === customerId);
  return customer
    ? customer.name
    : customerId;
}

function getGroupedUserOptions(usersByCustomerId, customers) {
  if (!usersByCustomerId) {
    return [];
  }

  return Object.entries(usersByCustomerId)
    .map(([customerId, users]) => ({
      label: getCustomerName(customers, Number(customerId)),
      options: getUserOptions(users),
    }))
}

const getGroupedUserOptionsMemoized = memoize(getGroupedUserOptions);

function UserSelector({
  selectedUser,
  handleChange,
  users,
  isLoading,
  customers,
  isDisabled,
}) {
  const userOptions = getGroupedUserOptionsMemoized(users, customers);
  const selectedOption = selectedUser && { label: selectedUser, value: selectedUser };

  return (
    <Select
      options={userOptions}
      isClearable={true}
      onChange={handleChange}
      value={selectedOption}
      noOptionsMessage={() => locale.impersonationManager._noOptionsMessage}
      isLoading={isLoading}
      isDisabled={isDisabled}
      placeholder={locale.impersonationManager._selectUserPlaceholder}
    />
  );
}

UserSelector.propTypes = {
  selectedUser: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  users: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  customers: PropTypes.array.isRequired,
  isDisabled: PropTypes.bool.isRequired,
}

export default UserSelector;
