import React, { Component, ReactNode } from 'react';
import { clamp } from '../shared/mathUtility';
import './PoiCaption.scss';

export interface IPoiCaptionProps {
  color: string;
  borderColor: string;
  zoomLevel: number;
  scale?: number;
  text: string;
}

export class PoiCaption extends Component<IPoiCaptionProps> {
  private static readonly collapseLevel = 12;

  public getSize(): number {
    const { zoomLevel } = this.props;
    const diameter = 20 - (17 - zoomLevel);
    const clampedDiameter = clamp(diameter, 6, 20);

    return clampedDiameter;
  }

  public render(): ReactNode {
    const { color, zoomLevel, text, borderColor, scale = 1 } = this.props;
    const size = this.getSize();
    const fontSize = size / 2;
    const width = scale * size;
    const height = scale * size;
    const caption = zoomLevel < PoiCaption.collapseLevel ? '' : text;

    return (
      <span
        className="fa-layers-text poi-caption"
        style={{ color, fontSize, borderColor, width, height }}
      >
        {caption}
      </span>
    );
  }
}
