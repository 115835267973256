import React, { Component, ReactNode } from 'react';
import { DetailsTooltip } from '../shared/customMapLayer';
import moment from 'moment';
import {
  faHome,
  faClock,
  faBuilding,
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';
import { IRouteDeviation } from '../../models';

export interface IRouteStopTooltipProps {
  companyName: string;
  address: string;
  time: Date;
  deviations: Array<IRouteDeviation>;
}

export class RouteStopTooltip extends Component<IRouteStopTooltipProps> {
  public formatDeviations(deviations: Array<IRouteDeviation>): string {
    return deviations && deviations.length > 0 ? deviations.join(', ') : null;
  }

  public formatTime(time: Date): string {
    if (!time) return '';
    return moment(time, 'YYYY-MM-DDThh:mm:ss.SSS').format('HH:mm:ss');
  }

  public render(): ReactNode {
    const { time, companyName, address, deviations } = this.props;
    const formattedTime = this.formatTime(time);

    return (
      <DetailsTooltip
        details={[
          { icon: faBuilding, value: companyName },
          { icon: faHome, value: address },
          { icon: faClock, value: formattedTime },
          {
            icon: faExclamationCircle,
            value: this.formatDeviations(deviations),
          },
        ]}
      />
    );
  }
}
