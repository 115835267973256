import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { SequenceModal } from './SequenceModal';
import { IRootState } from '../../redux/state';
import {
  getIsChangeSequenceLoading,
  getMarkedRouteSequences,
  getValidationResults,
} from '../../redux/modules/route/routeSelectors';
import {
  changeSequence,
  validateSequence,
  clearValidationResult,
} from '../../redux/modules/route/routeCreators';
import { first as _first } from 'lodash';

function mapStateToProps(state: IRootState) {
  return {
    /** for now there can be only one */
    poi: _first(getMarkedRouteSequences(state)),
    isLoading: getIsChangeSequenceLoading(state),
    isAsyncValidated: !!getValidationResults(state)?.length,
    validationsResults: getValidationResults(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      onSubmit: changeSequence,
      onValidate: validateSequence,
      reset: clearValidationResult,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SequenceModal);
