import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import cacheRefresherActions from '../../redux/modules/cacheRefresher/cacheRefresherActions';
import * as orderActions from '../../redux/modules/order/orderActions';
import * as vehicleTrackingActions from '../../redux/modules/vehicleTracking/vehicleTrackingActions';
import serviceOrderActions from '../../redux/modules/serviceOrders/serviceOrderActions';
import containerOrderActions from '../../redux/modules/containerOrder/containerOrderActions';
import { loadTrackingLines } from '../../redux/modules/trackingLine/trackingLineCreators';
import { loadTrackInfos } from '../../redux/modules/trackInfo/trackInfoCreators';
import CacheRefresher from './CacheRefresher';

function mapStateToProps(state) {
  return {
    filter: state.cacheRefresher.filter,
    countdownEnd: state.cacheRefresher.countdownEnd,
    isLoading: state.cacheRefresher.requests.length !== 0,
    order: state.order.selected,
    companySettings:
      (state.settings.companySettings &&
        state.settings.companySettings.find(
          (settings) =>
            settings.companyId === Number(state.authentication.customerId)
        )) ||
      null,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    orderActions: bindActionCreators(orderActions, dispatch),
    vehicleTrackingActions: bindActionCreators(
      vehicleTrackingActions,
      dispatch
    ),
    cacheRefresherActions: bindActionCreators(cacheRefresherActions, dispatch),
    serviceOrderActions: bindActionCreators(serviceOrderActions, dispatch),
    containerOrderActions: bindActionCreators(containerOrderActions, dispatch),
    loadTrackingLines: bindActionCreators(loadTrackingLines, dispatch),
    loadTrackInfos: bindActionCreators(loadTrackInfos, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CacheRefresher);
