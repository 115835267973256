import { IRoutePoi } from '../../models';

export type DataItem = {
  field: string;
  value: string;
  items: Array<DataItem | IRoutePoi>;
};

export function isDataItem(item: DataItem | IRoutePoi): item is DataItem {
  return (
    Object.prototype.hasOwnProperty.call(item, 'field') &&
    Object.prototype.hasOwnProperty.call(item, 'value')
  );
}

export function getPois(item: DataItem | IRoutePoi): Array<IRoutePoi> {
  if (isDataItem(item)) {
    return item.items.reduce((memo, item) => [...memo, ...getPois(item)], []);
  }
  return [item];
}
