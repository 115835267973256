import React, { ReactElement, useRef, useEffect } from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ReactToggleButton from 'react-toggle-button';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IToggleButtonProps {
  onToggle: (e: React.FormEvent<HTMLInputElement>) => void;
  value: boolean;
}

const ToggleButton = (props: IToggleButtonProps): ReactElement => {
  const { onToggle, value, ...restProps } = props;
  const myRef = useRef<any>(null);

  useEffect(() => {
    if (myRef.current) {
      const htmlEl = myRef.current as HTMLInputElement;
      const hasDNoneClass = htmlEl.classList.contains('d-none');
      if (!hasDNoneClass) {
        htmlEl.classList.add('d-none');
      }
    }
  });

  return (
    <>
      <ReactToggleButton
        value={value}
        onToggle={() => {
          myRef.current.click();
        }}
        activeLabel={<FontAwesomeIcon icon={faCheck} className="icon-place" />}
        inactiveLabel={
          <FontAwesomeIcon icon={faTimes} className="icon-place" />
        }
        trackStyle={{
          height: '22px',
        }}
        colors={{
          active: {
            base: '#007bff',
          },
        }}
        {...restProps}
      />
      <input type="checkbox" checked={value} onChange={onToggle} ref={myRef} />
    </>
  );
};

export default ToggleButton;
