import React from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Col } from 'react-bootstrap';
import { locale } from '../../common/localization';
import { useFormikContext } from 'formik';

export default function UserTextField({ name, placeholderValue, ...props }) {
  const {
    values,
    handleChange,
    touched,
    errors,
    handleBlur,
  } = useFormikContext();

  return (
    <Form.Group as={Row}>
      <Col sm={12}>
        <Form.Label>{locale.userAdmin['_' + name]}</Form.Label>
      </Col>
      <Col sm={12}>
        <Form.Control
          type="text"
          placeholder={placeholderValue ? placeholderValue : locale.userAdmin['_' + name]}
          value={values[name]}
          name={name}
          onChange={handleChange}
          isInvalid={touched[name] && !!errors[name]}
          onBlur={handleBlur}
          {...props}
        />
        <Form.Control.Feedback type="invalid">
          {errors[name]}
        </Form.Control.Feedback>
      </Col>
    </Form.Group>
  );
}

UserTextField.propTypes = {
  name: PropTypes.string.isRequired,
}
