import { Action, combineReducers } from 'redux';
import { authentication } from './modules/auth/authReducer';
import { geodatatoken } from './modules/geodata/geodataReducer';
import { order } from './modules/order/orderReducer';
import orderDetails from './modules/orderDetails/orderDetailsReducer';
import { settings } from './modules/settings/settingsReducer';
import { vehicleTracking } from './modules/vehicleTracking/vehicleTrackingReducer';
import cacheRefresher from './modules/cacheRefresher/cacheRefresherReducer';
import serviceOrders from './modules/serviceOrders/serviceOrderReducer';
import containerOrder from './modules/containerOrder/containerOrderReducer';
import loading from './modules/loading/loadingReducer';
import users from './modules/users/userReducer';
import customer from './modules/customer/customerReducer';
import module from './modules/module/moduleReducer';
import contractor from './modules/contractor/contractorReducer';
import agreement from './modules/agreement/agreementReducer';
import depot from './modules/depot/depotReducer';
import routeOptimalization from './modules/routeOptimalization/routeOptimalizationReducer';
import { route } from './modules/route/routeReducer';
import { trackingLine } from './modules/trackingLine/trackingLineReducer';
import { trackInfo } from './modules/trackInfo/trackInfoReducer';
import { vehicles } from './modules/vehicles/vehiclesReducer';
import { map } from './modules/map/mapReducer';
import { AuthConstants } from './modules/auth/authConstants';
import { IRootState } from './state';
import { deviation } from './modules/deviaton/deviationReducer';

const appReducer = combineReducers({
  authentication,
  geodatatoken,
  order,
  vehicleTracking,
  orderDetails,
  settings,
  cacheRefresher,
  serviceOrders,
  containerOrder,
  loading,
  users,
  customer,
  module,
  contractor,
  agreement,
  depot,
  routeOptimalization,
  map,
  route,
  trackingLine,
  trackInfo,
  vehicles,
  deviation,
});

export const initialState: IRootState = {} as IRootState;

const rootReducer = (state: any = initialState, action: Action): any => {
  if (
    action.type === AuthConstants.USERS_LOGOUT_SUCCESS ||
    action.type === AuthConstants.USERS_LOGOUT_FAILURE
  ) {
    return appReducer(initialState as any, action);
  }
  if (action.type === AuthConstants.SET_IMPERSONATED_USER) {
    return appReducer(
      { ...initialState, authentication: state.authentication } as any,
      action
    );
  }
  return appReducer(state, action);
};

export default rootReducer;
