import React from 'react';
import PropTypes from 'prop-types';
import {MapUtility} from '../../map/MapUtility';
import RoutePointMarker from './RoutePointMarker';
import routes from '../../../common/routes';
import { ClusteredMapLayer } from '../../shared/customMapLayer';
import ImportedRouteZoomManager from './ImportedRouteZoomManager';
import { selectedRegion } from '../../../appConfig';

function mapRouteToMarker(route, zoomLevel) {
  const position = [route.latitude, route.longitude];
  if (
    !route
    || !MapUtility.isInRegion(route.latitude, route.longitude, selectedRegion)
  ) {
    return null;
  }

  return (
    <RoutePointMarker
      key={route.gln}
      id={route.gln}
      name={route.customerName}
      zoomLevel={zoomLevel}
      position={position}
    />
  );
}

function routeOptimalizationViewActive(location) {
  return location.pathname.startsWith(routes.routeOptimalizationWizardPath);
}

function getMarkers(importedRoutes, zoomLevel) {
  if (!importedRoutes) {
    return null;
  }

  return importedRoutes
    .map((route) => mapRouteToMarker(route, zoomLevel));
}

function ImportedRoutesLayer({
  clusteringActive,
  importedRoutes,
  zoomLevel,
  location,
  activeTab,
  unassignedStops,
}) {
  let markers = null;
  if (routeOptimalizationViewActive(location)) {
    if (activeTab === 0) {
      markers = getMarkers(importedRoutes, zoomLevel);
    } else if (activeTab === 2) {
      markers = getMarkers(unassignedStops, zoomLevel);
    }
  }

  return (
    <ClusteredMapLayer isClusteringActive={clusteringActive}>
      <ImportedRouteZoomManager />
      {markers}
    </ClusteredMapLayer>
  );
}

ImportedRoutesLayer.propTypes = {
  importedRoutes: PropTypes.array,
  clusteringActive: PropTypes.bool,
  zoomLevel: PropTypes.number.isRequired,
  location: PropTypes.object.isRequired,
  activeTab: PropTypes.number.isRequired,
  unassignedStops: PropTypes.array.isRequired,
};

export default ImportedRoutesLayer;
