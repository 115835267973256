import React from 'react';
import PropTypes from 'prop-types';
import AnimatedNumber from '../shared/AnimatedNumber';
import {
  Chart,
  ChartArea,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartTooltip,
} from '@progress/kendo-react-charts';
import './orderDetailsDiagram.scss';
import { Scrollbars } from 'react-custom-scrollbars';
import classNames from 'classnames';
import { locale } from '../../common/localization';

const placeholderDataSeries = [
  {
    category: '',
    value: 1,
    color: '#5c6670',
  },
];

function renderPieTooltips({ point }) {
  if (point) {
    return (
      <span>
        {point.category}: {point.value}
      </span>
    );
  }
}

function LegendItem({ text, color }) {
  return (
    <span className="legend-item">
      <span className="legend-marker" style={{ background: color }}></span>
      <span>{text}</span>
    </span>
  );
}

LegendItem.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

function renderChart(deviations, totalDeviations) {
  let chartData;
  if (deviations && deviations.length > 0) {
    chartData = deviations;
  } else {
    chartData = placeholderDataSeries;
  }
  const totalDeviationsLength = totalDeviations.toString().length;
  let animatedNumberSize = 'normal-size';
  if (totalDeviationsLength > 4) {
    animatedNumberSize = 'medium-small-size';
  } else if (totalDeviationsLength > 6) {
    animatedNumberSize = 'small-size';
  }
  return (
    <Chart
      donutCenterRender={() => (
        <span className={'donut-hole-text ' + animatedNumberSize}>
          <AnimatedNumber number={totalDeviations} />
        </span>
      )}
    >
      <ChartArea width="110px" height="110px" />
      <ChartLegend visible={false} />
      <ChartTooltip render={renderPieTooltips} visible={totalDeviations > 0} />
      <ChartSeries className={'text-size'}>
        <ChartSeriesItem
          type="donut"
          data={chartData}
          field="value"
          categoryField="category"
          padding={2}
          holeSize={30}
        />
      </ChartSeries>
    </Chart>
  );
}

function renderLegend(deviations) {
  const legendClassName = classNames(
    'deviations-legend-list',
    [`item-count-${deviations.length}`],
    { longer: deviations.length > 5 }
  );

  return (
    <Scrollbars>
      <div className={legendClassName}>
        {deviations &&
          deviations.map((deviation) => (
            <LegendItem
              key={deviation.category}
              text={deviation.category}
              color={deviation.color}
            />
          ))}
      </div>
    </Scrollbars>
  );
}

function renderNoDeviationsMessage() {
  return (
    <div className="no-deviations-message">
      <span>{locale.orderDetailsDiagram._noDeviations}</span>
    </div>
  );
}

function DeviationsChart({ totalDeviations, deviations }) {
  return (
    <div className="row deviations-chart-row">
      <div className="col flex-grow-0">
        {renderChart(deviations, totalDeviations)}
      </div>
      <div className="col">
        {totalDeviations > 0
          ? renderLegend(deviations, totalDeviations)
          : renderNoDeviationsMessage()}
      </div>
    </div>
  );
}

DeviationsChart.propTypes = {
  deviations: PropTypes.array.isRequired,
  totalDeviations: PropTypes.number.isRequired,
};

export default DeviationsChart;
