import React from 'react';
import PropTypes from 'prop-types';

export default function DetailCell({ label, value, children }) {
  return (
    <div className="cell">
      <div className="label">{label}</div>
      <div className="value">
        {value}
        {children}
      </div>
    </div>
  );
}

DetailCell.propTypes = {
  label: PropTypes.any,
  value: PropTypes.any,
  children: PropTypes.any,
};
