import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  GridColumn as Column,
  GridColumnMenuFilter,
  GridNoRecords,
} from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import { locale } from '../../common/localization';
import ColumnMenu from '../shared/ColumnMenu';
import InfoButtonCell from '../shared/infoButtonCell/InfoButtonCell';
import ExecutedCell from './ExecutedCell';
import { groupAndProcessServiceOrders } from '../shared/serviceOrderUtility';
import OrderItemTypeCell from './OrderItemTypeCell';
import DeviationCell from './DeviationCell';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { GridPDFExport } from '@progress/kendo-react-pdf';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import ActionButtonCell from '../shared/ActionButtonCell';

function getFileName(serviceOrders) {
  return serviceOrders
    && serviceOrders[0]
    && serviceOrders[0].listName
    || '';
}

class ServiceOrdersGrid extends Component {
  constructor(props) {
    super(props);

    this.dataStateChange = this.dataStateChange.bind(this);
    this.InfoButtonCell = this.InfoButtonCell.bind(this);
    this.OrderItemTypeCell = this.OrderItemTypeCell.bind(this);
    this.DeviationCell = this.DeviationCell.bind(this);
  }

  static propTypes = {
    currentUrl: PropTypes.string.isRequired,
    serviceOrders: PropTypes.array,
    selectedOrderId: PropTypes.number,
    onRowClick: PropTypes.func.isRequired,
    deleteItem: PropTypes.func.isRequired,
  }

  state = {
    dataState: {
      sort: [{ field: 'updated', dir: 'desc' }],
    }
  }

  dataStateChange(event) {
    this.setState({ dataState: event.data });
  }

  columnProps(field) {
    return {
      field: field,
      columnMenu: ColumnMenu,
      headerClassName: this.isColumnActive(field, this.state.dataState) ? 'active' : '',
      title: locale.serviceOrdersGrid['_' + field],
    };
  }

  isColumnActive(field, dataState) {
    return GridColumnMenuFilter.active(field, dataState.filter);
  }

  InfoButtonCell(gridProps) {
    const { currentUrl } = this.props;

    return <InfoButtonCell
      linkToUrl={`${currentUrl}/${gridProps.dataItem.listItemId}`}
      {...gridProps}
    />;
  }

  OrderItemTypeCell(props) {
    return <OrderItemTypeCell onClick={this.props.onRowClick} {...props} />;
  }

  DeviationCell(props) {
    return <DeviationCell onClick={this.onRowClick} {...props} />;
  }

  static processOrders(serviceOrders, selectedOrderId, dataState) {
    if (!serviceOrders) {
      return [];
    }

    const groupedOrders = groupAndProcessServiceOrders(serviceOrders);
    const extendedOrders = groupedOrders.map((order) => ({
      ...order,
      selected: order.listItemId === selectedOrderId,
      estimatedArrivalTime: new Date(order.estimatedArrivalTime),
    }));

    return process(extendedOrders, dataState);
  }

  excelExport() {
    this.excelExporter.save();
  }

  pdfExport() {
    const { serviceOrders, selectedOrderId } = this.props;
    const result = ServiceOrdersGrid.processOrders(serviceOrders, selectedOrderId, this.state.dataState);
    this.pdfExporter.save(result.data);
  }

  DeleteButtonCell = (props) => (
    <ActionButtonCell {...props} icon={faTrash} action={this.props.deleteItem} />
  );

  render() {
    const { serviceOrders, onRowClick, selectedOrderId } = this.props;
    const result = ServiceOrdersGrid.processOrders(serviceOrders, selectedOrderId, this.state.dataState);

    const grid = (
      <Grid
        {...this.state.dataState}
        className="mb-3"
        data={result}
        scrollable={"none"}
        sortable
        onDataStateChange={this.dataStateChange}
        onRowClick={onRowClick}
        selectedField="selected"
      >
        <GridNoRecords>
          {locale.serviceOrders._noOrders}
        </GridNoRecords>
        <Column {...this.columnProps('sequence')} width={80} />
        <Column {...this.columnProps('externalOrderId')} width={100} />
        <Column {...this.columnProps('agreementCompanyName')} />
        <Column {...this.columnProps('address')} />
        <Column {...this.columnProps('estimatedArrivalTime')} width={80} filter="date" format="{0:HH:mm}" />
        <Column {...this.columnProps('executed')} width={70} cell={ExecutedCell} filter="boolean" />
        <Column {...this.columnProps('deviation')} width={70} cell={this.DeviationCell} filter="boolean" />
        <Column width="40px" cell={this.InfoButtonCell} />
        <Column width="40px" cell={this.DeleteButtonCell} />
      </Grid>
    );

    return (
      <div>
        <GridPDFExport
          fileName={getFileName(serviceOrders)}
          ref={(ref) => this.pdfExporter = ref}
        >
          <Column {...this.columnProps('sequence')} width={80} />
          <Column {...this.columnProps('externalOrderId')} width={100} />
          <Column {...this.columnProps('agreementCompanyName')} />
          <Column {...this.columnProps('address')} />
          <Column {...this.columnProps('estimatedArrivalTime')} width={80} filter="date" format="{0:HH:mm}" />
          {grid}
        </GridPDFExport>
        <ExcelExport
          data={result.data}
          fileName={getFileName(serviceOrders)}
          ref={(ref) => this.excelExporter = ref}
        >
          <ExcelExportColumn {...this.columnProps('sequence')} width={80} />
          <ExcelExportColumn {...this.columnProps('externalOrderId')} width={100} />
          <ExcelExportColumn {...this.columnProps('agreementCompanyName')} />
          <ExcelExportColumn {...this.columnProps('address')} />
          <ExcelExportColumn {...this.columnProps('estimatedArrivalTime')} width={80} filter="date" format="{0:HH:mm}" />
        </ExcelExport>
        {grid}
      </div>
    );
  }
}

export default ServiceOrdersGrid;
