import { Dispatch } from 'redux';
import { IDeviation, IDeviationCategory } from '../../../models/Deviation';
import { DeviationConstants } from './deviationConstants';
import * as Actions from './deviatonActions';
import DeviationsService from '../../../services/deviationsService';

export function createDeviationListRequest(): Actions.GetDeviations {
  return {
    type: DeviationConstants.GET_DEVIATIONS_REQUEST,
  };
}

export function createDeviationListSuccess(
  payload: Array<IDeviation>
): Actions.GetDeviations.Success {
  return {
    type: DeviationConstants.GET_DEVIATIONS_SUCCESS,
    payload,
  };
}

export function createDeviationListFailure(
  error: Error
): Actions.GetDeviations.Failure {
  return {
    type: DeviationConstants.GET_DEVIATIONS_FAILURE,
    error,
  };
}

export function createDeviationCategoryListRequest(): Actions.GetDeviationCategories {
  return {
    type: DeviationConstants.GET_DEVIATIONS_CATEGORY_REQUEST,
  };
}

export function createDeviationCategoryListListSuccess(
  payload: Array<IDeviationCategory>
): Actions.GetDeviationCategories.Success {
  return {
    type: DeviationConstants.GET_DEVIATIONS_CATEGORY_SUCCESS,
    payload,
  };
}

export function createDeviationCategoryListListFailure(
  error: Error
): Actions.GetDeviationCategories.Failure {
  return {
    type: DeviationConstants.GET_DEVIATIONS_CATEGORY_FAILURE,
    error,
  };
}

export function loadDeviations(
  customerId: number,
  ignoreCache = false
): (dispatch: Dispatch<Actions.All>) => Promise<void> {
  return async function (dispatch: Dispatch<Actions.All>): Promise<void> {
    dispatch(createDeviationListRequest());
    try {
      const result: Array<IDeviation> = await DeviationsService.getDeviations(
        customerId,
        ignoreCache
      );
      dispatch(createDeviationListSuccess(result));
    } catch (error) {
      dispatch(createDeviationListFailure(error));
    }
  };
}

export function loadDeviationCategories(
  customerId: number,
  ignoreCache = false
): (dispatch: Dispatch<Actions.All>) => Promise<void> {
  return async function (dispatch: Dispatch<Actions.All>): Promise<void> {
    dispatch(createDeviationCategoryListRequest());
    try {
      const result: Array<IDeviationCategory> =
        await DeviationsService.getDeviationCategories(customerId, ignoreCache);
      dispatch(createDeviationCategoryListListSuccess(result));
    } catch (error) {
      dispatch(createDeviationCategoryListListFailure(error));
    }
  };
}
