import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './spinner.scss';

/** @deprecated use Spinner2 instead! */
function Spinner({ delay }) {
  const [show, setShow] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true);
    }, delay);
    return () => clearTimeout(timer);
  }, []);

  if (!show) return null;
  return (
    <div className="spinner-wrapper">
      <div className="spinner text-center">
        <div className="cube1"></div>
        <div className="cube2"></div>
      </div>
    </div>
  )
}

Spinner.propTypes = {
  delay: PropTypes.number,
}

Spinner.defaultProps = {
  delay: 300,
}

export default Spinner;
