import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import {
  loadTrackingLines,
} from '../../redux/modules/trackingLine/trackingLineCreators';
import TrackingLineLayer from './TrackingLineLayer';
import { IRootState } from '../../redux/state';
import {
  getTrackingLines,
} from '../../redux/modules/trackingLine/trackingLineSelectors';

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      loadTrackingLines,
    },
    dispatch
  );
}

function mapStateToProps(state: IRootState) {
  return {
    trackingLines: getTrackingLines(state),
    location: state.router.location,
    order: state.order.selected,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TrackingLineLayer);
