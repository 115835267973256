import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import agreementActions from '../../../redux/modules/agreement/agreementActions';
import ImportManager from './ImportManager';

function mapDispatchToProps(dispatch) {
  return {
    agreementActions: bindActionCreators(agreementActions, dispatch),
  }
}

export default connect(null, mapDispatchToProps, null, { withRef: true })(ImportManager);
