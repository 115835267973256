import React from 'react';
import Select from 'react-select';
import memoizeOne from 'memoize-one';
import { IVehicle } from '../../models';
import { locale } from '../../common/localization';

export interface IVehicleSelectorProps {
  vehicles: Array<IVehicle>;
  handleChange: (...args: any) => any;
  [key: string]: any;
}

export interface IVehicleOption {
  label: string;
  value: string;
}

export const getVehicleOptions = (
  vehicles: Array<IVehicle>
): Array<IVehicleOption> => {
  return (
    (vehicles &&
      vehicles.map((v) => ({
        label: v.vehicleId,
        value: v.vehicleId,
      }))) ||
    []
  );
};

export const getCustomCategoryOptionsMemoized = memoizeOne(getVehicleOptions);

const VehicleSelector = (props: IVehicleSelectorProps): React.ReactElement => {
  const { vehicles, handleChange, ...restProps } = props;
  const options = getCustomCategoryOptionsMemoized(vehicles);
  return (
    <div className="form-group row">
      <div className="col-sm-12">
        <label className="form-label">
          {locale.customerRegister.message._vehicle}
        </label>
      </div>
      <Select
        options={options}
        onChange={handleChange}
        placeholder={locale.customerRegister.message._selectVehicle}
        noOptionsMessage={() => locale.customerRegister.message._noVehicle}
        {...restProps}
      />
    </div>
  );
};

export default VehicleSelector;
