import React from "react";
import { GridColumn as Column } from '@progress/kendo-react-grid';
import { average, clamp } from "../shared/mathUtility";

const averageCharacterWidth = 7.3;
const cellPadding = 10;
const headerPadding = 18;
const maxColumnWidth = 300;
const minTableWidth = 670;
const dateColumnProperties = {
  filter: 'date',
  format: '{0:dd.MM HH:mm}',
};

function capitalize(s) {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

function getCharacterCount(value) {
  return String(value).length;
}

function getNonEmptyEntries(object) {
  return Object.entries(object)
    .filter(([, value]) => !!value);
}

function getColumnStatistics(data) {
  const statistics = {};

  for (const row of data) {
    for (const [col, value] of getNonEmptyEntries(row)) {
      let colStats = statistics[col];
      if (!colStats) {
        colStats = {
          characterCounts: [],
          isDate: false,
        }
      }

      colStats.isDate = value instanceof Date;
      if (!colStats.isDate) {
        colStats.characterCounts.push(getCharacterCount(value));
      }

      statistics[col] = colStats;
    }
  }

  return statistics;
}

function getColumnWidth(columnStatistics) {
  if (columnStatistics.isDate) {
    return 90;
  }

  const averageCharacterCount = average(columnStatistics.characterCounts);
  const averageWidth = averageCharacterCount * averageCharacterWidth;

  return averageWidth;
}

function getColumnProperties(columnStatistics) {
  const result = {};

  for (const col in columnStatistics) {
    const statistics = columnStatistics[col];
    const proposedWidth = getColumnWidth(statistics) + cellPadding;
    const minColumnWidth = col.length * averageCharacterWidth + headerPadding;

    result[col] = {
      isDate: statistics.isDate,
      width: clamp(proposedWidth, minColumnWidth, maxColumnWidth),
    }
  }

  return result;
}

function getTableWidth(columnProperties) {
  return Object.values(columnProperties)
    .reduce((total, current) => total + current.width, 0);
}

function setFlexibleColumn(columnProperties) {
  const tableWidth = getTableWidth(columnProperties);
  if (tableWidth > minTableWidth) {
    return;
  }

  let max = '';
  for (const key in columnProperties) {
    if (
      !max
      || columnProperties[key].width > columnProperties[max].width
    ) {
      max = key;
    }
  }

  columnProperties[max].isFlexible = true;
}

function createColumn([name, statistics]) {
  const properties = {
    width: statistics.width,
  };
  
  if (name === 'routeName') delete properties.width;

  if (statistics.isDate) {
    Object.assign(properties, dateColumnProperties);
  }

  if (statistics.isFlexible) {
    properties.width = undefined;
  }

  return (
    <Column
      key={name}
      field={name}
      title={capitalize(name)}
      {...properties}
    />
  );
}

function ReportColumns({ report }) {
  if (report.length === 0) {
    return null;
  }

  const columnStatistics = getColumnStatistics(report);
  const columnProperties = getColumnProperties(columnStatistics);
  setFlexibleColumn(columnProperties);

  return Object.entries(columnProperties)
    .map(createColumn);
}

export default ReportColumns;
