import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { login, loginWithAuth0 } from '../../redux/modules/auth/authCreators';
import { IRootState } from '../../redux/state';

import  Login from './Login';

function mapStateToProps(state: IRootState) {
  const { isLoading, token, errorMessage } = state.authentication;
  return {
    isLoading,
    token,
    errorMessage,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    login: bindActionCreators(loginWithAuth0, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
