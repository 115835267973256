import React from 'react';
import { Modal, Button, Alert } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { locale } from '../../../common/localization';

function FileResultsModal({ onClose, errors, show }) {
  return (
    <Modal onHide={onClose} show={show} centered>
      <Modal.Header closeButton >
        <Modal.Title>
          {locale.routeOptimalizationWizard.importManager._fileLoadResultsModalTitle}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!errors || errors.length === 0
          ? locale.routeOptimalizationWizard.importManager._fileLoadSuccess
          : renderErrorList(errors)
        }
      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary" onClick={onClose}>
          {locale.routeOptimalizationWizard.importManager._fileLoadModalCloseButton}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function renderErrorList(errors) {
  return errors.map((error, i) => (
    <Alert variant="danger" key={i}>
      {error}
    </Alert>
  ))
}

FileResultsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  errors: PropTypes.array,
  show: PropTypes.bool,
}

FileResultsModal.defaultProps = {
  onClose: () => { },
}

export default FileResultsModal;
