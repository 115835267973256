import React, { Component, ReactNode } from 'react';
import { RouteEditButtonRole } from '../../models';

export interface IRouteAdminButtonProps {
  label: string;
  title: string;
  disabled?: boolean;
  onClick: () => Promise<void>;
  role?: RouteEditButtonRole;
  placeholder?: boolean;
}

export class RouteAdminButton extends Component<IRouteAdminButtonProps> {
  private buttonRef = React.createRef<HTMLButtonElement>();

  private getElementClasses(): Array<string> {
    const baseClasses = ['button-container'];
    const elementClasses: Array<string> = [...baseClasses];

    if (
      this.props.role === RouteEditButtonRole.Submit ||
      this.props.role === RouteEditButtonRole.Cancel
    ) {
      elementClasses.push('btn-change-pos-sub');
    }
    if (this.props.role === RouteEditButtonRole.Submit) {
      elementClasses.push('submit');
    }
    if (this.props.role === RouteEditButtonRole.Cancel) {
      elementClasses.push('cancel');
    }
    if (this.props.placeholder) {
      elementClasses.push('placeholder');
    }

    return elementClasses;
  }

  private addClassToElTemporary(
    el: HTMLButtonElement,
    className: string,
    intervalMs: number = 0
  ): Promise<void> {
    return new Promise<void>((resolve) => {
      if (!el || !className) resolve();
      if (intervalMs === 0) {
        el.classList.add(className);
        resolve();
      } else {
        el.classList.add(className);
        setTimeout(() => {
          el.classList.remove(className);
          resolve();
        }, intervalMs);
      }
    });
  }

  private async handleClick(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    cb: <T>(arg?: T) => Promise<void>
  ): Promise<void> {
    e.preventDefault();
    try {
      const buttonEl = this.buttonRef.current;

      if (this.props.role === RouteEditButtonRole.Submit && buttonEl) {
        await this.addClassToElTemporary(buttonEl, 'success', 1000);
      }

      cb();
    } catch (error) {
      console.error(error ?? '');
      cb();
    }
  }

  public render(): ReactNode {
    const elementClasses: Array<string> = this.getElementClasses();

    return (
      <button
        className={elementClasses.join(' ')}
        title={this.props.title}
        onClick={(e) => this.handleClick(e, this.props.onClick)}
        disabled={this.props.disabled}
        ref={this.buttonRef}
      >
        {this.props.label}
      </button>
    );
  }
}
