import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import classNames from 'classnames';

export default function ErrorMessage(props) {
  const { name } = props;
  const { errors, touched } = useFormikContext();

  const visible = !!touched[name] && !!errors[name];
  const className = classNames({ visible });

  return (
    <Form.Control.Feedback type="invalid" className={className} {...props}>
      {errors[name]}
    </Form.Control.Feedback>
  );
}

ErrorMessage.propTypes = {
  name: PropTypes.string.isRequired,
}

ErrorMessage.defaultProps = {
  name: '',
}
