import ServiceOrderDetails from "./ServiceOrderDetails";
import { bindActionCreators } from 'redux';
import { connect } from "react-redux";
import serviceOrderActions from "../../redux/modules/serviceOrders/serviceOrderActions";
import { createLoadingSelector } from '../../redux/modules/loading/loadingSelectors';
import serviceOrderConstants from '../../redux/modules/serviceOrders/serviceOrderConstants';
import { makeGetServiceOrdersByPathParams } from '../../redux/modules/serviceOrders/serviceOrderSelectors';

const isServiceOrdersLoading = createLoadingSelector(serviceOrderConstants.GET_SERVICEORDERS);
const getServiceOrdersByPathParams = makeGetServiceOrdersByPathParams();

function mapStateToProps(state, props) {
  return {
    isLoading: isServiceOrdersLoading(state),
    serviceOrders: getServiceOrdersByPathParams(state, props),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    serviceOrderActions: bindActionCreators(serviceOrderActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceOrderDetails);
