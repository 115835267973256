import { connect } from 'react-redux';
import { MapWrapper } from './MapWrapper';
import {
  getSelectedServiceList,
  getSelectedServiceOrder,
} from '../../redux/modules/serviceOrders/serviceOrderSelectors';
import { getActiveObject } from '../../redux/modules/map/mapSelectors';
import { IRootState } from '../../redux/state';
import { ICompanySettings } from '../../models';

function mapStateToProps(state: IRootState) {
  return {
    selectedOrder: state.order.selected,
    selectedRouteStop: state.orderDetails.selectedRouteStop,
    vehicles: state.vehicleTracking.vehicles,
    location: state.router.location,
    selectedServiceList: getSelectedServiceList(state),
    selectedServiceOrder: getSelectedServiceOrder(state),
    activeObject: getActiveObject(state),
    token: state.geodatatoken.token,
    companySettings:
      state.settings.companySettings?.find(
        (settings: ICompanySettings) =>
          settings.companyId === Number(state.authentication.customerId)
      ) || null,
  };
}

export default connect(mapStateToProps, null, null, { withRef: true })(
  MapWrapper
);
