import React from 'react';
import PropTypes from 'prop-types';
import WorklistItemTooltip from './WorklistItemTooltip';
import { Marker } from 'react-leaflet';
import { clamp } from '../../shared/mathUtility';
import { renderToStaticMarkup } from 'react-dom/server';
import { divIcon } from 'leaflet';
import './worklistItemMarker.scss';

function getSize(zoomLevel) {
  const diameter = 40 - 2 * (17 - zoomLevel);
  const clampedDiameter = clamp(diameter, 20, 40);

  return clampedDiameter;
}

function getIcon(label, zoomLevel, color) {
  const size = getSize(zoomLevel);
  const iconHtml = renderToStaticMarkup(
    <svg viewBox="-50 -50 100 100" >
      <circle r="50" fill={color}></circle>
      <text fontSize="50" fill="white" dy=".35em" textAnchor="middle">
        {label || 'X'}
      </text>
    </svg>
  );

  return divIcon({
    html: iconHtml,
    className: 'worklist-item-marker',
    iconSize: [size, size],
  })
}

function WorklistItemMarker({
  id,
  name,
  position,
  zoomLevel,
  label,
  color,
}) {
  return (
    <Marker
      position={position}
      icon={getIcon(label, zoomLevel, color)}
    >
      <WorklistItemTooltip
        id={id}
        name={name}
      />
    </Marker>
  );
}

WorklistItemMarker.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  position: PropTypes.array.isRequired,
  zoomLevel: PropTypes.number.isRequired,
  label: PropTypes.string,
  color: PropTypes.string,
};

WorklistItemMarker.defaultProps = {
  color: 'green',
};

export default React.memo(WorklistItemMarker);
