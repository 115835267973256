import contractorConstants from './contractorConstants';

const initialState = {
  contractors: {},
}

export default function contractorsReducer(state = initialState, action) {
  switch (action.type) {
    case contractorConstants.GET_CONTRACTORS_SUCCESS:
      return {
        contractors: {
          ...state.contractors,
          [action.customerId]: action.contractors,
        },
      }
    case contractorConstants.GET_CONTRACTORS_FAILURE:
      return {
        ...state,
        error: action.error,
      }
    default:
      return state;
  }
}
