import { getDataButtonValues } from './dataButtonUtility';
import parser from 'fast-xml-parser';

function getLogData(details) {
  if (!details || !details.logData) {
    return null;
  }

  const logData = parser.parse(details.logData);
  return logData && logData.Logdata && logData.Logdata.Log;
}

function getPictures(logData) {
  const pictureUrls = logData.PicURL;
  return pictureUrls && pictureUrls.split('|');
}

function getArrivedTime(logData) {
  return (logData && logData.ArrivedTime) || '';
}

function trimMessageId(message) {
  return message.substring(message.indexOf(':') + 1);
}

function getDeviations(logData) {
  if (!logData || !logData.UnitDeviation) {
    return null;
  }

  if (Array.isArray(logData.UnitDeviation)) {
    return logData.UnitDeviation.map(trimMessageId);
  }

  return [trimMessageId(logData.UnitDeviation)];
}

export function getAddress(agreementLine) {
  if (!agreementLine) return '';

  return (
    (agreementLine &&
      agreementLine.place &&
      (agreementLine.place.name || agreementLine.place.address)) ||
    (agreementLine.agreement && agreementLine.agreement.estate) ||
    ''
  );
}

export function extractRouteStopDetails(routeStop, dataButtons) {
  if (!routeStop) {
    return null;
  }

  const details = routeStop.reportDetailsView;
  const time = details && details.time && new Date(details.time);

  const agreementLine = routeStop.agreementLine;
  const address = getAddress(agreementLine);

  const unit = agreementLine && agreementLine.unit && agreementLine.unit.name;

  const executed = (details && details.message) || '-';

  const vehicleId = details && details.vehicleId;

  const logData = getLogData(details);
  const driver = logData && logData.DriverId;
  const comment = logData && String(logData.Comment);
  const pictures = logData && getPictures(logData);
  const arrivedTime = logData && getArrivedTime(logData);

  const unitDeviations = getDeviations(logData);
  const hasDeviation = !!unitDeviations && unitDeviations.length > 0;

  const externalOrderId = details && details.externalOrderId;

  const agreementId =
    routeStop.agreementLine && routeStop.agreementLine.agreementId;

  const dataButtonValues = logData && getDataButtonValues(logData, dataButtons);

  const routeLine = routeStop.routeLine;

  const sequence = routeLine && routeLine.sequence ? routeLine.sequence : '';

  const companyName =
    agreementLine &&
    agreementLine.agreement &&
    agreementLine.agreement.companyName;

  return {
    time,
    arrivedTime,
    address,
    unit,
    executed,
    deviation: hasDeviation,
    vehicleId,
    driver,
    routeStop,
    unitDeviations,
    pictures,
    agreementId,
    comment,
    externalOrderId,
    dataButtonValues,
    companyName,
    sequence,
  };
}

export function extractRouteStopDetailsForStatistics(routeStop, dataButtons) {
  if (!routeStop) {
    return null;
  }

  const details = routeStop.reportDetailsView;

  const executed = (details && details.message) || '-';

  const logData = getLogData(details);

  const unitDeviations = getDeviations(logData);

  const dataButtonValues = logData && getDataButtonValues(logData, dataButtons);

  return {
    executed,
    unitDeviations,
    dataButtonValues,
  };
}
