import cacheRefresherConstants from './cacheRefresherConstants';
import { autoRefreshInterval } from "../../../appConfig";

function startCacheRefreshTimer() {
  return (dispatch) => {
    dispatch({
      type: cacheRefresherConstants.START_CACHE_REFRESH_TIMER,
      countdownEnd: Date.now() + autoRefreshInterval,
    });
  };
}

function stopCacheRefreshTimer() {
  return { 
    type: cacheRefresherConstants.STOP_CACHE_REFRESH_TIMER,
  };
}

function setCacheRefreshFilter(filter) {
  return { 
    type: cacheRefresherConstants.SET_CACHE_REFRESH_FILTER,
    filter,
  };
}

function addRequestInProgress(request) {
  return { 
    type: cacheRefresherConstants.ADD_REQUEST_IN_PROGRESS_CACHE_REFRESH,
    request,
  };
}

function removeRequestInProgress(request) {
  return { 
    type: cacheRefresherConstants.REMOVE_REQUEST_IN_PROGRESS_CACHE_REFRESH,
    request,
  };
}

export default {
  startCacheRefreshTimer,
  stopCacheRefreshTimer,
  setCacheRefreshFilter,
  addRequestInProgress,
  removeRequestInProgress,
};
