import { orderDetailsGridPageSize } from '../../../appConfig';
import orderDetailsConstants from './orderDetailsConstants';

const initialState = {
  filterData: {
    sort: [{ field: 'time', dir: 'desc' }],
    take: orderDetailsGridPageSize,
    skip: 0,
  },
  filterDatas: [],
  selectedRouteStop: null,
};

export default function orderDetails(state = initialState, action) {
  switch (action.type) {
    case orderDetailsConstants.SET_SELECTED_ROUTE_STOP_SUCCESS:
      return {
        ...state,
        selectedRouteStop: action.routeStop,
      };
    case orderDetailsConstants.SET_ORDER_DETAILS_GRID_FILTER:
      return {
        ...state,
        filterData: action.filterData,
      };
    case orderDetailsConstants.SET_ORDER_DETAILS_GRID_FILTERS:
      return {
        ...state,
        filterDatas: action.filterDatas,
      };
    default:
      return state;
  }
}
