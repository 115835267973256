import orderDetailsConstants from './orderDetailsConstants';

function setSelectedRouteStop(routeStop) {
  return (dispatch) => {
    dispatch(success(routeStop));
  };

  function success(routeStop) {
    return {
      type: orderDetailsConstants.SET_SELECTED_ROUTE_STOP_SUCCESS,
      routeStop,
    };
  }
}

function setOrderDetailsGridFilter(filterData) {
  return (dispatch) => {
    dispatch(success(filterData));
  };

  function success(filterData) {
    return {
      type: orderDetailsConstants.SET_ORDER_DETAILS_GRID_FILTER,
      filterData,
    };
  }
}

function setOrderDetailsGridFilters(filterDatas) {
  return (dispatch) => {
    dispatch(success(filterDatas));
  };

  function success(filterDatas) {
    return {
      type: orderDetailsConstants.SET_ORDER_DETAILS_GRID_FILTERS,
      filterDatas,
    };
  }
}

export default {
  setSelectedRouteStop,
  setOrderDetailsGridFilter,
  setOrderDetailsGridFilters,
};
