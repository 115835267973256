import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import Vehicles from './Vehicles';
import { IRootState } from '../../redux/state';
import { IUser } from '../../models';
import customerActions from '../../redux/modules/customer/customerActions';
import contractorActions from '../../redux/modules/contractor/contractorActions';
import { fetchVehicles } from '../../redux/modules/vehicles/vehiclesCreators';
import { IVehiclesState } from '../../redux/modules/vehicles/vehiclesState';

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getContractors: contractorActions.getContractors,
      getCustomers: customerActions.getCustomers,
      fetchVehicles: fetchVehicles,
    },
    dispatch
  );
}

function mapStateToProps(state: IRootState) {
  return {
    impersonatedUser: state.authentication.impersonatedUser,
    customers: state.customer.customers,
    authentication: state.authentication as unknown as IUser,
    vehicles: state.vehicles as IVehiclesState,
  };
}
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(Vehicles);
