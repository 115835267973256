import { MapLayer, MapLayerProps, withLeaflet } from 'react-leaflet';
import * as esri from 'esri-leaflet';
import { RefObject } from 'react';

export interface IDynamicMapLayerProps extends MapLayerProps {
  url: string;
  f?: string;
  ref?: RefObject<MapLayer>;
}
export class DynamicMapLayer extends MapLayer<
  IDynamicMapLayerProps,
  esri.DynamicMapLayer
> {
  public createLeafletElement(
    props: IDynamicMapLayerProps
  ): esri.DynamicMapLayer {
    return esri.dynamicMapLayer({ ...this.getOptions(props) });
  }

  public updateLeafletElement(
    fromProps: IDynamicMapLayerProps,
    toProps: IDynamicMapLayerProps
  ): void {
    super.updateLeafletElement(fromProps, toProps);
    if (toProps.url !== fromProps.url) {
      this.leafletElement.setUrl(toProps.url);
    }
  }
}

export default withLeaflet(DynamicMapLayer);
