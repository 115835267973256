import { mapServiceSettings, mapServiceUrls } from '../appConfig';
import api from '../services/mapServiceClient';
import {
  ITrackInfo,
  ITrackInfoDisplay,
  ITrackInfoLines,
  ITrackInfoPolyLine,
} from '../models';
import { IAxiosCacheAdapterOptions } from 'axios-cache-adapter';
import moment from 'moment';
import { cloneDeep, groupBy } from 'lodash';
import { MapUtility } from '../components/map/MapUtility';

export default abstract class TrackInfoService {
  public static async getTrackInfo(
    ignoreCache = false,
    orderId: number
  ): Promise<Array<ITrackInfo>> {
    try {
      const url = mapServiceUrls.baseUrl + '/trackinfo';
      const axiosCacheConfig: IAxiosCacheAdapterOptions = {
        maxAge: mapServiceSettings.trackingLinesRequestCacheInMilliseconds,
        ignoreCache,
      };
      const response = await api.get(url, {
        cache: axiosCacheConfig,
        params: {
          orderId: orderId,
        },
      });

      if (response.status === 200 && response.data?.length) {
        const result: Array<ITrackInfo> = response.data.map(
          (trackInfo: ITrackInfo) => {
            const obj = {
              id: trackInfo.id,
              latitude: trackInfo.latitude,
              longitude: trackInfo.longitude,
              accuracy:
                Math.round((trackInfo.accuracy + Number.EPSILON) * 100) / 100,
              speed: Math.floor(trackInfo.speed * 3.6),
              clientSoftwareVersion: trackInfo.clientSoftwareVersion,
              orderId: trackInfo.orderId,
              username: trackInfo.username,
              createdAtUtc: new Date(trackInfo.createdAtUtc),
            };
            return obj;
          }
        );

        const trackInfo = result.filter(
          (info: ITrackInfo) => info.latitude && info.longitude
        );

        return trackInfo;
      } else {
        return [];
      }
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  public static convertTrackInfoToTrackInfoLines(
    trackInfos: Array<ITrackInfo>
  ): ITrackInfoLines {
    const orderAndDriverLinked: Array<ITrackInfo> = trackInfos
      .filter((i) => i.orderId && i.username)
      .slice(0, 3000);
    const driverGrouped = groupBy(orderAndDriverLinked, 'username');
    const propertyArranged: ITrackInfoLines = {};
    Object.keys(driverGrouped).map((key: string) => {
      const lines = driverGrouped[key].map((i: ITrackInfoDisplay) => {
        return {
          id: i.id,
          latitude: i.latitude,
          longitude: i.longitude,
          accuracy: i.accuracy,
          speed: i.speed,
          clientSoftwareVersion: i.clientSoftwareVersion,
          orderId: i.orderId,
          username: i.username,
          createdAtUtc: i.createdAtUtc,
        };
      });

      propertyArranged[key] = {
        polyLines: this.distributeLinesByArea(
          lines,
          mapServiceSettings.minTrackInfoLinePointDistance,
          mapServiceSettings.maxTrackInfoLinePointDistance
        ),
        boundaries: this.getBoundaries(lines),
      };
    });
    return propertyArranged;
  }

  private static distributeLinesByArea(
    lines: Array<ITrackInfoDisplay>,
    minDistanceInMeters: number = 0,
    maxDistanceInMeters: number = 150
  ): Array<ITrackInfoPolyLine> {
    const result = [];
    const currentLine: ITrackInfoPolyLine = {
      lines: [],
      boundaries: null,
    };

    lines.forEach((line, index) => {
      const originalLineCopy = { ...line };
      if (index === 0) {
        currentLine.lines.push(originalLineCopy);
      } else {
        const distance = MapUtility.getDistanceInMeters(
          lines[index - 1].latitude,
          lines[index - 1].longitude,
          line.latitude,
          line.longitude
        );
        if (
          distance >= minDistanceInMeters &&
          distance <= maxDistanceInMeters
        ) {
          currentLine.lines.push(originalLineCopy);
        } else {
          currentLine.boundaries = this.getBoundaries(currentLine.lines);
          if (currentLine.lines.length > 1) {
            result.push(cloneDeep(currentLine));
          }
          currentLine.lines = [];
          currentLine.lines.push(originalLineCopy);
        }
      }
    });
    currentLine.boundaries = this.getBoundaries(currentLine.lines);
    if (currentLine.lines.length > 1) {
      result.push(cloneDeep(currentLine));
    }
    return result.map((r) => {
      r.lines = r.lines.map((l) => {
        l.displayDate = moment(l.createdAtUtc).format('DD.MM.YYYY HH:mm:ss');
        return l;
      });
      return r;
    });
  }

  private static getBoundaries(lines: Array<ITrackInfoDisplay>) {
    const minLat = Math.min(...lines.map((i) => i.latitude));
    const maxLat = Math.max(...lines.map((i) => i.latitude));
    const minLng = Math.min(...lines.map((i) => i.longitude));
    const maxLng = Math.max(...lines.map((i) => i.longitude));

    return {
      minLat,
      maxLat,
      minLng,
      maxLng,
    };
  }
}
