export enum SettingsConstants {
  GET_COMPANY_SETTINGS_REQUEST = 'GET_COMPANY_SETTINGS_REQUEST',
  GET_COMPANY_SETTINGS_SUCCESS = 'GET_COMPANY_SETTINGS_SUCCESS',
  GET_COMPANY_SETTINGS_FAILURE = 'GET_COMPANY_SETTINGS_FAILURE',
  GET_DATABUTTONS_REQUEST = 'GET_DATABUTTONS_REQUEST',
  GET_DATABUTTONS_SUCCESS = 'GET_DATABUTTONS_SUCCESS',
  GET_DATABUTTONS_FAILURE = 'GET_DATABUTTONS_FAILURE',
  GET_VISIBLE_FIELDS_REQUEST = 'GET_VISIBLE_FIELDS_REQUEST',
  GET_VISIBLE_FIELDS_SUCCESS = 'GET_VISIBLE_FIELDS_SUCCESS',
  GET_VISIBLE_FIELDS_FAILURE = 'GET_VISIBLE_FIELDS_FAILURE',
}
