import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ZoomManager from '../zoomManager';

function mapVehicleToCoordinates(vehicle) {
  return [vehicle.latitude, vehicle.longitude];
}

function vehiclesToCoordinates(vehicles) {
  return vehicles
    ? vehicles.map(mapVehicleToCoordinates)
    : [];
}

function VehicleZoomManager({ vehicles }) {
  return (
    <ZoomManager
      name="VEHICLES"
      isActive={true}
      objectId={vehicles}
      priority={1}
      positions={vehiclesToCoordinates(vehicles)}
      ignoreUpdate={true}
    />
  );
}

VehicleZoomManager.propTypes = {
  vehicles: PropTypes.array,
};

function mapStateToProps(state) {
  return {
    vehicles: state.vehicleTracking.vehicles,
  }
}

export default connect(mapStateToProps)(VehicleZoomManager);
