import moment from 'moment';

function getLocalDateTime(dateTimeString) {
  const utcTime = moment.utc(dateTimeString, 'YYYY-MM-DDThh:mm:ss.SSS');
  return utcTime.local().format();
}

function extendVehiclesWithLocalTime(vehicles) {
  for (let vehicle of vehicles) {
    vehicle.updatedAtLocal = getLocalDateTime(vehicle.updatedAtUTC);
  }
}

const service = {
  extendVehiclesWithLocalTime,
}

export default service;
