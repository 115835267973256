import React from 'react';
import PropTypes from 'prop-types';
import { faCaretLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import './paneHeadline.scss';

function PaneHeadline({
  titleIcon,
  titleText,
  backUrl,
  backTitle,
  className,
  children,
}) {
  const paneClassName = classNames(className, 'pane-headline');

  return (
    <div className={paneClassName}>
      <div className="title-col">
        <h2 title={titleText}>
          <FontAwesomeIcon icon={titleIcon} />
          {titleText}
        </h2>
      </div>
      {children && <div className="pane-content">{children}</div>}
      {backUrl && backTitle && (
        <Link to={backUrl}>
          <button className="btn btn-outline-dark back-nav-button">
            <FontAwesomeIcon icon={faCaretLeft} /> {backTitle}
          </button>
        </Link>
      )}
    </div>
  );
}

PaneHeadline.propTypes = {
  titleIcon: PropTypes.any.isRequired,
  titleText: PropTypes.string.isRequired,
  backUrl: PropTypes.string,
  backTitle: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
};

export default PaneHeadline;
