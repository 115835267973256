import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { faSearchLocation } from '@fortawesome/free-solid-svg-icons';
import RouteStopDetailsGrid from './RouteStopDetailsGrid';
import './routeStopDetails.scss';
import PaneHeadline from '../shared/paneHeadline';
import routes from '../../common/routes';

class RouteStopDetails extends Component {
  static paramKeyRouteLineId = 'routeLineId';
  static paramKeyPASystem = 'paSystem';
  static paramKeyAgreementLineId = 'agreementLineId';

  static propTypes = {
    routeStop: PropTypes.object,
    match: PropTypes.object.isRequired,
    orderActions: PropTypes.object.isRequired,
    orderDetailsActions: PropTypes.object.isRequired,
    order: PropTypes.object,
  }

  componentDidMount() {
    const { order, routeStop } = this.props;
    this.setSelectedOrder(order);
    this.setSelectedRouteStop(routeStop);

    this.getOrder();
  }

  componentDidUpdate(prevProps) {
    const { order, routeStop } = this.props;
    if (!prevProps.order) {
      this.setSelectedOrder(order);
    }
    if (!prevProps.routeStop) {
      this.setSelectedRouteStop(routeStop);
    }
  }

  setSelectedOrder(order) {
    if (order) {
      this.props.orderActions.setSelectedOrder(order);
    }
  }

  setSelectedRouteStop(routeStop) {
    if (routeStop) {
      this.props.orderDetailsActions.setSelectedRouteStop(routeStop);
    }
  }

  getOrder() {
    const {
      match,
      orderActions,
    } = this.props;

    const orderId = parseInt(match.params.orderId, 10);
    orderActions.getOrderDetails(orderId);
  }

  render() {
    const { routeStop, match } = this.props;
    const backUrl = match.url.substring(0, match.url.indexOf('/' + routes.routeStopDetails.base))

    const agreementLine = routeStop
      && routeStop.agreementLine;
    const address = agreementLine
      && agreementLine.place
      && agreementLine.place.name
      || '';

    return (
      <div className="route-stop-details-view">
        <PaneHeadline
          titleText={address}
          titleIcon={faSearchLocation}
          backUrl={backUrl}
          backTitle="Ordredetaljer"
        />
        <RouteStopDetailsGrid routeStop={routeStop} />
      </div>
    );
  }
}

export default RouteStopDetails;
