import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';

class CacheRefreshCounter extends Component {
  state = {
    countdown: null,
  }

  componentDidMount() {
    this.setUpCountdown();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.countdownEnd !== this.props.countdownEnd) {
      this.setUpCountdown();
    }
  }

  componentWillUnmount() {
    this.clearCountdown();
  }

  setUpCountdown() {
    const { countdownEnd } = this.props;
    this.clearCountdown();

    const now = Date.now();
    if (countdownEnd > now) {
      this.updateCountdown();
      this.intervalId = setInterval(this.updateCountdown.bind(this), 1000);
    } else {
      this.setState({ countdown: null });
    }
  }

  updateCountdown() {
    const { countdownEnd } = this.props;
    const now = Date.now();
    const remainingSeconds = Math.floor((countdownEnd - now) / 1000);

    if (remainingSeconds >= 0) {
      this.setState({ countdown: remainingSeconds });
    } else {
      this.clearCountdown();
    }
  }

  clearCountdown() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  render() {
    const { countdown } = this.state;
    if (countdown == null) {
      return null;
    }

    return (
      <div className="auto-refresh-timer">
        {countdown > 0 &&
          <span>{countdown}</span>
        }
        {countdown === 0 &&
          <FontAwesomeIcon
            icon={faSyncAlt}
            className="cache-refresh-loading-icon"
          />
        }
      </div>
    );
  }
}

CacheRefreshCounter.propTypes = {
  countdownEnd: PropTypes.number.isRequired,
}

function mapStateToProps(state) {
  return {
    countdownEnd: state.cacheRefresher.countdownEnd,
  }
}

export default connect(mapStateToProps)(CacheRefreshCounter);
