import { ICompanySettings } from './models';

const companySettings: Array<ICompanySettings> = [
  {
    companyId: 5, //Fieldata AS
    trackingLineLayer: {
      visible: false,
      enabled: false,
    },
    WMSLayer: {
      visible: true,
      enabled: true,
      preSelectedWMSLayers: [''].join(','),
      nameStartsWithFilter: 'Veivedlikehold',
    },
    trackInfoLayer: {
      visible: false,
      enabled: true,
    },
    trackInfoLineLayer: {
      visible: false,
      enabled: true,
    },
  },
  {
    companyId: 268, //Stavanger
    trackingLineLayer: {
      visible: false,
      enabled: false,
    },
    WMSLayer: {
      visible: true,
      enabled: true,
      preSelectedWMSLayers: [''].join(','),
      nameStartsWithFilter: 'Veivedlikehold',
    },
    trackInfoLayer: {
      visible: false,
      enabled: true,
    },
    trackInfoLineLayer: {
      visible: false,
      enabled: true,
    },
  },
];

export default companySettings;
