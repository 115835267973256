import React, { Component, ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faCog } from '@fortawesome/free-solid-svg-icons';

export interface ILinkCellProps {
  linkToUrl: string;
  icon?: IconDefinition;
  className?: boolean;
}

export default class LinkCell extends Component<ILinkCellProps> {
  public render(): ReactNode {
    const { linkToUrl, icon = faCog, className } = this.props;
    return (
      <td className={`link-button ${className}`}>
        <Link to={linkToUrl}>
          <FontAwesomeIcon icon={icon} />
        </Link>
      </td>
    );
  }
}
