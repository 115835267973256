const OrderItemTypes = {
  unknown: 0,
  in: 1,
  out: 2,
  replace: 3,
  disposal: 4,
  rep: 5,
  bef: 6,
  inspection: 7,
  service: 8,
}

export default OrderItemTypes;
