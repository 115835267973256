import wasteManagementClient from './../services/wasteManagementClient';
import { wasteManagementUrls } from '../appConfig';
import { locale } from '../common/localization';
import { IAuth, IUser } from '../models';
import { Auth0ContextInterface, User, LogoutOptions } from '@auth0/auth0-react';

export abstract class AuthService {

  public static async loginWithAuth0(user: IAuth): Promise<IUser> {
    try {
      const response = await wasteManagementClient.get(`${wasteManagementUrls.auth0Auth}?userName=${user.username}`);

      console.log(response);

      response.data.token = user.password;

      const { token, customerId, contractorId, isAdmin, isLocalAdmin, impersonatedUser } =
        response.data;
      if (!token) {
        throw new Error('Response is missing token parameter');
      }
      return {
        name: user.username,
        token,
        customerId,
        contractorId,
        isAdmin,
        isLocalAdmin,
        impersonatedUser
      };
    } catch (error) {
      if (error.response.status === 401) {
        throw new Error(locale.login._usernamePasswordInvalid);
      } else {
        throw new Error('Unsuccessful attempt');
      }
    }
  }

  public static logout(auth0: Auth0ContextInterface<User>): void {
    const logoutParams: LogoutOptions = { logoutParams: { returnTo: window.location.origin }};
    try {
      auth0.logout(logoutParams);
      localStorage.clear();
    } catch (error) {
      console.error(error);
    }
  }
}
