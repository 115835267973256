import React, { ReactElement, useEffect, useState } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { locale } from '../../common/localization';
import {
  IContractor,
  ICustomer,
  IUser,
  IVehicle,
  SelectOption,
} from '../../models';
import {
  copyVehicleSettings,
  filterVehicles,
  getVehicleDropdownOptions,
} from '../vehicles/vehicleUtils';
import Select from 'react-select';
import { Toastr } from '../../utils/Toastr';
import { connect } from 'react-redux';
import { IRootState } from '../../redux/state';
import { bindActionCreators, Dispatch } from 'redux';
import { fetchVehicles } from '../../redux/modules/vehicles/vehiclesCreators';
import customerActions from '../../redux/modules/customer/customerActions';
import contractorActions from '../../redux/modules/contractor/contractorActions';
import { isNil } from 'lodash';
import ContractorSelector from '../userAdmin/ContractorSelector';
import CustomerSelector from '../userAdmin/CustomerSelector';

interface IVehicleSettingCopyFromDialogProps {
  show: boolean;
  onHide: (rerender: boolean) => void;
  targetVehicle: IVehicle;
  vehiclesList: Array<IVehicle>;
  fetchVehicles: (ignoreCache?: boolean) => Promise<void>;
  customers: Array<ICustomer>;
  authentication: IUser;
  getContractors: (
    customerId: number,
    ignoreCache?: boolean
  ) => Promise<Record<string, any>>;
  getCustomers: () => void;
}

const VehicleSettingCopyFromDialog = (
  props: IVehicleSettingCopyFromDialogProps
): ReactElement => {
  const {
    show,
    targetVehicle,
    vehiclesList,
    authentication,
    customers,
    onHide,
    fetchVehicles,
    getCustomers,
    getContractors,
  } = props;
  const { isAdmin, contractorId } = authentication;

  const [vehicles, setVehicles] = useState<Array<IVehicle>>([]);
  const [vehicleOptions, setVehicleOptions] = useState<Array<SelectOption>>([]);
  const [selectedOption, setSelectedOption] = useState<SelectOption | null>(
    null
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [contractorsList, setContractorsList] = useState<Array<IContractor>>(
    []
  );
  const [selectedContractorId, setSelectedContractorId] = useState<number>(
    targetVehicle.contractorId ?? -1
  );
  const [selectedCustomerId, setSelectedCustomerId] = useState<number>(
    targetVehicle.customerId ?? -1
  );

  const loadVehicles = (customerId: number, contractorId: number): void => {
    setSelectedOption(null);
    const vehicles = contractorId
      ? filterVehicles(vehiclesList, { customerId, contractorId })
      : filterVehicles(vehiclesList, { customerId });
    setVehicles(vehicles);
  };

  const handleVehicleChange = (selectedOption: SelectOption): void => {
    setSelectedOption(selectedOption);
  };

  const handleCopyFromVehicle = async (): Promise<void> => {
    try {
      setIsLoading(true);
      await copyVehicleSettings(
        selectedOption.value.vehicleId,
        targetVehicle.vehicleId
      );
      Toastr.success(locale.vehicleSettings._copyFromVehicleSuccess);
      onHide(true);
    } catch (error) {
      console.error(error);
      Toastr.error(locale.vehicleSettings._copyFromVehicleError, true);
    } finally {
      setIsLoading(false);
    }
  };

  const loadCustomers = (): void => {
    if (isAdmin) {
      getCustomers();
    }
  };

  const loadContractors = async (
    customerId: number,
    isAfterCustomerChanged = false,
    ignoreCache = false
  ): Promise<void> => {
    if (!isNil(contractorId)) return new Promise((resolve) => resolve(null));
    const contractorsReq = await getContractors(customerId, ignoreCache);
    const contractors: Array<IContractor> = contractorsReq.contractors
      ? contractorsReq.contractors.sort((a: IContractor, b: IContractor) =>
          a.contractorName.localeCompare(b.contractorName)
        )
      : [];
    setContractorsList(contractors);
    if (isAfterCustomerChanged) {
      const selectedContractorId =
        (Array.isArray(contractors) && contractors[0]?.contractorId) || -1;
      setSelectedContractorId(selectedContractorId);
    }
  };

  const handleContractorChanged = (
    selectedContractorId: Record<string, any>
  ) => {
    setSelectedContractorId(selectedContractorId.value);
    loadVehicles(selectedCustomerId, selectedContractorId?.value);
  };

  const handleCustomerChanged = async (
    selectedCustomerId: Record<string, any>
  ) => {
    setSelectedCustomerId(selectedCustomerId.value);
    await loadContractors(selectedCustomerId.value, true);
    loadVehicles(selectedCustomerId?.value, selectedContractorId);
  };

  useEffect(() => {
    setVehicleOptions(getVehicleDropdownOptions(vehicles));
    if (!selectedOption && vehicles.length) {
      setSelectedOption({
        value: vehicles[0],
        label: vehicles[0].vehicleId,
      });
    }
  }, [vehicles]);

  useEffect(() => {
    fetchVehicles(false);
    const { customerId, contractorId } = props.authentication;
    loadCustomers();
    if (isNil(contractorId)) {
      loadContractors(customerId);
    }
    if (isAdmin) {
      loadVehicles(targetVehicle.customerId, targetVehicle.contractorId);
    } else {
      loadVehicles(customerId, targetVehicle.contractorId);
    }
  }, []);

  return (
    <Modal
      show={show}
      onHide={() => onHide(false)}
      className="vehicle-setting-edit-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {locale.vehicleSettings._copyFromVehicleDialogTitle}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isAdmin && isNil(contractorId) && (
          <Form.Group as={Row}>
            <Col sm={12}>
              <Form.Label>{locale.vehicleSettings._selectCustomer}:</Form.Label>
            </Col>
            <Col sm={12}>
              <CustomerSelector
                customers={customers}
                handleChange={handleCustomerChanged}
                selectedCustomerId={selectedCustomerId}
                className="mb-3"
              />
            </Col>
          </Form.Group>
        )}
        {isNil(contractorId) && (
          <Form.Group as={Row}>
            <Col sm={12}>
              <Form.Label>
                {locale.vehicleSettings._selectContractor}:
              </Form.Label>
            </Col>
            <Col sm={12}>
              <ContractorSelector
                contractors={contractorsList}
                handleChange={handleContractorChanged}
                selectedContractorId={selectedContractorId}
                className="mb-3"
              />
            </Col>
          </Form.Group>
        )}
        <Form.Group as={Row}>
          <Col sm={12}>
            <Form.Label>{locale.vehicleSettings._selectVehicle}:</Form.Label>
          </Col>
          <Col sm={12}>
            <Select
              options={vehicleOptions}
              onChange={handleVehicleChange}
              value={selectedOption}
              placeholder={locale.vehicleSettings._vehicleSelectorPlaceholder}
              noOptionsMessage={() => locale.vehicles._noVehicles}
            />
          </Col>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => onHide(false)}>
          {locale.vehicleSettings._cancel}
        </Button>
        <Button
          variant="primary"
          onClick={handleCopyFromVehicle}
          disabled={!selectedOption || isLoading}
        >
          {locale.vehicleSettings._copyButton}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state: IRootState) => ({
  vehiclesList: state.vehicles.vehiclesList,
  customers: state.customer.customers,
  authentication: state.authentication,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getContractors: contractorActions.getContractors,
      getCustomers: customerActions.getCustomers,
      fetchVehicles: fetchVehicles,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
)(VehicleSettingCopyFromDialog);
