import React from 'react';
import PropTypes from 'prop-types';

function WorklistRow({
  listName,
  worklist,
  onNameChanged,
  index,
  disabled,
}) {
  return (
    <tr>
      <td>
        <input
          name={index}
          value={listName}
          className="form-control"
          onChange={onNameChanged}
          disabled={disabled}
        />
      </td>
      <td>{worklist.length}</td>
    </tr>
  );
}

WorklistRow.propTypes = {
  listName: PropTypes.string.isRequired,
  worklist: PropTypes.array.isRequired,
  onNameChanged: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
}

WorklistRow.defaultProps = {
  listName: '',
  worklist: {},
  onNameChanged: () => { },
  index: 0,
}

export default WorklistRow;
