import loadingConstants from './loadingConstants';

function addLoadingInProgress(name) {
  return { type: loadingConstants.ADD_LOADING_IN_PROGRESS, name }
}

function removeLoadingInProgress(name) {
  return { type: loadingConstants.REMOVE_LOADING_IN_PROGRESS, name }
}

export default {
  addLoadingInProgress,
  removeLoadingInProgress,
}
