import { connect } from 'react-redux';
import ImportedRoutesLayer from './ImportedRoutesLayer';

function mapStateToProps(state) {
  return {
    importedRoutes: state.routeOptimalization.importedRoutes,
    unassignedStops: state.routeOptimalization.unassignedStops,
    location: state.router.location,
    activeTab: state.routeOptimalization.activeTab,
  }
}

export default connect(mapStateToProps)(ImportedRoutesLayer);
