import { vehicleTrackingConstants } from './vehicleTrackingConstants';

const initialState = {
  vehicles: []
};

export function vehicleTracking(state = initialState, action) {
  switch (action.type) {
    case vehicleTrackingConstants.GET_VEHICLES_SUCCESS:
      return {
        vehicles: action.vehicles
      }
    case vehicleTrackingConstants.GET_VEHICLES_FAILURE:
      return {
        ...state,
        error: action.error
      }
    default:
      return state
  }
}
