import React from 'react';
import { DetailsTooltip } from '../../shared/customMapLayer';
import PropTypes from 'prop-types';
import { faHashtag, faUserTie } from '@fortawesome/free-solid-svg-icons';

function RoutePointTooltip({ id, name }) {
    return (
      <DetailsTooltip details={[
        { icon: faHashtag, value: id },
        { icon: faUserTie, value: name },
      ]} />
    );
}

RoutePointTooltip.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
};

export default React.memo(RoutePointTooltip);
