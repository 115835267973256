import { connect } from 'react-redux';
import { IRootState } from '../../redux/state';

import  Main from './Main';

function mapStateToProps(state: IRootState) {
  const { isAdmin, isLocalAdmin } = state.authentication;
  const authentication = state.authentication;
  return {
    isAdmin,
    isLocalAdmin, 
    authentication   
  };
}

export default connect(mapStateToProps)(Main);
