import React, { Component, createRef, ReactNode, RefObject } from 'react';
import { Tooltip } from 'react-leaflet';
import { locale } from '../../common/localization';
import { IAgreementConnectionInfo } from '../../models';

import './PropertyTooltip.scss';

export interface IPropertyTooltipProps {
  info: IAgreementConnectionInfo;
  disabled: boolean;
}

export class PropertyTooltip extends Component<IPropertyTooltipProps> {
  public readonly tooltip: RefObject<Tooltip> = createRef();

  public close(): void {
    if (!this.tooltip.current) return;
    const options: any = this.tooltip.current.leafletElement.options;
    options.leaflet.map.closeTooltip(this.tooltip.current.leafletElement);
  }

  public render(): ReactNode {
    const { info, disabled } = this.props;

    if (disabled) return null;

    /** @description */
    /** this workaround is needed, because we lose
     *  reference of the original element on rerender */
    this.close();

    return (
      <Tooltip direction="top" interactive={false} ref={this.tooltip}>
        <table className="info-table">
          <tbody>
            <tr>
              <td>{locale.routeAdminPage._agreementId}</td>
              <td>{info.agreementId}</td>
            </tr>
            <tr>
              <td>{locale.routeAdminPage._customerName}</td>
              <td>{info.name}</td>
            </tr>
            <tr>
              <td>{locale.routeAdminPage._agreementGid}</td>
              <td>{info.agreementGid}</td>
            </tr>
            <tr>
              <td>{locale.routeAdminPage._placeId}</td>
              <td>{info.placeId}</td>
            </tr>
            <tr>
              <td>{locale.routeAdminPage._address}</td>
              <td>{info.placeName}</td>
            </tr>
          </tbody>
        </table>
      </Tooltip>
    );
  }
}
